import React, { useEffect, useState } from 'react'
import PreDispatchInspectionForms from './PreDispatchInspection/PreDispatchInspectionForms';
import QaAtSiteForms from './QaAtSite/QaAtSiteForms';
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Storage } from '../../../Core/Services/StorageService';

const IndexPage = () => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const [tblkey, setTblkey] = useState<any>('pre_dispatch_inspection');
  const [roleid, setRoleid] = useState<any>(0);
  const [discomid, setDiscomid] = useState<any>(0);
  const [isActiveClass, setIsActiveClass] = useState<any>('nav-link active');
  const [deActiveClass, setdeActiveClass] = useState<any>('nav-link');
  const [isActiveTabClass, setIsActiveTabClass] = useState<any>('tab-pane fade show active');
  const [deActiveTabClass, setdeActiveTabClass] = useState<any>('tab-pane fade');
  const [locationQaDataState, SetLocationQADataState] = useState<any[]>([]);
  const [locationPDIDataState, SetLocationPDIDataState] = useState<any[]>([]);

  const location = useLocation();
  
  const tabkeyChangeValue = (evt?: any) => {
    setTblkey(evt);
  };

  useEffect(() => {
    setRoleid(Storage.get('roleid'));
    setDiscomid(Storage.get('discom_id'))
}, [roleid, discomid]);

  useEffect(() => {
        (async () => {
            if (location.state) {
                if(location.state.selected == 'qa_at_site')
                {
                  setIsActiveClass('nav-link');
                  setdeActiveClass('nav-link active');
                  setIsActiveTabClass('tab-pane fade');
                  setdeActiveTabClass('tab-pane fade show active');
                  SetLocationQADataState(location.state);
                  SetLocationPDIDataState([]);
                }else{
                  SetLocationQADataState([]);
                  SetLocationPDIDataState(location.state);
                }
            }
        })();
    }, []);

  return (
    <>
    <div className="mainWrap">
      <Header />
      <section className="InnerBanner">
        <div className="container">
          <ul className="BreadCumb list-unstyled mb-0">
            <li><Link to="/" className="text-decoration-none">Home</Link></li>
            {(roleid == 5 || roleid == 6) ?
             <>
              <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
              <li><Link to="/nodal-view#QualityMonitoring" className="text-decoration-none">Quality Monitoring</Link></li>
            </>
            :
            <>
              <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
              <li><Link to="/rdss#QualityMonitoring" className="text-decoration-none">Quality Monitoring</Link></li>
            </>
            }
            <li>Material Quality Inspection</li>
          </ul>
        </div>
      </section>
      <section className="financialForm pt-2">
        <div className='container'>

          <div className="financialMainFrom">

            <ul className="nav nav-tabs row gx-0" id="financialForm" role="tablist">
              <li className="nav-item col-md-6" role="presentation">
                  <button className={isActiveClass} onClick={() => tabkeyChangeValue('pre_dispatch_inspection')} id="PreDispatchInspectionTab" data-bs-toggle="tab" data-bs-target="#PreDispatchInspection" type="button" role="tab" aria-controls="PreDispatchInspection" aria-selected="true">Pre-Dispatch Inspection</button>
                 </li>
              <li className="nav-item col-md-6" role="presentation">
                <button className={deActiveClass} onClick={() => tabkeyChangeValue('qa_at_site')} id="QaAtSiteTab"  data-bs-toggle="tab" data-bs-target="#QAatSite" type="button" role="tab" aria-controls="QAatSite" aria-selected="false">QA at Site</button>
              </li>
            </ul>

            <div className="tab-content" id="myTabContent">
              <div className={isActiveTabClass} id="PreDispatchInspection" role="tabpanel" aria-labelledby="PreDispatchInspectionTab">
                <div className="accordion" id="accordionExample">
                  <PreDispatchInspectionForms location={locationPDIDataState}  />
                </div>
              </div>
              <div className={deActiveTabClass} id="QAatSite" role="tabpanel" aria-labelledby="QaAtSiteTab">
                <div className="accordion" id="accordionExample">
                <QaAtSiteForms location={locationQaDataState} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div >
    </>
  );
}

export default IndexPage;