import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/HomePage';
import RDSS from './Pages/RDSS';
import SignIn from './Pages/SignIn';
import ForgotPassword from './Pages/ForgotPassword';
import ChangePassword from './Pages/ChangePassword';
import InputForm from './RdssForms/InputForm';
import EvaluationForm from './RdssForms/EvaluationForm';
import Dashboard from './Dashboard/Dashboard';
import DetailedDashboard from './Dashboard//DetailedDashboard';
import PqSopForm from './Components/InputForms/Section1/PqSopForm';
import NotFound from './Pages/404Page';
import FundDisbursalForm from './RdssForms/FundDisbursalForm';
import ScreenReaderAccess from './Pages/ScreenReaderAccess';
import TermsConditions from './Pages/TermsConditions';
import CopyRightPolicy from './Pages/CopyRightPolicy';
import Help from './Pages/Help';
import Disclaimer from './Pages/Disclaimer';
import Faq from './Pages/Faq';
import WebsitePolicy from './Pages/WebsitePolicy';
import NodalView from './Pages/NodalView';
import About from './Pages/About';
import ContactUs from './Pages/ContactUs';
import DetailedDashboardv2 from './Dashboard/DetailedDashboardv2';
import SiteMap from './Pages/SiteMap';
import VerificationOtp from './Pages/VerificationOtp';
import DashboardStateView from './Dashboard/DashboardStateView';
import DashboardDiscomView from './Dashboard/DashboardDiscomView';
import SmartMeteringMisReports from './Reports/SmartMeteringMisReports';
import LossReductionMisReports from './Reports/LossReductionMisReports';
import MisReports from './Reports/MisReports';
import MisCustomReports from './Reports/CustomMisReports';

import FundDisbursalMain from './Components/FundDisbursalForms/FundDisbursalMain';
import FundUtilization from './Components/FundDisbursalForms/FundUtilization';

import FundDisbursalLink from './Components/FundDisbursalForms/FundDisbursalLink';
import AuthorisedSignatoryForm from './Components/FundDisbursalForms/AuthorisedSignatoryForm';
import RequestForFundForm from './Components/FundDisbursalForms/RequestForFundForm';
import ClaimForm1 from './Components/FundDisbursalForms/ClaimForms/ClaimForm1';
import ClaimForm2 from './Components/FundDisbursalForms/ClaimForms/ClaimForm2';
import ClaimFormDistrictLink from './Components/FundDisbursalForms/ClaimForms/ClaimFormDistrictLink';
import ClaimFormAgencyLink from './Components/FundDisbursalForms/ClaimForms/ClaimFormAgencyLink';
import PhysicalProgressForm from './Components/FundDisbursalForms/PhysicalProgressForm';
import FinancialProgressForm from './Components/FundDisbursalForms/FinancialProgressForm';
import UtilisationForm from './Components/FundDisbursalForms/UtilisationForm';
import AuditCertificateForm from './Components/FundDisbursalForms/AuditCertificateForm';
import UtilisationFormYearReport from './Components/FundDisbursalForms/UtilisationFormYearReport';
import AuthorisedSignatoryFormYearReports from './Components/FundDisbursalForms/AuthorisedSignatoryFormYearReports';
import FinancialProgressFormYearReport from './Components/FundDisbursalForms/FinancialProgressFormYearReport';
import PhysicalProgressFormYearReport from './Components/FundDisbursalForms/PhysicalProgressFormYearReport';

import EnergyAccounting from './Components/EngeryAccounting/EnergyAccounting';
import MisReportView from './Reports/MisReportView';
import Monitoring from './Components/Monitoring/Monitoring';
import QuarterlyTargets from './Components/Monitoring/QuarterlyTargets/QuarterlyTargetsDetails';
import ProgressDetails from './Components/Monitoring/ProgressDetails/ProgressDetails';
import AllProgressDetails from './Components/Monitoring/ProgressDetails/AllProgressDetails';
import ConsumerDetails from './Components/Monitoring/ProgressDetails/SmartMeteringConsumer/ConsumerDetails';
import VvpHhData from './Module/VvpPvtg/Pages/VvpHhData';
import PvtgHhData from './Module/VvpPvtg/Pages/PvtgHhData';

import EA from './Pages/EA';
import GeneralInformation from './Components/EngeryAccounting/GeneralInformation';
import InfrastructureDetailsForm from './Components/EngeryAccounting/InfrastructureDetailsForm';
import DetailsReceivedSourcesForm from "./Components/EngeryAccounting/DetailsReceivedSourcesForm";

import MaterialQualityInspection from "./Components/QualityMonitoring/MaterialQualityInspection/IndexPage";
import CreateNewFormEdit from "./Components/QualityMonitoring/MaterialQualityInspection/PreDispatchInspection/CreateNewFormEdit";
import CreateNewFormQaEdit from "./Components/QualityMonitoring/MaterialQualityInspection/QaAtSite/CreateNewFormQaEdit";
import FieldWorksQualityInspection from "./Components/QualityMonitoring/FieldWorksQualityInspection/IndexPage";

import FundReleasedSmReport from "./Components/FundReleased/Reports/SmartMeteringReports";

import SmGrantReleasedReports from "./Components/FundReleased/Reports/SmartMetering/GrantReleasedReports";
import SmGrantLapsedReports from "./Components/FundReleased/Reports/SmartMetering/GrantLapsedReports";
import SmCumulativeGrantReports from "./Components/FundReleased/Reports/SmartMetering/SmCumulativeGrantReports";

import LrGrantReleasedReports from "./Components/FundReleased/Reports/LossReduction/GrantReleasedReports";
import LrGrantLapsedReports from "./Components/FundReleased/Reports/LossReduction/GrantLapsedReports";
import LrCumulativeGrantReports from "./Components/FundReleased/Reports/LossReduction/LrCumulativeGrantReports";

import NotificationsView from "./Components/Notifications/NotificationsView";
import DMSChecklist from './Module/DMSChecklist/DMSChecklist';

import {
  Csrd,
  Energy,
  Agsdp,
  Praapti,
  Krp,
  Discomir,
  QualityMonitoring,
  FeederProgress,
  CapacityBuilding
} from './Module';
import AllStateProgressView from './Dashboard/AllStateProgressView';
import AllStateProgressViewLoss from './Dashboard/AllStateProgressViewLoss';
import SmFinancialProgressView from './Dashboard/SmFinancialProgressView';
import LsFinancialProgressView from './Dashboard/LrFinancialProgressView';
import CommonTableData from './Dashboard/CommonTableData';
import SmDistrictwiseData from './Dashboard/SmDistrictwiseData';
import LsDistrictwiseData from './Dashboard/LsDistrictwiseData';
import MisAwards from './Reports/MisReports/MisAwards';
import AllStatePhysicalProgressView from './Dashboard/AllStatePhysicalProgressView';
import AllStatePhysicalProgressViewLoss from './Dashboard/AllStatePhysicalProgressViewLoss';
import AssetWise from './Reports/AssetsWise';
import FeederReports from './Reports/FeederReports';
import PvtgVvpMIS from './Reports/PvtgVvp';
import { useAuth } from './Core/Providers';
import { Storage } from './Core/Services/StorageService';

// import Mapview from './Pages/Mapview';

function IsAuth(props: any) {
  const user: any = useAuth().user();
  return (user.role_id) ? props.children : <Navigate to={'/sign-in'} />;
}

function IsDiscom({ children }: any) {

  const user: any = useAuth().user();

  const roleids = [
    process.env.REACT_APP_DDE,
    process.env.REACT_APP_DDR,
    process.env.REACT_APP_DDA
  ];

  if (roleids.includes(String(user.role_id))) {
    return children;
  }

  return <Navigate to={'/nodal-view'} />;

}

function IsNodal({ children }: any) {

  const user: any = useAuth().user();

  const roleids = [
    process.env.REACT_APP_NDR,
    process.env.REACT_APP_NDA
  ];

  if (roleids.includes(String(user.role_id))) {
    return children;
  }

  return <Navigate to={'/input-form'} />;

}

function IsRdss({ children }: any) {

  const user: any = useAuth().user();

  const roleids = [
    process.env.REACT_APP_DDE,
    process.env.REACT_APP_DDR,
    process.env.REACT_APP_DDA,
    process.env.REACT_APP_NDR,
    process.env.REACT_APP_NDA
  ];

  if (roleids.includes(String(user.role_id))) {
    return children;
  }

  return <Navigate to={'/'} />;
}

function AppRoutes() {

  const auth = useAuth();
  const location = useLocation();
  const locationModal = location.state && location.state.modal;

  useEffect(() => {

    const handleBrowserClose = () => {
      auth.logout()
        .then(() => {
          Storage.delete('roleid');
          Storage.delete('username');
        });
    };
    window.addEventListener('unload', handleBrowserClose);

    return () => {
      window.removeEventListener('unload', handleBrowserClose);
    };

  }, []);

  return (
    <>
      <Routes location={locationModal || location}>
        <Route path='/' element={<Home />} />
        {/* <Route path='/map' element={<Mapview />} /> */}
        <Route path='/rdss' element={<RDSS />} />
        <Route path='/energy/*' element={<Energy />} />
        <Route path='/agsdp/*' element={<IsAuth><Agsdp /></IsAuth>} />
        <Route path='/csrd/*' element={<IsAuth><Csrd /></IsAuth>} />
        <Route path='/krp/*' element={<IsAuth><Krp /></IsAuth>} />
        <Route path='/rdss/quality-monitoring/*' element={<IsAuth><QualityMonitoring /></IsAuth>} />
        <Route path='/discomir/*' element={<IsAuth><Discomir /></IsAuth>} />
        <Route path='/praapti/*' element={<IsAuth><Praapti /></IsAuth>} />
        <Route path='/ea' element={<IsAuth><EA /></IsAuth>} />
        <Route path='/about-us' element={<About />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/screen-reader-access' element={<ScreenReaderAccess />}></Route>
        <Route path='/sitemap' element={<SiteMap />}></Route>
        <Route path='/terms-conditions' element={<TermsConditions />}></Route>
        <Route path='/copyright-policy' element={<CopyRightPolicy />}></Route>
        <Route path='/help' element={<Help />}></Route>
        <Route path='/disclaimer' element={<Disclaimer />}></Route>
        <Route path='/website-policy' element={<WebsitePolicy />}></Route>
        <Route path='/nodal-view' element={<IsRdss><IsNodal><NodalView /></IsNodal></IsRdss>}></Route>
        <Route path='/input-form' element={<IsRdss><IsDiscom><InputForm /></IsDiscom></IsRdss>} />
        <Route path='/evaluation-form' element={<IsRdss><EvaluationForm /></IsRdss>} />

        <Route path='/fund-disbursal' element={<IsRdss><FundDisbursalMain /></IsRdss>} />
        <Route path='/fund-disbursal-link' element={<IsRdss><FundDisbursalLink /></IsRdss>} />
        <Route path='/fund-disbursal-form' element={<IsRdss><FundDisbursalForm /></IsRdss>} />
        <Route path='/authorised-signatory-form' element={<IsAuth><AuthorisedSignatoryForm /></IsAuth>} />
        <Route path='/request-for-fund-release-form' element={<IsAuth><RequestForFundForm /></IsAuth>} />
        <Route path='/claim-forms-form-1' element={<IsAuth><ClaimForm1 /></IsAuth>} />
        <Route path='/claim-forms-form-2' element={<IsAuth><ClaimForm2 /></IsAuth>} />
        <Route path='/claim-forms-district-link' element={<IsAuth><ClaimFormDistrictLink /></IsAuth>} />
        <Route path='/claim-forms-agency-link' element={<IsAuth><ClaimFormAgencyLink /></IsAuth>} />
        <Route path='/fund-utilization' element={<IsRdss><FundUtilization /></IsRdss>} />

        <Route path='/physical-progress-form' element={<IsAuth><PhysicalProgressForm /></IsAuth>} />
        <Route path='/financial-progress-form' element={<IsAuth><FinancialProgressForm /></IsAuth>} />
        <Route path='/utilisation-forms-form' element={<IsAuth><UtilisationForm /></IsAuth>} />
        <Route path='/audit-certificate-form' element={<IsAuth><AuditCertificateForm /></IsAuth>} />
        <Route path='/utilisation-form-yearly' element={<IsAuth><UtilisationFormYearReport /></IsAuth>} />
        <Route path='/authorised-signatory-form-yearly' element={<IsAuth><AuthorisedSignatoryFormYearReports /></IsAuth>} />
        <Route path='/financial-progress-form-yearly' element={<IsAuth><FinancialProgressFormYearReport /></IsAuth>} />
        <Route path='/physical-progress-form-yearly' element={<IsAuth><PhysicalProgressFormYearReport /></IsAuth>} />

        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/state-view-dashboard' element={<DashboardStateView />} />
        <Route path='/discom-view-dashboard' element={<DashboardDiscomView />} />
        <Route path='/detailed-dashboard' element={<DetailedDashboard />} />
        <Route path='/detailed-dashboard-2' element={<DetailedDashboardv2 />} />
        <Route path='/all-state-progress' element={<AllStateProgressView />} />
        <Route path='/all-state-progress-loss' element={<AllStateProgressViewLoss />} />
        <Route path='/sm-financial-progress' element={<SmFinancialProgressView />} />
        <Route path='/lr-financial-progress' element={<LsFinancialProgressView />} />
        <Route path='/common-table-data' element={<CommonTableData />} />
        <Route path='/sm-districtwise-data' element={<SmDistrictwiseData />} />
        <Route path='/ls-districtwise-data' element={<LsDistrictwiseData />} />
        <Route path='/all-state-physical-progress' element={<AllStatePhysicalProgressView />} />
        <Route path='/all-state-physical-progress-loss' element={<AllStatePhysicalProgressViewLoss />} />

        <Route path='/pqsop-form' element={<IsAuth><PqSopForm /></IsAuth>} />
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/change-password' element={<IsAuth><ChangePassword /></IsAuth>} />
        <Route path='/verification-otp' element={<VerificationOtp />} />
        <Route path='/smart-metering-mis-report' element={<SmartMeteringMisReports />} />
        <Route path='/loss-reduction-mis-report' element={<LossReductionMisReports />} />
        <Route path='/mis-report/:type?' element={<MisReports />} />
        <Route path='/miscustom-report/' element={<MisCustomReports />} />

        <Route path='/pq-sop-mis-report' element={<IsAuth><MisReportView type='pq_sop' /></IsAuth>} />
        <Route path='/cost-gbs-mis-report' element={<MisReportView type='cost_gbs' />} />
        <Route path='/progress-mis-report' element={<IsAuth><MisReportView type='progress' /></IsAuth>} />
        <Route path='/rdss-awards-mis-report' element={<MisReportView type='rdss_awards' />} />

        <Route path='/atc-loss-state-mis-report' element={<IsAuth><MisReportView type='atc_ls_states' /></IsAuth>} />
        <Route path='/atc-loss-discom-mis-report' element={<IsAuth><MisReportView type='atc_ls_discoms' /></IsAuth>} />

        <Route path='/acs-arr-state-mis-report' element={<IsAuth><MisReportView type='acs_states' /></IsAuth>} />
        <Route path='/acs-arr-discom-mis-report' element={<IsAuth><MisReportView type='acs_discoms' /></IsAuth>} />

        <Route path='/pay-rec-state-mis-report' element={<IsAuth><MisReportView type='pay_rec_states' /></IsAuth>} />
        <Route path='/pay-rec-discom-mis-report' element={<IsAuth><MisReportView type='pay_rec_discoms' /></IsAuth>} />

        <Route path='/energy-accounting' element={<IsAuth><EnergyAccounting /></IsAuth>} />
        <Route path='/general-information' element={<IsAuth><GeneralInformation /></IsAuth>} />
        <Route path='/infrastructure-details' element={<IsAuth><InfrastructureDetailsForm /></IsAuth>} />
        <Route path='/details-received-sources' element={<IsAuth><DetailsReceivedSourcesForm /></IsAuth>} />

        <Route path='/monitoring' element={<IsRdss><Monitoring /></IsRdss>} />
        <Route path='/monthly-targets' element={<IsRdss><QuarterlyTargets /></IsRdss>} />
        <Route path='/progress-details' element={<IsRdss><ProgressDetails /></IsRdss>} />
        <Route path='/feeder-progress-details' element={<IsRdss><FeederProgress /></IsRdss>} />
        <Route path='/all-progress-details' element={<IsRdss><AllProgressDetails /></IsRdss>} />
        <Route path='/sm-consumer-details' element={<IsAuth><ConsumerDetails /></IsAuth>} />
        <Route path='/vvp-hh-data' element={<IsAuth><VvpHhData /></IsAuth>} />
        <Route path='/pvtg-hh-data' element={<IsAuth><PvtgHhData /></IsAuth>} />

        <Route path='/material-quality-inspection' element={<IsAuth><MaterialQualityInspection /></IsAuth>} />
        <Route path='/material-quality-inspection-edit' element={<IsAuth><CreateNewFormEdit /></IsAuth>} />
        <Route path='/material-quality-inspection-qa-edit' element={<IsAuth><CreateNewFormQaEdit /></IsAuth>} />
        <Route path='/field-works-quality-inspection' element={<IsAuth><FieldWorksQualityInspection /></IsAuth>} />

        <Route path='/fr-sm-gr-report' element={<IsAuth><SmGrantReleasedReports /></IsAuth>} />
        <Route path='/fr-sm-gl-report' element={<IsAuth><SmGrantLapsedReports /></IsAuth>} />
        <Route path='/fr-sm-cum-grant-report' element={<IsAuth><SmCumulativeGrantReports /></IsAuth>} />
        <Route path='/fr-lr-gr-report' element={<IsAuth><LrGrantReleasedReports /></IsAuth>} />
        <Route path='/fr-lr-gl-report' element={<IsAuth><LrGrantLapsedReports /></IsAuth>} />
        <Route path='/fr-lr-cum-grant-report' element={<IsAuth><LrCumulativeGrantReports /></IsAuth>} />

        <Route path='/notifications-view/:module?' element={<IsAuth><NotificationsView /></IsAuth>} />
        <Route path='/mis-awards' element={<IsAuth><MisAwards /></IsAuth>} />

        <Route path='/dms-checklist' element={<IsAuth><DMSChecklist /></IsAuth>} />
        <Route path='/mis-custom-reports' element={<IsRdss><IsNodal><MisCustomReports /></IsNodal></IsRdss>} />
        <Route path='/asset-wise-reports' element={<AssetWise />} />
        <Route path='/feeder-wise-reports' element={<FeederReports />} />
        <Route path='/pvtg-vvp-reports' element={<IsAuth><PvtgVvpMIS /></IsAuth>} />
        <Route path='/rdss/capacity-building/*' element={<IsAuth><CapacityBuilding /></IsAuth>} />

        <Route path="*" element={<NotFound />} />

      </Routes>
      {locationModal && (
        <Routes>
          <Route path='all-state-progress' element={<AllStateProgressView />} />
          <Route path='all-state-progress-loss' element={<AllStateProgressViewLoss />} />
          <Route path='sm-financial-progress' element={<SmFinancialProgressView />} />
          <Route path='lr-financial-progress' element={<LsFinancialProgressView />} />
          <Route path='all-state-physical-progress' element={<AllStatePhysicalProgressView />} />
          <Route path='all-state-physical-progress-loss' element={<AllStatePhysicalProgressViewLoss />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
      {/* <div className="App">
            <div className='container'>
            <FormComponent />
            </div>
        </div> */}
    </>
  );
}

export default AppRoutes;