import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect } from "../../../assets/js/formbuilder";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import FieldWorksQualityInspectionForm from './FieldWorksQualityInspectionForm';
import { Http } from '../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2, FiEye } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiUpload } from 'react-icons/bi';
import swal from 'sweetalert';

import FieldComponent from "../../../FieldComponent";
import { Storage } from "../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

type SmartMeteringProps = {
    apiurl: string;
};

const IndexPage = (props?: any) => {

    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any>(0);
    const [getdistricts, setDistrictsResult] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const syear: any = useRef(null);
    const [selected, setSelected] = useState("Section1");
    const formData: any = useRef(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [gettblkey, setTblkey] = useState<any>('mo_pd_ls_general');
    const [year, setCurrentYear] = useState<any>(null);
    const [month, setCurrentMonth] = useState<any>(null);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        let currentYearValue = new Date().getFullYear();
        const month = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
        const d = new Date();
        if (d.getMonth() > 2) {
            currentYearValue++;
        }
        setCurrentYear(currentYearValue);
        let currentMonthValue = month[d.getMonth()];
        setCurrentMonth(currentMonthValue);
    }, [roleid]);

    const ChangeValueYears = (evt?: any) => {
        setCurrentYear(evt.target.value);
        setIsLoaded(true);
        setSelected("Section1");
    };

    const ChangeValueDistrict = (evt?: any) => {
        setDistricts(evt.target.value);
        setIsLoaded(true);
        setRefresh(true);
    };

    const ChangeValueMonth = (evt?: any) => {
        if (!["jan", "feb", "mar"].includes(evt.target.value)) {
            setCurrentYear((new Date().getFullYear() + 1));
        }
        setCurrentMonth(evt.target.value);
        setIsLoaded(true);
    };

    const getDistricts = () => {
        (async () => {
            if (apiurl) {
                await Http.get(apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setDistrictsResult(result.district);
                        setTimeout(() => {
                        }, 2000);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
            }
        })();
    };

    useEffect(() => {
        if (discomid) {
            setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
        }
    }, [apiurl, discomid, year, month]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        (async () => {
            getDistricts();
        })();
    }, [apiurl]);


    const saveasdraft = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districts);
        myFormData.append('year', year);
        myFormData.append('month', month);
        myFormData.append('module', gettblkey);

        Http.post('/apiv1/save-field-works-progress', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Field Works Progress Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districts);
        myFormData.append('year', year);
        myFormData.append('month', month);
        myFormData.append('module', gettblkey);

        Http.post('/apiv1/save-field-works-progress', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Field Works Progress Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    const navtab = (opt: any) => {
        setTblkey(opt);
    }


    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div><LoadingSpinner /></div>;
    } else {
        return (
            <>
            <Header />
            <div className="mainWrap">
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 5 || roleid == 6) ?
                                <>
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                                <li><Link to="/nodal-view#QualityMonitoring" className="text-decoration-none">Quality Monitoring</Link></li>
                                </>
                                :
                                <>
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                <li><Link to="/rdss#QualityMonitoring" className="text-decoration-none">Quality Monitoring</Link></li>
                                </>
                            }
                            <li>Field Works Progress</li>
                        </ul>
                    </div>
                </section>
                <div className="mainWrap inputFromNew">
                    <div className="container">
                        <div className='aboutAccordioin d-block mt-5'>
                            <div className='Accordion-group mw-100'>
                                <div className='Tab-panel'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-auto text-end'>
                                            <label>Select District</label>
                                        </div>
                                        <div className='col-md-3'>
                                            {
                                                getdistricts ? <>
                                                    <select className='form-select text-start w-100' onChange={ChangeValueDistrict} name="district_id" id="district_id">
                                                        <option value={0}>Select District</option>
                                                        {
                                                            getdistricts.map((district: any) => {
                                                                const attr = { value: district.id };
                                                                return <><option {...attr}>{district.district_name}</option></>;
                                                            })
                                                        }
                                                    </select>
                                                    </>
                                                    : null
                                            }
                                        </div>
                                        <div className='col-md-auto text-end'>
                                            <label>Progress for Month</label>
                                        </div>
                                        <div className='col-md-2'>
                                            <select onChange={ChangeValueMonth} name="month" id="month" className="form-select" defaultValue={month} >
                                                <option value="apr" >Apr</option>
                                                <option value="may">May</option>
                                                <option value="jun">Jun</option>
                                                <option value="jul">Jul</option>
                                                <option value="aug">Aug</option>
                                                <option value="sep">Sep</option>
                                                <option value="oct">Oct</option>
                                                <option value="nov">Nov</option>
                                                <option value="dec">Dec</option>
                                                <option value="jan">Jan</option>
                                                <option value="feb">Feb</option>
                                                <option value="mar">Mar</option>
                                            </select>
                                        </div>
                                        <div className='col-md-auto text-end'>
                                            <label>Financial year</label>
                                        </div>
                                        <div className='col-md-2'>
                                            <select onChange={ChangeValueYears} name="year" id="year" className="form-select" value={year} >
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                            </select>
                                        </div>
                                    </div>
                                    <form noValidate onSubmit={onSubmit} ref={formData}>
                                        <section className="financialForm mt-3">
                                            <div className="financialMainFrom">
                                                <ul className="nav nav-tabs row gx-0" id="financialForm" role="tablist">
                                                </ul>
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="General" role="tabpanel" aria-labelledby="GeneralTab">
                                                        <FieldWorksQualityInspectionForm districts={districts} year={year} month={month} refresh={refresh} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="formBtnSection justify-content-between is-sticky">
                                                {districts != 0 && roleid == 2 ?
                                                    <>
                                                    <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)} >
                                                        Save as Draft
                                                    </Link>
                                                    <button className="btn btnSubmit" type="submit">Submit</button>
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </section>
                                    </form>
                                    {isLoading ? <LoadingSpinner /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </>
        );
    }
};
export default IndexPage;
