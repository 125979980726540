import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css';
import { Http } from '../../../Core/Services/HttpService';
import General from './LossReductionDetails/General';
import Substations from './LossReductionDetails/Substations';
import ItOtScada from './LossReductionDetails/ItOtScada';
import { Link } from "react-router-dom";
import swal from 'sweetalert';

// Images
import FieldComponent from "../../../FieldComponent";
import { Storage } from "../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

type SmartMeteringProps = {
    apiurl: string;
};

const LossReduction = (props?: any) => {

    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any>(0);
    const [getdistricts, setDistrictsResult] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const syear: any = useRef(null);
    const [selected, setSelected] = useState("Section1");
    const formData: any = useRef(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [gettblkey, setTblkey] = useState<any>('mo_pd_ls_general');
    const [year, setCurrentYear] = useState<any>(null);
    const [month, setCurrentMonth] = useState<any>(null);
    const [viewMode, setViewMode] = useState<any>('N');
    const [itOtFlag, setItOtFlag] = useState<boolean>(false);
    const [alertState, setAlertState] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState<any>({});

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
        setRoleid(Storage.get('roleid'));
        let currentYearValue = new Date().getFullYear();
        const month = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
        const d = new Date();
        if (d.getMonth() > 2) {
            currentYearValue++;
        }
        setCurrentYear(currentYearValue);
        let currentMonthValue = month[d.getMonth()];
        setCurrentMonth(currentMonthValue);
    }, [roleid]);

    const ChangeValueYears = (evt?: any) => {
        setCurrentYear(evt.target.value);
        setIsLoaded(true);
        setSelected("Section1");
    };

    const ChangeValueDistrict = (evt?: any) => {
        setDistricts(evt.target.value);
        setIsLoaded(true);
        // setRefresh(true);
    };

    const ChangeValueMonth = (evt?: any) => {
        // if (!["jan", "feb", "mar"].includes(evt.target.value)) {
        //     setCurrentYear((new Date().getFullYear() + 1));
        // }
        setCurrentMonth(evt.target.value);
        setIsLoaded(true);
    };

    const getDistricts = () => {
        (async () => {
            if (apiurl) {
                await Http.get(apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setDistrictsResult(result.district);
                        setTimeout(() => {
                        }, 2000);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
            }
        })();
    };

    useEffect(() => {
        if (discomid) {
            setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
        }
    }, [discomid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        (async () => {
            getDistricts();
        })();
    }, [apiurl]);

    function viewModeFlag(viewMode: any) {
        setViewMode(viewMode);
    }

    const saveasdraft = async (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districts);
        myFormData.append('year', year);
        myFormData.append('month', month);
        myFormData.append('module', gettblkey);

        //console.log('/apiv1/save-monitoring-pd-lr-new', myFormData, config);
        await Http.post('/apiv1/save-monitoring-pd-lr-new', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setResponseMessage({ title: 'Warning', type: 'info', text: res.message });
                    setAlertState(true);
                    setIsLoading(false);
                    //swal("Warning", res.message, "info");
                } else {
                    //swal("Input Form", "Data have been saved", "success");
                    setResponseMessage({ title: 'Input Form', type: 'success', text: 'Data have been saved' });
                    setAlertState(true);
                    setIsLoading(false);
                    setRefresh(true);
                }
            })
            .catch((error) => { });
    };

    const saveAsDraftItOt = async (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', '0');
        myFormData.append('year', year);
        myFormData.append('month', month);
        myFormData.append('module', gettblkey);

        await Http.post('/apiv1/save-monitoring-pd-lr-itot', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    };

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);

        let apiEndPoint = 'save-monitoring-pd-lr-new';
        if (itOtFlag) {
            apiEndPoint = 'save-monitoring-pd-lr-itot';
            setDistricts('0');
        }

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districts);
        myFormData.append('year', year);
        myFormData.append('month', month);
        myFormData.append('module', gettblkey);

        await Http.post('/apiv1/' + apiEndPoint, myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setResponseMessage({ title: 'Warning', type: 'info', text: res.message });
                    setAlertState(true);
                    setIsLoading(false);
                    //swal("Warning", res.message, "info");
                } else {
                    //swal("Input Form", "Data have been saved", "success");
                    setResponseMessage({ title: 'Input Form', type: 'success', text: 'Data have been saved' });
                    setAlertState(true);
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    };

    // useEffect(() => {
    //     setRefresh(false);
    //     if (props.refresh !== undefined && props.refresh === true) {
    //         setRefresh(true);
    //     }
    // }, [props.refresh]);

    const navtab = (opt: any) => {
        setRefresh(false);
        setTblkey(opt);
        setItOtFlag(false);
        if (opt === 'mo_pd_ls_itotscada') {
            setItOtFlag(true);
            setDistricts('0');
            setRefresh(true);
        }
    };

    useEffect(() => {
        /* Added this due to Sweet alert was not firing after success or failed to save */
        if (alertState) {
            setAlertState(false);
            swal(responseMessage?.title, responseMessage?.text, responseMessage?.type);
        }
    }, [alertState]);


    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                <div className='fYearMonth setPosition pdYearMonth'>
                    <div className='row justify-content-end align-items-center'>
                        <div style={{ "paddingRight": "0px" }} className='col-md-auto text-end'>
                            <label>FY</label>
                        </div>
                        <div className='col-md-auto'>
                            <select onChange={ChangeValueYears} name="year" id="year" className="form-select" value={year} >
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select onChange={ChangeValueMonth} name="month" id="month" className="form-select" defaultValue={month} >
                                <option value="apr">Apr</option>
                                <option value="may">May</option>
                                <option value="jun">Jun</option>
                                <option value="jul">Jul</option>
                                <option value="aug">Aug</option>
                                <option value="sep">Sep</option>
                                <option value="oct">Oct</option>
                                <option value="nov">Nov</option>
                                <option value="dec">Dec</option>
                                <option value="jan">Jan</option>
                                <option value="feb">Feb</option>
                                <option value="mar">Mar</option>
                            </select>
                        </div>
                        <div className='col-md-4'>
                            {
                                (getdistricts && !itOtFlag) ? <>
                                    <select className='form-select text-start w-100' onChange={ChangeValueDistrict} name="district_id" id="district_id">
                                        <option value={0}>Select District</option>
                                        {
                                            getdistricts.map((district: any, index: number) => {
                                                const attr = { value: district.id };
                                                return <option key={index} {...attr}>{district.district_name}</option>;
                                            })
                                        }
                                    </select>
                                </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <form id={`loss-reduction_${idRef.current}`} noValidate onSubmit={onSubmit} ref={formData}>
                    <section className="financialForm mt-3">
                        <div className="financialMainFrom">
                            <ul className="nav nav-tabs row gx-0" id="financialForm" role="tablist">
                                <li className="nav-item col-md-4" role="presentation">
                                    <button className="nav-link active" id="GeneralTab" data-bs-toggle="tab" data-bs-target="#General" type="button" role="tab" aria-controls="General" aria-selected="true" onClick={() => navtab('mo_pd_ls_general')}>General</button>
                                </li>
                                <li className="nav-item col-md-4" role="presentation">
                                    <button className="nav-link" id="SubstationsTab" data-bs-toggle="tab" data-bs-target="#Substations" type="button" role="tab" aria-controls="Substations" aria-selected="false" onClick={() => navtab('mo_pd_ls_substations')}>Substations</button>
                                </li>
                                <li className="nav-item col-md-4" role="presentation">
                                    <button className="nav-link" id="ItOtScadaTab" data-bs-toggle="tab" data-bs-target="#ItOtScada" type="button" role="tab" aria-controls="ItOtScada" aria-selected="false" onClick={() => navtab('mo_pd_ls_itotscada')} >IT/OT Initiatives and SCADA</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="General" role="tabpanel" aria-labelledby="GeneralTab">
                                    {(gettblkey === 'mo_pd_ls_general') && <General key={gettblkey} frmid={`loss-reduction_${idRef.current}`} districts={districts} year={year} month={month} refresh={refresh} viewModeFlag={viewModeFlag} module={gettblkey} />}
                                </div>
                                <div className="tab-pane fade" id="Substations" role="tabpanel" aria-labelledby="SubstationsTab">
                                    {(gettblkey === 'mo_pd_ls_substations') && <Substations key={gettblkey} frmid={`loss-reduction_${idRef.current}`} districts={districts} year={year} month={month} refresh={refresh} viewModeFlag={viewModeFlag} module={gettblkey} />}
                                </div>
                                <div className="tab-pane fade" id="ItOtScada" role="tabpanel" aria-labelledby="ItOtScadaTab">
                                    {(gettblkey === 'mo_pd_ls_itotscada') && <ItOtScada key={gettblkey} frmid={`loss-reduction_${idRef.current}`} districts={districts} year={year} month={month} refresh={refresh} viewModeFlag={viewModeFlag} module={gettblkey} />}
                                </div>
                            </div>
                        </div>
                        <div className="formBtnSection justify-content-between is-sticky">
                            {viewMode === 'N' && districts != 0 && roleid == 2 ?
                                <>
                                    <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)} >
                                        Save as Draft
                                    </Link>
                                    <button className="btn btnSubmit" type="submit">Submit</button>
                                </>
                                : (viewMode === 'N' && itOtFlag && districts == '0' && roleid == 2) ?
                                    <>
                                        <Link to="#" className="btnDraft" onClick={() => saveAsDraftItOt(1)} > Save as Draft </Link>
                                        <button className="btn btnSubmit" type="submit">Submit</button>
                                    </>
                                    : null
                            }
                        </div>
                    </section>
                </form>
                {isLoading ? <LoadingSpinner /> : null}
            </>
        );
    }
};
export default LossReduction;
