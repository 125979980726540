import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect, inputEventChecker } from "../../../../assets/js/formbuilder";
import { Http } from '../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2, FiEye } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiUpload,BiDownload } from 'react-icons/bi';
import swal from 'sweetalert';
import { useStateMounted } from "../../../../Core/Hooks";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from "../../../../Components/Header/Header";
import Footer from "../../../../Components/Footer/Footer";

// Images
import FieldComponent from "../../../../FieldComponent";
import { Storage } from "../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

type CreateNewProps = {
    apiurl: string;
    districts:any;
    refresh:any;
    months:any;
};

const Block1 = (props: any) => {

    let block1SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
      if (props.field1) {
          setHasBlock2FieldsDeletes(false);
          setBlock2fields([]);
          setNewBlock2fields(props.field1.addnew);
          setTimeout(() => {
            const fields1 = props.field1.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields1);
         }, 500);
      }
  }, [props.field1]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block1SrNo = 0;
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });
        
        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 1000);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }

    return (
        <>
        <table className="table caption-top newTableUcss">
            <thead className='text-center'>
                    <tr>
                    <th rowSpan={2} style={{width:50}}>Sl No.</th>
                    <th rowSpan={2} style={{minWidth:120}}>Date of Testing</th>
                    <th rowSpan={2} style={{minWidth:200}}>Name of Material</th>
                    <th rowSpan={2} style={{minWidth:200}}>Details of Material Inspected</th>
                    <th rowSpan={2} style={{minWidth:150}}>Rating/Capacity/Size</th>
                    <th rowSpan={2} style={{minWidth:150}}>Name of Manufacturer</th>
                    <th rowSpan={2} style={{minWidth:150}}>Name of Turnkey Conductor</th>
                    <th rowSpan={2} style={{minWidth:150}}>Name of Supplier</th>
                    <th rowSpan={2} style={{minWidth:150}}>Testing location </th>
                    <th colSpan={3} className="border-end noPadding"><span>
                            Name & Designation of official(s) witnessed during Testing & Inspection</span>
                    </th>
                    <th rowSpan={2} style={{minWidth:120}}>Whether the sample material tested is as per respective Indian Standard (IS)</th>
                    <th rowSpan={2} style={{minWidth:120}}>Whether material is Accepted/Rejected </th>
                    <th rowSpan={2} style={{minWidth:150}}>LOT Number</th>
                    <th rowSpan={2} style={{minWidth:120}}>Sample material Unique Identification Number</th>
                    <th rowSpan={2} style={{minWidth:50}}>Lot Size</th>
                    <th colSpan={2} className="border-end noPadding">Whether the sample tested is Passed or Failed</th>
                    <th rowSpan={2} style={{minWidth:120}}>Whether Lot is Accepted/Rejected by Discom/PD</th>
                    <th rowSpan={2} style={{minWidth:120}}>Remarks</th>
                    {props.act != 'view' ?
                        <th rowSpan={2} className='text-end pe-1' style={{ width: "30px" }}>
                                <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                        </th>
                       :null
                    }
                </tr>
                <tr>
                    <th className="fw-normal" style={{minWidth:120}}>Discom officials</th>
                    <th className="fw-normal" style={{minWidth:120}}>TKC Officials</th>
                    <th className="fw-normal" style={{minWidth:120}}>Manufacturer/ Supplier Officials</th>
                    <th className="fw-normal" style={{minWidth:120}}>First Sample</th>
                    <th className="fw-normal" style={{minWidth:120}}>Second Sample</th>
                </tr>
            </thead>
            <tbody>
                {
                    (block1Fields.length)
                        ? block1Fields.map((item: any, key: number) => {
                            return (item.delete === false) ? <>
                                <tr key={key}>
                                    <td className="text-center" >{(block1SrNo++) + 1}</td>
                                    {
                                        item.fields.map((field: any, fkey: number) => {
                                            return <>
                                                <td key={key + '_' + fkey}>
                                                    <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                                </>;
                                        })
                                    }
                                    {props.act != 'view' ?
                                       <td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                    :null
                                    }
                                </tr>
                                </>
                                : null;
                        })
                        : null
                }

            </tbody>
        </table>
        </>
    );
}

const CreateNew = (props: CreateNewProps) => {
    const target = useRef(null);
    const formData: any = useRef(null);
    const [refresh, setRefresh] = useState(false); 
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [getdistricts, setGetDistricts] = useState<any>(0);
    const [districtsresult, setDistrictsResult] = useState<any>(0);
    const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [fieldsvalues, setFieldsValues] = useState<any>(0);
    const [sampleFileLink, setSampleFileLink] = useStateMounted('#');
    const [error, setError] = useStateMounted<string>();
    const [loading, setLoading] = useStateMounted<boolean>(true);
    const [getId, setId] = useState<any>(0);
    const [act, setAct] = useState<any>(null);
    const [districts, setDistricts] = useState<any>(0);
    const [months, setMonths] = useState<any>(0);
    const location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (location.state) {
                setId(location.state.ref_id);
                setAct(location.state.act);
                setDistricts(location.state.district_id);
                setMonths(location.state.months);
            }else{
                navigate('/material-quality-inspection');
            }
        })();
    }, []);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [props.apiurl, roleid, discomid]);

    const ChangeValue = (evt?: any) => {
        setGetDistricts(evt.target.value);
        setIsLoaded(true);
    };

    useEffect(() => {
        (async () => {
            if(getId){
                setIsLoaded(true);
                await Http.get(props.apiurl+"&ref_id="+getId+ "&act=" + act)
                    .then((result) => {
                        setIsLoaded(false);
                        setFields(result.fields);
                        setTimeout(() => {
                            init();
                            multijsoninit();
                        }, 500);
                    })
                    .catch((error) => {
                        setIsLoaded(false);
                        setError(error);
                    })
            }
        })();
    }, [props.apiurl+"&ref_id="+getId+ "&act=" + act]);

    const saveasdraft = (no: any) => {
        setIsLoaded(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districts);
        myFormData.append('months', months);
        myFormData.append('block','0');
        myFormData.append('ref_id', getId);

        Http.post('/apiv1/save-pre-dispatch-inspection', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoaded(false);
                    swal("Warning", res.message, "info");
                } else {
                    const importFileEle: any = document.getElementById(`import_data_file`);
                    if (importFileEle !== null) {
                      const dataTransfer = new DataTransfer();
                      importFileEle.files = dataTransfer.files;
                    }
                    swal("Pre-Dispatch Inspection Form", "Data have been saved", "success");
                    setIsLoaded(false);
                    setRefresh(true);
                    navigate('/material-quality-inspection', { state: {district_id: districts,months: months, selected: 'pre_dispatch_inspection'}});
                }
            });
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoaded(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districts);
        myFormData.append('months', months);
        myFormData.append('block', '1');
        myFormData.append('ref_id', getId);
        
        swal({
            title: "Warning",
            text: "Upon submission, the form will be uneditable.Are you sure you would like to submit?",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Pre-Dispatch Inspection Form!',
                    text: 'Your data has been submitted.',
                    icon: 'success'
                }).then(function () {
                    Http.post('/apiv1/save-pre-dispatch-inspection', myFormData, config)
                        .then((res) => {
                            if (res._resultflag == 0) {
                                setIsLoaded(false);
                                swal("Warning", res.message, "info");
                            } else {
                                const importFileEle: any = document.getElementById(`import_data_file`);
                                if (importFileEle !== null) {
                                  const dataTransfer = new DataTransfer();
                                  importFileEle.files = dataTransfer.files;
                                }
                                setIsLoaded(false);
                                setRefresh(true);
                                navigate('/material-quality-inspection', { state: {district_id: districts,months: months, selected: 'pre_dispatch_inspection'}});
                            }
                        });
                });
            } else {
                setIsLoaded(false);
            }
        });
    }

    const handleFileInput = (e: any) => {
        setIsLoaded(true);
        setRefresh(false);
        setError('');
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let formData = new FormData();
        formData.append("file_to_upload", e.target.files[0]);
        formData.append("role_id", roleid);
        formData.append("discom_id", discomid);
        formData.append("form_id", "175");
        formData.append('district_id', districts);
        formData.append('months', months);

        setFields([]);
        Http.post('/apiv1/pre-dispatch-inspection-import', formData, config)
          .then((res) => {
            if (res.resultflag && res.fields) {
                if (res.fields && Array.isArray(res.fields)) {
                    if (!res.fields.length) setError("No data found.");
                    setFields(res.fields);
                    setDistrictsResult(res.district_id);
                    setTimeout(() => {
                        init();
                        multijsoninit();
                    }, 2000);
                    console.log("File uploaded successfully");
                } else {
                    setError("This file format is wrong.");
                }
                setIsLoaded(false);
            } else if (!res.resultflag) {
                setError(res.message);
            } else {
                setError('No data found.');
            }
            setIsLoaded(false);
        }).catch((error) => {
            setError('This file format is wrong.');
            setIsLoaded(false);
        })
        .finally(() => {
            setIsLoaded(false);
        });
    }

    useEffect(() => {
        if (discomid){
            let samplelink = process.env.REACT_APP_API_ENDPOINT + '/uploads/quality-monitoring/Pre-Dispatch-Inspection.xlsx';
            setSampleFileLink(samplelink);
        }
    }, [discomid]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

        return (
                <>
                <div className="mainWrap">
                <Header />
                <section className="InnerBanner">
                    <div className="container">
                    <ul className="BreadCumb list-unstyled mb-0">
                        <li><Link to="/" className="text-decoration-none">Home</Link></li>
                        <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                        {(roleid == 5 || roleid == 6) ?
                            <>
                            <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            <li><Link to="/nodal-view#QualityMonitoring" className="text-decoration-none">Quality Monitoring</Link></li>
                            </>
                            :
                            <>
                            <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                            <li><Link to="/rdss#QualityMonitoring" className="text-decoration-none">Quality Monitoring</Link></li>
                            </>
                        }                 
                        <li><Link to="/material-quality-inspection" className="text-decoration-none">Material Quality Inspection</Link></li>
                    </ul>
                    </div>
                </section>
                    
                    <section className="financialForm pt-2">
                        <div className="container">
                            <div className="financialMainFrom">                            
                                <div className="tab-content border-0 p-0">
                                    <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                       
                                        <form noValidate onSubmit={onSubmit} ref={formData}>
                                            {act != 'view' ?
                                            <div className='fYearMonth setPosition'>
                                                <div className='row justify-content-end align-items-end'>
                                                    <div className='col-md-1'>
                                                        <div className="form-field">
                                                            <div className="fileUpload position-relative text-center">
                                                                <div className="downEqule">
                                                                <input type="file" onChange={handleFileInput} data-prefix="fpl_expenditure_details" className="form-control"/>
                                                                <div className="customUpload justify-content-center flex-wrap">
                                                                    <span><BiUpload/></span>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                    <div className="w-2">
                                                            <Link title="Download Sample Format" to={sampleFileLink} target="_blank"><BiDownload className="text-success h3 mt-2" /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :null   
                                            }
                                            {error && (
                                                <>
                                                <Alert className='mt-3 mb-3' variant="danger">{error}</Alert>
                                                </>
                                            )}
                                            <div className="table-responsive RevenueDetailsTable PqTable mt-3">
                                                <Block1 field1={fields[0]} act={act} />
                                            </div>
                                            <div className="formBtnSection justify-content-between is-sticky">
                                                {roleid == 2 ?
                                                    <>
                                                     <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)} >
                                                        Save as Draft
                                                    </Link>
                                                    <button className="btn btnSubmit" type="submit">Submit</button>
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                {isLoaded ? <LoadingSpinner /> : null}
            <Footer />
        </div >
        </>
        );
};

const CreateNewFormEdit = (props: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid]);

    return (
        <>
        {roleid == 0 ? null :
            <CreateNew apiurl={"/apiv1/qmfields/?form_id=175&roleid=" + roleid + "&discom_id=" + discomid} districts={props.districts} months={props.months} refresh={props.refresh} />
        }
        </>
    )
}
export default CreateNewFormEdit;