import type { RouteProps } from '../../../Core/Components/Routes';
import { lazy } from 'react';
import CsrdAccess from '../Middlewares/CsrdAccess';
import MisAccess from '../Middlewares/MisAccess';

const InputForm = lazy(() => import('../Pages/InputForm'));
const RfmsForm = lazy(() => import('../Pages/RfmsForm'));
const EvaluationForm = lazy(() => import('../Pages/EvaluationForm'));

/* Route */
const routes: RouteProps[] = [
  {
    path: '',
    element: lazy(() => import('../Pages/Main')),
    index: true,
    private: true,
    middleware: CsrdAccess
  },
  {
    path: 'mis-report/:type?',
    element: lazy(() => import('../Pages/MisReport')),
    middleware: MisAccess
  },
  {
    path: 'input-form',
    element: InputForm,
    private: true,
    middleware: CsrdAccess
  },
  {
    path: 'input-form/:discomId/:year?',
    element: InputForm,
    private: true,
    middleware: CsrdAccess
  },
  {
    path: 'evaluation-form',
    element: EvaluationForm,
    private: true,
    middleware: CsrdAccess
  },
  {
    path: 'evaluation-form/:discomId/:year?',
    element: EvaluationForm,
    private: true,
    middleware: CsrdAccess
  },
  {
    path: 'evaluate-discoms',
    element: lazy(() => import('../Pages/EvaluateDiscoms')),
    private: true,
    middleware: CsrdAccess
  },
  {
    path: 'scoring-form',
    to: '/csrd/evaluate-discoms',
    private: true,
    middleware: CsrdAccess
  },
  {
    path: 'scoring-form/:discomId/:year?',
    element: lazy(() => import('../Pages/Scoring')),
    private: true,
    middleware: CsrdAccess
  },
  {
    path: 'rfms-form',
    element: RfmsForm,
    private: true,
    middleware: MisAccess
  },
];

export default routes;