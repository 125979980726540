import React, { useState, useEffect, useRef } from "react";
import { Http } from '../../Core/Services/HttpService';
import swal from 'sweetalert';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GFR12AForm from './UtilisationForms/GFR12AForm';
import GFR12CForm from './UtilisationForms/GFR12CForm';
import { useStateMounted } from '../../Core/Hooks';
import { Storage } from "../../Core/Services/StorageService";

const UtilisationForm = () => {
    const [selected, setSelected] = useState("GFR12C");
    const formData: any = useRef(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getdocumentation, getDocumentation] = useState<any[]>([]);
    const [getcomponentname, setComponentname] = useState<any>(null);
    const [gettranchename, setTranchename] = useState<any>(null);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [getreleasefundid, setReleaseFundId] = useState<any>(0);
    const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
    const [type_of_entity, setTypeOfEntity] = useState<any>(0);
    const [gettrackerid, setTrackerId] = useState<any>(0);
    const [roleid, setRoleid] = useState<any>(0);
    const [isSubmittedStatus, setSubmittedStatus] = useState<any>(0);
    const [isDgSignatureAccepted, setIsDgSignatureAccepted] = useState<any>(0);
    const [view, setView] = useState<any[]>([]);
    const [error, setError] = useStateMounted<string>();
    const [formId, setFormId] = useState<any>(0);
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setTypeOfEntity(Storage.get('type_of_entity'));
        if (Storage.get('type_of_entity') == "2") {
            setFormId(23);
            setSelected("GFR12C");
        } else {
            setFormId(24);
            setSelected("GFR12A");
        }
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (roleid == 2 || roleid == 3 || roleid == 4) {
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let myFormData = new FormData(formData.current);
            myFormData.append('discom_id', discomid);
            myFormData.append('discom_user_id', discomuserid);
            myFormData.append("release_fund_id", getreleasefundid);
            myFormData.append("utilisation_form_type", selected);
            myFormData.append('tranche_id', gettrancheid);
            myFormData.append('component_id', getcomponentid);
            myFormData.append("aplication_tracker_id", getaplicationtrackerid);
            myFormData.append("roleid", roleid);

            Http.post('/apiv1/save-fund-disbursal-data', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        swal("Warning", res.message, "info");
                    } else {
                        swal("Input Form", "Data have been saved", "success");
                        navigate('/fund-disbursal-link', { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
                    }
                });

        }
    }

    useEffect(() => {
        (async () => {
            if (location.state) {
                getDocumentation(location.state.data);
                setComponentname(location.state.component_name);
                setTranchename(location.state.tranche_name);
                setComponentId(location.state.component_id);
                setTrancheId(location.state.tranche_id);
                setReleaseFundId(location.state.id);
                setAplicationTrackerId(location.state.aplication_tracker_id);
                setTrackerId(location.state.ref_id);
            }
            else {
                navigate('/fund-disbursal');
            }
        })();
    }, [getcomponentid, gettrancheid, getaplicationtrackerid]);

    const navtab = () => {
        setPageLink('/fund-disbursal-link');
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
            }
        })();
    }, [getpagelink]);

    const getFields = () => {
        (async () => {

            if (getaplicationtrackerid) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: formId,
                    discom_user_id: discomuserid,
                    application_id: getaplicationtrackerid,
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                await Http.get(`/apiv1/fdfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoaded(true);
                                setSubmittedStatus(res.submittedStatus);
                                setIsDgSignatureAccepted(res.isDgSignatureAccepted);
                                setView(res.view);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoaded(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [getaplicationtrackerid]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                :
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            }
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Fund Disbursal</Link></li>
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Request for Fund Release</Link></li>
                            <li>Utilisation Forms</li>
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <form noValidate onSubmit={onSubmit} ref={formData}>
                                        <div>
                                            <div>
                                                <div>
                                                    <div className="tab-pane">
                                                        <div className="radioYear pt-2">
                                                            <div className="row justify-content-center align-items-center px-4">
                                                                <div className="col-xl-3 col-md-4 col-sm-6 col-10">
                                                                    <div className='d-flex justify-content-between'>
                                                                        {
                                                                            type_of_entity == "2" ?
                                                                                <div className="form-check">
                                                                                    <input type="radio" className="form-check-input" name="GFR" value="GFR12C" id="GFR12C" checked={selected === "GFR12C"} onChange={changeHandler} />
                                                                                    <label className="form-check-label" htmlFor="GFR12C">
                                                                                        GFR 12 - C
                                                                                    </label>
                                                                                </div>
                                                                                :
                                                                                <div className="form-check">
                                                                                    <input type="radio" className="form-check-input" name="GFR" value="GFR12A" id="GFR12A" checked={selected === "GFR12A"} onChange={changeHandler} />
                                                                                    <label className="form-check-label" htmlFor="GFR12A">GFR 12 - A</label>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {type_of_entity == "2" ?
                                                            <div aria-hidden={selected !== "GFR12C" ? true : false}><GFR12CForm getaplicationtrackerid={getaplicationtrackerid} /></div>
                                                            :
                                                            <div aria-hidden={selected !== "GFR12A" ? true : false}><GFR12AForm getaplicationtrackerid={getaplicationtrackerid} /></div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="formBtnSection justify-content-between">
                                                    <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab()}>Back</Link>
                                                    {((isSubmittedStatus == 1) && (roleid == 2 || roleid == 3 || roleid == 4) && isDgSignatureAccepted != 1) ?
                                                        <button className="btnDraft" type="submit">Save & Back</button>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    )
}
export default UtilisationForm;