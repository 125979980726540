import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Http } from '../../Core/Services/HttpService';
import Select from "react-select";
import ExcelIcn from '../../images/excel.svg';
import PdfIcn from '../../images/pdf.svg';
import { RiFileExcel2Line } from "react-icons/ri";
import { BsSearch, BsFillFileEarmarkPdfFill, BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { set } from "lodash";
import { Link } from "react-router-dom";
import {useAuth} from "../../Core/Providers";

const LrReports = (props: any) => {
    const [colums, setColums] = useState<any[]>();
    const [reportCols, setReportCols] = useState<any[]>([]);

    useEffect(() => {
        setColums(props.columnsPar);
    }, [props.columnsPar]);

    useEffect(() => {
        setReportCols(props.reportCols);
    }, [props.reportCols]);

    return (
        <div className="table-responsive RevenueDetailsTable">
            <table className="table">
                <thead>
                {
                    (colums) ?
                        <tr className="text-center">
                            <th scope="col">Sr. No</th>
                            <th scope="col">State</th>
                            <th scope="col">Discom</th>
                            <th scope="col">Package Identifier</th>
                            <>
                                {
                                    (reportCols) && (
                                        reportCols.map((item, index) => {
                                            return <th scope="col">{item.title}</th>
                                        })
                                    )
                                }
                            </>
                        </tr>
                        : <>
                            <tr className="text-center">
                                <th scope="col" rowSpan={2}>Sr. No</th>
                                <th scope="col" rowSpan={2}>State</th>
                                <th scope="col" rowSpan={2}>Discom</th>
                                <th scope="col" rowSpan={2}>Package Identifier</th>
                                <th scope="col" colSpan={17}>Post Award Status (wrt. Awarded value)</th>
                            </tr>
                            <tr className="text-center">
                                <th scope="col">Name of TKC</th>
                                <th scope="col">Date of Award</th>
                                <th scope="col">Awarded Cost (Supply Portion) (INR Cr)</th>
                                <th scope="col">Awarded Cost (Erection Portion) (INR Cr)</th>
                                <th scope="col">Actual Awarded Cost of Works (INR Cr)</th>
                                <th scope="col">Sanction Cost corresponding to awarded works  (Rs. Crores)</th>
                                <th scope="col">Variation with Sanction (INR Cr)</th>
                                <th scope="col">Date of Contract Agreement</th>
                                <th scope="col">Survey Completed (%)</th>
                                <th scope="col">PO placed to vendors by TKC (%)</th>
                                <th scope="col">Engineering Approval (incl. GTP/Drawings etc.) (%)</th>
                                <th scope="col">Pre Dispatch Inspection offered (%)</th>
                                <th scope="col">Value of Material received at site (INR Cr)</th>
                                <th scope="col">Value of Total Supply portion of Contract for PO placed (INR Cr)</th>
                                <th scope="col">Material received at site (%)</th>
                                <th scope="col">Physical Progress Achieved (%)</th>
                                <th scope="col">Work Details</th>
                            </tr>
                        </>
                }
                </thead>
                <tbody>
                {props.dprData ?
                    props.dprData.map((reportData: any, index: number) => {
                        return <tr>
                            <td>{reportData.srNo}</td>
                            <td>{reportData.state_name}</td>
                            <td>{reportData.discom_name}</td>
                            <td>{reportData.lr_work_package_name_no}</td>
                            <>
                                {
                                    (reportCols) && (
                                        reportCols.map((item, childIndex) => {
                                            return (item.display && item.display == 'N') ? null : <td className="text-center">{reportData[item.key]}</td>
                                        })
                                    )
                                }
                            </>
                            {/*<td className="text-center">{reportData.lr_name_of_successful_bidder}</td>
                            <td className="text-center">{reportData.lr_actual_date_of_loa}</td>
                            <td className="text-center">{reportData.lr_awarded_cost_supply}</td>
                            <td className="text-center">{reportData.lr_awarded_cost_erection}</td>
                            <td className="text-center">{reportData.lr_actual_awarded_cost_works}</td>
                            <td className="text-center">{reportData.lr_sanctioned_cost}</td>
                            <td className="text-center">{reportData.variation_wrt_sanction}</td>
                            <td className="text-center">{reportData.lr_contract_agreement_date}</td>
                            <td className="text-center">{reportData.survey_completed}</td>
                            <td className="text-center">{reportData.po_placed_to_vendors_by_tkc}</td>
                            <td className="text-center">{reportData.lr_engineering_approval}</td>
                            <td className="text-center">{reportData.pre_dispatch_inspection_offered}</td>
                            <td className="text-center">{reportData.value_of_material_received_at_site}</td>
                            <td className="text-center">{reportData.value_of_total_supply_portion_contract_po_placed}</td>
                            <td className="text-center">{reportData.material_received_at_site}</td>
                            <td className="text-center">{reportData.physical_progress_achieved}</td>
                            <td className="text-center">{reportData.work_details}</td>*/}
                        </tr>
                    })
                    :
                    <tr>
                        <td colSpan={19} className="text-center my-5">
                            <h5>No Record Found</h5>
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
};

const SmReports = (props: any) => {
    const [colums, setColums] = useState<any[]>();
    const [reportCols, setReportCols] = useState<any[]>([]);

    useEffect(() => {
        setColums(props.columnsPar);
    }, [props.columnsPar]);

    useEffect(() => {
        setReportCols(props.reportCols);
    }, [props.reportCols]);

    return (
        <div className="table-responsive RevenueDetailsTable">
            <table className="table">
                <thead>
                {
                    (colums) ?
                        <tr className="text-center">
                            <th scope="col">Sr. No</th>
                            <th scope="col">State</th>
                            <th scope="col">Discom</th>
                            <th scope="col">Package Identifier</th>
                            <>
                                {
                                    (reportCols) && (
                                        reportCols.map((item, index) => {
                                            return <th scope="col">{item.title}</th>
                                        })
                                    )
                                }
                            </>
                        </tr>
                        : <>
                            <tr className="text-center">
                                <th scope="col" rowSpan={3}>Sr. No</th>
                                <th scope="col" rowSpan={3}>State</th>
                                <th scope="col" rowSpan={3}>Discom</th>
                                <th scope="col" rowSpan={3}>Package Identifier</th>
                                <th scope="col" colSpan={17}>Post Award Status (wrt. Awarded quantity)</th>
                            </tr>
                            <tr className="text-center">
                                <th scope="col" rowSpan={2}>Date of Award</th>
                                <th scope="col" rowSpan={2}>Consumer Meters Phase-I (Nos)</th>
                                <th scope="col" rowSpan={2}>Consumer Meters Phase-II (Nos)</th>
                                <th scope="col" rowSpan={2}>Total Consumer Meters (Nos.)</th>
                                <th scope="col" rowSpan={2}>DT Meters (Nos.)</th>
                                <th scope="col" rowSpan={2}>Feeder Meters (Nos.)</th>
                                <th scope="col" rowSpan={2}>Total Meters (Nos.)</th>
                                <th scope="col" rowSpan={2}>Name of AMISP</th>
                                <th scope="col" colSpan={3}>Payment Security Mechanism executed</th>
                                <th scope="col" rowSpan={2}>Date of Contract Agreement</th>
                                <th scope="col" rowSpan={2}>Survey Completion (%)</th>
                                <th scope="col" rowSpan={2}>Engineering Approval (%)</th>
                                <th scope="col" rowSpan={2}>Meters Supplied (Nos.)</th>
                                <th scope="col" rowSpan={2}>Meters Installed (Nos.)</th>
                                <th scope="col" rowSpan={2}>Area covered</th>
                            </tr>
                            <tr className="text-center">
                                <th scope="col">Whether PSM executed (Yes/No)</th>
                                <th scope="col">Type of Payment Security Mechanism</th>
                                <th scope="col">Date of Execution</th>
                            </tr>
                        </>
                }
                </thead>
                <tbody>
                {props.dprData ?
                    props.dprData.map((reportData: any, index: number) => {
                        return <tr>
                            <td>{reportData.srNo}</td>
                            <td>{reportData.state_name}</td>
                            <td>{reportData.discom_name}</td>
                            <td>{reportData.sm_work_package_name_no}</td>
                            <>
                                {
                                    (reportCols) && (
                                        reportCols.map((item, childIndex) => {
                                            return (item.display && item.display == 'N') ? null : <td className="text-center">{reportData[item.key]}</td>
                                        })
                                    )
                                }
                            </>
                            {/*<td className="text-center">{reportData.sm_actual_date_of_loa}</td>
                            <td className="text-center">{reportData.sm_specified_consumer_meters_phase_1_nos}</td>
                            <td className="text-center">{reportData.sm_specified_consumer_meters_phase_2_nos}</td>
                            <td className="text-center">{reportData.consumer_meters_total}</td>
                            <td className="text-center">{reportData.sm_specified_dt_meters_phase_1_nos}</td>
                            <td className="text-center">{reportData.sm_specified_feeder_meters_nos}</td>
                            <td className="text-center">{reportData.total_meters_nos}</td>
                            <td className="text-center">{reportData.sm_name_of_successful_bidder}</td>
                            <td className="text-center">{reportData.sm_whether_psm_executed}</td>
                            <td className="text-center">{reportData.sm_type_payment_security_mechanism_in_rfp}</td>
                            <td className="text-center">{reportData.sm_date_of_execution}</td>
                            <td className="text-center">{reportData.sm_contract_agreement_date}</td>
                            <td className="text-center">{reportData.survey_completion}</td>
                            <td className="text-center">{reportData.sm_engineering_approval}</td>
                            <td className="text-center">{reportData.meter_supplied}</td>
                            <td className="text-center">{reportData.meter_installed}</td>
                            <td className="text-center">{reportData.sm_area_covered}</td>*/}
                        </tr>
                    })
                    :
                    <tr>
                        <td colSpan={21} className="text-center my-5">
                            <h5>No Record Found</h5>
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
};

const AwardedReports = (props:any) => {
    const user = useAuth().user();
    let currentYearValue = new Date().getFullYear();
    let srNo = 0;
    const [reportType, setReportType] = useState<any>('AwardedTenders');
    const [year, setYear] = useState(currentYearValue);
    const [works, setWorks] = useState('LR');
    const [getstatesapiurl, setStatesApiurl] = useState<any>(null);
    const [statesList, setStatesList] = useState([{ 'id': 0, 'title': 'All' }]);
    const [discomList, setDiscomList] = useState([{ 'id': 0, 'discom_name': 'All' }]);

    const [getstate, setState] = useState<any>(0);
    const [selectedState, setSelectedState] = useState<any>(0);
    const [selectedMultiState, setSelectedMultiState] = useState<any>();
    const [selectedDiscom, setSelectedDiscom] = useState<any>(0);
    const [selectedMultiDiscom, setSelectedMultiDiscom] = useState<any>();
    const [dprData, setDprData] = useState<any>();
    const [selectedAgency, setSelectedAgency] = useState<any>(0);
    const [selectedMonitoringParameters, setSelectedMonitoringParameters] = useState<any>();
    const [selectedMultiMonitoringParameters, setSelectedMultiMonitoringParameters] = useState<any>();
    const [selectedColumns, setSelectedColumns] = useState<any>();
    const [reportCols, setReportCols] = useState<any>(null);

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);

    const searchParams = new URLSearchParams(window.location.search);
    const filterStateVal = searchParams.get('stateid');
    const [userData, setUserData] = useState<any>();
    const [columnsopts, setColumnsOpts] = useState(
        [
            { value: "0", label: 'All' },
            { value: "1", label: "Name of TKC" },
            { value: "2", label: "Date of Award" },
            { value: "3", label: "Awarded Cost (Supply Portion) (INR Cr)" },
            { value: "4", label: "Awarded Cost (Erection Portion) (INR Cr)" },
            { value: "5", label: "Actual Awarded Cost of Works (INR Cr)" },
            { value: "6", label: "Sanction Cost corresponding to awarded works (Rs. Crores)" },
            { value: "7", label: "Variation with Sanction (INR Cr)" },
            { value: "8", label: "Date of Contract Agreement" },
            { value: "9", label: "Survey Completed (%)" },
            { value: "10", label: "PO placed to vendors by TKC (%)" },
            { value: "11", label: "Engineering Approval (incl. GTP/Drawings etc.) (%)" },
            { value: "12", label: "Pre Dispatch Inspection offered (%)" },
            { value: "13", label: "Value of Material received at site (INR Cr)" },
            { value: "14", label: "Value of Total Supply portion of Contract for PO placed (INR Cr)" },
            { value: "15", label: "Material received at site (%)" },
            { value: "16", label: "Physical Progress Achieved (%)" },
            { value: "17", label: "Work Details" },
        ]
    );

    useEffect(() => {
        setTimeout(() => {
            setUserData(user);
        },1000);
    },[]);

    const handleOptionChange = (e: any) => {
        let value = e.target.value;
        setWorks(value);
        setDprData([]);
        setColumnsOpts([
                { value: "0", label: 'All' },
                { value: "1", label: "Name of TKC" },
                { value: "2", label: "Date of Award" },
                { value: "3", label: "Awarded Cost (Supply Portion) (INR Cr)" },
                { value: "4", label: "Awarded Cost (Erection Portion) (INR Cr)" },
                { value: "5", label: "Actual Awarded Cost of Works (INR Cr)" },
                { value: "6", label: "Sanction Cost corresponding to awarded works (Rs. Crores)" },
                { value: "7", label: "Variation with Sanction (INR Cr)" },
                { value: "8", label: "Date of Contract Agreement" },
                { value: "9", label: "Survey Completed (%)" },
                { value: "10", label: "PO placed to vendors by TKC (%)" },
                { value: "11", label: "Engineering Approval (incl. GTP/Drawings etc.) (%)" },
                { value: "12", label: "Pre Dispatch Inspection offered (%)" },
                { value: "13", label: "Value of Material received at site (INR Cr)" },
                { value: "14", label: "Value of Total Supply portion of Contract for PO placed (INR Cr)" },
                { value: "15", label: "Material received at site (%)" },
                { value: "16", label: "Physical Progress Achieved (%)" },
                { value: "17", label: "Work Details" },
            ]
        );
        if(value === 'SM'){
            setColumnsOpts([
                { value: "0", label: 'All' },
                { value: "1", label: "Date of Award" },
                { value: "2", label: "Consumer Meters Phase-I (Nos)" },
                { value: "3", label: "Consumer Meters Phase-II (Nos)" },
                { value: "4", label: "Total Consumer Meters (Nos.)" },
                { value: "5", label: "DT Meters (Nos.)" },
                { value: "6", label: "Feeder Meters (Nos.)" },
                { value: "7", label: "Total Meters (Nos.)" },
                { value: "8", label: "Name of AMISP" },
                { value: "9", label: "Whether PSM executed (Yes/No)" },
                { value: "10", label: "Type of Payment Security Mechanism" },
                { value: "11", label: "Date of Execution" },
                { value: "12", label: "Date of Contract Agreement" },
                { value: "13", label: "Survey Completion (%)" },
                { value: "14", label: "Engineering Approval (%)" },
                { value: "15", label: "Meters Supplied (Nos.)" },
                { value: "16", label: "Meters Installed (Nos.)" },
                { value: "17", label: "Area covered" },
            ])
        }
        clearparams();
    };

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };

    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event: any) => {
        if (isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if (event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if (event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e: any) => {
        if (e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };

    const fetchDprData = async (optParams?: {}) => {
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        setSelectedColumns(selectedMultiMonitoringParameters);
        let params = {
            year: year,
            reportType: reportType,
            state_id: selectedMultiState,
            discom_id: selectedMultiDiscom,
            agency_id: selectedAgency,
            works: works,
            page: curPage,
            pageSize: pageSize,
            role_id: userData?.role_id,
            userDiscomId: user?.discom_id,
            columnsPar: selectedMultiMonitoringParameters,
        };
        params = Object.assign(params, optParams);
        if (curPage >= 0) {
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner ) footerspinner.classList.remove('d-none');
            await Http.get('/apiv1/award-report/getAwardedMisData', { params })
                .then((res) => {
                    setReportCols(res.cols);
                    setDprData(res.rows);
                    setTotalItems(res.totalRecord);
                    setPageCount(Math.ceil(res.totalRecord / pageSize));
                    setTotalPages(Math.ceil(res.totalRecord / pageSize));
                    //console.log(res.totalRecord,pageSize,(Math.ceil(res.totalRecord / pageSize)));

                    let cPage = (!currentPage) ? 1 : currentPage;
                    setFirst((cPage - 1) * pageSize + 1);
                    setLast(Math.min(res.totalRecord, cPage * pageSize));

                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    footerspinner.classList.add('d-none');
                });
        }
    };

    useEffect(() => {
        if (currentPage != 0) {
            fetchDprData();
        }
    }, [currentPage]);

    useEffect(() => {
        fetchDprData();
        setSelectedState('');
        setSelectedDiscom('');
        setSelectedMultiState('');
        setSelectedMultiDiscom('');
        setReportCols(null);
    }, [works]);

    const getStates = () => {
        (async () => {
            if (getstatesapiurl) {
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner) footerspinner.classList.remove('d-none');
                await Http.get(getstatesapiurl + '?agency_id=' + selectedAgency)
                    .then((res) => {
                        setStatesList(res.statesList);
                        setDiscomList([]);
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    const handleAgencySelect = (SelectedAgency: any) => {
        setCurrentPage(0);
        setSelectedAgency(SelectedAgency.target.value);
        setStatesList([]);
        setDiscomList([]);
        setSelectedState('');
        setSelectedDiscom('');
        setSelectedMultiState('');
        setSelectedMultiDiscom('');
    };

    const handleStateSelect = (SelectedState: any) => {
        setCurrentPage(0);
        //setState(SelectedState.value);
        let multiSelectedState = SelectedState.map((item: any) => item.value).join(",");
        setSelectedMultiState(multiSelectedState);
        setSelectedState(SelectedState);
    };

    const handleDiscomSelect = (selectedDiscom: any) => {
        setCurrentPage(0);
        let multiSelectedDiscom = selectedDiscom.map((item: any) => item.value).join(",");
        setSelectedMultiDiscom(multiSelectedDiscom);
        setSelectedDiscom(selectedDiscom);
    };

    const handleMonitoringParametersSelect = (selectedMonitoringParameters: any) => {
        setCurrentPage(0);
        let multiMonitoringParameters = selectedMonitoringParameters.map((item: any) => item.value).join(",");
        setSelectedMultiMonitoringParameters(multiMonitoringParameters);
        setSelectedMonitoringParameters(selectedMonitoringParameters);
    };

    const clearparams = () => {
        setState(0);
        setSelectedState(0);
        setSelectedMultiDiscom('');
        setSelectedMultiState('');
        setSelectedDiscom(0);
        setCurrentPage(0);
        setTotalItems(0);
        setPageCount(0);
        setSelectedMultiMonitoringParameters('');
        setSelectedMonitoringParameters('');
        setReportCols(null);
    };

    useEffect(() => {
        setStatesApiurl("/apiv1/award-report/getMisStates");
    }, [getstatesapiurl]);

    useEffect(() => {
        (async () => {
            getStates();
        })();
    }, [getstatesapiurl,selectedAgency]);

    const fetchReportData = async () => {
        let footerspinner: any = document.getElementById('footer-spinner');
        if (footerspinner) footerspinner.classList.remove('d-none');
        await Http.get('/apiv1/award-report/getMisDiscom?state_id=' + selectedMultiState + '&module=ls')
            .then((res) => {
                setDiscomList(res.discoms);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                footerspinner.classList.add('d-none');
            });
    }

    useEffect(() => {
        fetchReportData();
    }, [selectedMultiState]);

    useEffect( () => {
        setReportType(props?.reportType);
        setYear(props?.year);
    },[props?.reportType,props?.year]);

    return (
        <>
            <div className="row align-items-center mt-2">
                <div className='col-md-3'>
                    <label htmlFor="optsm" className="form-label">Select Works</label>
                    <select name="optsm" id="optsm" defaultValue={works} className="form-select" onChange={handleOptionChange} >
                        <option value="LR">Loss Reduction</option>
                        <option value="SM">Smart Metering</option>
                    </select>
                </div>
                {
                    (!user?.discom_id) ?
                        <>
                            <div className="col-md-2">
                                <div className="misSelectReportPosition">
                                    <label htmlFor="agency" className="form-label">Select Nodal Agency</label>
                                    <select name="agency" id="agency" defaultValue={selectedAgency} className="form-select" onChange={handleAgencySelect} >
                                        <option value="0">All</option>
                                        <option value="1">PFC</option>
                                        <option value="2">REC</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="misSelectReportPosition">
                                    <label htmlFor="states" className="form-label">Select State(s)</label>
                                    <Select id="states" className="misReportSelect" aria-label="States" onChange={handleStateSelect} isSearchable={true} options={statesList} value={selectedState} isMulti />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="misSelectReportPosition">
                                    <label htmlFor="discom" className="form-label">Select Discom(s)</label>
                                    <Select id="discom" className="misReportSelect" aria-label="Discom" onChange={handleDiscomSelect} isSearchable={true} options={discomList} value={selectedDiscom} isMulti />
                                </div>
                            </div>
                            <div className="col-md-4 mt-2">
                                <label htmlFor="monitoringparameters" className="form-label">Select Column</label>
                                <Select id="monitoringparameters" className="misReportSelect" aria-label="Select Column" onChange={handleMonitoringParametersSelect} isSearchable={true} options={columnsopts} value={selectedMonitoringParameters} isMulti />
                            </div>
                            <div className="col-md-3 text-start">
                                <button className="bsSearchBtn mt-0" onClick={() => { fetchDprData(); }}>Search</button>
                            </div>
                        </>
                        : null
                }
                <div className={(user?.discom_id) ? 'col-md-9 text-end' : 'col-md-5 text-end'}>
                    <div className="dIcnBtn">
                        <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/award-report/getAwardedMisData?reportType=' + reportType + '&agency_id=' + selectedAgency + '&state_id=' + selectedMultiState + '&discom_id=' + selectedMultiDiscom + '&works=' + works + '&userDiscomId=' + user?.discom_id + '&columnsPar=' + selectedMultiMonitoringParameters + '&task=excel&discom_shortname=test'}><img src={ExcelIcn} /></a>
                        <a className="bsSearchBtn text-center" target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/award-report/getAwardedMisData?reportType=' + reportType + '&agency_id=' + selectedAgency + '&state_id=' + selectedMultiState + '&discom_id=' + selectedMultiDiscom + '&works=' + works + '&userDiscomId=' + user?.discom_id + '&columnsPar=' + selectedMultiMonitoringParameters + '&task=pdf&discom_shortname=test'}><img src={PdfIcn} /></a>
                    </div>
                </div>
            </div>

            <div className="mt-2 financialMainFrom misReportTable">
                {
                    totalItems ?
                        <div>Displaying {first} to {last} of {totalItems}</div>
                        :
                        null
                }
                <div className="tab-content p-0 border-0">
                    {/*<div className="table-responsive RevenueDetailsTable">
                        <table className="table">
                            <thead>
                            <tr className="text-center">
                                <th scope="col" rowSpan={2}>Sr. No</th>
                                <th scope="col" rowSpan={2}>State</th>
                                <th scope="col" rowSpan={2}>Discom</th>
                                <th scope="col" rowSpan={2}>Package Identifier</th>
                                <th scope="col" colSpan={2}>RFP yet to be Issued</th>
                                <th scope="col" colSpan={3}>RFP Issued (Bids neither yet opened nor Awarded)</th>
                                <th scope="col" colSpan={3}>Bids Opened and Under Evaluation</th>
                                <th scope="col" rowSpan={2}>Awarded (YES / NO)</th>
                            </tr>
                            <tr className="text-center">
                                <th scope="col">Expected RFP Issue Date</th>
                                <th scope="col">Cost of LR Works (Rs. Crores)</th>
                                <th scope="col">Actual RFP Issue Date</th>
                                <th scope="col">Expected Date of Technical Bid Opening</th>
                                <th scope="col">Cost of LR Works (Rs. Crores)</th>
                                <th scope="col">Date of Technical Bid Opening</th>
                                <th scope="col">Date of Financial Bid Opening</th>
                                <th scope="col">Cost of LR Works (Rs. Crores)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dprData ?
                                dprData.map((reportData: any, index: number) => {
                                    return <tr>
                                        <td>{reportData.srNo}</td>
                                        <td>{reportData.state_name}</td>
                                        <td>{reportData.discom_name}</td>
                                        <td>{reportData.lr_work_package_name_no}</td>
                                        <td className="text-center">{reportData.lr_expected_rfp_issue_date}</td>
                                        <td className="text-center">{reportData.lr_sanctioned_cost}</td>
                                        <td className="text-center">{reportData.lr_actual_rfp_issue_date}</td>
                                        <td className="text-center">{reportData.lr_expected_date_of_technical_bid_opening}</td>
                                        <td className="text-center">{reportData.lr_sanctioned_cost_issue}</td>
                                        <td className="text-center">{reportData.lr_actual_date_of_technical_bid_opening_eva}</td>
                                        <td className="text-center">{reportData.lr_actual_date_of_financial_bid_opening_eva}</td>
                                        <td className="text-center">{reportData.lr_sanctioned_cost_issue_eva}</td>
                                        <td className="text-center">{reportData.lr_present_status}</td>
                                    </tr>
                                })
                                :
                                <tr>
                                    <td colSpan={11} className="text-center my-5">
                                        <h5>No Record Found</h5>
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>*/}
                    {works === 'LR' && (
                        <LrReports dprData={dprData} reportCols={reportCols} columnsPar={selectedColumns}/>
                    )}
                    {works === 'SM' && (
                        <SmReports dprData={dprData} reportCols={reportCols} columnsPar={selectedColumns}/>
                    )}
                    {
                        totalItems != 0 ?
                            <div className="mt-3">
                                <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item">
                                            <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                        </li>
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                <BsChevronCompactLeft />
                                            </button>
                                        </li>
                                        <li className="page-item col-1">
                                            <input type="text" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                        </li>
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages || totalPages === 1}>
                                                <BsChevronCompactRight />
                                            </button>
                                        </li>
                                        <li className="page-item">
                                            <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages || totalPages === 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="page-item text-center">
                                <span>
                                    Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                </span>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        </>
    );
}

export default AwardedReports;