import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import QuarterlyTargets from '../../assets/ImageCompo/QuarterlyTargets'
import ProgressDetails from '../../assets/ImageCompo/ProgressDetails'
import Report from '../../assets/ImageCompo/Report'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import InnerTemplate from '../../Components/InnerTemplate/InnerTemplate'
import "../../css/style.scss";
import { Http } from '../../Core/Services/HttpService';
import { Storage } from '../../Core/Services/StorageService'

const Monitoring = (props:any) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submittedStatus, setSubmittedStatus] = useState<any>(0);
    const getSubmittedStatus = () => {
        (async () => {
            if(discomid) {
              setIsLoading(true);
            await Http.get("/apiv1/sanction-block-status?form_id=&discom_id="+discomid)
                .then((res) => {
                    setIsLoaded(true);
                    setIsLoading(false);
                    setSubmittedStatus(res.submitted_status);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError(error);
                });
            }
        })();
      };

      useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
      }, [discomid]);
        
      useEffect(() => {
        (async () => {
            getSubmittedStatus();
        })();
      }, [discomid]);

        return (
            <>
             <Header /> 
                <InnerTemplate PageTitle="Monitoring" />
                <div className="mainWrap inputFromNew">
                    <div className="container">                   
                        <div className='aboutAccordioin d-block mt-5'>
                            <div className='Accordion-group mw-100'>
                                <div className='Tab-panel'>
                                    <div className='ActionCenterSection bgSizeCover'>
                                        <div className="row gx-0">
                                            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                                                <Link to={submittedStatus == 1 ? "/monthly-targets" : "#"} className="d-block w-100 text-decoration-none h-100">
                                                    <div className="tabPanelInner">
                                                        <QuarterlyTargets />
                                                        <h5>Monthly Targets</h5> 
                                                        <p className='text-white px-4 display-6'>Set Monthly Targets for Smart Metering, Loss Reduction and Modernization Works for the Financial Year</p>                                                   
                                                    </div>
                                                </Link>
                                            </div>
                                            {/* <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                                                <Link to={submittedStatus == 1 ? "/progress-details" : "#"} className="d-block w-100 text-decoration-none h-100">
                                                    <div className="tabPanelInner">
                                                        <ProgressDetails />
                                                        <h5>Progress Details</h5>   
                                                        <p className='text-white px-4 display-6'>Enter progress Details for Smart Metering, Loss Reduction and Modernization Works for the Financial Year </p>                                                     
                                                    </div>
                                                </Link>
                                            </div> */}
                                            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                                                <Link to={submittedStatus == 1 ? "/all-progress-details" : "#"} className="d-block w-100 text-decoration-none h-100">
                                                    <div className="tabPanelInner">
                                                        <ProgressDetails />
                                                        <h5>Progress Details</h5>   
                                                        <p className='text-white px-4 display-6'>Enter progress Details for Smart Metering, Loss Reduction and Modernization Works for the Financial Year </p>                                                     
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                                                <Link to="/mis-report" className="d-block w-100 text-decoration-none h-100">
                                                    <div className="tabPanelInner">
                                                        <Report />
                                                        <h5>Report</h5>  
                                                        <p className='text-white px-4 display-6'>Generate reports for Smart Metering, Loss Reduction and Modernization Works for the Financial Year </p>                                                      
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        submittedStatus == 0 ?
                                        <>
                                            <div className="mt-2 alert alert-info">Please submit sanction data</div>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <Footer />
            </>
        )
    }
export default Monitoring;