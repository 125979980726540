import React, { useEffect, useRef, useState } from "react";
import DashboardFooter from "../Components/Footer/DashboardFooter";
import { Link, useLocation, useParams } from "react-router-dom";
import BothMapSvg from "../Components/DashboardComponents/Chart/BothMapSvg";
import "./dashboard.scss"
import Indicators from './Indicators/IndicatorDropdown';
import { CgFileDocument } from "react-icons/cg";

// Images
import { BiArrowBack, BiRupee } from "react-icons/bi";
import DashboardHeader from "../Components/Header/DashboardHeader";
import { Http } from '../Core/Services/HttpService';
import MyTooltip from "../Components/DashboardComponents/Chart/PopOver";
import { useTranslation } from "../Core/Providers/TranslationProvider";
import { strToNumber, toFixed } from "../assets/js/formbuilder";
import { useStateMounted } from "../Core/Hooks";
import { FaBoxes, FaDownload } from "react-icons/fa";
import SmartMetering from "../Components/DashboardComponents/Chart/SmartMetering";

// Icons
import ATCLoss from '../images/box1.png'
import ARRGap from '../images/box2.png'
import Payable from '../images/box3.png'
import Receivable from '../images/box4.png'
import Discoms from '../images/box5.png'
import SanctionedCost from '../images/box6.png'
import GBSSanctioned from '../images/box7.png'
import GBSRelease from '../images/box8.png'
import ConsumerMeteringChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/ConsumerMetering";
import DTMeteringChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/DTMetering";
import FeederMeteringChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/FeederMetering";
import BoundaryMeteringChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/BoundaryMetering";
import LTlinesChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/LTlines";
import HTlinesChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/HTlines";
import DTRsChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/DTRs";
import SubstationsChart from "../Components/DashboardComponents/Chart/ProgressDetailsChart/Substations";
import LossReducation from "../Components/DashboardComponents/Chart/LossReducation";
import { Storage } from "../Core/Services/StorageService";


const Dashboard = () => {
  const query = new URLSearchParams(useLocation().search);
  const [mobile, setMobile] = useStateMounted<string>(query.get('mobile') || '');
  const location = useLocation();
  const [chartLink, setChartLink] = useState<string>('');
  const [chartLinkLr, setChartLinkLr] = useState<string>('');
  const [__, setLangCode] = useTranslation();
  const [selected, setSelected] = useState("0");
  const [evaluationselected, setEvaluationselected] = useState("2022-23");
  const [selectedDrop, setSelectedDrop] = useState("19");
  const [isDesktop, setDesktop] = useState(window.innerWidth);
  const [physicalProgressLoss, setPhysicalProgressLoss] = useState("0.00");

  const [headerInfomativeData, setHeaderInfomativeData] = useState<any>({
    total_sanction_cost: 0,
    total_gbs_sanctioned: 0,
    total_gbs_released: 0,
    total_discoms: 0,
    total_states: 0,
    acc_arr_info: [],
    pqsop: { 'Qualified': '00', 'Under Evaluation': '00', 'To be Re-evaluated': '00', 'Not Submitted': '00' },
    pqsop_previous:{ 'Qualified': '00', 'Under Evaluation': '00', 'To be Re-evaluated': '00', 'Not Submitted': '00' }
  });

  const [childFundData, setChildFundData] = useState<any>({
    sm_gbs: 0,
    sm_fund_released: 0,
    lsr_gbs: 0,
    lsr_fund_released: 0
  });
  const fundChildData = (data: any) => {
    setChildFundData(data);
  }

  const [smartMetering, setSmartMeteringData] = useState<any>({
    // Consumer Metering
    cm: { sanData: 0, awaData: 0, instData: 0 },
    // DT Metering
    dtm: { sanData: 0, awaData: 0, instData: 0 },
    //Feeder Metering
    fm: { sanData: 0, awaData: 0, instData: 0 },
    // Boundary Metering
    bm: { sanData: 0, awaData: 0, instData: 0 }
  });
  const [fundChartData, setFundChartData] = useState<any | null>(null);

  const [infrastructureWorks, setInfrastructureWorks] = useState<any[]>([
    { title: 'LT lines', sanctioned: 0, awarded: 0, installed: 0, unit: 'Ckm.', type: 'LT', santion_rate: 0, achievment: 0, santion_cost: 0, sanction_pern: 0, award_pern: 0, surveyed_pern: 0 },
    { title: 'HT lines', sanctioned: 0, awarded: 0, installed: 0, unit: 'Ckm.', type: 'HT', santion_rate: 0, achievment: 0, santion_cost: 0, sanction_pern: 0, award_pern: 0, surveyed_pern: 0 },
    { title: 'DTRs', sanctioned: 0, awarded: 0, installed: 0, unit: 'Nos.', type: 'DTR', santion_rate: 0, achievment: 0, santion_cost: 0, sanction_pern: 0, award_pern: 0, surveyed_pern: 0 },
    { title: 'Substations', sanctioned: 0, awarded: 0, installed: 0, unit: 'Nos.', type: 'SUB', santion_rate: 0, achievment: 0, santion_cost: 0, sanction_pern: 0, award_pern: 0, surveyed_pern: 0 },
  ]);

  const fetchHeaderInfomativeData = async (params: any = {}) => {
    await Http.get('/apiv1/d-board/infomativeDataHeader', { params: params })
      .then((res) => {
        if (res._resultflag == "1")
          setHeaderInfomativeData(res.headerInfomativeData);
      }).catch((e) => { });
  };

  const fetchSmartMetering = async (params: any = {}) => {
    const cmParams = {
      ...params,
      reportType: 'Allindia',
      majorcomponentid: '1,2,3,4',
      subcomp: '0',
    };
    Http.get('apiv1/getProgressDetailSm', { params: cmParams })
      .then((res: any) => {
        if (res._resultflag == "1" && res.rows) {
          setSmartMeteringData((prev: any) => {
            return {
              ...prev,
              // Consumer Metering
              cm: {
                sanData: strToNumber(res.rows["1"].santion_items),
                awaData: strToNumber(res.rows["1"].award_items),
                instData: strToNumber(res.rows["1"].communicating_metering_cumulative)
              },
              // DT Metering
              dtm: {
                sanData: strToNumber(res.rows["2"].santion_items),
                awaData: strToNumber(res.rows["2"].award_items),
                instData: strToNumber(res.rows["2"].communicating_metering_cumulative)
              },
              //Feeder Metering
              fm: {
                sanData: strToNumber(res.rows["3"].santion_items),
                awaData: strToNumber(res.rows["3"].award_items),
                instData: strToNumber(res.rows["3"].communicating_metering_cumulative)
              },
              // Boundary Metering
              bm: {
                sanData: strToNumber(res.rows["4"].santion_items),
                awaData: strToNumber(res.rows["4"].award_items),
                instData: strToNumber(res.rows["4"].communicating_metering_cumulative)
              }
            };
          });
        }
      }).catch((e) => { });
  }

  const fetchInfrastructureWorks = async (params: any = {}) => {
    const paramsAll = {
      ...params,
      charttype: '',
      reportType: 'Allindia',
    };
    await Http.get('apiv1/getProgressDetailLs', { params: paramsAll })
      .then((res: any) => {
        if (res._resultflag == "1" && res.rows) {
          setInfrastructureWorks((prev: any) => {
            prev[0].sanction_pern = res.rows.lt.sanction_pern;
            prev[0].award_pern = res.rows.lt.award_pern;
            prev[0].surveyed_pern = res.rows.lt.surveyed_pern;
            prev[0].sanctioned = res.rows.lt.santion_items;
            prev[0].awarded = res.rows.lt.award_items;
            prev[0].installed = res.rows.lt.installed_items;
            prev[1].sanction_pern = res.rows.ht.sanction_pern;
            prev[1].award_pern = res.rows.ht.award_pern;
            prev[1].surveyed_pern = res.rows.ht.surveyed_pern;
            prev[1].sanctioned = res.rows.ht.santion_items;
            prev[1].awarded = res.rows.ht.award_items;
            prev[1].installed = res.rows.ht.installed_items;
            prev[2].sanction_pern = res.rows.dtr.sanction_pern;
            prev[2].award_pern = res.rows.dtr.award_pern;
            prev[2].surveyed_pern = res.rows.dtr.surveyed_pern;
            prev[2].sanctioned = res.rows.dtr.santion_items;
            prev[2].awarded = res.rows.dtr.award_items;
            prev[2].installed = res.rows.dtr.installed_items;
            prev[3].sanction_pern = res.rows.sub.sanction_pern;
            prev[3].award_pern = res.rows.sub.award_pern;
            prev[3].surveyed_pern = res.rows.sub.surveyed_pern;
            prev[3].sanctioned = res.rows.sub.santion_items;
            prev[3].awarded = res.rows.sub.award_items;
            prev[3].installed = res.rows.sub.installed_items;
            return [...prev];
          });
          setPhysicalProgressLoss(toFixed(res.rows.pern, 2));
        }
      }).catch((e) => { });
  }

  const [pdfonboardLink1, setPdfonBoardLink1] = useStateMounted('#');
  const [pdfonboardLink2, setPdfonBoardLink2] = useStateMounted('#');
  const [smAwardStatusLink, setSmAwardStatusLink] = useStateMounted('#');
  const [lrAwardStatusLink, setLrAwardStatusLink] = useStateMounted('#');
  const [xlsforatcloss, setXlsforatcloss] = useStateMounted('#');
  const [xlsforarrgap, setXlsforarrgap] = useStateMounted('#');
  useEffect(() => {
    fetchHeaderInfomativeData({ selected: selected });
    fetchSmartMetering({ selected: selected });
    fetchInfrastructureWorks({ selected: selected });
    setFundChartData({ selected: selected });
    let pdfonboardLink1 = process.env.REACT_APP_API_ENDPOINT + '/dashboard/RDSS-PMA-SM.xlsx';
    setPdfonBoardLink1(pdfonboardLink1);
    let pdfonboardLink2 = process.env.REACT_APP_API_ENDPOINT + '/dashboard/RDSS-PMA-LR.xlsx';
    setPdfonBoardLink2(pdfonboardLink2);
    let smAwardStatusLink = process.env.REACT_APP_API_ENDPOINT + '/dashboard/Rdss-award-status-SM.xlsx';
    setSmAwardStatusLink(smAwardStatusLink);
    let lrAwardStatusLink = process.env.REACT_APP_API_ENDPOINT + '/dashboard/Rdss-award-status-LR.xlsx';
    setLrAwardStatusLink(lrAwardStatusLink);
    let xlsforatcloss = process.env.REACT_APP_API_ENDPOINT + '/dashboard/ATC Loss.xlsx';
    setXlsforatcloss(xlsforatcloss);
    let xlsforarrgap = process.env.REACT_APP_API_ENDPOINT + '/dashboard/ACS_ARR.xlsx';
    setXlsforarrgap(xlsforarrgap);
  }, [selected]);

  const changeHandler = (e: any) => {
    setSelected(e.target.value);
  };

  const evaluationChangeHandler = (e: any) => {
    setEvaluationselected(e.target.value);
  };

  useEffect(() => {
    function handleResize() {
      setDesktop(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setChartLink('/sm-financial-progress');
    setChartLinkLr('/lr-financial-progress');
  }, []);

  const roleids = [
    process.env.REACT_APP_NDR,
    process.env.REACT_APP_NDA
  ];
  let roleid:any = Storage.get('roleid') ? Storage.get('roleid') : 0;

  return (
    <>
      <div className="mainWrap dashboard">
        {mobile === '' ? <DashboardHeader /> : null}
        <div className="container-fluid">
          <div className="dashboardInner detailedDashboard">
            <div className="heading">
              <div className="row align-items-center">
                <div className="col-md-8 col-lg-8 col-xl-9 float-start">
                  <h2 className="mb-0"><Link to={(roleids.includes(roleid.toString())) ? "/nodal-view" : (roleid == 0) ? "/" : "/rdss"}><em className="arrow"><BiArrowBack /></em></Link>RDSS Dashboard</h2>
                </div>
                <div className="col-md-3 text-end">
                  <a href="/rdss#Guidelines" style={{ "color": "#f9c267", "fontWeight": "bold", "marginRight":"36px" }}>View Scheme Related Documents</a>
                  <a href="/mis-report" style={{ "color": "#f9c267", "fontWeight": "bold" }}>Detail Report</a>
                </div>
              </div>
            </div>
            <div className="row gx-xxl-5">
              <div className="col-xxl-12 col-xl-12">
                <div className="discomSection">
                  <div className="row g-3">
                    <div className="col-xxl-12 col-xl-6 order-2 order-xxl-1">
                      <div className="topStripBox">
                        <div className="customRow">
                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link to={xlsforatcloss}>
                                <div className="headModal">
                                  <img src={ATCLoss} alt="AT&C Loss" title="AT&C Loss" />
                                </div>
                              </Link>
                              <div>
                                <Link className="text-decoration-none" to={xlsforatcloss}>
                                  <h4 className="text-dark">{headerInfomativeData.acc_arr_info['atc_loss']}</h4>
                                </Link>
                                <span className="d-flex w-100 align-items-center justify-content-center">
                                  <Link className="text-decoration-none" to={xlsforatcloss}><h6 className="mb-0">{__('AT&C Loss')}</h6></Link>
                                  <MyTooltip>
                                    All India AT&C Loss (incl. private DISCOMs) as on FY2022-23
                                  </MyTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link to={xlsforarrgap}>
                                <div className="headModal">
                                  <img src={ARRGap} alt="ACS-ARR Gap" title="ACS-ARR Gap" />
                                </div>
                              </Link>
                              <div>
                                <Link className="text-decoration-none" to={xlsforarrgap}>
                                  <h4 className="text-dark"><sub>INR</sub> {headerInfomativeData.acc_arr_info['acc_arr']}<sub>/kWh</sub></h4>
                                </Link>
                                <span className="d-flex w-100 align-items-center justify-content-center">
                                  <Link className="text-decoration-none" to={xlsforarrgap}><h6>{__('ACS-ARR Gap')}</h6></Link>
                                  <MyTooltip>
                                    All India ACS-ARR Gap (Cash Adjusted) as on FY2022-23
                                  </MyTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link className="text-decoration-none" to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/Payable Days.xlsx'} target="_blank" title="Payable Days">
                                <div className="headModal postionTooltip">
                                  <img src={Payable} alt="Payable" title="Payable" />
                                </div>
                              </Link>
                              <div>
                                <Link className="text-decoration-none" to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/Payable Days.xlsx'} target="_blank">
                                  <h4 className="text-dark">{headerInfomativeData.acc_arr_info['payable_day']}</h4>
                                </Link>
                                <span className="d-flex w-100 align-items-center justify-content-center">
                                  <Link className="text-decoration-none" to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/Payable Days.xlsx'} target="_blank"><h6>Payable</h6></Link>
                                  <MyTooltip>
                                    All India Level data as on FY2022-23
                                  </MyTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="customCol-1">
                            <div className="dashBox-1">
                              <Link className="text-decoration-none" to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/Receivable Days.xlsx'} target="_blank">
                                <div className="headModal">
                                  <img src={Receivable} alt="Receivable" title="Receivable" />
                                </div>
                              </Link>
                              <div>
                                <Link className="text-decoration-none" to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/Receivable Days.xlsx'} target="_blank">
                                  <h4 className="text-dark">{headerInfomativeData.acc_arr_info['receivable_day']}</h4>
                                </Link>
                                <span className="d-flex w-100 align-items-center justify-content-center">
                                  <Link to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/Receivable Days.xlsx'} target="_blank" className="text-decoration-none"><h6>Receivable</h6></Link>
                                  <MyTooltip>
                                    All India Level data as on FY2022-23
                                  </MyTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="customCol-2">
                            <div className="dashBox-1">
                              <Link className="text-decoration-none" to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/DiscomsandState.xlsx'} target="_blank">
                                <div className="headModal">
                                  <img src={Discoms} alt="Discoms" title="Discoms" />
                                  <h3 className="text-dark">{headerInfomativeData.total_states} States & UTs</h3>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link className="text-decoration-none" to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/DiscomsandState.xlsx'} target="_blank"><h6>{headerInfomativeData.total_discoms} Discoms</h6></Link>
                                <MyTooltip>DISCOMs sanctioned under RDSS</MyTooltip>
                              </span>
                            </div>
                          </div>
                          <div className="customCol-3">
                            <div className="dashBox-1">
                              <Link to={`/common-table-data?type=sanctioned_cost`} className="text-decoration-none">
                                <div className="headModal">
                                  <img src={SanctionedCost} alt="Total Sanctioned Cost" title="Total Sanctioned Cost" />
                                  <h3 className="text-dark">INR {new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(headerInfomativeData.total_sanction_cost)} Cr</h3>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link to={`/common-table-data?type=sanctioned_cost`} className="text-decoration-none"><h6>Total Sanctioned Cost</h6></Link>
                                <MyTooltip>Total Sanctioned Cost (incl. Smart Metering, Loss Reduction & PMA)</MyTooltip>
                              </span>
                            </div>
                          </div>
                          <div className="customCol-3">
                            <div className="dashBox-1">
                              <Link to={`/common-table-data?type=gbs_sanctioned`} className="text-decoration-none">
                                <div className="headModal">
                                  <img src={GBSSanctioned} alt="Total GBS Sanctioned" title="Total GBS Sanctioned" />
                                  <h3 className="text-dark">INR {new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(headerInfomativeData.total_gbs_sanctioned)} Cr</h3>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link to={`/common-table-data?type=gbs_sanctioned`} className="text-decoration-none"><h6>Total GBS Sanctioned</h6></Link>
                                <MyTooltip>Total GBS Sanctioned (Smart metering(incl. additional incentive), Loss Reduction & PMA)</MyTooltip>
                              </span>
                            </div>
                          </div>
                          <div className="customCol-3">
                            <div className="dashBox-1">
                              <Link to={`/common-table-data?type=gbs_released`} className="text-decoration-none">
                                <div className="headModal">
                                  <img src={GBSRelease} alt="Total GBS Released" title="Total GBS Released" />
                                  <h4 className="text-dark">INR {new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(headerInfomativeData.total_gbs_released)} Cr</h4>
                                </div>
                              </Link>
                              <span className="d-flex w-100 align-items-center justify-content-center">
                                <Link to={`/common-table-data?type=gbs_released`} className="text-decoration-none"><h6>Total GBS Released</h6></Link>
                                <MyTooltip>Total GBS Released (incl. Smart Metering, Loss Reduction & PMA)</MyTooltip>
                              </span>
                            </div>
                          </div>
                          <div className="customCol-4">
                            <div className="dashBox-1 text-start">
                              <div className="row">
                                <div className="col-md-8"><h5>Evaluation Status
                                {
                                  evaluationselected === "2021-22" && (
                                  <Link to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/Evaluation-Status-FY2022-23-V2.xlsx'} target="_blank" title="Evaluation Status" className="text-dark"><FaDownload /></Link>
                                  )
                                }
                                {
                                  evaluationselected === "2022-23" && (
                                  <Link to={process.env.REACT_APP_API_ENDPOINT + '/dashboard/Evaluation-Status-FY2022-23-V2.xlsx'} target="_blank" title="Evaluation Status" className="text-dark"><FaDownload /></Link>
                                  )
                                }
                                </h5>
                                </div>
                                <div className="col-md-4 ps-sm-0 pt-sm-0 pt-2">
                                  <select className="form-select form-select-sm" onChange={evaluationChangeHandler}>
                                    <option>Select FY</option>
                                    <option value={"2021-22"}>2021-22</option>
                                    <option value={"2022-23"} selected>2022-23</option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-flex dashBox-3 mt-2 mt-sm-4">
                                {
                                  evaluationselected === "2021-22" && (<>
                                    <div className="pqBox w-50 pe-2">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <Link className="text-decoration-none text-dark" to="#" target="_blank" title="Qualified">
                                              <h4 className="color-1 me-2 mb-0">
                                                {(headerInfomativeData.pqsop_previous['Qualified'] ? headerInfomativeData.pqsop_previous['Qualified'] : '00')}
                                              </h4>
                                            </Link>
                                            <h6>{__('Qualified')}</h6>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link className="text-decoration-none text-dark" to="#" target="_blank" title="To be Re-evaluated">
                                              <h4 className="color-2 me-2 mb-0">
                                                {(headerInfomativeData.pqsop_previous['To be Re-evaluated'] ? headerInfomativeData.pqsop_previous['To be Re-evaluated'] : '00')}
                                              </h4>
                                            </Link>
                                            <h6>{__('To be Re-evaluated')}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pqBox w-50 ps-2">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <Link className="text-decoration-none text-dark" to="#" target="_blank" title="Under Evaluation">
                                              <h4 className="color-3 me-2 mb-0">
                                                {(headerInfomativeData.pqsop_previous['Under Evaluation'] ? headerInfomativeData.pqsop_previous['Under Evaluation'] : '00')}
                                              </h4>
                                            </Link>
                                            <h6>{__('Under Evaluation')}</h6>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link className="text-decoration-none text-dark" to="#" target="_blank" title="Not Submitted">
                                              <h4 className="color-4 me-2 mb-0">
                                                {(headerInfomativeData.pqsop_previous['Not Submitted'] ? headerInfomativeData.pqsop_previous['Not Submitted'] : '00')}
                                              </h4>
                                            </Link>
                                            <h6>{__('Not Submitted')}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>)
                                }
                                {
                                  evaluationselected === "2022-23" && (<>
                                    <div className="pqBox w-50 pe-2">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <Link className="text-decoration-none text-dark" to="#" target="_blank" title="Qualified">
                                              <h4 className="color-1 me-2 mb-0">
                                                {(headerInfomativeData.pqsop['Qualified'] ? headerInfomativeData.pqsop['Qualified'] : '00')}
                                              </h4>
                                            </Link>
                                            <h6>{__('Qualified')}</h6>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link className="text-decoration-none text-dark" to="#" target="_blank" title="To be Re-evaluated">
                                              <h4 className="color-2 me-2 mb-0">
                                                {(headerInfomativeData.pqsop['To be Re-evaluated'] ? headerInfomativeData.pqsop['To be Re-evaluated'] : '00')}
                                              </h4>
                                            </Link>
                                            <h6>{__('To be Re-evaluated')}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pqBox w-50 ps-2">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <div className="d-flex align-items-center">
                                            <Link className="text-decoration-none text-dark" to="#" target="_blank" title="Under Evaluation">
                                              <h4 className="color-3 me-2 mb-0">
                                                {(headerInfomativeData.pqsop['Under Evaluation'] ? headerInfomativeData.pqsop['Under Evaluation'] : '00')}
                                              </h4>
                                            </Link>
                                            <h6>{__('Under Evaluation')}</h6>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link className="text-decoration-none text-dark" to="#" target="_blank" title="Not Submitted">
                                              <h4 className="color-4 me-2 mb-0">
                                                {(headerInfomativeData.pqsop['Not Submitted'] ? headerInfomativeData.pqsop['Not Submitted'] : '00')}
                                              </h4>
                                            </Link>
                                            <h6>{__('Not Submitted')}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-xxl-4 h-100 order-1 order-xxl-2">
                      <div className="discomLeft h-auto">
                        <div className="heading">
                          <div className="row justify-content-end align-items-center">
                            <div className="col-md-4 col-xxl-4">
                              <label>Select</label>
                              <select defaultValue={0} onChange={changeHandler} className="form-select">
                                <option value={0}>{__('All India')}</option>
                                <option value={1}>{__('PFC States/UTs')}</option>
                                <option value={2}>{__('REC States/UTs')}</option>
                              </select>
                            </div>
                            <div className="col-md-8 col-xxl-8 mt-3 mt-sm-0">
                              <label>{__('KPI')}</label>
                              <Indicators defaultId={selectedDrop} setSelecteddrop={setSelectedDrop} />
                            </div>
                          </div>
                        </div>
                        <div className="mapSection pb-0 position-relative">
                          <div aria-hidden={selected !== "0" ? true : false}><BothMapSvg opt="0" indicatorid={selectedDrop} /></div>
                          <div aria-hidden={selected !== "1" ? true : false}><BothMapSvg opt="1" indicatorid={selectedDrop} /></div>
                          <div aria-hidden={selected !== "2" ? true : false}><BothMapSvg opt="2" indicatorid={selectedDrop} /></div>
                        </div>
                        <div className="IndiaMapLegend">
                          {selectedDrop == "19" ? <ul className="list-unstyled">
                            <li><span></span> &lt;= INR 0/ kWh</li>
                            <li><span></span> &gt; INR 0/ kWh – INR 0.55/ kWh </li>
                            <li><span></span> &gt; INR 0.55/kWh</li>
                          </ul> : ''}
                          {selectedDrop == "18" ? <ul className="list-unstyled">
                            <li><span></span> &lt;=15%</li>
                            <li><span></span> &gt; 15% - 25% </li>
                            <li><span></span> &gt; 25%</li>
                          </ul> : ''}
                          {selectedDrop == "23" ? <ul className="list-unstyled">
                            <li><span></span> &lt;= 146 days</li>
                            <li><span></span> &gt; 146 - 179 days</li>
                            <li><span></span> &gt; 179 days</li>
                          </ul> : ''}
                          {selectedDrop == "24" ? <ul className="list-unstyled">
                            <li><span></span> &lt;= 128 days</li>
                            <li><span></span> &gt; 128 - 156 days</li>
                            <li><span></span> &gt; 156 days</li>
                          </ul> : ''}
                          {['19', '18', '23', '24'].includes(selectedDrop) ? <span><b>12th Integrated Rating Report, As on FY 2022-23</b></span> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-xxl-8 order-3">
                      <div className="discomRight">
                        <div className="dashChart">
                          <div className="DiscomSummary">
                            <div className="row">
                              <div className="col-xl-5 position-relative">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="mb-0 me-3">Smart Metering</h4>
                                  <span className="progressTitle"><BiRupee /> Financial Progress
                                    <span className="alert alert-success">{(childFundData) ? toFixed((parseInt(childFundData.sm_fund_released) / parseInt(childFundData.sm_gbs) * 100), 2) : 0}%
                                    </span>
                                  </span>
                                </div>
                                {(fundChartData) && (<SmartMetering chartdata={fundChartData} childData={fundChildData} chartLink={chartLink} state={{ modal: location }} />)}
                                <span className="chartMainTitle">Fund Details (INR cr)</span>
                                <span className="chartMainTitle anchorLink"><Link to={smAwardStatusLink} title="SM Award Status" target="_blank" className="text-decoration-none">SM Award Status – <CgFileDocument /></Link></span>
                              </div>
                              <div className="col-xl-7 position-relative mt-5 mt-sm-0">
                                <div className="smartMeteringChart">
                                  <Link to={`/all-state-physical-progress?type=sm`} state={{ modal: location }} title="Physical Progress In %" className="text-decoration-none">
                                    <div className="d-flex justify-content-end align-items-center">
                                      <span className="progressTitle"><FaBoxes className="text-primary me-2" /> Physical Progress <span className="alert alert-primary">
                                        {toFixed(((parseInt(smartMetering.cm.instData) + parseInt(smartMetering.dtm.instData) + parseInt(smartMetering.fm.instData) + parseInt(smartMetering.bm.instData)) / (parseInt(smartMetering.cm.sanData) + parseInt(smartMetering.dtm.sanData) + parseInt(smartMetering.fm.sanData) + parseInt(smartMetering.bm.sanData)) * 100), 2)}%
                                      </span>
                                      </span>
                                    </div>
                                  </Link>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row gx-0">
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center mb-0 mt-4">
                                            <div className="chartBreadCrumb">
                                              <Link to={`/all-state-progress?type=cm`} state={{ modal: location }} title="Consumer Metering(nos.)">Consumer Metering <sub>(nos.)</sub></Link>
                                              <ul>
                                                <li>Sanctioned: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.cm.sanData))}</li>
                                                <li>Awarded: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.cm.awaData))}</li>
                                                <li>Installed: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.cm.instData))}</li>
                                              </ul>
                                            </div>
                                            <ConsumerMeteringChart sanData={parseInt(smartMetering.cm.sanData)} awaData={parseInt(smartMetering.cm.awaData)} instData={parseInt(smartMetering.cm.instData)} categories="Consumer Metering" />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center mb-0 mt-4">
                                            <div className="chartBreadCrumb">
                                              <Link to={`/all-state-progress?type=dm`} state={{ modal: location }} title="DT Metering(nos.)">DT Metering <sub>(nos.)</sub></Link>
                                              <ul>
                                                <li>Sanctioned: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.dtm.sanData))}</li>
                                                <li>Awarded: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.dtm.awaData))}</li>
                                                <li>Installed: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.dtm.instData))}</li>
                                              </ul>
                                            </div>
                                            <DTMeteringChart sanData={parseInt(smartMetering.dtm.sanData)} awaData={parseInt(smartMetering.dtm.awaData)} instData={parseInt(smartMetering.dtm.instData)} categories="DT Metering" />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <div className="chartBreadCrumb">
                                              <Link to={`/all-state-progress?type=fm`} state={{ modal: location }} title="Feeder Metering(nos.)">Feeder Metering <sub>(nos.)</sub></Link>
                                              <ul>
                                                <li>Sanctioned: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.fm.sanData))}</li>
                                                <li>Awarded: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.fm.awaData))}</li>
                                                <li>Installed: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.fm.instData))}</li>
                                              </ul>
                                            </div>
                                            <FeederMeteringChart sanData={parseInt(smartMetering.fm.sanData)} awaData={parseInt(smartMetering.fm.awaData)} instData={parseInt(smartMetering.fm.instData)} categories="Feeder Metering" />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <div className="chartBreadCrumb">
                                              <Link to={`/all-state-progress?type=bm`} state={{ modal: location }} title="Boundary Metering(nos.)">Boundary Metering <sub>(nos.)</sub></Link>
                                              <ul>
                                                <li>Sanctioned: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.bm.sanData))}</li>
                                                <li>Awarded: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.bm.awaData))}</li>
                                                <li>Installed: {new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(parseInt(smartMetering.bm.instData))}</li>
                                              </ul>
                                            </div>
                                            <BoundaryMeteringChart sanData={parseInt(smartMetering.bm.sanData)} awaData={parseInt(smartMetering.bm.awaData)} instData={parseInt(smartMetering.bm.instData)} categories="Boundary Metering" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="apexchartsLegendCustom d-flex align-items-center justify-content-center">
                                    <div><span className="sanctionedBG"></span> Sanctioned</div>
                                    <div><span className="gbsBg"></span> Awarded</div>
                                    <div><span className="installBg"></span> Installed</div>
                                  </div>
                                </div>
                                <span className="chartMainTitle">
                                  <span>Progress Details (Nos.)</span>
                                </span>
                                <span className="chartMainTitle anchorLink">
                                  <Link to={pdfonboardLink1} title="PMA" target="_blank" className="text-decoration-none">
                                    <span>
                                      {(selected == '0')
                                        ? '36 Nos.'
                                        : (selected == '1') ? '16 Nos.' : '20 Nos.'}
                                    </span>
                                    PMA onboarded – <CgFileDocument />
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="DiscomSummary mt-3">
                            <div className="row">
                              <div className="col-xl-5 position-relative">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="mb-0 me-3">Loss Reduction</h4>
                                  <span className="progressTitle"><BiRupee /> Financial Progress <span className="alert alert-success">{(childFundData) ? toFixed((parseInt(childFundData.lsr_fund_released) / parseInt(childFundData.lsr_gbs) * 100), 2) : 0}%</span></span>
                                </div>
                                {(fundChartData) && (<LossReducation chartdata={fundChartData} chartLinkLr={chartLinkLr} state={{ modal: location }} />)}
                                <span className="chartMainTitle">Fund Details (INR cr)</span>
                                <span className="chartMainTitle anchorLink"><Link to={lrAwardStatusLink} title="LR Award Status" target="_blank" className="text-decoration-none">LR Award Status – <CgFileDocument /></Link></span>
                              </div>
                              <div className="col-xl-7 position-relative mt-5 mt-sm-0">
                                <div className="smartMeteringChart">
                                  <Link to={`/all-state-physical-progress-loss?type=ls`} state={{ modal: location }} title="Physical Progress In %" className="text-decoration-none">
                                    <div className="d-flex justify-content-end align-items-center">
                                      <span className="progressTitle"><FaBoxes className="text-primary me-2" /> Physical Progress <span className="alert alert-primary">{physicalProgressLoss}%</span></span>
                                    </div>
                                  </Link>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row gx-0">
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center mb-0 mt-4">
                                            <div className="chartBreadCrumb">
                                              <Link to={`/all-state-progress-loss?type=LT`} title="LT lines" state={{ modal: location }}>LT lines<sub>(ckm.)</sub></Link>
                                              <ul>
                                                <li>Sanctioned: {infrastructureWorks[0].sanctioned}</li>
                                                <li>Awarded: {infrastructureWorks[0].awarded}</li>
                                                <li>Installed: {infrastructureWorks[0].installed}</li>
                                              </ul>
                                            </div>
                                            {
                                            infrastructureWorks[0]['sanction_pern'] > 0 ? 
                                            <LTlinesChart infraData={infrastructureWorks[0]} />
                                            :null
                                            }
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center mb-0 mt-4">
                                            <div className="chartBreadCrumb">
                                              <Link to={`/all-state-progress-loss?type=HT`} title="HT lines" state={{ modal: location }}>HT lines<sub>(ckm.)</sub></Link>
                                              <ul>
                                                <li>Sanctioned: {infrastructureWorks[1].sanctioned}</li>
                                                <li>Awarded: {infrastructureWorks[1].awarded}</li>
                                                <li>Installed: {infrastructureWorks[1].installed}</li>
                                              </ul>
                                            </div>
                                            {
                                            infrastructureWorks[1]['sanction_pern'] > 0 ? 
                                            <HTlinesChart infraData={infrastructureWorks[1]} />
                                            :null
                                            }
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <div className="chartBreadCrumb">
                                              <Link to={`/all-state-progress-loss?type=DTR`} title="DTRs" state={{ modal: location }}>DTRs<sub>(nos.)</sub></Link>
                                              <ul>
                                                <li>Sanctioned: {infrastructureWorks[2].sanctioned}</li>
                                                <li>Awarded: {infrastructureWorks[2].awarded}</li>
                                                <li>Installed: {infrastructureWorks[2].installed}</li>
                                              </ul>
                                            </div>
                                            {
                                            infrastructureWorks[2]['sanction_pern'] > 0 ? 
                                            <DTRsChart infraData={infrastructureWorks[2]} />
                                            :null
                                            }
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="d-flex align-items-center">
                                            <div className="chartBreadCrumb">
                                              <Link to={`/all-state-progress-loss?type=SUB`} title="Substations" state={{ modal: location }}>Substations<sub>(nos.)</sub></Link>
                                              <ul>
                                                <li>Sanctioned: {infrastructureWorks[3].sanctioned}</li>
                                                <li>Awarded: {infrastructureWorks[3].awarded}</li>
                                                <li>Installed: {infrastructureWorks[3].installed}</li>
                                              </ul>
                                            </div>
                                            {
                                            infrastructureWorks[3]['sanction_pern'] > 0 ? 
                                            <SubstationsChart infraData={infrastructureWorks[3]} />
                                            :null
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="apexchartsLegendCustom d-flex align-items-center justify-content-center">
                                    <div><span className="sanctionedBG"></span> Sanctioned</div>
                                    <div><span className="gbsBg"></span> Awarded</div>
                                    <div><span className="installBg"></span> Installed</div>
                                  </div>
                                </div>
                                <span className="chartMainTitle">
                                  <span>Progress Details (%)</span>
                                </span>
                                <span className="chartMainTitle anchorLink">
                                  <Link to={pdfonboardLink2} title="PMA" target="_blank" className="text-decoration-none">
                                    <span>
                                      {(selected == '0')
                                        ? '45 Nos. '
                                        : (selected == '1') ? '22 Nos.' : '23 Nos.'}
                                    </span>
                                    PMA onboarded – <CgFileDocument />
                                  </Link>
                                  <Link className="float-end me-4" to={'/asset-wise-reports'}>View Asset-wise report</Link>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {mobile === '' ? <DashboardFooter remove='d-none' /> : null}
      </div>
    </>
  );
}

export default Dashboard;