import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Scheme from "../Components/HomeComponents/Scheme/Scheme";
import RModules from "../Components/HomeComponents/ReportingModule/ReportingModule";
import PressRelease from "../Components/HomeComponents/PressRelease/PressRelease";
import ImageGallery from "../Components/HomeComponents/ImageGallery/ImageGallery";
import 'aos/dist/aos.css'
import '../css/banner.scss'

// Image
import PmImg from '../images/narendra-modi.png';
import MarqueeText from "../Components/HomeComponents/Marquee/MarqueeText";
import Marquee from "react-fast-marquee";
import { Tweet, Timeline } from "react-twitter-widgets";

export default function Home() {
    const AOS = require('aos');
    useEffect(() => {
        AOS.init({
            once: true,
            disable: 'mobile'
        });
        AOS.refresh();
    }, []);
    return (
        <>
            <div className="mainWrap">
                <Header />

                <section className="bannerSection schemeSection bannerVideoSection">
                <div className="banner-video-wrap">
                    <video className="banner-video" title="Powermin" playsInline muted autoPlay loop src={ process.env.REACT_APP_API_ENDPOINT + "/accenture_rdss.mp4"}>
                    </video>
                </div>
                    {/* <div className="container position-relative h-100">
                        <h1 className="text-center" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-easing="linear" data-aos-duration="500"><span>Integrated Platform for Power Distribution Sector Schemes</span></h1>
                        <div className="bannerModiWrap">
                            <div className="imgWrap" data-aos="fade-in" data-aos-duration="1200">
                                <img src={PmImg} className="img-fluid" alt="Hon'ble Prime Minister of India" title="Hon'ble Prime Minister of India" />
                            </div>
                            <div className="quote">
                                <div className="quoteInner">
                                    <p className="mb-0 quotation">Energy sector plays a big role in the progress of the country and contributes to both ease of living and ease of doing business</p>
                                    <p className="pmName mb-0">Shri Narendra Modi</p>
                                    <p className="pmTag mb-0">Hon'ble Prime Minister of India</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="banner-video-wrap">
                        <video class="banner-video" control="false" autoplay="true" loop="true" muted="true">
                            <source src="images/banner-video.mp4" type="video/mp4" />
                        </video>
                    </div> */}
                    <Scheme />
                </section>

                <MarqueeText />


                {/* <RModules /> */}

                <section className="reportSection">
                    <div className="container">
                        <div className="row">
                            <PressRelease />
                            <ImageGallery />
                            <div className="col-xl-4 col-xxl-4 col-md-6">
                                <Timeline dataSource={{ sourceType: "profile", screenName: "MinOfPower" }} options={{height: '400'}}/>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    );
};
