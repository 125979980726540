import React, { Component } from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'

export default class WebsitePolicy extends Component {

    render() {
        return (
            <>
                <Header />
                <InnerTemplate sublink={false} PageTitle="Website Policy" />
                <div className='main'>
                    <div className='container'>
                        <div className='screenReader'>
                            <h4>Privacy Policy:</h4>
                            <p>As a rule, this website does not collect Personal Information about user who visits the site. Any person can generally visit the site without revealing Personal Information unless he / she chooses to provide such information.</p>
                            <ul>
                                <li><p><b>Site Visit Data</b>: This website records user visits and logs the following information for statistical purposes: IP address from which the user accesses the website; the type of browser and operating system used to access the website; the date and time user accessed the website; the pages user has accessed, and the documents downloaded; if user reaches this website from another website, the address of that referring website. This information is used only to help REC make the website more user-friendly. REC never tracks or records information about individuals and their visits. REC makes no attempt to link this information with the identity of individuals visiting the website unless an attempt to damage the site has been detected. REC does not identify users or their browsing activities, except when a law enforcement agency may exercise a warrant to inspect the server provider’s logs.</p></li>
                                <li><p><b>Cookies</b>: A cookie is a piece of software code that an internet web site sends to user’s browser when he /she accesses information at that site. This website uses cookies but not for user information gathering.</p></li>
                                <li><p><b>Email Management</b>: User's email address is recorded only if user chooses to send a message. It will only be used for the purpose for which the user provides it and will not be added to a mailing list. User’s email address will not be used for any other purpose unless it is intended by the user, and will not be disclosed, without user's consent.</p></li>
                                <li><p><b>Email Management</b>: User's email address is recorded only if user chooses to send a message. It will only be used for the purpose for which the user provides it and will not be added to a mailing list. User’s email address will not be used for any other purpose unless it is intended by the user, and will not be disclosed, without user's consent.</p></li>
                            </ul>
                            <h4 className='mt-5'>Hyperlink Policy:</h4>
                            <ul className='mt-4'>
                                <li><p><b>Links to REC website by other websites</b>: Prior permission is required before hyperlinks are directed from any website/portal to this site. Permission for the same, stating the nature of the content on the pages from where the link must be given, and the exact language of the hyperlink should be obtained by sending request to REC.</p></li>
                                <li><p><b>Links to external websites / portals</b>: At some places, this website contains links to other websites/portals. These links have been placed for the user’s convenience. REC is not responsible for the contents and reliability of the links. Mere presence of the link or its listing on this website should not be assumed as endorsement of any kind. REC cannot guarantee that these links will work all the time and REC has no control over availability of linked pages at all times.</p></li>
                            </ul>
                            <h4 className='mt-5'>Broken Links Policy:</h4>
                            <p>REC monitors the platform to address and fix the broken links (if any).</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
