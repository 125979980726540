import React, { useEffect, useState } from "react"
import DashboardFooter from '../Components/Footer/DashboardFooter'
import DashboardHeader from '../Components/Header/DashboardHeader'
import { BiArrowBack } from 'react-icons/bi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProgressDetailsMainChart from '../Components/DashboardComponents/Chart/ProgressDetailsChart/FinancialProgressMain'
import { BsBarChart, BsFileEarmarkPdf, BsTable } from "react-icons/bs"
import './dashboard.scss';
import { useLayout } from "../Core/Providers"
import { RiFileExcel2Line } from "react-icons/ri"
import { Http } from "../Core/Services/HttpService"
import { utils, writeFile } from "xlsx";
import { Modal } from "react-bootstrap"
import Select from 'react-select';

const SmFinancialProgressView = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const locationModal = location.state && location.state.modal;

    const [params, setParams] = useState<any>();
    const [progressData, setProgressData] = useState<any>([]);
    const [reportData, setReportData] = useState<any>([]);
    const [selected, setSelected] = useState("ChartSection");
    const [chartLink, setChartLink] = useState<string>('');
    const [headingTitle, setHeadingTitle] = useState<string>('Smart Metering State wise Progress');
    const [noDataMessage, setNoDataMessage] = useState<string>('Loading...');
    const [radioOptions, setRadioOptions] = useState<any>([]);
    const [defaultVal, setDefaultVal] = useState("");
    const [radioval, setRadioVal] = useState("");
    const [options, setOptions] = useState<any>([]);
    const [filterParams, setFilterParams] = useState<any>({
        sortBy: 'alphabetically',
        query: [],
    });

    const layout = useLayout();
    let srNo = 1;
    let reportType = 'DisStates';

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
        layout.set({ awarditemstype: e.target.value });
    };

    const fetchProgressData = async (fParams: any = {}) => {
        setProgressData([]);
        setNoDataMessage('Loading...');
        let majorcomponentidVal = '0';
        if (fParams.type == 'san-cost') {
            majorcomponentidVal = '1';
        } else if (fParams.type == 'awar-cost') {
            majorcomponentidVal = '2';
        } else if (fParams.type == 'gbs-sanc') {
            majorcomponentidVal = '3';
        } else if (fParams.type == 'gbs-rel') {
            majorcomponentidVal = '4';
        }

        if (fParams.chartfor == 'state') {
            fParams.stateid = fParams.id;
            reportType = 'Discom';
        }
        if (fParams.chartfor == 'discom') {
            fParams.stateid = fParams.state;
            fParams.discomid = fParams.id;
            reportType = 'Districts';
        }

        const fnlParams = {
            ...fParams,
            reportType: reportType,
            majorcomponentid: majorcomponentidVal,
            subcomp: '0',
            //monitoringPar: '1, 3, 4, 6, 7, 9, 12',
            module: 'sm'
        };
        await Http.get('/apiv1/d-board/financialProgressData', { params: fnlParams })
            .then((res) => {
                if (res._resultflag == "1") {
                    setProgressData(res.stateProgressData);
                } else {
                    setNoDataMessage('No Data Found!');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found!');
            });
    };

    const exportToPDF = () => {
        let table = document.getElementById("progress-data")?.cloneNode(true) as any;
        table = document.createDocumentFragment().appendChild(table);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const frmData = new FormData();
        frmData.append('content', encodeURIComponent(table.outerHTML));
        frmData.append('filename', 'progressdata');
        frmData.append('download', 'no');
        Http.post('/apiv1/contentExportPdf', frmData, config)
            .then((res) => {
                if (res._resultflag == "1") {
                    window.open(res.file, "_blank");
                } else {
                    setNoDataMessage('No Data Found!');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found!');
            });
    };

    const exportToExcel = () => {
        const table = document.getElementById('progress-data');
        const wb = utils.table_to_book(table);
        writeFile(wb, 'progressdata.xlsx');
    };

    const sortBy = (e: any) => {
        setFilterParams((prev: any) => { return { ...prev, sortBy: e.target.value } });
    };

    const filterQuery = (item: any) => {
        setFilterParams((prev: any) => { return { ...prev, query: item } });
    };

    useEffect(() => {
        setReportData(progressData);
    }, [progressData]);

    useEffect(() => {
        if (params) {
            setChartLink('');
            //setSelected("ChartSection");
            fetchProgressData(params);
            switch (params.chartfor) {
                case 'discom':
                    setHeadingTitle('District wise Financial Progress');
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
                case 'state':
                    setChartLink(`/sm-financial-progress?chartfor=discom&type=${params.type}&state=${params.id}`);
                    setHeadingTitle('Smart Metering Discom wise Financial Progress');
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
                case 'all':
                    setChartLink(`/sm-financial-progress?chartfor=state&type=${params.type}`);
                    setHeadingTitle('Smart Metering State wise Financial Progress');
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
            }
        }
    }, [params]);

    useEffect(() => {
        if (params?.type) {
            if (params?.type == 'san-cost') {
                setRadioOptions([
                    { label: 'Sanctioned and Awarded Cost – INR Cr', value: 'san-cost' },
                    { label: 'Awarded / Sanctioned Cost - %', value: 'aws_cost' },
                    { label: 'GBS Released / GBS Sanctioned - %', value: 'gbs_res' },
                ]);
            }
            if (params?.type == 'awar-cost') {
                setRadioOptions([
                    { label: 'Sanctioned and Awarded Cost – INR Cr', value: 'awar-cost' },
                    { label: 'Awarded / Sanctioned Cost - %', value: 'aws_cost' },
                    { label: 'GBS Released / GBS Sanctioned - %', value: 'gbs_res' },
                ]);
            }
            if (params?.type == 'gbs-sanc') {
                setRadioOptions([
                    { label: 'GBS Sanctioned and GBS Released – INR Cr', value: 'gbs-sanc' },
                    { label: 'Awarded / Sanctioned Cost - %', value: 'aws_cost' },
                    { label: 'GBS Released / GBS Sanctioned - %', value: 'gbs_res' },
                ]);
            }
            if (params?.type == 'gbs-rel') {
                setRadioOptions([
                    { label: 'GBS Sanctioned and GBS Released – INR Cr', value: 'gbs-rel' },
                    { label: 'Awarded / Sanctioned Cost - %', value: 'aws_cost' },
                    { label: 'GBS Released / GBS Sanctioned - %', value: 'gbs_res' },
                ]);
            }
        }
    }, [params?.type]);

    const handleOptionChange = async (event: any) => {
        const value = event.target.value;
        setDefaultVal(value);
        setRadioVal(event.target.labels[0].innerText);
        setParams((prev: any) => ({ ...prev, type: value }));
    };

    useEffect(() => {
        const sectionfor = new URLSearchParams(location.search).get('sectionfor');
        if (sectionfor == 'TableSection') { setSelected("TableSection"); }

        const type = new URLSearchParams(location.search).get('type');
        if (type == 'san-cost') {
            setDefaultVal('san-cost');
            setRadioVal('Sanctioned and Awarded Cost – INR Cr');
        }
        if (type == 'awar-cost') {
            setDefaultVal('awar-cost');
            setRadioVal('Awarded / Sanctioned Cost - %');
        }
        if (type == 'gbs-sanc') {
            setDefaultVal('gbs-sanc');
            setRadioVal('GBS Sanctioned and GBS Released – INR Cr');
        }
        if (type == 'gbs-rel') {
            setDefaultVal('gbs-rel');
            setRadioVal('GBS Released / GBS Sanctioned - %');
        }
        setParams({
            id: new URLSearchParams(location.search).get('id') || null,
            state: new URLSearchParams(location.search).get('state') || null,
            discom: new URLSearchParams(location.search).get('discom') || null,
            chartfor: new URLSearchParams(location.search).get('chartfor') || 'all',
            type: new URLSearchParams(location.search).get('type')
        });
    }, [location]);

    useEffect(() => {
        const opt = progressData.map((item: any) => {
                return { label: item.title, value: item.title };
        });
        opt.pop();
        setOptions(opt);
    }, [progressData]);

    useEffect(() => {
        (async () => {
            if (progressData.length > 0) {
                let filterData = JSON.parse(JSON.stringify(progressData));
                let lastrec = filterData.pop();
                if (filterParams.query.length > 0) {
                    filterData = await filterData.filter((element: any) => filterParams.query.some((item: any) => item.value === element.title));
                }
                filterData.sort((a: any, b: any) => (a.title > b.title) ? 1 : -1);
                if (filterParams.sortBy == 'alphabetically') {
                    filterData.sort((a: any, b: any) => (a.title > b.title) ? 1 : -1);
                }
                else if (filterParams.sortBy == 'asc') {
                    filterData.sort((a: any, b: any) => (a.approved_project_cost > b.approved_project_cost) ? 1 : -1);
                } else {
                    filterData.sort((a: any, b: any) => (a.approved_project_cost < b.approved_project_cost) ? 1 : -1);
                }
                if ( filterParams.query.length == 0 )
                    filterData.push(lastrec);
                setReportData(filterData);
            }
        })();
    }, [progressData, filterParams]);

    const Content = () => {
        const linkState = (locationModal)
            ? { state: { modal: locationModal } }
            : {};

        return <div className="container-fluid">
            <div className="dashboardInner detailedDashboard">
                <div className="row gx-xxl-5">
                    <div className="col-xxl-12 col-xl-12">
                        <div className="discomSection">
                            <div className="discomRight">
                                <div className="dashChart">
                                    <div className="DiscomSummary">
                                        <div className="row align-items-center">
                                            <div className="col-md-2">
                                                <select className="form-select" onChange={sortBy}>
                                                    <option value={"alphabetically"} selected={filterParams.sortBy === "alphabetically"}>A-Z</option>
                                                    <option value={"asc"} selected={filterParams.sortBy === "asc"}>Ascending</option>
                                                    <option value={"desc"} selected={filterParams.sortBy === "desc"}>Descending</option>
                                                </select>
                                            </div>
                                            <div className="col-md-7">
                                                <Select
                                                    isMulti={true}
                                                    options={options}
                                                    onChange={filterQuery}
                                                    value={filterParams.query}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <ul className="list-unstyled d-flex switch-field justify-content-end">
                                                    <li className="position-relative">
                                                        <input type="radio" value="ChartSection" id="ChartSection" checked={selected === "ChartSection"} onChange={changeHandler} />
                                                        <label htmlFor="ChartSection"><BsBarChart /></label>
                                                    </li>
                                                    <li className="position-relative">
                                                        <input type="radio" value="TableSection" id="TableSection" checked={selected === "TableSection"} onChange={changeHandler} />
                                                        <label htmlFor="TableSection"><BsTable /></label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {selected == "ChartSection" ? <div className="col-md-12 d-flex align-items-center radioSelect mb-3">
                                            {radioOptions.map((option: any) => (
                                                <div className='form-check me-3'>
                                                    <input
                                                        id={option.value}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={option.value}
                                                        checked={defaultVal === option.value}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <label htmlFor={option.value} className="form-check-label" key={option.value}>
                                                        {option.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div> : ''}
                                        <div aria-hidden={selected !== "ChartSection" ? true : false}>
                                            {(selected == "ChartSection") && <ProgressDetailsMainChart progressData={reportData} noDataMessage={noDataMessage} chartLink={chartLink} ptype={params?.type} headingTitle={headingTitle} />}
                                        </div>
                                        <div aria-hidden={selected !== "TableSection" ? true : false}>
                                            <div className="d-flex justify-content-end mt-2">
                                                <div className="pdfBox" onClick={() => exportToPDF()}><BsFileEarmarkPdf /></div>
                                                <div className="excelBox bg-success" onClick={() => exportToExcel()}><RiFileExcel2Line /></div>
                                            </div>
                                            <div className="table-responsive progressDetailsTable">
                                                <table className="table-responsive RevenueDetailsTable PqTable mx-auto" id="progress-data">
                                                    <thead className="text-center">
                                                        <tr className="d-none show-pdf">
                                                            <th colSpan={6} className="text-start">
                                                                <h6 className="mb-2 text-start">
                                                                    {params?.type == 'san-cost' ? headingTitle + ' - Sanctioned and Awarded Cost – INR Cr' : ''}
                                                                    {params?.type == 'awar-cost' ? headingTitle + ' - Sanctioned and Awarded Cost – INR Cr' : ''}
                                                                    {params?.type == 'gbs-sanc' ? headingTitle + ' - GBS Sanctioned and GBS Released – INR Cr' : ''}
                                                                    {params?.type == 'gbs-rel' ? headingTitle + ' - GBS Sanctioned and GBS Released – INR Cr' : ''}
                                                                    {params?.type == 'aws_cost' ? headingTitle + ' - Awarded/ Sanctioned Cost - %' : ''}
                                                                    {params?.type == 'gbs_res' ? headingTitle + ' - GBS Released/ GBS Sanctioned - %' : ''}
                                                                </h6>
                                                                <span>{radioval}</span>
                                                                <p className="text-end"> {new Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'medium', timeZone: 'Asia/Kolkata' }).format(new Date())}</p>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th className="text-start">
                                                                {params?.chartfor == 'all' ? 'State' : ''}
                                                                {params?.chartfor == 'state' ? 'Discom' : ''}
                                                                {params?.chartfor == 'discom' ? 'District' : ''}
                                                            </th>
                                                            <th>Approved Project Cost (INR Cr)</th>
                                                            <th>Award Cost (INR Cr)</th>
                                                            <th>Award Cost/ Sanctioned Cost (%)</th>
                                                            <th>GBS Sanctioned(INR Cr)</th>
                                                            {['awar-cost', 'aws_cost', 'gbs_res', 'gbs-sanc', 'san-cost', 'gbs-rel'].includes(defaultVal) && (params?.chartfor == 'discom') ? '' : <th>GBS Released (INR Cr)</th>}
                                                            {['awar-cost', 'aws_cost', 'gbs_res', 'gbs-sanc', 'san-cost', 'gbs-rel'].includes(defaultVal) && (params?.chartfor == 'discom') ? '' : <th>GBS Released/ GBS Sanctioned (%)</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-center">
                                                        {
                                                            (reportData.length <= 0)
                                                                ? <tr><td colSpan={7} className="text-center">{noDataMessage}</td></tr>
                                                                : null
                                                        }
                                                        {
                                                            reportData.map((item: any, index: any) => (
                                                                <tr key={index}>
                                                                    <td>{item.title == 'Total..' ? '' : srNo++}</td>
                                                                    { item.title == 'Total..' || params?.chartfor == 'discom' || (params?.chartfor == 'state' && defaultVal == 'gbs_res') ? <td className="text-start">{item.title}</td> : <td className="text-start"><Link to={chartLink + '&id=' + item.id + '&sectionfor=TableSection'} {...linkState}>{item.title}</Link></td>}
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.approved_project_cost)}</td>
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sm_award_cost)}</td>
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sm_percentage_award_cost_approved_cost)}</td>
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.gbs_sanctioned)}</td>
                                                                    {['awar-cost', 'aws_cost', 'gbs_res', 'gbs-sanc', 'san-cost', 'gbs-rel'].includes(defaultVal) && (params?.chartfor == 'discom') ? '' : <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sm_gbs_released)}</td>}
                                                                    {['awar-cost', 'aws_cost', 'gbs_res', 'gbs-sanc', 'san-cost', 'gbs-rel'].includes(defaultVal) && (params?.chartfor == 'discom') ? '' : <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.sm_percentage_gbs_released_gbs_anctioned)}</td>}
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <h4 className="mb-0 text-end stickyBack"><a onClick={() => navigate(-1)} className="text-decoration-none text-dark"><em className="arrow"><BiArrowBack onClick={() => navigate(-1)} /></em> Go Back</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    };

    return (
        <>
            {
                (locationModal)
                    ? (
                        <Modal show={true} onHide={() => navigate(-1)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {params?.type == 'san-cost' ? headingTitle + ' - Sanctioned and Awarded Cost – INR Cr' : ''}
                                    {params?.type == 'awar-cost' ? headingTitle + ' - Sanctioned and Awarded Cost – INR Cr' : ''}
                                    {params?.type == 'gbs-sanc' ? headingTitle + ' - GBS Sanctioned and GBS Released – INR Cr' : ''}
                                    {params?.type == 'gbs-rel' ? headingTitle + ' - GBS Sanctioned and GBS Released – INR Cr' : ''}
                                    {params?.type == 'aws_cost' ? headingTitle + ' - Awarded/ Sanctioned Cost - %' : ''}
                                    {params?.type == 'gbs_res' ? headingTitle + ' - GBS Released/ GBS Sanctioned - %' : ''}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Content />
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                    )
                    : (
                        <div className="mainWrap dashboard">
                            <DashboardHeader />
                            <Content />
                            <DashboardFooter remove='d-none' />
                        </div>
                    )
            }
        </>
    );
};

export default SmFinancialProgressView;