import { Stepper, Step } from "react-form-stepper";
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./rdssforms.scss";
import InnerTemplate from "../Components/InnerTemplate/InnerTemplate";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
// Forms
import { Http } from "../Core/Services/HttpService";
import AuthorisedSignatoryForm from "../Components/FundDisbursalForms/AuthorisedSignatoryForm";
import RequestForFundForm from "../Components/FundDisbursalForms/RequestForFundForm";
import ClaimForms from "../Components/FundDisbursalForms/ClaimForm";
import PhysicalProgressForm from "../Components/FundDisbursalForms/PhysicalProgressForm";
import FinancialProgressForm from "../Components/FundDisbursalForms/FinancialProgressForm";
import UtilisationForm from "../Components/FundDisbursalForms/UtilisationForm";
import AuditCertificateForm from "../Components/FundDisbursalForms/AuditCertificateForm";

export default function FundDisbursalForm(props: any) {
    return (
        <div className="App">
            <PlaceOrder {...props} />
        </div>
    );
}

export const PlaceOrder = (props: any) => {
    function onSubmit(e: any) {
        console.log('hrere');
        e.preventDefault();
        // console.log(validatefrm());
        let myFormData = new FormData(e.target);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        Http.post('/apiv1/save-form', myFormData, config)
            .then((res) => {
                console.log(res);
            });

    }
    const formData: any = useRef(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [steps, setSteps] = useState(['active', '', '', '', '', '']);
    // Nav Steps
    const next = (opt: number) => {
        if (opt == 0)
            setSteps(['active', '', '', '']);
        if (opt == 1)
            setSteps(['completed', 'active', '', '']);
        if (opt == 2)
            setSteps(['completed', 'completed', 'active', '']);
        if (opt == 3)
            setSteps(['completed', 'completed', 'completed', 'active']);
        if (opt == 4)
            setSteps(['completed', 'completed', 'completed', 'completed', 'active']);
        if (opt == 5)
            setSteps(['completed', 'completed', 'completed', 'completed', 'completed', 'active']);
        if (opt == 6)
            setSteps(['completed', 'completed', 'completed', 'completed', 'completed', 'completed', 'active']);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        Http.post('/apiv1/next-form', myFormData, config)
            .then((res) => {
                // console.log(res);
            });

        setGoSteps(opt);
    }

    const navtab = (opt: number) => {
        if (opt == 0)
            setSteps(['active', '', '', '']);
        if (opt == 1)
            setSteps(['completed', 'active', '', '']);
        if (opt == 2)
            setSteps(['completed', 'completed', 'active', '']);
        if (opt == 3)
            setSteps(['completed', 'completed', 'completed', 'active']);
        if (opt == 4)
            setSteps(['completed', 'completed', 'completed', 'completed', 'active']);
        if (opt == 5)
            setSteps(['completed', 'completed', 'completed', 'completed', 'completed', 'active']);
        if (opt == 6)
            setSteps(['completed', 'completed', 'completed', 'completed', 'completed', 'completed', 'active']);
        setGoSteps(opt);
    }
    const [goSteps, setGoSteps] = useState(0);

    useEffect(() => {
        if (props.opt) navtab(parseInt(props.opt));
    }, [props]);

    return (
        <>
            <Header />
            <div className="mainWrap">
                <InnerTemplate PageTitle="Fund Disbursal Form" />
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <form noValidate onSubmit={onSubmit}>
                                        <div>
                                            {/* <Stepper activeStep={goSteps}>
                                            <Step onClick={() => setGoSteps(0)} label="Authorised Signatory" />
                                            <Step onClick={() => setGoSteps(1)} label="Request for fund release" />
                                            <Step onClick={() => setGoSteps(2)} label="Claim Forms" />
                                            <Step onClick={() => setGoSteps(3)} label="Physical Progress" />
                                            <Step onClick={() => setGoSteps(4)} label="Financial Progress" />
                                            <Step onClick={() => setGoSteps(5)} label="Utilisation Forms" />
                                            <Step onClick={() => setGoSteps(6)} label="Audit Certificate" />
                                        </Stepper> */}
                                            <div className='container'>
                                                <div className='customStepper'>
                                                    <div className="StepperContainer-0-2-1">
                                                        <div className="StepContainer-0-2-2">
                                                            <div className="StepMain-0-2-3">
                                                                <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 " + (steps[0] ? steps[0] : '')}>
                                                                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 " + (steps[0] ? steps[0] : '')}>1</span>
                                                                </button>
                                                                <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Authorised Signatory</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="StepContainer-0-2-2">
                                                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                                                            <div className="StepMain-0-2-3">
                                                                <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 " + (steps[1] ? steps[1] : '')}>
                                                                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 " + (steps[1] ? steps[1] : '')}>2</span>
                                                                </button>
                                                                <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Request for fund release</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="StepContainer-0-2-2">
                                                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d2-0-2-22"><span className="Connector-0-2-15 Connector-d3-0-2-23"></span></div>
                                                            <div className="StepMain-0-2-3">
                                                                <button onClick={() => navtab(2)} className={"StepButton-0-2-4 StepButton-d4-0-2-20 " + (steps[2] ? steps[2] : '')}>
                                                                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d5-0-2-21 " + (steps[2] ? steps[2] : '')}>3</span>
                                                                </button>
                                                                <div className="LabelContainer-0-2-8 LabelContainer-d4-0-2-24"><span className="Label-0-2-9 Label-d5-0-2-25">Claim Forms</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="StepContainer-0-2-2">
                                                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                                                            <div className="StepMain-0-2-3">
                                                                <button onClick={() => navtab(3)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[3] ? steps[3] : '')}>
                                                                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[3] ? steps[3] : '')}>4</span>
                                                                </button>
                                                                <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Physical Progress</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="StepContainer-0-2-2">
                                                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                                                            <div className="StepMain-0-2-3">
                                                                <button onClick={() => navtab(4)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[4] ? steps[4] : '')}>
                                                                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[4] ? steps[4] : '')}>5</span>
                                                                </button>
                                                                <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Financial Progress</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="StepContainer-0-2-2">
                                                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                                                            <div className="StepMain-0-2-3">
                                                                <button onClick={() => navtab(5)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[5] ? steps[5] : '')}>
                                                                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[5] ? steps[5] : '')}>6</span>
                                                                </button>
                                                                <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Utilisation Forms</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="StepContainer-0-2-2">
                                                            <div className="ConnectorContainer-0-2-14 ConnectorContainer-d4-0-2-28"><span className="Connector-0-2-15 Connector-d5-0-2-29"></span></div>
                                                            <div className="StepMain-0-2-3">
                                                                <button onClick={() => navtab(6)} className={"StepButton-0-2-4 StepButton-d6-0-2-26 " + (steps[6] ? steps[6] : '')}>
                                                                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d7-0-2-27 " + (steps[6] ? steps[6] : '')}>7</span>
                                                                </button>
                                                                <div className="LabelContainer-0-2-8 LabelContainer-d6-0-2-30"><span className="Label-0-2-9 Label-d7-0-2-31">Audit Certificate</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {goSteps === 0 && (
                                                <div>
                                                    <div>
                                                        <AuthorisedSignatoryForm />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between">
                                                        <div></div>
                                                        <button className="btnDraft" type="submit">Save as Draft</button>
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => next(1)}>Next</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 1 && (
                                                <div>
                                                    <div>
                                                        <RequestForFundForm />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between">
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(0)}>Previous</Link>
                                                        </div>
                                                        <button className="btnDraft" type="submit">Save as Draft</button>
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => next(2)}>Next</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 2 && (
                                                <div>
                                                    <div>
                                                        <ClaimForms cf={props.cf} />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between mt-4">
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => navtab(1)}>Previous</Link>
                                                        </div>
                                                        <button className="btnDraft me-0" type="submit">Save as Draft</button>
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => next(3)}>Next</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 3 && (
                                                <div>
                                                    <div>
                                                        <PhysicalProgressForm />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between">
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(2)}>Previous</Link>
                                                        </div>
                                                        <button className="btnDraft" type="submit">Save as Draft</button>
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => next(4)}>Next</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 4 && (
                                                <div>
                                                    <div>
                                                        <FinancialProgressForm />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between">
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(3)}>Previous</Link>
                                                        </div>
                                                        <button className="btnDraft" type="submit">Save as Draft</button>
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => next(5)}>Next</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 5 && (
                                                <div>
                                                    <div>
                                                        <UtilisationForm />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between">
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(4)}>Previous</Link>
                                                        </div>
                                                        <button className="btnDraft" type="submit">Save as Draft</button>
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => next(6)}>Next</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 6 && (
                                                <div>
                                                    <div>
                                                        <AuditCertificateForm />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between">
                                                        <div></div>
                                                        <button className="btnDraft" type="submit">Save as Draft</button>
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit">Submit</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>
    );
};
