import React, { Component } from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'

export default class SiteMap extends Component {

    render() {
        return (
            <>
                <Header/>
                <InnerTemplate sublink={false} PageTitle="Site Map"/>
                {/* <div className='main'>
                    <div className='container'>
                        <div className="sitemap mt-4 mb-5">
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/about.htm" title="About">About</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/themes.htm" target="_self" title="Themes">Themes</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/themes-2-0.htm" target="_self" title="Themes 2.0">Themes 2.0</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/events-activities.htm" target="_self" title="Events">Events</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/campaign.htm" target="_self" title="Campaigns">Campaigns</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/stories-of-change.htm" target="_self" title="Stories of Change">Stories of Change</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/competitions.htm" target="_self" title="Competitions">Competitions</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="javascript:void(0)" target="_parent" title="History Corner">History Corner</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/veer-baal-diwas.htm" target="_self" title="Veer Baal Diwas">Veer Baal Diwas</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/unity-festival.htm" target="_self" title="Unity Festival">Unity Festival</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/partition-horror-remembrance-day.htm" target="_self" title="Partition Horrors Remembrance Day">Partition Horrors Remembrance Day</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/digital-district-repository.htm" target="_self" title="Digital District Repository">Digital District Repository</a>
                                    </li>
                                    <li>
                                        <a className="menuExt" href="https://amritmahotsav.nic.in/digital-district-repository.htm" target="_blank" title="Districtwise narratives of our splendid heritage">Districtwise narratives of our splendid heritage</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="javascript:void(0)" target="_parent" title="Podcast on Freedom Movement">Podcast on Freedom Movement</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/zara-yaad-karo-qurbani.htm" target="_self" title="Zara Yaad Karo Qurbani">Zara Yaad Karo Qurbani</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/bharat-ki-kahani-ml-ki-zubaani.htm" target="_self" title="Bharat Ki Kahani, Meenakashi Lekhi Ki Zubaani">Bharat Ki Kahani, Meenakashi Lekhi Ki Zubaani</a>
                                    </li>
                                    <li>
                                        <a className="menuExt" href="https://indianculture.gov.in/freedom-archive" target="_blank" title="Freedom Corner">Freedom Corner </a>
                                    </li>
                                    <li>
                                        <a className="menuExt" href="https://indianculture.gov.in/freedom-archive" target="_blank" title="Stories of India’s Freedom Struggle">Stories of India’s Freedom Struggle</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/unsung-heroes.htm" target="_self" title="Unsung Heroes of India’s freedom struggle">Unsung Heroes of India’s freedom struggle</a>
                                    </li>
                                    <li>
                                        <a className="menuExt" href="https://amritmahotsav.nic.in/unsung-heroes.htm" target="_blank" title="Paying tribute to India’s freedom fighters">Paying tribute to India’s freedom fighters</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/melodies-of-freedom-list.htm" target="_self" title="Rajyageet">Rajyageet</a>
                                    </li>
                                    <li>
                                        <a className="menuExt" href="https://amritmahotsav.nic.in/melodies-of-freedom-list.htm" target="_blank" title="Melodic identity of our States">Melodic identity of our States</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/swatantra-swar.htm" target="_self" title="Swatantra Swar">Swatantra Swar</a>
                                    </li>
                                    <li>
                                        <a className="menuExt" href="https://amritmahotsav.nic.in/swatantra-swar.htm" target="_blank" title="Revolutionary poetry banned during the British Raj">Revolutionary poetry banned during the British Raj</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/blog.htm" target="_self" title="Blog">Blog</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/gallery.htm" target="_self" title="Media Coverage">Media Coverage</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/photo-gallery.htm" target="_self" title="Photo Gallery">Photo Gallery</a>
                                    </li>
                                    <li>
                                        <a className="menuFirstNode" href="/video-gallery.htm?Album_1/Naval+Videos" target="_self" title="Video Gallery">Video Gallery</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sitemapDiv">
                                <ul>
                                    <li className="sitemapTitle">
                                        <a className="menuFirstNode menuParent" href="/downloads.htm" target="_self" title="Downloads">Downloads</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Footer/>
            </>
        )
    }
}
