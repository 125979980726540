import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Http } from "../Core/Services/HttpService";
import { init, validatefrm } from "../assets/js/formbuilder";
import { Link } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";
import { Jsencrypt } from "../Core/Services/JsencryptService";


const ForgotPassword = () => {

  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);

  function onSubmit(e: any) {
    e.preventDefault();
    setErr("");
    setSucc("");
    let submitform = validatefrm();
    if (submitform) {
      let myFormData = new FormData(e.target);
      const captcha = myFormData.get('captcha');
      if (captcha && !validateCaptcha(captcha)) {
        setErr("Invalid Captcha");
      } else {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        // Email Encryption
        const encEmail: any = Jsencrypt.encrypt(myFormData.get('email'));
        myFormData.set('email', encEmail);
        // Password Encryption
        const encPwd: any = Jsencrypt.encrypt(myFormData.get('captcha'));
        myFormData.set('captcha', encPwd);

        Http.post('/apiv1/forgotpassword', myFormData, config)
          .then((res: any) => {
            if (res._resultflag == '1') {
              setSucc(res.message);
              e.target.reset();
            }
            else {
              setErr(res.message ?? 'Something went wrong, Please try again.');
            }
          })
          .catch((err: any) => {
            setErr('Something went wrong, Please try again.');
          });
      }
    }
  }

  setTimeout(() => {
    init();
  }, 1000);

  return (
    <>
      <Header />
      <div className="mainWrap">
        <div className="loginWrap">
          <div className="login-main">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 col-sm-8 col-lg-6 col-xl-5 col-xxl-4">
                  <div className="position-relative loginPosition">
                    <div className="loginbox">
                      <h4 className="text-white">Forgot Password</h4>
                      <form noValidate onSubmit={onSubmit}>
                        {err != '' ?
                          <div className="alert alert-danger">{err}</div>
                          : null
                        }
                        {succ != '' ?
                          <div className="alert alert-success">{succ}</div>
                          : null
                        }
                        <div className="form-group">
                          <div className="form-field">
                            <label className="control-label" htmlFor="email">Username (Email)</label>
                            <input data-required="Y" autoComplete="off" className="form-control" type="email" name="email" id="email" />
                          </div>
                        </div>

                        <div className="form-group">
                          <LoadCanvasTemplate reloadColor="white" />
                        </div>
                        <div className="form-group">
                          <div className="form-field">
                            <input data-required="Y" type="text" className="form-control" id="captcha" name="captcha" placeholder="Enter the Captcha" autoComplete="off" />
                          </div>
                        </div>
                        <div className="form-group">
                          <div>
                            <Link className="nav-link text-white" to="/sign-in">Back To Login</Link>
                          </div>
                          <div className="formBtnSection">
                            <button className="btnSubmit" type="submit">Submit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer remove="d-none" />
    </>
  );
}

export default ForgotPassword;
