import React, { useEffect, useState } from 'react'
import FundDisbursal from "./FundDisbursal";
import YearlyReportSubmission from "./YearlyReportSubmission";
import DisbursementDetails from "./DisbursementDetails";
import CurrentUtilizationStatus from "./CurrentUtilizationStatus";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Storage } from '../../Core/Services/StorageService';

const FundDisbursalMain = () => {
    const [selected, setSelected] = useState("RequestForFundRelease");
    const [discomid, setDiscomId] = useState<any>(0);
    const [roleid, setRoleid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);

    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            if (location.state.is_yearly_back == 1) {
                setSelected("YearlyReportSubmission");
            } else {
                setSelected("RequestForFundRelease");
            }
        }
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);

        if (e.target.value == 'CurrentUtilizationStatus') {
            setRefresh(true);
        } else {
            setRefresh(false);
        }
    };

    useEffect(() => {
        setDiscomId(Storage.get('discom_id'));
        setRoleid(Storage.get('roleid'));
    }, [discomid, roleid]);

    useEffect(() => {
        if (roleid == 5 || roleid == 6) {
            if (discomid == 0 || discomid == '') {
                navigate('/nodal-view');
            } else {
                navigate('/fund-disbursal');
            }
        }
    }, [discomid, roleid]);

    return (
        <>
            <Header />
            <div className="mainWrap">
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                :
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            }
                            <li>Fund Disbursal</li>
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom inputFromNew fd-tab-top">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className='radioTitleSection pb-1'>
                                        <div className='row mx-0 gx-0 justify-content-between'>
                                            <div className='col-lg-12 col-xl-7 col-xxl-5'>
                                                <div className="radioYear h-auto">
                                                    <div className="row justify-content-center align-items-center gx-0 mx-0">
                                                        <div className="col-md-3">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="RequestForFundRelease" value="RequestForFundRelease" id="RequestForFundRelease" checked={selected === "RequestForFundRelease"} onChange={changeHandler} />
                                                                <label className="form-check-label" htmlFor="RequestForFundRelease">
                                                                    Request for <br />Fund Release
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="YearlyReportSubmission" value="YearlyReportSubmission" id="YearlyReportSubmission" checked={selected === "YearlyReportSubmission"} onChange={changeHandler} />
                                                                <label className="form-check-label" htmlFor="YearlyReportSubmission">
                                                                    Yearly Report <br />Submission
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="DisbursementDetails" value="DisbursementDetails" id="DisbursementDetails" checked={selected === "DisbursementDetails"} onChange={changeHandler} />
                                                                <label className="form-check-label" htmlFor="DisbursementDetails">
                                                                    Disbursement <br /> Details
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="CurrentUtilizationStatus" value="CurrentUtilizationStatus" id="CurrentUtilizationStatus" checked={selected === "CurrentUtilizationStatus"} onChange={changeHandler} />
                                                                <label className="form-check-label" htmlFor="CurrentUtilizationStatus">
                                                                    Current Utilization <br />Status
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {selected == 'RequestForFundRelease' ?
                                        <>
                                            <div aria-hidden={selected !== "RequestForFundRelease" ? true : false}><FundDisbursal /></div>
                                        </>
                                        : null
                                    }

                                    {selected == 'YearlyReportSubmission' ?
                                        <>
                                            <div aria-hidden={selected !== "YearlyReportSubmission" ? true : false}><YearlyReportSubmission /></div>
                                        </>
                                        : null
                                    }

                                    {selected == 'DisbursementDetails' ?
                                        <>
                                            <div aria-hidden={selected !== "DisbursementDetails" ? true : false}><DisbursementDetails /></div>
                                        </>
                                        : null
                                    }

                                    {selected == 'CurrentUtilizationStatus' ?
                                        <>
                                            <div aria-hidden={selected !== "CurrentUtilizationStatus" ? true : false}><CurrentUtilizationStatus refresh={refresh} /></div>
                                        </>
                                        : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>
    );
}

export default FundDisbursalMain;