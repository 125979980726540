import { Stepper, Step } from "react-form-stepper";
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./rdssforms.scss";
import InnerTemplate from "../Components/InnerTemplate/InnerTemplate";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import swal from 'sweetalert';
import FormSteps from "../Components/InputForms/Section1/FormStep"
import Section2 from "../Components/InputForms/Section2/Section2"

// Forms
import { Http } from "../Core/Services/HttpService";
import PQForm from "../Components/EvaluationForms/PQForm";
import SopForm from "../Components/EvaluationForms/SopForm";
import FormStepsEvaluation from "../Components/EvaluationForms/FormStepsEvaluation";
import RefForm from "../Components/InputForms/Section1/RefForm";
import FinancialForm from "../Components/InputForms/Section1/FinancialForm";
import EnergyForm from "../Components/InputForms/Section1/EnergyForm";
import Arrow from "../images/right-arrow.svg";
import { useLayout } from "../Core/Providers/LayoutProvider";
import { Storage } from "../Core/Services/StorageService";

export default function EvaluationForm() {
    return (
        <PlaceOrder />
    );
}

export const Discomhistory = (props: any) => {
    // return <></>;
    const layout = useLayout();
    const [discomhistory, setDiscomHistory] = useState<any>([]);
    let layoutDataSubscribe: any;

    let discomHistoryNo = 0;

    useEffect(() => {
        (async () => {
            layoutDataSubscribe = await layout.data.subscribe((res: any) => {
                if (res.submittedStatus.discom_history)
                    setDiscomHistory(res.submittedStatus.discom_history)
            });
        })();
        return () => {
            if (layoutDataSubscribe !== undefined) {
                layoutDataSubscribe.unsubscribe();
            }
        }
    }, []);

    return (
        <>
            {(discomhistory.length > 0) ?
                <div className="accordion-body">
                    <div className="table-responsive RevenueDetailsTable actionHistory">
                        <h4 className="accordion-header blankPara">Action History</h4>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sl No.</th>
                                    <th>Sender</th>
                                    <th>Receiver</th>
                                    <th>Action</th>
                                    <th>Remark</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(discomhistory.length > 0) ?
                                    discomhistory.map((item: any, key: number) => (
                                        <>
                                            <tr key={key}>
                                                <td>{(discomHistoryNo++) + 1}</td>
                                                <td>{item.sender}</td>
                                                <td>{item.receiver}</td>
                                                <td>{item.action}</td>
                                                <td>{item.remark}</td>
                                                <td>{item.date}</td>
                                            </tr>
                                        </>
                                    ))
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                : null
            }
        </>
    );
}

export const PlaceOrder = (props: any) => {
    const splitkey1 = Storage.get('splitkey1');
    const splitkey2 = Storage.get('splitkey2');
    const allowevaluation = (splitkey1 == "2" || splitkey2 == "2") ? true : false;
    const selectedsection = (allowevaluation) ? "Section1" : "Section2";
    const formData: any = useRef(null);
    const layout = useLayout();
    const syear: any = useRef(null);
    const [year, setYear] = useState("2022");
    const [tblkey, setTblkey] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [selected, setSelected] = useState(selectedsection);

    const refreshform = () => {
        setYear(syear.current.value);
        setSelected(selectedsection);
    }
    const [isSubmittedStatus, setSubmittedStatus] = useState('');
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [remarks, setRemarks] = useState('');

    let layoutDataSubscribe: any;
    const [roleid, setRoleid] = useState<any>(0);

    useEffect(() => {
        console.log(tblkey);

    }, [tblkey]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [discomuserid]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        (async () => {
            layoutDataSubscribe = await layout.data.subscribe((res: any) => {
                setSubmittedStatus(res.submittedStatus.submittedStatus);
                setRemarks(res.submittedStatus.remarks);
            });
        })();
        return () => {
            if (layoutDataSubscribe !== undefined) {
                layoutDataSubscribe.unsubscribe();
            }
        }
    }, []);


    function onSubmit(e: any) {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        // console.log(validatefrm());
        let myFormData = new FormData(e.target);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', tblkey);
        myFormData.append('discom_id', discomid);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        Http.post('/apiv1/savedraftevaluationform', myFormData, config)
            .then((res) => {
                setIsLoading(false);
                // window.location.reload();
                setRefresh(true);
                swal("Input Form", "Data have been saved", "success");
            });

    }

    const next = (opt: number) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', tblkey);
        myFormData.append('discom_id', discomid);
        Http.post('/apiv1/savedraftevaluationform', myFormData, config)
            .then((res) => {
                // console.log(res);
            });

        setGoSteps(opt);
    }

    const submittoreviewer = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', form_step_no);
        myFormData.append('discom_id', discomid);

        swal({
            title: "Are you sure to submit this form?",
            text: "After submit, You will not able to edit this form.",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Input Form!',
                    text: 'Your data has been submitted.',
                    icon: 'success'
                }).then(function () {
                    Http.post('/apiv1/forward', myFormData, config)
                        .then((res) => {
                            setIsLoading(false);
                            setRefresh(true);
                            // swal("Input Form", "Data has been forwarded", "success");
                        });
                });
            } else {
                setIsLoading(false);
                // swal("Cancelled", "Your data has been is safe :)", "error");
            }
        });

    }

    const submittobackward = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('roleid', roleid);
        myFormData.append('year', year);
        myFormData.append('form_step_no', form_step_no);
        myFormData.append('discom_id', discomid);

        swal({
            title: "Are your sure want to return this form?",
            text: "",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                Http.post('/apiv1/backward', myFormData, config)
                    .then((res) => {
                        if (res._resultflag == 0) {
                            setIsLoading(false);
                            swal("Warning", res.message, "info");
                        } else {
                            setIsLoading(false);
                            setRefresh(true);
                            swal("Input Form", "The form has been returned for correction", "success");
                        }
                    });

            } else {
                setIsLoading(false);
                // swal("Cancelled", "Your data has been is safe :)", "error");
            }
        });

    }

    const [goSteps, setGoSteps] = useState(0);

    useEffect(() => {
        setSelected(selectedsection);
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
    };
    const sanctionLetterMainForm = () => {
        document.body.classList.add('sanctionLetterMainForm');
    }
    const FinancialMainForm = () => {
        document.body.classList.remove('sanctionLetterMainForm');
    }

    return (
        <>
            <Header />
            <div className="mainWrap">
                <InnerTemplate PageTitle="Evaluation Form" />
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom inputFromNew">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className='radioTitleSection'>
                                        <div className='row mx-0 gx-0 justify-content-between'>
                                            {allowevaluation ?
                                            <>
                                            <div className='col-lg-12 col-xl-6 col-xxl-5'>
                                                <div className="radioYear">
                                                    <div className="row justify-content-center align-items-center gx-0 mx-0">
                                                        <div className="col-md-5">
                                                            <div className="ps-5 form-check">
                                                                <input type="radio" className="form-check-input" name="section" value="Section1" id="Section1" checked={selected === "Section1"} onChange={changeHandler} onClick={FinancialMainForm} />
                                                                <label className="form-check-label" htmlFor="Section1">
                                                                    Evaluation Details
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="section" value="Section2" id="Section2" checked={selected === "Section2"} onChange={changeHandler} onClick={sanctionLetterMainForm} />
                                                                <label className="form-check-label" htmlFor="Section2">Sanction Letter and DPR Details</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </> :
                                            <>
                                            <div className='col-lg-12 col-xl-6 col-xxl-5'>
                                                <div className="radioYear">
                                                    <div className="row justify-content-center align-items-center gx-0 mx-0">
                                                        <div className="col-md-12">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="section" value="Section2" id="Section2" checked={selected === "Section2"} onChange={changeHandler} onClick={sanctionLetterMainForm} />
                                                                <label className="form-check-label" htmlFor="Section2">Sanction Letter and DPR Details</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </>                                            
                                            }
                                            <div className="col-lg-12 col-xl-5 col-xxl-3 text-end">
                                                <div className="fYearMonth">
                                                    <div className="row gx-0 justify-content-end">
                                                        <div className="col-sm-12 sanctionLetterYearNone">
                                                            <div className="year resYear justify-content-end">
                                                                <label>Select Year</label>
                                                                <select ref={syear} className="form-select" aria-label="Default select example" style={{width:'100px'}}>
                                                                    <option value="2022" selected>2022</option>
                                                                    <option value="2023">2023</option>
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                </select>
                                                                <div className="inputBtn">
                                                                    <Link to="#" onClick={() => refreshform()}><span><img src={Arrow} /></span></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div aria-hidden={selected !== "Section1" ? true : false}><FormStepsEvaluation year={year} /></div>
                                    <div aria-hidden={selected !== "Section2" ? true : false}><Section2 year={year} /></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>
    );
};
