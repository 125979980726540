import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStateMounted } from '../../../../Core/Hooks';
import GrantLapsedDiscom from '../../Module/LossReduction/GrantLapsed/GrantLapsedDiscom';
import GrantLapsedDistrict from '../../Module/LossReduction/GrantLapsed/GrantLapsedDistrict';
import { Http } from '../../../../Core/Services/HttpService';
import { fyByStartEnd, yearByStartEnd } from '../../../../Core/Utility/Datetime';
import { Storage } from "../../../../Core/Services/StorageService";
export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

const GrantLapsed = (props: any) => {
    const [selected, setSelected] = useState<any>(0);
    const [discoms, setDiscoms] = useState<any[]>([]);
    const [nodalAgency, setNodalAgency] = useState<any>(0);
    const [error, setError] = useState(null);
    const [apiurl, setApiurl] = useState<any>(null);
    const [defaultDiscom, setDefaultDiscom] = useState<any>(0);
    const [defaultTab, setDefaultTab] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [discomid, setDiscomId] = useState<any>(0);
    const [years, setYears] = useStateMounted<[{ label: string, value: string }] | []>([]);
    const [year, setYear] = useState<any>(2021);
    const [roleid, setRoleid] = useState<any>(0);

    const location = useLocation();
    let navigate = useNavigate();
    const changeLrTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        (async () => {
            const fyearList: any = fyByStartEnd("2021", new Date().getFullYear().toString());
            await fyearList.map((item: any) => {
                item.label = `${item.label} - ${(parseInt(item.label) + 1)}`;
                return item;
            });
            setYears(fyearList);

            if (roleid == 2 || roleid == 3 || roleid == 4) {
                setSelected("GrantLapsedDistrict");
            }

            if (roleid == 5 || roleid == 6) {
                setSelected("GrantLapsedDiscom");
            }

        })();
    }, [roleid]);

    const onChangeYear = (e: any) => {
        if (props.year !== e.target.value)
            setYear(e.target.value);
    };

    const changeTab = (e: any) => {
        setSelected(e.target.value);
    };

    useEffect(() => {
        (async () => {
            if (location.state) {
                setDiscomId(location.state.discomid)
                setDefaultDiscom(location.state.discomid);
                setDefaultTab(location.state.sub_module_type);
            }
        })();
    }, []);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, [roleid, discomuserid]);

    return (
        <>
            <section className="financialForm">
                <div className="financialMainFrom inputFromNew">
                    <div className="tab-content border-0 p-0">
                        <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                            <div className='radioTitleSection'>
                                <div className='row mx-0 gx-0 justify-content-between'>
                                    <div className='col-lg-12 col-xl-6 col-xxl-7'>
                                        <div className="radioYear">
                                            <div className="row justify-content-center align-items-center mx-0 gx-0">
                                                {(roleid == 5 || roleid == 6) ?
                                                    <div className="col-3 col-md-6">
                                                        <div className="form-check">
                                                            <input type="radio" className="form-check-input" name="GrantLapsedDiscom" value="GrantLapsedDiscom" id="GrantLapsedDiscom" checked={selected === "GrantLapsedDiscom"} onChange={changeLrTab} />
                                                            <label className="form-check-label" htmlFor="GrantLapsedDiscom">Discom</label>
                                                        </div>
                                                    </div>
                                                    : null
                                                }

                                                {(roleid == 5 || roleid == 6) ?
                                                    <>
                                                        <div className="col-3 col-md-6">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="GrantLapsedDistrict" value="GrantLapsedDistrict" id="GrantLapsedDistrict" checked={selected === "GrantLapsedDistrict"} onChange={changeLrTab} />
                                                                <label className="form-check-label" htmlFor="GrantLapsedDistrict">District</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-3 col-md-12">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="GrantLapsedDistrict" value="GrantLapsedDistrict" id="GrantLapsedDistrict" checked={selected === "GrantLapsedDistrict"} onChange={changeLrTab} />
                                                                <label className="form-check-label" htmlFor="GrantLapsedDistrict">District</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-xl-6 col-xxl-4 ps-2'>
                                        <div className='row justify-content-end align-items-center gx-2'>
                                            <div className='col-md-4'>
                                                <label style={{ "width": "300px" }}>Select Year (FY)</label>
                                            </div>
                                            <div className='col-md-6'>

                                                <select
                                                    onChange={onChangeYear}
                                                    value={year}
                                                    className="form-select" aria-label="Select Year (FY)">
                                                    {
                                                        years.map((year, index) => {
                                                            return (
                                                                <option key={index} value={year.value}>{year.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div aria-hidden={selected !== "GrantLapsedDiscom" ? true : false}><GrantLapsedDiscom year={year} /></div>
                            <div aria-hidden={selected !== "GrantLapsedDistrict" ? true : false}><GrantLapsedDistrict year={year} /></div>
                        </div>
                    </div>
                </div>
                {isLoading ? <LoadingSpinner /> : null}
            </section>
        </>
    );
};

export default GrantLapsed;