import React, { Component } from 'react'

export class AuditHistory extends Component {
  render() {
    return (
      <div>Audit History</div>
    )
  }
}

export default AuditHistory