import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Evaluation from '../../assets/ImageCompo/Evaluation'
import FundDisbursal from '../../assets/ImageCompo/FundDisbursal'
import Monitor from '../../assets/ImageCompo/Monitor'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import InnerTemplate from '../../Components/InnerTemplate/InnerTemplate'
import "../../css/style.scss";
import { Http } from '../../Core/Services/HttpService';
import { Storage } from '../../Core/Services/StorageService'

const NodalAction = (props:any) => {
        const [discomid, setDiscomId] = useState<any>(0);
        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [discomuserid, setDiscomUserid] = useState<any>(0);
        const [apiurl, setApiurl] = useState<any>(null);
        const [fields, setFields] = useState<any[]>([]);
        const [isEvaluationBox, setEvaluationBox] = useState<any>(0);
        const [isFundBox, setFundBox] = useState<any>(null);
        const [nodalAgency, setNodalAgency] = useState<any>(0);

        const ChangeValue = (evt?: any) => {
            const discomSplit = evt.target.value.split(",");
            Storage.set('discom_id', discomSplit[0]);
            setDiscomId(discomSplit[0]);
            const splitkey1 = discomSplit[1] ? discomSplit[1] : 0;
            const splitkey2 = discomSplit[2] ? discomSplit[2] : 0;
            Storage.set('splitkey1', splitkey1);
            Storage.set('splitkey2', splitkey2);
            setEvaluationBox('/evaluation-form');
            setFundBox('/fund-disbursal-form');
            
            // if(splitkey1 == 3 || splitkey2 == 3)
            // {
            //     setFundBox('/fund-disbursal-form');
            // }else{
            //     setFundBox('#');
            // }
        };
        const getDiscoms = () => {
            (async () => {
                if(apiurl) {
                await Http.get(apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setFields(result.discoms);
                        setTimeout(() => {
                        }, 2000);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
                }
            })();
        };

        useEffect(() => {
            if(discomuserid) {
                setApiurl("/apiv1/getdiscoms/?discom_user_id="+discomuserid + "&nodal_agency=" + nodalAgency);
            }
        }, [apiurl, discomuserid, nodalAgency]);

        useEffect(() => {
            setDiscomUserid(Storage.get('discom_user_id'));
            setNodalAgency(Storage.get('nodal_agency'));  
        }, [discomuserid,nodalAgency]);

        useEffect(() => {
            (async () => {
                getDiscoms();
            })();
        }, [apiurl]);

        if (error) {
            return <div>Error: {error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {    
        return (
            <>
                <div className='screenReader'>
                    <div className='caption mt-4'>
                        <div className='row justify-content-center'>
                            <div className='col-md-3'>
                                <select onChange={ChangeValue} className="form-select">
                                    <option value={0} selected>Select Discom</option>
                                    {
                                        (fields.length && discomuserid > 0) ?
                                        fields.map((discom: any) => (
                                            <>
                                                <option value={[discom.id,discom.discom_tab]}>{discom.discom_name}</option>
                                            </>
                                        ))
                                        : null
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    { discomid && discomid > 0 ?
                    <div className='aboutAccordioin d-block mt-5'>
                        <div className='Accordion-group mw-100'>
                            <div className='Tab-panel'>
                                <div className='ActionCenterSection'>
                                    <div className="row gx-0">
                                        <div className="col-lg-6 col-6 d-flex align-items-center justify-content-center">
                                        <Link to="/monitoring" className="d-block w-100 text-decoration-none h-100">
                                            <div className="tabPanelInner">
                                                <Monitor />
                                                <h5>Monitoring</h5>                                                    
                                            </div>
                                        </Link>
                                        </div>
                                        <div className="col-lg-6 col-6 d-flex align-items-center justify-content-center">
                                            <Link to={isEvaluationBox} className="d-block w-100 text-decoration-none h-100">
                                                <div className="tabPanelInner">
                                                    <Evaluation />
                                                    <h5>Evaluation, Sanction and Award Details</h5>                                                        
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-lg-6 col-6 d-flex align-items-center justify-content-center">
                                            <Link to={isFundBox} className="d-block w-100 text-decoration-none h-100">
                                                <div className="tabPanelInner">
                                                    <FundDisbursal />
                                                    <h5>Fund Disbursal</h5>                                                        
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-lg-6 col-6 d-flex align-items-center justify-content-center">
                                            <Link to="/fund-utilization" className="d-block w-100 text-decoration-none h-100">
                                                <div className="tabPanelInner">
                                                    <FundDisbursal />
                                                    <h5>Fund Utilization</h5>                                                        
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                    }
                </div>
            </>
        )
    }
}

export default NodalAction;