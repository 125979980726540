import React, { useEffect, useState } from "react";
import { Http } from '../../../../Core/Services/HttpService';
import { init, multijsoninit, initselect, inputEventChecker  } from "../../../../assets/js/formbuilder";
import FieldComponent from "../../../../FieldComponent";
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { GrAddCircle, GrTrash } from "react-icons/gr";
import { BiUpload } from "react-icons/bi";
import { useStateMounted } from "../../../../Core/Hooks";
import { OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Storage } from "../../../../Core/Services/StorageService";

const APCTooltip = (props: any) => (<Tooltip id="button-tooltip" {...props}>Value fetched from district-level grand total of Approved Project Cost</Tooltip>);
const GGCTooltip = (props: any) => (<Tooltip id="button-tooltip" {...props}>Value fetched from district-level grand total of GoI Grant Sanctioned</Tooltip>);
const AIGGITooltip = (props: any) => (<Tooltip id="button-tooltip" {...props}>Value fetched from district-level grand total of Additional Incentive (GoI Grant)</Tooltip>);

const DLAPCTooltip = (props: any) => (<Tooltip id="button-tooltip" {...props}>Values fetched from district-wise grand total of Sanctioned cost (Path: Sanction and Award Details-Sanction Details- Smart Metering-Sanctioned Items-District)</Tooltip>);
const DLGGCTooltip = (props: any) => (<Tooltip id="button-tooltip" {...props}>For GoI Grant Sanctioned, values are calculated but editable. Values are calculated based on the following formulae:
1. For normal states, GoI Grant Sanctioned = 15% of Approved Project Cost
2. For special states, GoI Grant Sanctioned = 22.5% of Approved Project Cost 
</Tooltip>);
const DLAIGGITooltip = (props: any) => (<Tooltip id="button-tooltip" {...props}>For Additional Incentive, values are calculated but editable. Values are calculated based on the following formulae:
1. For normal states, Additional Incentive = 7.5% of Approved Project Cost
2. For special states, GoI Grant Sanctioned = 11.25% of Approved Project Cost 
</Tooltip>);

const ApprovedCostDetails = (props: any) => {
    const [show, setShow] = useState(false);
    const [date, setDate] = useState()
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [loading, setLoading] = useStateMounted<boolean>(true);
    const [error, setError] = useStateMounted<string>();


    const getFields = () => {
        (async () => {
            setFields([]);
            setIsLoaded(false);
            let footerspinner:any = document.getElementById('footer-spinner');
            if ( footerspinner )
                footerspinner.classList.remove('d-none');
                Http.get(props.apiurl)
                .then((res) => {
                  if (res.resultflag && res.fields) {
                      if (res.fields && Array.isArray(res.fields)) {
                          if (!res.fields.length) setError("No data found.");
                          setIsLoaded(true);
                          setFields(res.fields);
                          setTimeout(() => {
                              multijsoninit();
                              init();
                          }, 2000);

                      } else {
                        setError('System error occurred!! please try again.');
                      }
                   
                  } else if (!res.resultflag) {
                      setError(res.message);
                      footerspinner.classList.remove('d-none');
                      setIsLoaded(true);
                  } else {
                      setError('No data found.');
                      footerspinner.classList.remove('d-none');
                      setIsLoaded(true);
                  }
               
              }).catch((error) => {
                setError('System error occurred!! please try again.');
                setIsLoaded(true);
                  footerspinner.classList.remove('d-none');
              })
              .finally(() => {
                setIsLoaded(true);
                    footerspinner.classList.add('d-none');
              });

        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [props.apiurl, props.refresh]);

 
    if (error) {
        return <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            fields.length ?
            <>
                <div className="formBox">
                    <div className="row mx-0 g-2 justify-content-between">
                        <div className="col-md-1 mt-0">
                             <label htmlFor="MCMNo">MCM No.</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                        </div>
                        <div className="col-md-3 mt-0">
                             <label htmlFor="SanctionLetterNo">Sanction Letter No.</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[1].key} field={fields[1]} />
                        </div>
                        <div className="col-md-2 mt-0">
                             <label htmlFor="SchemeCode">Grant No./ Scheme Code</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                        </div>
                        <div className="col-md-2 mt-0">
                             <label htmlFor="MCMDate">MCM Date</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                        </div>
                        <div className="col-md-2 mt-0">
                             <label htmlFor="SanctionLetterDate">Sanction Letter Date</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[4].key} field={fields[4]} />
                        </div>
                        <div className="col-md-2 mt-0">
                             <label htmlFor="DWSLD">District wise Sanction Date</label>
                            <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                        </div>
                    </div>
                </div>
                <div className="pageMiddleTitle">
                    <div className="row justify-content-between align-items-center mx-0 gx-0">
                        <div className="col-md-4 col-xxl-4">
                            <h5>DISCOM level</h5>
                        </div>
                        <div className="col-xl-3 col-xxl-4">
                            <div className="form-field me-4 uploadSelect">
                                {/* <div className="fileUpload position-relative text-center">
                                    <div className="downEqule"> */}
                                        {/* <input type="file" data-prefix="fpl_expenditure_details"  className="form-control"/> */}
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[13].key} field={fields[13]} />
                                        {/* <div className="customUpload justify-content-center flex-wrap">
                                            <span className="pe-2"><BiUpload/></span>
                                            <span>Upload Document</span>
                                        </div> */}
                                    {/* </div>
                                </div> */}
                            </div>
                        </div>
                    </div>                                        
                </div>
                <div className="table-responsive RevenueDetailsTable PqTable pb-0">
                    <Block1 field={fields[6]} field2={fields[7]} act={props.act}/>
                </div>

                <div className="pageMiddleTitle">
                    <div className="row justify-content-between align-items-center mx-0 gx-0">
                        <div className="col-md-4 col-xxl-4">
                            <h5>District Level</h5>
                        </div>
                    </div>
                </div>
                <div className="table-responsive RevenueDetailsTable PqTable pb-0">
                    <Block2 field={fields[8]} field2={fields[9]} act={props.act} />
                </div>
            
                <div className="pageMiddleTitle">
                    <div className="row justify-content-between align-items-center mx-0 gx-0">
                        <div className="col-md-6 col-xxl-4">
                            <h5>Details about PMA- Smart Metering</h5>
                        </div>
                    </div>
                </div>
                <div className="table-responsive RevenueDetailsTable PqTable">
                    <table className="table">
                        <thead className="text-center">
                            <th>Grant No/ Scheme Code for PMA</th>
                            <th>Approved Cost (INR Cr) </th>
                            <th>GoI Grant sanctioned (INR Cr)</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[10].key} field={fields[10]} />
                                </td>
                                <td>
                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[11].key} field={fields[11]} />
                                </td>
                                <td>
                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[12].key} field={fields[12]} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
            : null
        );
    }
}

const Block1 = (props: any) => {
    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {

        if(block1Fields.length < 100){
            setBlock1fields((prev: any[]) => {
                return [...prev, { fields: newBlock1Fields, delete: false }];
            });
        }
    
        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }
    return (
        <>
            <table className="table">
                <thead className="text-center">
                    {/* <th style={{ minWidth: "40px" }}>S. No.</th> */}
                    <th style={{width: 300}}>Name of the DISCOM</th>
                    <th>Grant No./ Scheme Code</th>
                    <th>Approved Project Cost (INR Cr)
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={APCTooltip}>
                            <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
                        </OverlayTrigger>
                    </th>
                    <th>GoI Grant Sanctioned (INR Cr)
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={GGCTooltip}>
                            <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
                        </OverlayTrigger>
                    </th>
                    <th>Additional Incentive (GoI Grant)(INR Cr)
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={AIGGITooltip}>
                            <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
                        </OverlayTrigger>
                    </th>
                </thead>
                <tbody>
                    <>
                        {
                            block1Fields.map((item: any, key: number) => {
                                return (item.delete === false) ? <>
                                    <tr key={key}>
                                        {/* <td>{(block1SrNo++) + 1}</td> */}
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    <td key={key + '_' + fkey}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                                </>;
                                            })
                                        }
                                            {/* <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                                       
                                        </tr>
                                </>
                                    : null;
                            })
                        }
                    </>
                    <>
                        {
                            (props.field2)
                                ? <>
                                    <tr>
                                        <td colSpan={2} className="text-center"><h5>Total</h5></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[1].key} field={props.field2.childrens[1]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[2].key} field={props.field2.childrens[2]} /></td>
                                    </tr>
                                </>
                                : null
                        }
                    </>
                </tbody>
            </table>
        </>
    );
}

const Block2 = (props: any) => {
    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items:  any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields2);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });
        
        setTimeout(() => {
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            multijsoninit();
        }, 500);
    }
    return (
        <>
            <table className="table">
                <thead className="text-center">
                    <th style={{ minWidth: "50px" }}>S. No.</th>
                    <th>Name of the District/ Project</th>
                    <th>Grant No./ Scheme Code</th>
                    <th>Approved Project Cost (INR Cr)
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={DLAPCTooltip}>
                            <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
                        </OverlayTrigger>
                    </th>
                    <th>GoI Grant Sanctioned (INR Cr)
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={DLGGCTooltip}>
                            <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
                        </OverlayTrigger>
                    </th>
                    <th>Additional Incentive (GoI Grant)(INR Cr)
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={DLAIGGITooltip}>
                            <Link to="" className="text-decoration-none text-black ps-2"><BsFillInfoCircleFill /></Link>
                        </OverlayTrigger>
                    </th>
                     
                </thead>
                <tbody>
                    <>
                    {
                            block2Fields.map((item: any, key: number) => {
                                return (item.delete === false) ? <>
                                    <tr key={key}>
                                        <td>{(block2SrNo++) + 1}</td>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    <td key={key + '_' + fkey}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                                </>;
                                            })
                                        }
                                        
                                        {/* <td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                                        
                                        </tr>
                                </>
                                    : null;
                            })
                        }
                    </>
                    <>
                        {
                            (props.field2)
                                ? <>
                                    <tr>
                                        <td colSpan={3} className="text-center"><h5>Total</h5></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[1].key} field={props.field2.childrens[1]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[2].key} field={props.field2.childrens[2]} /></td>
                                    </tr>
                                </>
                                : null
                        }
                    </>
                </tbody>
            </table>
        </>
    );
}

const ApprovedCost = (props?: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh]);

    return (
        <>
            {roleid == 0 ? null :
                <ApprovedCostDetails apiurl={"/apiv1/formfields/?form_id=28&roleid=" + roleid+"&discom_id=" + discomid+"&act=" + props.act} refresh={refresh} act={props.act} />
            }
        </>
    )
}

export default ApprovedCost;
