import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Http } from '../Core/Services/HttpService';
import './misreport.scss';
import { BsSearch, BsChevronDoubleLeft,BsChevronDoubleRight,BsChevronCompactLeft,BsChevronCompactRight } from "react-icons/bs";


const MisReportView = (props: any) => {

    const [misReportData, setMisReportData] = useState<any>();
    const [reportCols, setReportCols] = useState<any>([]);
    const [topcols, setTopcols] = useState<any>([]);
    const [reportTitle, setReportTitle] = useState<any>([]);

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [totalPages, setTotalPages] = useState(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);

    const [reportType, setReportType] = useState(props.type);

    // For filter data
    const [searchText, setSearchText] = useState<any>('');
    const searchParams = new URLSearchParams(window.location.search);
    const filterVal = searchParams.get('filter');

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };
    
    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event:any) => {
        if(isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if(event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if(event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e:any) => {
        if(e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };


    function toFixed(amt: any, decimal: number = 0) {
        amt = `${amt}`;
        let hasCondition = amt.includes("mark");
        if (hasCondition) {
            amt = amt.replace(/<\/?[^>]+(>|$)/g, "");
        }
        amt = (amt+"").replace(/[^\-.0-9]+/g, '');
        if ( amt == '' ) amt = decimal == 0 ? 0 : "0.00";

        amt = new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        }).format(amt);
        if (hasCondition) {
            amt = "<mark class='d-inline-block'>"+amt+"</mark>";
        }
        return amt;
    }

    const fetchMisReportData = async (search:any) => {
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        await Http.get('/apiv1/misreportdata?report_type=' + reportType+'&searchtext='+search+'&page='+curPage+'&pageSize='+pageSize)
            .then((res) => {
                setReportCols(res.reportcols);
                setTopcols(res.topcols);
                setReportTitle(res.report_title);
                setMisReportData(res.reportDataList);

                setTotalItems(res.totalRecord);
                setPageCount(Math.ceil(res.totalRecord / pageSize));  
                setTotalPages(Math.ceil(res.totalRecord / pageSize));
                
                let cPage = (!currentPage) ? 1 : currentPage;
                setFirst((cPage - 1) * pageSize + 1);
                setLast(Math.min(res.totalRecord, cPage * pageSize));
            }).catch((e) => {
                console.log(e);
            });
    }
    useEffect(() => {
        if(!filterVal) {
            fetchMisReportData(searchText);
        }
    }, [])

    useEffect(() => {
        if(currentPage != 0) {
            fetchMisReportData(searchText);
        }
    }, [currentPage]);

    const handleSearchTextChange = (event:any) => {
        setSearchText(event.target.value);
    }

    const handleSearchEnter = (event:any) => {
        if (event.key === 'Enter') {
            fetchMisReportData(searchText);
        }
    }

    useEffect(() => {
        if(filterVal) {
            setSearchText(filterVal);
            fetchMisReportData(filterVal);
        }
    }, [filterVal]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <div className="container mb-5 mt-3">
                    <div className="dashboardInner detailedDashboard ">
                        <div className="row gx-xxl-5">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="heading mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-md-12 d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0">{reportTitle ? reportTitle : null}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="input-group mb-3 mt-3">
                        <input type="text" className="form-control" onChange={handleSearchTextChange} onKeyDown={handleSearchEnter} value={searchText} placeholder="Search by keyword" />
                        <button className="btn btn-success text-white" type="button" onClick={() => {setPageInput(1);setCurrentPage(1);fetchMisReportData(searchText)}}><BsSearch/></button>
                    </div>
                    <section className="financialForm misNew">
                        <div className="mt-4">
                            {
                                totalItems ?
                                    <div>Displaying {first} to {last} of {totalItems}</div>
                                :
                                    null
                            }
                            <div className="mt-2 financialMainFrom misReportTable">
                                <div className="tab-content p-0 border-0">
                                    <div className="table-responsive RevenueDetailsTable misreportTable">
                                        <table className="table">
                                            <thead>
                                                {topcols && topcols.length ?
                                                    <>
                                                        <tr className="text-center">
                                                            <th>&nbsp;</th>
                                                            {
                                                                topcols.map((topcol: any, index: number) =>
                                                                    <th scope="col" className="text-center" colSpan={topcol['colspan']}>{topcol['title']}</th>
                                                                )
                                                            }
                                                        </tr>
                                                    </>
                                                    : null
                                                }
                                                <tr className="text-center">
                                                    {reportCols ?
                                                        <>
                                                            <th>Sr.</th>
                                                            {
                                                                reportCols.map((reportcol: any, index: number) =>
                                                                    <th scope="col">{reportcol['title']}</th>
                                                                )
                                                            }
                                                        </>
                                                        : null
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {misReportData ?
                                                    misReportData.map((reportData: any, index: number) => {
                                                        return <tr>
                                                            <td>{(reportData.srNo) + 1}</td>
                                                            {
                                                                reportCols.map((reportcol: any) =>
                                                                    <td className={reportcol.key}>
                                                                    {
                                                                        reportcol.formatted == 'Y' ?
                                                                            // toFixed(reportData[reportcol.key], 2)
                                                                            <div dangerouslySetInnerHTML={{ __html: toFixed(reportData[reportcol.key], 2)}}></div>
                                                                        :
                                                                            <div dangerouslySetInnerHTML={{ __html:reportData[reportcol.key] }}></div>                                                                            
                                                                    }
                                                                    </td>
                                                                )
                                                            }
                                                        </tr>
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={10} className="text-center my-5">
                                                            <h5>No Record Found</h5>
                                                        </td>
                                                    </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                    {totalItems != 0 ?
                                        <div className="mt-3">
                                            <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                                <ul className="pagination justify-content-center">
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                            <BsChevronDoubleLeft/>
                                                        </button>
                                                    </li>
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                            <BsChevronCompactLeft/>
                                                        </button>
                                                    </li>
                                                    <li className="page-item col-1">
                                                        <input type="text" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage } onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                                    </li>
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages}>
                                                            <BsChevronCompactRight/>
                                                        </button>
                                                    </li>
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages}>
                                                            <BsChevronDoubleRight/>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <div className="page-item text-center">
                                                <span>
                                                    Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer remove='d-none' />
            </div>
        </>
    );
}
export default MisReportView;