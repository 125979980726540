import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../../../../../Core/Providers';
import { useStateMounted } from '../../../../../../Core/Hooks';
import { callFunctions, init, initselect, inputEventChecker, multijsoninit, validatefrm } from '../../../../../../assets/js/formbuilder';
import { Http } from '../../../../../../Core/Services/HttpService';
import swal from 'sweetalert';
import QueryString from 'qs';
import {Alert, Button} from 'react-bootstrap';
import { BiDownload, BiUpload, BiUpsideDown } from 'react-icons/bi';
import { BsFileEarmark, BsFileEarmarkExcel, BsFileEarmarkPdf } from 'react-icons/bs';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { FiEye, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import FieldComponent from '../../../../../../FieldComponent';
import { set } from 'lodash';
import {FaArrowLeft} from "react-icons/fa";
import { Storage } from '../../../../../../Core/Services/StorageService';


// Multijson
type MultijsonProps = {
    frmId: string;
    field: any;
    index: number;
};

const multijsonPropTypes = {
    frmId: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

function Multijson({ frmId, field, index }: MultijsonProps) {
    let srNo = 1;
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
    const [fields, setFields] = useStateMounted<any>([]);
    const [newFields, setNewFields] = useStateMounted<any>();
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getkeyindex, setKeyIndex] = useState<any>(0);
    let navigate = useNavigate();

    useEffect(() => {
        if (field) {
            setHasFieldDelete(false);
            setFields([]);
            setNewFields(field.addnew);
            const fieldsMap = field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setFields(fieldsMap);
        }
    }, [field]);

    useEffect(() => {
        if (fields.length) {
            const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
            setHasFieldDelete(hasDelete);
            srNo = 1;
            setTimeout(() => {
                initselect('#' + frmId + ' .form-control');
                multijsoninit('#' + frmId + ' .form-control');
                init();
            }, 500);
        }
    }, [fields]);

    const addNewField = () => {
        setFields((prev: any[]) => {
            return [...prev, { fields: newFields, delete: false }];
        });
        setTimeout(() => {
            init();
            multijsoninit();
        }, 1000);
    }

    const removeField = (index: number) => {
        setFields((prev: any[]) => {
            const newFields = prev.map((item, i) => {
                if (i === index) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });
        setTimeout(() => {
            init();
            multijsoninit();
        }, 1000);
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { indexid: getkeyindex } });
            }
        })();
    }, [getpagelink]);

    return (
        <>
            <table className="table caption-top newTableUcss">
                {
                    <thead className='text-center'>
                        <tr key={idRef.current}>
                            <th style={{ minWidth: "50px" }} className="text-center">Sr. No.</th>
                            {
                                (newFields)
                                    ? newFields.map((field: any, index: number) => {
                                        return (field.type === 'hidden') ?
                                            <th key={`${idRef.current}-${index}`} aria-hidden="true"></th>
                                            : <th key={`${idRef.current}-${index}`} className="text-center" style={{ minWidth: '150px' }}>{field.caption}</th>;
                                    })
                                    : null
                            }
                            {/* {
                                (newFields) ?
                                    <th rowSpan={2} className='text-end pe-1' style={{ width: "50px" }}>
                                        <Link to="javascript:void(0);" className='p-0 text-success' onClick={() => addNewField()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                                    </th>
                                    : null
                            } */}
                        </tr>
                    </thead>
                }
                <tbody>
                    {
                        fields.map((item: any, i: number) => {
                            return (item.delete === false)
                                ? (
                                    <tr key={`${idRef.current}-${i}`}>
                                        <td className="text-center">{(srNo++)}</td>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                const options: any = { showTitle: false, extraId: '_' + i + '_' + fkey };
                                                const newField: any = field;
                                                if (field.type == 'file') {
                                                    options.parentIndex = [(srNo - 2)];
                                                    newField.parent_type = ['multijson', 'multijson'];
                                                    newField.isMultiFile = false;
                                                }
                                                return <>
                                                    <td key={i + '_' + fkey} aria-hidden={(newField.type === 'hidden') ? "true" : "false"}>
                                                        <FieldComponent options={options} fieldName={newField.key} field={newField} />
                                                    </td>
                                                </>;
                                            })
                                        }
                                        {/* <td className="text-end">{(hasFieldDelete) ? <Link className='text-danger' to="javascript:void(0);" onClick={() => removeField(i)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
                                    </tr>
                                )
                                : null;
                        })
                    }
                </tbody>
            </table>
        </>
    );
}

Multijson.propTypes = multijsonPropTypes;

// ChildInput
type ChildInputProps = {
    field: any;
    index?: number
};

const childInputPropTypes = {
    field: PropTypes.object.isRequired,
    index: PropTypes.number
};

function ChildInput({ field, index }: ChildInputProps) {

    const [fields, setFields] = useStateMounted<string[]>([]);

    useEffect(() => {
        if (field.childrens.length) {
            setFields(field.childrens);
        }
    }, [field]);

    return (
        <>
            {
                (fields.length)
                    ? fields.map((field: any, i: number) => (
                        <td key={index + '-' + i + '-' + field.key} aria-hidden={(field.type === 'hidden') ? "true" : "false"}>
                            <FieldComponent
                                options={{ showTitle: false }}
                                fieldName={field.key}
                                field={field} />
                        </td>))
                    : null
            }
        </>
    );
}

ChildInput.propTypes = childInputPropTypes;

export type Form248Props = {
    form_id?: number;
};

const propTypes = {
    form_id: PropTypes.number,
};

function Form248({ form_id }: Form248Props) {

    const qParams = {
        vvpid: new URLSearchParams(document.location.search).get('id')
    };
    const [qparams, setQparams] = useStateMounted(qParams);
    const user: any = useAuth().user();
    const navigate = useNavigate();
    const location = useLocation();
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [loading, setLoading] = useStateMounted<boolean>(true);
    const [fields, setFields] = useStateMounted<any[]>([]);
    const [error, setError] = useStateMounted<string>();
    const [frmLoading, setFrmLoading] = useStateMounted<boolean>(false);
    const frmRef = useRef<any>();
    const [prevfields, setPrevFields] = useStateMounted<any[]>([]);
    const [pdfLink, setPdfLink] = useStateMounted('#');
    const [excelLink, setExcelLink] = useStateMounted('#');
    const [importFile, setImportFile] = useStateMounted<any>(null);
    const [sampleFileLink, setSampleFileLink] = useStateMounted('#');
    const [vvpData, setVvpData] = useStateMounted<any>({});
    const [discomId, setDiscomId] = useStateMounted<any>(0);
    const [roleId, setRoleId] = useStateMounted<any>(0);
    
    useEffect(() => {
        if (roleId == "5" || roleId == "6") {
            setDiscomId(Storage.get('discom_id'));
            setRoleId(Storage.get('roleid'));
        } else {
            setDiscomId(user?.discom_id);
            setRoleId(user?.role_id);
        }
    }, [discomId, roleId]);


    const getFields = () => {
        (async () => {
            setLoading(true);
            setError('');
            setFields([]);
            setVvpData({});
            if (discomId) {
                const params = {
                    form_id: form_id,
                    discom_id: discomId,
                    role_id: roleId,
                    user_id: user?.discom_user_id,
                    vvp_id: qparams?.vvpid,
                };
                await Http.get(`/apiv1/vvp/hhform/${form_id}`, { params })
                    .then((res) => {
                        if (res.resultflag && res.fields) {
                            setVvpData(res.vvpData);
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setFields(res.fields);
                                setTimeout(() => {
                                    init();
                                    multijsoninit();
                                }, 1000);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (res.resultflag !== undefined && !res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        })();
    };
    useEffect(() => {
        if (form_id) {
            getFields();
            let samplelink = process.env.REACT_APP_API_ENDPOINT + `/uploads/vvp-pvtg-data-sample/HH/vvpdata.xlsx`;
            setSampleFileLink(samplelink);
        }
    }, [form_id, discomId, roleId]);

    const onSubmit = (e: any, type: string) => {
        e.preventDefault();
        const isValid = validatefrm(`#frm-${form_id}_${idRef.current} .form-control`);
        if (!isValid) return false;
        setFrmLoading(true);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const formData = new FormData(frmRef?.current);
        const params: any = {
            form_id: form_id,
            discom_id: discomId,
            role_id: roleId,
            user_id: user?.discom_user_id,
            vvp_id: qparams?.vvpid,
            village_id: vvpData.village_id,
            district_id: vvpData.district_id,
            state_id: vvpData.state_id,
            action: type
        };
        for (let key in params) {
            formData.append(key, params[key]);
        }
        Http.post(`/apiv1/vvp/hhSave/${form_id}`, formData, config)
            .then((res) => {
                if (res.resultflag) getFields();
                swal(res.message, "", (res.resultflag ? "success" : "error"));
            })
            .catch((err) => {
                swal("System error occurred!! please try again.", "", "error");
            })
            .finally(() => {
                setFrmLoading(false);
            });
    };

    useEffect(() => {
        if (form_id) {
            const params = {
                form_id: form_id,
                vvp_id: qparams?.vvpid,
            };
            let link = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/vvp/hhfrmPdf', form_id].join('/');
            link += '?' + QueryString.stringify(params);
            setPdfLink(link);
            let elink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/vvp/hhfrmExcel', form_id].join('/');
            elink += '?' + QueryString.stringify(params);
            setExcelLink(elink);
        }
    }, [form_id]);

    useEffect(() => {
        if (fields.length) {
            setPrevFields(fields);
            setTimeout(() => {
                const selector = `#frm-${form_id}_${idRef.current} .form-control`;
                inputEventChecker.removeGroupEvent(selector);
                init(selector);
                multijsoninit(selector);
            }, 2000);
        }
    }, [fields]);

    useEffect(() => {
        if (fields.length && importFile != null) {
            setTimeout(() => {
                const importFileEle: any = document.getElementById(`importDataFile_${idRef.current}`);
                if (importFileEle !== null) {
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(importFile);
                    importFileEle.files = dataTransfer.files;
                    // Safari out
                    if (importFileEle.webkitEntries.length) {
                        importFileEle.dataset.file = `${dataTransfer.files[0].name}`;
                    }
                }
                setImportFile(null);
            }, 100);
        }
    }, [importFile, fields]);

    const importData = (e: any) => {
        setLoading(true);
        setFields([]);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const formData = new FormData();
        formData.append('import-file', e.target.files[0]);
        const params: any = {
            form_id: form_id,
            vvp_id: qparams?.vvpid,
        };
        for (let key in params) {
            formData.append(key, params[key]);
        }
        Http.post(`/apiv1/vvp/hhfrmImportData/${form_id}`, formData, config)
            .then((res) => {
                const _msg = (res.message) ? res.message : "System error occurred!! please try again.";
                swal(_msg, "", (res.resultflag ? "success" : "error"));
                if (res.resultflag && res.fields) {
                    if (res.fields && Array.isArray(res.fields)) {
                        setFields(res.fields);
                        setImportFile(formData.get('import-file'));
                    } else {
                        setFields(prevfields);
                    }
                } else {
                    setFields(prevfields);
                }
            })
            .catch((err) => {
                swal("System error occurred!! please try again.", "", "error");
                setFields(prevfields);
            })
            .finally(() => {
                setLoading(false);
            });
        e.target.value = null;
    };

    const onSave = (e: any, type: string) => {
        if (type === 'draft') {
            frmRef.current.dispatchEvent(new Event('draft', { cancelable: true, bubbles: true }));
        } else {
            frmRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    useEffect(() => {
        if (frmRef) {
            frmRef.current.removeEventListener('draft', (e: any) => { onSubmit(e, 'draft'); });
            frmRef.current.addEventListener('draft', (e: any) => { onSubmit(e, 'draft'); });

            frmRef.current.removeEventListener('submit', (e: any) => { onSubmit(e, 'submit'); });
            frmRef.current.addEventListener('submit', (e: any) => { onSubmit(e, 'submit'); });
        }
    }, [frmRef]);

    const onBack = () => {
        navigate('/progress-details', { state: { tab: 'VVPPVTG', subTab: 'vvp', vvpCurrentPage: location?.state?.vvpCurrentPage ?? 0 } });
    }

    return (
        <div className="container">
            <div className="financialMainFrom inputFromNew">
                {(vvpData && vvpData.id) && <>
                    <div className='row justify-content-between'>
                        <h4 className="mt-3 text-center"><strong>House Hold Data</strong></h4>
                    </div>
                    <div className="row justify-content-start">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="villageName" className="form-label"><strong>Village : {vvpData.form_data.village_name}</strong></label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="districtName" className="form-label"><strong>District : {vvpData.form_data.district_name}</strong></label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="blockName" className="form-label"><strong>Block : {vvpData.form_data.block}</strong></label>
                            </div>
                        </div>
                    </div>
                </>}
                <div className='tab-content border-0'>
                    <form id={'frm-' + form_id + '_' + idRef.current} ref={frmRef}>
                        {frmLoading ? <div className="spinner-container">
                            <div className="spinner-outer">
                                <div className="loading-spinner"></div>
                            </div>
                        </div> : null}
                        {loading && (
                            <>
                                <div className="content-spinner spinner-container h-50vh">
                                    <div className="spinner-outer">
                                        <div className="loading-spinner"></div>
                                    </div>
                                </div>
                            </>
                        )}
                        {error && (
                            <>
                                <Alert className='mt-3 mb-3' variant="danger">{error}</Alert>
                            </>
                        )}
                        {((!loading && !error) && fields?.length > 0) && (
                            <>
                                <div className='row justify-content-end'>
                                    {(roleId == "2" || roleId == "3" || roleId == "4") ?
                                        <>
                                            <div className="mb-3 form-field w-3">
                                                <div className="d-none">
                                                    <input type="file" name="import_data_file" id={'importDataFile_' + idRef.current} className="form-control" />
                                                </div>
                                                <div className="fileUpload position-relative text-center">
                                                    <div className="downEqule">
                                                        <input title="Upload Data in Excel" type="file" onChange={importData} className="form-control" />
                                                        <div className="customUpload justify-content-center flex-wrap">
                                                            <span><BiUpload /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-2">
                                                <Link title="Download Sample Format" to={sampleFileLink} target="_blank"><BiDownload className="text-success h3 mt-2" /></Link>
                                            </div>
                                        </>
                                        : null}
                                    <div className="w-2">
                                        <Link title="Export in Excel" to={excelLink} target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
                                    </div>
                                    <div className="w-2">
                                        <Link title="Export in PDF" to={pdfLink} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
                                    </div>
                                    <div className="w-2">
                                        <Button type='button' title="Back" onClick={onBack} className="btn-warning h3 mt-2"><FaArrowLeft/></Button>
                                    </div>
                                </div>
                                <div className="table-responsive RevenueDetailsTable">
                                    <table className="table">
                                        <tbody>
                                            {
                                                fields.map((field: any, index: number) => (
                                                    field.type === 'multijson' ? (
                                                        <Multijson key={`${index}-${field.key}`} frmId={'frm-' + form_id + '_' + idRef.current} field={field} index={index} />
                                                    ) : field.childrens ? (
                                                        <tr key={`${index}-${field.key}`}>
                                                            <ChildInput field={field} index={index} />
                                                        </tr>
                                                    ) : null
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                        {(roleId == "2" || roleId == "3" || roleId == "4") ?
                        <div className="formBtnSection is-sticky justify-content-end">
                            <button className="bttn btnDraft" type="button" onClick={(e) => onSave(e, 'submit')}>Submit</button>
                        </div>
                        : null}
                    </form>
                </div>
            </div>
        </div>
    );
}

Form248.defaultProps = {
    form_id: 248
}

Form248.propTypes = propTypes;

export default Form248;