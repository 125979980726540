import React, { useState, useEffect, useRef, useId } from "react";
import 'react-nice-dates/build/style.css'
import { init, multijsoninit, initselect, inputEventChecker } from "../../../../../assets/js/formbuilder";
import { Http } from '../../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { GrAddCircle, GrTrash } from "react-icons/gr";
import { BiUpload } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { useStateMounted } from '../../../../../Core/Hooks';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
// Images
import FieldComponent from "../../../../../FieldComponent";
import { FiUploadCloud } from "react-icons/fi";
import { id } from "date-fns/locale";
import { Storage } from "../../../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
	return (
		<div className="spinner-container">
			<div className="spinner-outer">
				<div className="loading-spinner"></div>
			</div>
		</div>
	);
};

type AwardItemsProps = {
	apiurl: string,
	isLoading: boolean,
	refresh: boolean
};

const Block1 = (props: any) => {

	let block1SrNo = 0;
	const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
	const [block1Fields, setBlock1fields] = useState<any>([]);
	const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

	useEffect(() => {
		if (props.field3 != '0') {
			if ((props.field3 == 'District' && props.field4 != '0') || (props.field3 == 'Discom')) {
				if (props.field5) {
					setHasBlock1FieldsDeletes(false);
					setBlock1fields([]);
					setNewBlock1fields(props.field5.addnew);
					setTimeout(() => {
						const fields1 = props.field5.childrens.map((items: any) => {
							return {
								fields: items,
								delete: false
							}
						})
						setBlock1fields(fields1);
					}, 1000);
				}
			} else {
				setBlock1fields([]);
			}
		} else {
			setBlock1fields([]);
		}
	}, [props.field5]);


	useEffect(() => {
		if (block1Fields.length) {
			const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
			setHasBlock1FieldsDeletes(hasDelete);
			block1SrNo = 0;
			setTimeout(() => {
				initselect(`#${props.idRef} .form-control`);
				multijsoninit(`#${props.idRef} .form-control`);
			}, 1000);
		}
	}, [block1Fields]);

	const addItem = (block?: any) => {
		setBlock1fields((prev: any[]) => {
			return [...prev, { fields: newBlock1Fields, delete: false }];
		});
	}

	const removeItem = (rindex: number, block?: any) => {
		setBlock1fields((prev: any[]) => {
			const newFields = prev.map((item, index) => {
				if (index === rindex) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});
	}

	return (
		<>
			<table className="table-responsive RevenueDetailsTable PqTable">
				<thead className='text-center'>
					<tr>
						<th style={{ minWidth: "50px" }}>S. No.</th>
						<th>Major Components</th>
						<th>Item Summary</th>
						<th>Life Cycle Cost <br /> (INR/ meter)</th>
						<th>Quantity (Phase I)</th>
						<th>Quantity (Phase II)</th>
						<th>Total Quantity</th>
						<th>Estimated Cost<br />(INR Lakh)</th>
						<th>Award Rate <br />(INR/unit)</th>
						<th>Awarded Quantity</th>
						<th>Awarded Cost <br />(INR Lakh)</th>
						{/* <th rowSpan={2} className='text-end pe-1' style={{ width: "30px" }}>
                            <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                        </th> */}
					</tr>

				</thead>
				<tbody>
					{
						block1Fields.map((item: any, key: number) => {
							return (item.delete === false) ? <>
								<tr key={key}>
									<td className="text-center" >{(block1SrNo++) + 1}</td>
									{
										item.fields.map((field: any, fkey: number) => {
											return <>
												<td key={key + '_' + fkey}>
													<FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} module="award_" /></td>
											</>;
										})
									}
									{/* <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td> */}
								</tr>
							</>
								: null;
						})
					}
					<>
						{
							(block1Fields.length && props.field2)
								? <>
									<tr>
										<td colSpan={4} className="text-center"><h5>Total</h5></td>
										<td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} module="award_" /></td>
										<td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[1].key} field={props.field2.childrens[1]} module="award_" /></td>
										<td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[2].key} field={props.field2.childrens[2]} module="award_" /></td>
										<td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[3].key} field={props.field2.childrens[3]} module="award_" /></td>
										<td></td>
										<td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[4].key} field={props.field2.childrens[4]} module="award_" /></td>
										<td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[5].key} field={props.field2.childrens[5]} module="award_" /></td>
									</tr>
								</>
								: null
						}
					</>

				</tbody>
			</table>
		</>
	);
}

type MultiChildProps = {
	field: any;
	pIndex: number
	jIndex: number
	index?: string | number,
	totalFields: any,
	idRef: string
};

const multiChildPropTypes = {
	field: PropTypes.array.isRequired,
	index: PropTypes.any,
	pIndex: PropTypes.number.isRequired,
	totalFields: PropTypes.any.isRequired,
	idRef: PropTypes.string.isRequired
};

function MultiChild({ field, index, pIndex, totalFields, jIndex, idRef }: MultiChildProps) {

	let srNo = 1;
	const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
	const [fields, setFields] = useStateMounted<any>([]);
	const [newFields, setNewFields] = useStateMounted<any>();

	useEffect(() => {
		if (field) {
			setHasFieldDelete(false);
			setFields([]);
			setNewFields(field.addnew);
			const fieldsMap = field.childrens.map((items: any, index: number) => {
				return {
					fields: items,
					delete: false,
					saveIndex: '' + index
				}
			})
			setFields(fieldsMap);
		}
	}, [field]);

	useEffect(() => {
		if (fields.length) {
			const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
			setHasFieldDelete(hasDelete);
			srNo = 1;
			// setTimeout(() => {
			// 	initselect(`#${idRef} .form-control`);
			// 	multijsoninit(`#${idRef} .form-control`);
			// }, 1000);
		}
	}, [fields]);

	const addNewField = () => {
		setFields((prev: any[]) => {
			return [...prev, { fields: newFields, delete: false, saveIndex: false }];
		});
		setTimeout(() => {
			initselect(`#${idRef} .form-control`);
			multijsoninit(`#${idRef} .form-control`);
		}, 1000);
	}

	const removeField = (index: number) => {
		let footerspinner: any = document.getElementById('footer-spinner');
		if (footerspinner) footerspinner.classList.remove('d-none');
		setFields((prev: any[]) => {
			const newFields = prev.map((item, i) => {
				if (i === index) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});
		setTimeout(() => {
			if (footerspinner) footerspinner.classList.add('d-none');
			initselect(`#${idRef} .form-control`);
			multijsoninit(`#${idRef} .form-control`);
		}, 1000);
	}

	const extTD = <td colSpan={8} ></td>;

	return (
		<>
			{
				fields.map((item: any, i: number) => {
					return (item.delete === false)
						? (
							<>
								<tr key={`${idRef}-${index}`}>
									{
										item.fields.map((field: any, j: number) => {
											// if(field.key == 'sl_award_om_period' && (field.value == '' || field.value == null)) {
											// 	field.value = 93;
											// }
											return ([8, 9].includes(j))
												? <span style={{ display: 'none' }} key={`${idRef}-${index}-${field.key}`}>
													<FieldComponent
														options={
															{ showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
														}
														fieldName={field.key}
														field={field} />
												</span>
												: <td className={'td' + (jIndex + j)} key={`${idRef}-${index}-${field.key}`}>
													<FieldComponent
														options={
															{ showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
														}
														fieldName={field.key}
														field={field} />
												</td>
										})
									}

									{(i != 0 && hasFieldDelete)
										? <>
											<td className="td16 text-end">
												<Link className='text-danger' to="#" onClick={() => removeField(i)}>
													<FiTrash2 className="p-0 mb-0 h5" />
												</Link>
											</td>
										</>
										: null
									}

									{i == 0 ?
										<td className="td16 text-end">
											<Link to="#" className='p-0 text-success' onClick={addNewField}>
												<FiPlusCircle className="h5 p-0 mb-0" />
											</Link>
										</td>
										: null
									}

								</tr>
							</>
						)
						: (
							(item.delete === true && item.saveIndex !== false)
								? <span style={{ display: 'none' }} key={`${idRef}-${i}-${index}`}>
									<input type="hidden" name={`formdata[${item.fields[0].table_key}][removeAwardItems][${pIndex}][]`} value={item.saveIndex} />
								</span>
								: null
						);
				})
			}
			{
				totalFields && (
					<ChildInput fields={totalFields.field.childrens} index={totalFields.index} showTotal={hasFieldDelete} />
				)
			}

		</>
	);
}

MultiChild.propTypes = multiChildPropTypes;

// ChildInput
type ChildInputProps = {
	fields: any[];
	index?: string | number,
	showTotal?: any
};

const childInputPropTypes = {
	fields: PropTypes.array.isRequired,
	index: PropTypes.any,
	showTotal: PropTypes.any,
};

function ChildInput({ fields, index, showTotal }: ChildInputProps) {

	const [_fields, _setFields] = useStateMounted<string[]>([]);

	useEffect(() => {
		if (fields.length) {
			_setFields(fields);
		}
	}, [fields]);

	const extTD = <td colSpan={5} >&nbsp;</td>;

	return (
		<>
			<tr aria-hidden={(showTotal) ? "false" : "true"}>
				{/* <td><span className="text-center">Sub Total</span></td> */}
				{
					(_fields.length && index !== 1)
						? _fields.map((field: any, i: number) => {
							return ([3].includes(i))
								? <td key={index + '-' + i + '-' + field.key}>&nbsp;</td>
								: ([2].includes(i))
									? <td key={index + '-' + i + '-' + field.key} aria-hidden="true">
										<FieldComponent
											options={{ showTitle: false, extraId: '_' + index + '_' + i }}
											fieldName={field.key}
											field={field} />
									</td>
									:
									<>
										{(i === 1) ? extTD : null}
										<td key={index + '-' + i + '-' + field.key}>
											<FieldComponent
												options={{ showTitle: false, extraId: '_' + index + '_' + i }}
												fieldName={field.key}
												field={field} />
										</td>
									</>
						})
						: null
				}

			</tr>
		</>
	);
}

ChildInput.propTypes = childInputPropTypes;

// Multijson
type MultijsonProps = {
	field: any;
	index: number,
	totalField: any,
	isLoading: boolean,
	idRef: string,
	currentPage: any,
	limit: any,
	hasPagination: any
};

const multijsonPropTypes = {
	field: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	totalField: PropTypes.any.isRequired,
	idRef: PropTypes.string.isRequired,
	currentPage: PropTypes.any,
	limit: PropTypes.any,
	hasPagination: PropTypes.any
};

function Multijson({ field, index, totalField, isLoading, idRef, currentPage, limit, hasPagination }: MultijsonProps) {

	const [srNo, setSrNo] = useStateMounted<number>(1);
	const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
	const [fields, setFields] = useStateMounted<any>([]);
	const [newFields, setNewFields] = useStateMounted<any>();

	useEffect(() => {
		if (field) {
			setHasFieldDelete(false);
			setFields([]);
			setNewFields(field.addnew);
			const fieldsMap = field.childrens.map((items: any) => {
				return {
					fields: items,
					delete: false
				}
			})
			setFields(fieldsMap);
		}
	}, [field]);

	useEffect(() => {
		if (fields.length) {
			setSrNo((limit !== false && currentPage > 0) ? (limit * currentPage) + 1 : 1);
			const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
			setHasFieldDelete(hasDelete);
			setTimeout(() => {
				initselect(`#${idRef} .form-control`);
				multijsoninit(`#${idRef} .form-control`);
			}, 1000);
		}
	}, [fields]);

	const addNewField = () => {
		setFields((prev: any[]) => {
			return [...prev, { fields: newFields, delete: false }];
		});
	}

	const removeField = (index: number) => {
		setFields((prev: any[]) => {
			const newFields = prev.map((item, i) => {
				if (i === index) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});
	}

	const adjustwidth = () => {
		let awards: any = document.querySelectorAll('.award-td');
		for (let i = 0; i < awards.length; i++) {
			let awardid = awards[i].getAttribute('id');
			if (awardid) {
				let allcss: any = document.querySelectorAll('.' + awardid);
				if (allcss.length) {
					if (awardid == 'td1') {
						for (let j = 0; j < allcss.length; j++) {
							allcss[j].width = awards[i].offsetWidth;
						}
					}
					else
						awards[i].width = allcss[0].offsetWidth;
				}
			}
			let footid = document.getElementById('tdf' + i);
			if (footid)
				footid.style.width = awards[i].offsetWidth + 'px';
		}
	}

	return (
		<>
			<thead>
				<tr className="text-center">
					<th id="td1" className="award-td" style={{ maxWidth: 40, minWidth: 40 }}><span className="text-center">S.No.</span></th>
					<th id="td2" className="award-td"><span className="text-center">Major Components</span></th>
					<th id="td3" className="award-td"><span className="text-center">Item Summary</span></th>
					<th id="td4" className="award-td"><span className="text-center">Life Cycle Cost (INR/ meter)</span></th>
					<th id="td5" className="award-td"><span className="text-center">Quantity (Phase I)</span></th>
					<th id="td6" className="award-td"><span className="text-center">Quantity (Phase II)</span></th>
					<th id="td7" className="award-td"><span className="text-center">Total Quantity</span></th>
					<th id="td8" className="award-td"><span className="text-center">Estimated Cost (INR Lakh)</span></th>
					<th id="td9" className="award-td" style={{ maxWidth: 150, minWidth: 150, width: 150 }}><span className="text-center">Quantity in Nos</span></th>
					<th id="td10" className="award-td" style={{ maxWidth: 150, minWidth: 150, width: 150 }}><span className="text-center">Rate per Unit including all taxes and duties other than GST (INR/ month/ meter)</span></th>
					<th id="td11" className="award-td" style={{ maxWidth: 150, minWidth: 150, width: 150 }}><span className="text-center">AMISP Service Charge (INR/ month/ meter)</span></th>
					<th id="td12" className="award-td" style={{ maxWidth: 150, minWidth: 150, width: 150 }}><span className="text-center">O&M Period (No. of Months)</span></th>
					<th id="td13" className="award-td" style={{ maxWidth: 150, minWidth: 150, width: 150 }}><span className="text-center">Lumpsum payment per Unit  (in INR/ meter)</span></th>
					<th id="td14" className="award-td" style={{ maxWidth: 150, minWidth: 150, width: 150 }}><span className="text-center">Lifecycle Cost  <br /> (in INR/meter)</span></th>
					<th id="td15" className="award-td" style={{ maxWidth: 150, minWidth: 150, width: 150 }}><span className="text-center">Total cost for each category of meter  <br />(INR Lakh)</span></th>
					<th id="td16" className="award-td" colSpan={2}><span className="text-center">Package No</span></th>
				</tr>
			</thead>
			<tbody className="awardItemDynWidth">
				{
					fields.map((item: any, i: number) => {
						return (item.delete === false)
							? (
								<>
									<tr className="align-top">
										<td className="td1"><span className="text-center">{(srNo + i)}</span></td>
										{
											item.fields.map((field: any, j: number) => {
												let extraId = '_' + i + '_' + j;
												let style: any = {};
												let hidefield = false;
												let module = 'award_';
												if ([7, 8, 9, 10, 11].includes(j)) {
													module = 'award_';
													extraId = '_' + i + '_' + i + '_' + j;
													style.display = 'none';
													hidefield = true;
												}
												return (field.type !== 'multijson' && !field.childrens)
													? (
														!hidefield ?
															<td className={'td' + (j + 2)} key={`${idRef}-${index}-${field.key}`}>
																<FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
															</td>
															:
															<span style={style}>
																<FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
															</span>
													) : null;
											})
										}
										<td colSpan={8} className="multiple-award-items">
											<table className="table-responsive RevenueDetailsTable PqTable">
												<tbody>
													{
														item.fields.map((field: any, j: number) => {
															const totalFieldIndex = index + '_' + i + '_' + (item.fields.findIndex((field: any, j: number) => (field.type === 'json' && field.childrens)));
															const totalFields = item.fields.find((field: any, j: number) => (field.type === 'json' && field.childrens));
															return field.type === 'multijson' && (
																<MultiChild idRef={idRef} field={field} pIndex={i} jIndex={11} index={index + '_' + i + '_' + j} totalFields={{ field: totalFields, index: totalFieldIndex }} />
															)
														})
													}
												</tbody>
											</table>
										</td>
									</tr>
								</>
							)
							: null;
					})
				}
				{
					(totalField) && (
						<tr>
							<td colSpan={4}><span className="text-start">{(hasPagination) ? 'Page Total' : 'Total'}</span></td>
							<td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[0].key} field={totalField[0]} module="award_" /></td>
							<td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[1].key} field={totalField[1]} module="award_" /></td>
							<td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[2].key} field={totalField[2]} module="award_" /></td>
							<td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[3].key} field={totalField[3]} module="award_" /></td>
							<td colSpan={8}>
								<table className="table-responsive">
									<tbody>
										<tr className="border-0">
											<td id="tdf9" style={{ minWidth: 150, width: 150 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[5].key} field={totalField[5]} /></td>
											<td id="tdf10" style={{ minWidth: 150 }}></td>
											<td id="tdf11" style={{ minWidth: 150 }}></td>
											<td id="tdf12" style={{ minWidth: 150 }}></td>
											<td id="tdf13" style={{ minWidth: 150 }}></td>
											<td id="tdf14" style={{ minWidth: 153 }}></td>
											<td id="tdf15" style={{ minWidth: 150, width: 150 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[6].key} field={totalField[6]} /></td>
											<td id="tdf16"></td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					)
				}
			</tbody>
			{
				(isLoading) ? <LoadingSpinner /> : null
			}
		</>
	);
}

const AwardItemsForm = (props: AwardItemsProps) => {

	const uniqueId = useRef(Math.random().toString(36).substring(2, 15));
	const idRef = 'smart-award-items-' + uniqueId.current;
	const [isLoaded, setIsLoaded] = useState(false);
	const [limit, setLimit] = useState<any>(false);
	const [hasPagination, setHasPagination] = useState<boolean>(false);
	const [totalPage, setTotalPage] = useState<any>(0);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [allFields, setAllFields] = useState<any[]>([]);
	const [fields, setFields] = useState<any[]>([]);
	const [roleid, setRoleid] = useState<any>(0);
	const [apiurl, setApiurl] = useState<any>(null);
	const [discomid, setDiscomid] = useState<any>(0);
	const [refresh, setRefresh] = useState(false);
	const [districts, setDistricts] = useState<any[]>([]);
	const [getdistricts, setGetDistricts] = useState<any>(0)
	const [districtsresult, setDistrictsResult] = useState<any>(0);
	const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
	/*const [isLoading, setIsLoading] = useState(false);*/
	const [getawarditems, setGetAwardItems] = useState<any>();
	const [form_id, setFormId] = useState<any>(0);
	const [loading, setLoading] = useStateMounted<boolean>(true);
	const [error, setError] = useStateMounted<string>();
	const [getEndPointLink, setEndPointLink] = useState<any>(null);

	useEffect(() => {
		setDiscomid(Storage.get('discom_id'));
	}, [discomid]);

	useEffect(() => {
		setRoleid(Storage.get('roleid'));
	}, [roleid]);

	const ChangeValue = (evt?: any) => {
		setGetDistricts(evt.target.value);
		setCurrentPage(0);
		setIsLoaded(true);
	};

	const ChangeAwardItemsValue = (evt?: any) => {

		if (evt.target.value == 'Discom') {
			setGetDistricts(0);
			setFormId(29);
		} else {
			setFormId(32);
		}
		setGetAwardItems(evt.target.value);
		setIsLoaded(true);
	};

	const getDistricts = () => {
		(async () => {
			if (apiurl) {
				let footerspinner: any = document.getElementById('footer-spinner');
				if (footerspinner)
					footerspinner.classList.remove('d-none');
				await Http.get(apiurl)
					.then((result) => {
						setIsLoaded(true);
						setDistricts(result.district);
					})
					.catch((error) => {
						setIsLoaded(true);
						setError(error);
					}).finally(() => {
						footerspinner.classList.add('d-none');
					});
			}
		})();
	};

	useEffect(() => {
		if (discomid) {
			setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
		}
	}, [apiurl, discomid]);

	useEffect(() => {
		setDiscomid(Storage.get('discom_id'));
	}, [discomid]);

	useEffect(() => {
		(async () => {
			getDistricts();
		})();
	}, [apiurl]);

	const getFields = () => {
		(async () => {
			setHasPagination(false);
			//setCurrentPage(0);
			setTotalPage(0);
			setAllFields([]);
			setFields([]);
			setLoading(true);
			setError('');

			if (discomid && form_id && roleid && getawarditems) {

				const params = {
					roleid: roleid,
					discom_id: discomid,
					form_id: form_id,
					district_id: getdistricts,
					award_items_type: getawarditems,
					award_items_module: 1,
					module: 'award'
				};

				if (getawarditems == 'Discom') {
					setEndPointLink('/apiv1/formfields');
				} else {
					setEndPointLink('/apiv1/dprfields');
				}
				let footerspinner: any = document.getElementById('footer-spinner');
				if (footerspinner)
					footerspinner.classList.remove('d-none');
				await Http.get(getEndPointLink, { params })
					.then((res) => {
						if (res.resultflag && res.fields) {
							if (res.fields && Array.isArray(res.fields)) {
								if (!res.fields.length) setError("No data found.");
								// console.log(res.fields
								//setFields(res.fields);
								if(res.pagination){
									setLimit(res.limit);
									setHasPagination(true);
									setAllFields(res.fields);
								} else {
									setLimit(false);
									setFields(res.fields);
								}
								setDistrictsResult(res.district_id);
							} else {
								setError("Invalid data type.");
							}
						} else if (!res.resultflag) {
							setError(res.message);
						} else {
							setError('No data found.');
						}
					})
					.catch((error) => {
						setError('System error occurred!! please try again.');
					}).finally(() => {
						setLoading(false);
						footerspinner.classList.add('d-none');
					});
			}
		})();
	};

	useEffect(() => {
		getFields();
	}, [getawarditems, getEndPointLink, discomid, roleid, form_id, getdistricts]);

	useEffect(() => {
		if(props.refresh) {
			getFields();
		}
	}, [props.refresh]);

	useEffect(() => {
		if (fields.length) {
			setTimeout(() => {
				init(`#${idRef} .form-control`);
				multijsoninit(`#${idRef} .form-control`);
			}, 1500);
		}
	}, [fields]);

	useEffect(() => {
		if (hasPagination && allFields.length) {
			let footerspinner: any = document.getElementById('footer-spinner');
			if (footerspinner) footerspinner.classList.remove('d-none');
			setLoading(true);
			setFields([]);
			setTimeout(() => {
				setLoading(false);
				setTotalPage(allFields[0].childrens.length);
				const reFields = JSON.parse(JSON.stringify(allFields));
				reFields[0].childrens = allFields[0].childrens[currentPage];
				setFields(reFields);
				if (footerspinner) footerspinner.classList.add('d-none');
			}, 1500);
		}
	}, [hasPagination, allFields, currentPage]);

	return (
		<div id={idRef}>
			{error && (
				<>
					<Alert className='mt-3 mb-3' variant="danger">{error}</Alert>
				</>
			)}
			<div className='row'>
				<div className='col-md-3'>
					<select className='form-control text-start form-select w-100' onChange={ChangeAwardItemsValue} defaultValue="" name="sm_award_items_type" id="sm_award_items_type">
						<option value={0}>Select</option>
						{/*<option value={"Discom"}>Discom</option>*/}
						<option value={"District"}>District</option>
					</select>
				</div>
				<div className='col-md-3'>
					{
						(districts && getawarditems == 'District') ? <>
							<select className='form-control text-start form-select w-100' onChange={ChangeValue} defaultValue="" name="sm_award_district_id" id="sm_award_district_id">
								<option value={0}>Select District</option>
								{
									districts.map((district: any) => {
										const attr = (district.id === districtsdefaultvalue) ? { value: district.id, selected: true } : { value: district.id };
										return <><option {...attr}>{district.district_name}</option></>;
									})
								}
							</select>
						</>
							: null
					}
				</div>
			</div>
			{
				((!loading && !error) && fields?.length > 0) && (
					<>
						{getawarditems == 'Discom' ?
							<Block1 idRef={idRef} field5={fields[0]} field2={fields[1]} field3={getawarditems} field4={getdistricts} />
							: null
						}

						{(getawarditems == 'District' && getdistricts != 0) ?
							<div className="">
								<div className="row justify-content-end">
									{(hasPagination) && (
										<>
										<div className="col-md-6 text-start mt-3 text-danger">
											Please save current page before proceeding to the next page
										</div>
										<div className="col-md-6 text-end mb-2">
											<input type="hidden" name="formdata[sl_smart_metering_district][currentPage]" value={currentPage}/>
											{(currentPage != 0) && (
												<button type="button" onClick={() => setCurrentPage((prev) => prev - 1) } className="btn btn-primary me-2">Previous</button>
											)}
											{
												(() => {
													let arr = [];
													for(let i = 0; i < totalPage; i++){
														arr.push(
															<button type="button" onClick={() => setCurrentPage(i) } className={`btn btn-secondary me-2`} style={(currentPage == i) ? {"backgroundColor":"#54a800","border":"1px solid #54a800"} : {}}>{i+1}</button>
														);
													}
													return arr;
												})()
											}
											{((totalPage - 1) > currentPage) && (
												<button type="button" onClick={() => setCurrentPage((prev) => prev + 1) } className="btn btn-primary me-2">Next</button>
											)}
										</div>
										</>
									)}
								</div>
								<div className="table-responsive RevenueDetailsTable PqTable multiTableTD">
									<table>
										{
											fields.map((field: any, index: number) => {
												const totalField = (fields.length && fields[1].childrens) ? fields[1].childrens : null;
												return field.type === 'multijson' && (
													<Multijson {...{idRef, field, index, totalField, isLoading: props.isLoading, currentPage, limit, hasPagination}}/>
												)
											})
										}
									</table>
								</div>
							</div>
							: null
						}
					</>
				)
			}
		</div>
	);
};

const SmartMeteringAward = (props?: any) => {
	const [roleid, setRoleid] = useState<any>(0);
	const [discomid, setDiscomid] = useState<any>(0);

	useEffect(() => {
		setRoleid(Storage.get('roleid'));
		setDiscomid(Storage.get('discom_id'));
	}, [roleid]);

	return (
		<>
			{roleid == 0 ? null :
				<AwardItemsForm apiurl={"/apiv1/formfields/?roleid=" + roleid + "&discom_id=" + discomid + "&module=award"} isLoading={props.isLoading} refresh={props.refresh} />
			}
		</>
	)
}

export default SmartMeteringAward;