import React, { useEffect, useState } from 'react'
import SmMisReports from "../Reports/MisReport/SmMisReports";
import LsMisReports from "../Reports/MisReport/LsMisReports";
import InnerTemplate from "../Components/InnerTemplate/InnerTemplate";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import "./misreport.scss";
import { useParams } from 'react-router-dom';

const QuarterlyTargetsDetails = (props:any) => {
    const {type} = useParams<{ type: string }>();
    const [selected, setSelected] = useState("SmartMetering");
    const [reportType, setReportType] = useState<any>('Allindia');
    useEffect(() => {
        setSelected("SmartMetering");
        if ( type == 'LT lines' || type == '11 or 22 kV Line' || type == '33 or 66 kV Line' || 
             type == 'DTRs' || type == 'New Power Substation' || type == 'Cabling Works' || 
             type == 'HVDS' || type == 'Feeder Segregation' || type == 'Feeder Bifurcation'
             ) {
            setSelected("Loss-Reduction");
        }
    }, []);
    const changeHandler = (e: any) => {
        setSelected(e.target.value);

        if (e.target.value == 'SmartMetering') {
            setReportType('Allindia');
        }

    };

    return (
        <>
            <Header />
            <div className="mainWrap">
                <InnerTemplate PageTitle="MIS Reports" />
                <section className="financialForm misNew">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className='radioTitleSection'>
                                <div className='row mx-0 gx-0'>
                                    <div className='col-lg-12 col-xl-6 col-xxl-6'>
                                        <div className="radioYear">
                                            <div className="row mx-0">
                                                <div className="col-md-4">
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name="SanctionLetter" value="SmartMetering" id="SmartMetering" checked={selected === "SmartMetering"} onChange={changeHandler} />
                                                        <label className="form-check-label" htmlFor="SmartMetering">
                                                            Smart Metering
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name="SanctionLetter" value="Loss-Reduction" id="Loss-Reduction" checked={selected === "Loss-Reduction"} onChange={changeHandler} />
                                                        <label className="form-check-label" htmlFor="Loss-Reduction">Loss Reduction</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" name="SanctionLetter" value="Modernization" id="Modernization" checked={selected === "Modernization"} onChange={changeHandler} />
                                                        <label className="form-check-label" htmlFor="Modernization">Modernization</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div aria-hidden={selected !== "SmartMetering" ? true : false}><SmMisReports reptype={type} /></div>
                            <div aria-hidden={selected !== "Loss-Reduction" ? true : false}><LsMisReports filter={type} /></div>
                            <div aria-hidden={selected !== "Modernization" ? true : false}>c</div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </>
    );
}

export default QuarterlyTargetsDetails;