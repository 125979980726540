import React, { Component } from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'

export default class CopyRightPolicy extends Component {

    render() {
        return (
            <>
                <Header />
                <InnerTemplate sublink={false} PageTitle="Copyright Policy" />
                <div className='main'>
                    <div className='container'>
                        <div className='screenReader'>
                            <p>Contents of this website may not be reproduced partially or fully, without due permission from REC. If referred to, as a part of another website, the source must be appropriately acknowledged. The contents of this website cannot be used in any misleading or objectionable context. The permissions to reproduce this material shall not extend to any material which is identified as being copyright of a third party. Authorisation to reproduce such material must be obtained from the copyright holders concerned.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
