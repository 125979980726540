import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Report from '../../assets/ImageCompo/Report'
import Monitor from '../../assets/ImageCompo/Monitor'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import InnerTemplate from '../../Components/InnerTemplate/InnerTemplate'
import "../../css/style.scss";
import { Http } from '../../Core/Services/HttpService';
import { Storage } from '../../Core/Services/StorageService'

const QualityMonitoringNodalView = (props:any) => {
        const [discomid, setDiscomId] = useState<any>(0);
        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [discomuserid, setDiscomUserid] = useState<any>(0);
        const [apiurl, setApiurl] = useState<any>(null);
        const [fields, setFields] = useState<any[]>([]);
        const [isEvaluationBox, setEvaluationBox] = useState<any>(0);
        const [isFundBox, setFundBox] = useState<any>(null);
        const [nodalAgency, setNodalAgency] = useState<any>(0);

        const ChangeValue = (evt?: any) => {
            const discomSplit = evt.target.value.split(",");
            Storage.set('discom_id', discomSplit[0]);
            setDiscomId(discomSplit[0]);
        };
        const getDiscoms = () => {
            (async () => {
                if(apiurl) {
                await Http.get(apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setFields(result.discoms);
                        setTimeout(() => {
                        }, 2000);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
                }
            })();
        };

        useEffect(() => {
            if(discomuserid) {
                setApiurl("/apiv1/getdiscoms/?discom_user_id="+discomuserid + "&nodal_agency=" + nodalAgency);
            }
        }, [apiurl, discomuserid, nodalAgency]);

        useEffect(() => {
            setDiscomUserid(Storage.get('discom_user_id'));
            setNodalAgency(Storage.get('nodal_agency'));  
        }, [discomuserid,nodalAgency]);

        useEffect(() => {
            (async () => {
                getDiscoms();
            })();
        }, [apiurl]);

        if (error) {
            return <div>Error: {error}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {    
        return (
            <>
                <div className='screenReader'>
                    <div className='caption mt-4'>
                        <div className='row justify-content-center'>
                            <div className='col-md-3'>
                                <select onChange={ChangeValue} className="form-select">
                                    <option value={0} selected>Select Discom</option>
                                    {
                                        (fields.length && discomuserid > 0) ?
                                        fields.map((discom: any) => (
                                            <>
                                                <option value={[discom.id,discom.discom_tab]}>{discom.discom_name}</option>
                                            </>
                                        ))
                                        : null
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    { discomid && discomid > 0 ?
                    <div className='aboutAccordioin d-block mt-5'>
                        <div className='Accordion-group mw-100'>
                            <div className='Tab-panel'>
                            <div className="ActionCenterSection">
                                <div className="row gx-0">
                                    <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                                    {/* <Link to="/input-form" className="d-block w-100 text-decoration-none h-100"> */}
                                        <div className="tabPanelInner">
                                        <Report />
                                        <h5>Power Transformer <br/> Procurement</h5>
                                        <div className="hoverContent">
                                            <div><Report />
                                            <h5>Power Transformer <br/> Procurement</h5>
                                            <p></p></div>
                                        </div>
                                        </div>
                                    {/* </Link> */}
                                    </div>
                                    <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                                    <Link to="/material-quality-inspection" className="d-block w-100 text-decoration-none h-100">
                                    <div className="tabPanelInner">
                                        <Report />
                                        <h5>Material Quality <br/> Inspection</h5>
                                        <div className="hoverContent">
                                        <div><Report />
                                        <h5>Material Quality  Inspection</h5>
                                        <p>To add details of PDI inspection <br/> and arrival at site</p>
                                        </div>
                                        </div>
                                    </div>
                                    </Link>
                                    </div>
                                    <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                                    <Link to="/field-works-quality-inspection" className="d-block w-100 text-decoration-none h-100 h-100">
                                        <div className="tabPanelInner">
                                        <Report />
                                        <h5>Field Works Quality <br/> Inspection</h5>
                                        <div className="hoverContent">
                                        <div><Report />
                                            <h5>Field Works Quality <br/> Inspection</h5>
                                            <p></p></div>
                                        </div>
                                        </div>
                                    </Link>
                                    </div>
                                    <div className="col-lg-6 col-6 d-flex align-items-center justify-content-center">
                                    {/* <Link to="/fund-disbursal" className="d-block w-100 text-decoration-none h-100 h-100"> */}
                                        <div className="tabPanelInner">
                                        <Report />
                                        <h5>View TPQMA Inspection Report</h5>
                                        <div className="hoverContent">
                                        <div> <Report />
                                            <h5>View TPQMA Inspection Report</h5>
                                            <p>To request for fund release</p>
                                        </div>
                                        </div>
                                        </div>
                                    {/* </Link> */}
                                    </div>
                                    <div className="col-lg-6 col-6 d-flex align-items-center justify-content-center">
                                    <div className="tabPanelInner">
                                        <Report />
                                        <h5>Generate MIS and Reports</h5>
                                    </div>
                                    </div>
                                    {/* <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                                    <div className="tabPanelInner">
                                        <Report />
                                        <h5>Scheme Closure</h5>
                                    </div>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                    }
                </div>
            </>
        )
    }
}

export default QualityMonitoringNodalView;