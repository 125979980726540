import React, { useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import './chart.scss';
import { Http } from "../../../Core/Services/HttpService";
import { toFixed } from "../../../assets/js/formbuilder";

function SmartMetering(props: any) {
  const qParams = {
    stateid: new URLSearchParams(document.location.search).get('stateid'),
    discomid: new URLSearchParams(document.location.search).get('discomid'),
    indicatorid: new URLSearchParams(document.location.search).get('indicatorid')
  };
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<any>([{
    data: [],
    name: '',
  }]);
  const [chartImg, setChartImg] = React.useState<any>(null);
  const catIds = ['san-cost', 'awar-cost', 'gbs-sanc', 'gbs-rel'];
  const navigate = useNavigate();
  const location = useLocation();

  const setLinks = useCallback(() => {
    if (!props.chartLink) return;
    let xaxis = document.querySelector('#chart .apexcharts-xaxis');
    xaxis?.setAttribute('style', 'pointer-events: auto;');

    let barClick = document.querySelector('#chart .apexcharts-series');
    barClick?.setAttribute('style', 'cursor: pointer;');
    let labels = document.querySelectorAll('#chart .apexcharts-xaxis-label');

    labels.forEach((label: any, index: any) => {
      label.setAttribute('style', 'cursor: pointer;');
      label.addEventListener('click', () => {
        redirect({ type: catIds[index] });
      });
    });

  }, [series]);

  const redirect = (params: any) => {
    const navParams = {
      ...params,
      id: qParams.stateid,
      sectionfor: 'TableSection',
    };
    if (props.isDiscomView == 'true') {
      navParams['chartfor'] = 'state';
    }
    if(props.isDistrictView == 'true'){
      navParams['chartfor'] = 'discom';
      navParams['id'] = qParams.discomid
      navParams['state'] = qParams.stateid
    }
  const link = props.chartLink + '?' + new URLSearchParams(navParams).toString();
  navigate(link, { state: { modal: location } });
};

const renderchart = async () => {
  setSeries([{
    data: [],
    name: '',
  }]);
  let extraParam = {};
  if (props.chartdata) {
    extraParam = {
      ...extraParam,
      stateid: props.chartdata.stateid,
      indicatorid: props.chartdata.indicatorid,
      selected: props.chartdata.selected
    };
    if (props.chartdata.discomid) {
      extraParam = {
        ...extraParam,
        discomid: props.chartdata.discomid
      };
    }
    await Http.get('/apiv1/d-board/chartfundreleasedata', { params: extraParam })
      .then((res) => {
        const chartData = res.discomFundReleaseValuesData;
        props.childData(chartData);
        const seriesData = [
          {
            name: 'Smart Metering',
            data: [chartData.sm_approved_project_cost, chartData.sm_award_cost, chartData.sm_gbs, chartData.sm_fund_released]
          }
        ];
        setSeries(seriesData);
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
useEffect(() => {
  if (props) {
    setOptions({
      noData: {
        text: 'Loading...',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
      },
      tooltip: {
        y: {
          formatter: (value: any) => {
            return toFixed(value, 2);
          },
        }
      },
      chart: {
        type: 'bar',
        events: {
          dataPointSelection: (event: any, chartContext: any, { seriesIndex, dataPointIndex, config }: any) => {
            if (!props.chartLink) return;
            redirect({ type: catIds[dataPointIndex] });
          },
          mounted: () => {
            const chartDiv: any = document.querySelector('#chart');
            let settings = { childList: true },
              observer = new MutationObserver(() => setLinks());

            observer.observe(chartDiv, settings);
            setLinks();
          },
          updated: async (chartContext: any, config: any) => {

            const chartDiv: any = document.querySelector('#chart');
            let settings = { childList: true },
              observer = new MutationObserver(() => setLinks());

            observer.observe(chartDiv, settings);
            setLinks();
            await chartContext.dataURI().then((res: any) => {
              setChartImg(res);
            });
          },
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        },
        formatter: (value: any) => {
          return toFixed(value, 2);
        }
      },
      legend: {
        show: false
      },
      // colors: ['#feba3b', '#269ffa', '#bdddf7', '#26e7a6'],
      colors: ['#feba3b', '#269ffa', '#f36944', '#26e7a6'],
      grid: {
        borderColor: '#fff',
      },
      yaxis: {
        show: false
      },
      xaxis: {
        categories: [
          ['Sanctioned Cost'], ['Awarded Cost'], ['GBS Sanctioned'], ['GBS Released'],
        ],
        labels: {
          style: {
            fontSize: '12px'
          }
        },
      }
    });
    renderchart();
  }
}, [props.chartdata]);

return (
  <div id="chart" className="FundChart">
    {
      (options != null && series != null)
        ? <ReactApexChart options={options} series={series} type="bar" height={340} />
        : null
    }
  </div>
);
}
export default SmartMetering;
