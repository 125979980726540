import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import { FiCheck, FiX, FiExternalLink } from 'react-icons/fi'
import { Http } from "../../../Core/Services/HttpService";
import { AiOutlineFilePdf } from "react-icons/ai";
import { Storage } from "../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

export const ClaimFormAgencyLink = () => {
    const formData: any = useRef(null);
    const [roleid, setRoleid] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [error, setError] = useState(null);
    const [agencyList, setAgencyList] = useState<any[]>([]);
    const [getcomponentname, setComponentname] = useState<any>(null);
    const [gettranchename, setTranchename] = useState<any>(null);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
    const [getpagebacklink, setPageBackLink] = useState<any>(null);
    const [refresh, setRefresh] = useState(false);
    const [type_of_entity, setTypeOfEntity] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [gettrackerid, setTrackerId] = useState<any>(0);
    const [getsavedraft, setSaveDraft] = useState<any>(0);
    const [claimFormId, setClimFormId] = useState<any>(0);
    const [districtId, setDistrictId] = useState<any>(0);
    const [agencyName, setAgencyName] = useState<any>(null);
    const [formId, setFormId] = useState<any>(0);
    const location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (location.state) {
                setComponentname(location.state.component_name);
                setTranchename(location.state.tranche_name);
                setComponentId(location.state.component_id);
                setTrancheId(location.state.tranche_id);
                setAplicationTrackerId(location.state.aplication_tracker_id);
                setTrackerId(location.state.ref_id);
                setClimFormId(location.state.id);
                setDistrictId(location.state.district_id);

                if (location.state.id == 10) {
                    setFormId(19);
                } else {
                    setFormId(20);
                }
            } else {
                if (getsavedraft != 1) {
                    navigate('/fund-disbursal');
                }
            }
        })();
    }, [agencyList, getcomponentname, gettranchename, getcomponentid, gettrancheid, getaplicationtrackerid]);

    const gottopage = (gottopage: any, agency_name: any) => {
        setPageLink(gottopage);
        setAgencyName(agency_name);
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { ref_id: gettrackerid, id: claimFormId, data: agencyList, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid, claimformid: claimFormId, agency_name: agencyName, district_id: districtId } });
            }
        })();
    }, [getpagelink]);

    const navtab = () => {
        setPageBackLink('/claim-forms-district-link');
    }

    useEffect(() => {
        (async () => {
            if (getpagebacklink) {
                navigate(getpagebacklink, { state: { ref_id: gettrackerid, id: claimFormId, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid, claimformid: claimFormId, agency_name: agencyName, district_id: districtId } });
            }
        })();
    }, [getpagebacklink]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
        setTypeOfEntity(Storage.get('type_of_entity'));
    }, [roleid]);

    useEffect(() => {
        if (getaplicationtrackerid && districtId) {
            (async () => {
                setIsLoading(true);
                await Http.get(apiurl + "?roleid=" + roleid + "&discom_id=" + discomid + "&component_id=" + getcomponentid + "&tranche_id=" + gettrancheid + "&discom_user_id=" + discomuserid + "&application_id=" + getaplicationtrackerid + "&district_id=" + districtId + "&claim_form_id=" + claimFormId)
                    .then((result) => {
                        setAgencyList(result.data);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setError(error);
                        setIsLoading(false);
                    })
            })();
        }
    }, [apiurl + "?roleid=" + roleid + "&discom_id=" + discomid + "&component_id=" + getcomponentid + "&tranche_id=" + gettrancheid + "&discom_user_id=" + discomuserid + "&application_id=" + getaplicationtrackerid + "&district_id=" + districtId + "&claim_form_id=" + claimFormId]);

    useEffect(() => {
        setApiurl("/apiv1/claim-forms-agency-list/");
    }, [getaplicationtrackerid, districtId]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                :
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            }
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Fund Disbursal</Link></li>
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Request for Fund Release</Link></li>
                            <li>Claim Form {(claimFormId == 10) ? '1' : '2'} (

                                {(getcomponentid == 1 || getcomponentid == 2) ?
                                    <>
                                        Package No - Agency
                                    </>
                                    :
                                    <>
                                        Agency
                                    </>
                                }

                                )</li>
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className="radioYear px-3">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="RevenueDetailsTable">
                                                <div className="caption-top">
                                                    <caption className="text-center py-2 d-block w-100">Claim Form {(claimFormId == 10) ? '1' : '2'} ( {(getcomponentid == 1 || getcomponentid == 2) ?
                                                        <>
                                                            Package No - Agency
                                                        </>
                                                        :
                                                        <>
                                                            Agency
                                                        </>
                                                    } )
                                                    </caption>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive RevenueDetailsTable mt-4">
                                        <table className="table caption-top">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "5%" }}>S. No.</th>
                                                    {(getcomponentid == 1 || getcomponentid == 2) ?
                                                        <th style={{ width: "10%" }}>Package No - Agency Name</th>
                                                        :
                                                        <th style={{ width: "10%" }}>Agency Name</th>
                                                    }
                                                    <th style={{ width: "5%" }}>Status</th>
                                                    <th style={{ width: "5%" }}>Link</th>
                                                    <th style={{ width: "10%" }}>Supporting Documents</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(agencyList.length > 0) ?
                                                    <>
                                                        {
                                                            agencyList.map((item: any, key: number) => {
                                                                return <>
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td>{item.agency_name}</td>
                                                                        <td className="text-cenetr">
                                                                            {(item.submitted_status == 1) ?
                                                                                <FiCheck className="p-0 mb-0 h5 text-success" />
                                                                                :
                                                                                <>
                                                                                    <FiX className="p-0 mb-0 h5 text-danger" />
                                                                                </>
                                                                            }
                                                                        </td>
                                                                        <td className="text-cenetr">
                                                                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                                                                <>
                                                                                    {(claimFormId == 10) ?
                                                                                        <Link className='text-primary' to="" onClick={() => gottopage('/claim-forms-form-1', item.agency_name)}><FiExternalLink className="p-0 mb-0 h5 text-primary" /></Link>
                                                                                        :
                                                                                        <Link className='text-primary' to="" onClick={() => gottopage('/claim-forms-form-2', item.agency_name)}><FiExternalLink className="p-0 mb-0 h5 text-primary" /></Link>
                                                                                    }
                                                                                </>
                                                                                : null
                                                                            }

                                                                            {((roleid == 5 || roleid == 6) && (item.submitted_status == 1)) ?
                                                                                <>
                                                                                    {(claimFormId == 10) ?
                                                                                        <Link className='text-primary' to="" onClick={() => gottopage('/claim-forms-form-1', item.agency_name)}><FiExternalLink className="p-0 mb-0 h5 text-primary" /></Link>
                                                                                        :
                                                                                        <Link className='text-primary' to="" onClick={() => gottopage('/claim-forms-form-2', item.agency_name)}><FiExternalLink className="p-0 mb-0 h5 text-primary" /></Link>
                                                                                    }
                                                                                </>
                                                                                : null
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {(item.submitted_status == 1) ?
                                                                                <>
                                                                                    <a href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/fd-pdf-single-forms/?roleid=' + roleid + '&discom_id=' + discomid + '&component_id=' + getcomponentid + '&application_id=' + getaplicationtrackerid + '&district_id=' + districtId + '&agency_name=' + btoa(item.agency_name) + '&form_id=' + formId + '&type=export&claim_download_link=agency'}><AiOutlineFilePdf className="pl-3 mb-0 h5 text-warning ms-4" /> Generate report </a>
                                                                                </>
                                                                                : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <div role="alert" className="fade mt-3 mb-3 alert alert-info show text-center">Agency not found</div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="formBtnSection justify-content-between mt-4">
                                        <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab()}>Back</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <>
                    {isLoading ? <LoadingSpinner /> : null}
                </>
                <Footer />
            </div >
        </>
    );
};
export default ClaimFormAgencyLink;
