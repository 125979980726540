import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Http } from "../Core/Services/HttpService";
import { init, validatefrm } from "../assets/js/formbuilder";
import { useNavigate, Link } from "react-router-dom";
import { useLayout } from "../Core/Providers/LayoutProvider";
import { Jsencrypt } from "../Core/Services/JsencryptService";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Storage } from "../Core/Services/StorageService";

const ChangePassword = () => {

  const [oldPassType, setOldPassType] = useState("password");
  const [newPassType, setNewPassType] = useState("password");
  const [confirmPassType, setConfirmPassType] = useState("password");
  const [userId, setUserId] = useState<any>(0);

  let navigate = useNavigate();
  const [err, setErr] = useState('');
  const [succ, setSucc] = useState('');

  useEffect(() => {
    setUserId(Storage.get('discom_user_id'));
  }, [userId]);

  function onSubmit(e: any) {
    e.preventDefault();
    setErr("");
    setSucc("");
    let submitform = validatefrm();
    if (submitform) {
      let myFormData = new FormData(e.target);
      // Old Pwd Encryption
      const encOldPwd: any = Jsencrypt.encrypt(myFormData.get('old_pwd'));
      myFormData.set('old_pwd', encOldPwd);
      // New Password Encryption
      const encNewPwd: any = Jsencrypt.encrypt(myFormData.get('new_pwd'));
      myFormData.set('new_pwd', encNewPwd);
      // Confirm Password Encryption
      const encConfirmPwd: any = Jsencrypt.encrypt(myFormData.get('confirm_pwd'));
      myFormData.set('confirm_pwd', encConfirmPwd);
      // User Id
      const encUserId: any = Jsencrypt.encrypt(userId);
      myFormData.append('user_id', encUserId);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      Http.post('/apiv1/changepassword', myFormData, config)
        .then((res: any) => {
          if (res._resultflag == '1') {
            setSucc(res.message);
            navigate('/');
          }
          else {
            setErr(res.message ?? 'Something went wrong, Please try again.');
          }
        })
        .catch((err: any) => {
          setErr('Something went wrong, Please try again.');
        });

    }
  }

  setTimeout(() => {
    init();
  }, 1000);
  return (
    <>
      <Header />
      <div className="mainWrap">
        <div className="loginWrap">
          <div className="login-main">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 col-sm-8 col-lg-6 col-xl-5 col-xxl-4">
                  <div className="position-relative loginPosition">
                    <div className="loginbox">
                      <h4 className="text-white">Change Password</h4>
                      <form noValidate onSubmit={onSubmit}>
                        {err != '' ?
                          <div className="alert alert-danger">{err}</div>
                          : null
                        }
                        {succ != '' ?
                          <div className="alert alert-success">{succ}</div>
                          : null
                        }

                        <div className="form-group">
                          <div className="form-field">
                            <label className="control-label" htmlFor="old_pwd">Old Password</label>
                            <input data-required="Y" className="form-control" autoComplete="off" type={oldPassType} name="old_pwd" id="old_pwd" />
                            <button id="showhidepass" type="button" className="form-control pass-show"
                              onClick={() => setOldPassType(oldPassType === "password" ? "text" : "password")}>
                              {oldPassType === "password" ? <BsEyeSlash /> : <BsEye />}
                            </button>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-field">
                            <label className="control-label" htmlFor="new_pwd">New Password</label>
                            <input data-required="Y" className="form-control" autoComplete="off" type={newPassType} name="new_pwd" id="new_pwd" />
                            <button id="showhidepass" type="button" className="form-control pass-show"
                              onClick={() => setNewPassType(newPassType === "password" ? "text" : "password")}>
                              {newPassType === "password" ? <BsEyeSlash /> : <BsEye />}
                            </button>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-field">
                            <label className="control-label" htmlFor="cofirm_pwd">Confirm Password</label>
                            <input data-required="Y" className="form-control" autoComplete="off" type={confirmPassType} name="confirm_pwd" id="confirm_pwd" />
                            <button id="showhidepass" type="button" className="form-control pass-show"
                              onClick={() => setConfirmPassType(confirmPassType === "password" ? "text" : "password")}>
                              {confirmPassType === "password" ? <BsEyeSlash /> : <BsEye />}
                            </button>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="formBtnSection">
                            <button className="btnSubmit" type="submit">Submit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChangePassword;
