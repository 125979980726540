import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import {init, validatefrm, multijsoninit, initselect, inputEventChecker} from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { BiUpload } from 'react-icons/bi';
import swal from 'sweetalert';

// Images
import FieldComponent from "../../../FieldComponent";
import PropTypes from "prop-types";
import {useStateMounted} from "../../../Core/Hooks";
import { Storage } from "../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
      <div className="spinner-container">
        <div className="spinner-outer">
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
}

type LossReductionProps = {
    apiurl: string;
};

const Block2 = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);
    const [years, setYears] = useState<number[]>([]);
    const [getcollspan, setCollspan] = useState<any>(0);
    const [district, setDistrict] = useState<any>(0);

    const yearRange = (start: any, stop: any, step: number) => {
        return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))
    };

    useEffect(() => {
        if (props.field3 && district != 0) {
            if (props.field3.value != '') {
                setHasBlock2FieldsDeletes(false);
                setBlock2fields([]);
                setNewBlock2fields(props.field3.addnew);
                setTimeout(() => {
                    const fields2 = props.field3.childrens.map((items: any) => {
                        return {
                            fields: items,
                            delete: false
                        }
                    })
                    setBlock2fields(fields2);
                }, 500);
            } else {
                setBlock2fields([]);
            }
        } else {
            setBlock2fields([]);
        }
    }, [props.field3]);

    useEffect(() => {
        if (props.year) {
            setYears([]);
            setYears(yearRange(2023, props.year, 1));
        }
    }, [props.year]);

    useEffect(() => {
        if (props.district) {
            setDistrict(props.district);
        }
    }, [props.district]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 500);
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }

    useEffect(() => {
        setCollspan(12);
    }, [props.year]);

    return (
        <>
            <table className="table caption-top newTableUcss">
                <thead className='text-center'>
                    <tr>
                        <th rowSpan={2}>Sl No.</th>
                        <th rowSpan={2} style={{minWidth:200}}>Major Components</th>
                        <th rowSpan={2} style={{minWidth:200}}>Sub Component</th>
                        <th rowSpan={2} style={{minWidth:150}}>Item Specification</th>
                        <th rowSpan={2} style={{minWidth:95}}>Unit</th>
                        <th colSpan={3} className="border-end noPadding"><span>Quantity (nos.)</span></th>
                        {
                            (years && years.length)
                                ? years.map((year: any, i: number) => {
                                    return (props.year != year)
                                        ?
                                        <>
                                            <th rowSpan={2} style={{minWidth:95}}>Target for FY <br />{year}</th>
                                        </>
                                        : null;
                                })
                                : null
                        }
                        {
                            (years && years.length)
                                ? years.map((year: any, i: number) => {
                                    return (props.year == year)
                                        ?
                                        <>
                                            <th colSpan={getcollspan} className="noPadding"><span>Targets for the Month</span></th>
                                            <th rowSpan={2} style={{minWidth:95}}>Target for FY <br />{year} </th>
                                            {year != '2023'
                                                ?
                                                <th rowSpan={2}>Cumulative Target till FY <br />{year}</th>
                                                : null
                                            }
                                        </>

                                        : null;

                                })
                                : null

                        }

                    </tr>
                    <tr>
                        <th className="fw-normal">Sanctioned</th>
                        <th className="fw-normal">Awarded</th>
                        <th className="fw-normal">Surveyed</th>
                        {
                            (years && years.length)
                                ? years.map((year: any, i: number) => {
                                    return (props.year == year)
                                        ?
                                        <>
                                        <th className="fw-normal" style={{minWidth:95}}>Apr</th>
                                        <th className="fw-normal" style={{minWidth:95}}>May</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Jun</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Jul</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Aug</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Sep</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Oct</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Nov</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Dec</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Jan</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Feb</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Mar</th>
                                        </>
                                        :
                                        null;
                                })
                                : null
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        (block2Fields.length)
                            ? block2Fields.map((item: any, key: number) => {
                                return (item.delete === false) ? <>
                                    <tr key={key}>
                                        <td className="text-center" >{(block2SrNo++) + 1}</td>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    <td key={key + '_' + fkey}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                                </>;
                                            })
                                        }
                                    </tr>
                                </>
                                    : null;
                            })
                            : null
                    }
                    {/* <>
                        {
                            (block2Fields.length && props.field4)
                                ? <>
                                    <tr>
                                        <td colSpan={4} className="text-center"><h5>Total</h5></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[0].key} field={props.field4.childrens[0]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[1].key} field={props.field4.childrens[1]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[2].key} field={props.field4.childrens[2]} /></td>
                                        <td><FieldComponent options={{ showTitle: false }} fieldName={props.field4.childrens[3].key} field={props.field4.childrens[3]} /></td>
                                    </tr>
                                </>
                                : null
                        }
                    </> */}
                </tbody>
            </table>
        </>
    );
}

// ChildInput
type ChildInputProps = {
    fields: any[];
    index?: string | number,
    showTotal?: any
};

const childInputPropTypes = {
    fields: PropTypes.array.isRequired,
    index: PropTypes.any,
    showTotal: PropTypes.any,
};

function ChildInput({ fields, index, showTotal }: ChildInputProps) {

    const [_fields, _setFields] = useStateMounted<string[]>([]);

    useEffect(() => {
        if (fields.length) {
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
            _setFields(fields);
        }
    }, [fields]);

    // const extTD = <td colSpan={2}><span className="text-center">Sub Total</span></td>;

    return (
        <>
            <tr aria-hidden={(showTotal) ? "false" : "true"}>
                {/* <td><span className="text-center">Sub Total</span></td> */}
                {
                    (_fields.length && index !== 1)
                        ? _fields.map((field: any, i: number) => {
                            return ([
                                'sl_award_items_perunit_total',
                                'sl_award_items_cost_total',
                                'sl_award_items_surveyedcost_total',
                            ].includes(field.key))
                                ? null
                                : <>
                                    <td key={index + '-' + i + '-' + field.key}>
                                        <FieldComponent
                                            options={{ showTitle: false, extraId: '_' + index + '_' + i }}
                                            fieldName={field.key}
                                            field={field} module="award_" />
                                    </td>
                                    {
                                        ([1].includes(i)) && (
                                            <td colSpan={1}>&nbsp;</td>
                                        )
                                    }
                                </>
                        })
                        : null
                }

            </tr>
        </>
    );
}

ChildInput.propTypes = childInputPropTypes;

type MultiChildProps = {
    field: any;
    pIndex: number
    jIndex: number
    index?: string | number,
    totalFields: any
};

const multiChildPropTypes = {
    field: PropTypes.array.isRequired,
    index: PropTypes.any,
    pIndex: PropTypes.number.isRequired,
    totalFields: PropTypes.any.isRequired
};

function MultiChild({ field, index, pIndex, totalFields, jIndex }: MultiChildProps) {

    let srNo = 1;
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
    const [fields, setFields] = useStateMounted<any>([]);
    const [newFields, setNewFields] = useStateMounted<any>();

    useEffect(() => {
        if (field) {
            setHasFieldDelete(false);
            setFields([]);
            setNewFields(field.addnew);
            const fieldsMap = field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setFields(fieldsMap);
        }
    }, [field]);

    useEffect(() => {
        if (fields.length) {
            const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
            setHasFieldDelete(hasDelete);
            srNo = 1;
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                init();
                multijsoninit();
            }, 1000);
        }
    }, [fields]);

    return (
        <>

            {
                (fields.length > 0) && fields.map((item: any, i: number) => {
                    return (item.delete === false)
                        ? (
                            <>
                                <tr key={`${idRef.current}-${index}`}>
                                    {
                                        item.fields.map((field: any, j: number) => {
                                            return ([
                                                'sl_award_items_perunit',
                                                'sl_award_items_cost',
                                                'sl_award_items_surveyedcost',
                                                'sl_boq_feeder_name',
                                                'sl_boq_quantity',
                                                'sl_boq_remarks',
                                            ].includes(field.key))
                                                ? <span style={{display:'none'}} key={`${idRef.current}-${index}-${field.key}`}>
													<FieldComponent
                                                        options={
                                                            { showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
                                                        }
                                                        fieldName={field.key}
                                                        field={field} module="award_" />
												</span>
                                                : <td className={'td' + (jIndex + j)} key={`${idRef.current}-${index}-${field.key}`}>
                                                    <FieldComponent
                                                        options={
                                                            { showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
                                                        }
                                                        fieldName={field.key}
                                                        field={field} module="award_" />
                                                </td>
                                        })
                                    }

                                </tr>
                            </>
                        )
                        : null;
                })
            }

            {
                totalFields && (
                    <ChildInput fields={totalFields.field.childrens} index={totalFields.index} showTotal={hasFieldDelete} />
                )
            }

        </>
    );
}

MultiChild.propTypes = multiChildPropTypes;

// Multijson
type MultijsonProps = {
    field: any;
    index: number,
    totalField: any,
    data:{
        year: any
    },
    currentPage: any,
    limit: any,
    hasPagination: any
};

const multijsonPropTypes = {
    field: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    totalField: PropTypes.any.isRequired,
    data: PropTypes.any,
    currentPage: PropTypes.any,
    limit: PropTypes.any,
    hasPagination: PropTypes.any
};

function Multijson({ field, index, totalField, data, currentPage, limit, hasPagination }: MultijsonProps) {

    //let srNo = 1;
    const [srNo, setSrNo] = useStateMounted<number>(1);
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
    const [fields, setFields] = useStateMounted<any>([]);
    const [newFields, setNewFields] = useStateMounted<any>();
    const [years, setYears] = useState<number[]>([]);
    const [colSpan, setColSpan] = useState<any>(0);

    const yearRange = (start: any, stop: any, step: number) => {
        return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))
    };
    useEffect(() => {
        if (data.year) {
            setYears([]);
            setYears(yearRange(2023, data.year, 1));
        }
    }, [data.year]);

    useEffect(() => {
        if (field) {
            setHasFieldDelete(false);
            setFields([]);
            setNewFields(field.addnew);
            const fieldsMap = field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setFields(fieldsMap);
        }
    }, [field]);

    useEffect(() => {
        if (fields.length) {
            setSrNo((limit !== false && currentPage > 0) ? (limit * currentPage) + 1 : 1);
            const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
            setHasFieldDelete(hasDelete);
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                init();
                multijsoninit();
                adjustwidth();
            }, 1000);
        }
    }, [fields]);

    const adjustwidth = () => {
        let awards: any = document.querySelectorAll('.award-td');
        for (let i = 0; i < awards.length; i++) {
            let awardid = awards[i].getAttribute('id');
            if (awardid) {
                let allcss: any = document.querySelectorAll('.' + awardid);
                if (allcss.length) {
                    if (awardid == 'td1') {
                        for (let j = 0; j < allcss.length; j++) {
                            allcss[j].width = awards[i].offsetWidth;
                        }
                    }
                    else
                        awards[i].width = allcss[0].offsetWidth;
                }
            }
            let footid = document.getElementById('tdf'+i);
            if ( footid )
                footid.style.width = awards[i].offsetWidth+'px';
        }
    };

    useEffect(() => {
        setColSpan(12);
    }, [data.year]);

    return (
        <>
            <thead className="position-sticky top-0 bg-white">
                <tr className="text-center">
                    <th rowSpan={2}>Sl No.</th>
                    <th rowSpan={2} style={{minWidth:200}}>Major Components</th>
                    <th rowSpan={2} style={{minWidth:200}}>Sub Component</th>
                    <th rowSpan={2} style={{minWidth:150}}>Item Specification</th>
                    <th rowSpan={2} style={{minWidth:150}}>Remarks</th>
                    <th rowSpan={2} style={{minWidth:95}}>Unit</th>
                    <th colSpan={4} className="border-end noPadding"><span>Quantity (nos.)</span></th>
                    {
                        (years && years.length)
                            ? years.map((year: any, i: number) => {
                                return (data.year != year)
                                    ?
                                    <>
                                        <th rowSpan={2} style={{minWidth:95}}>Target for FY <br />{year}</th>
                                    </>
                                    : null;
                            })
                            : null
                    }
                    {
                        (years && years.length)
                            ? years.map((year: any, i: number) => {
                                return (data.year == year)
                                    ?
                                    <>
                                        <th colSpan={colSpan} className="noPadding"><span>Targets for the Month</span></th>
                                        <th rowSpan={2} style={{minWidth:95}}>Target for FY <br />{year} </th>
                                        {year != '2023'
                                            ?
                                            <th rowSpan={2}>Cumulative Target till FY <br />{year}</th>
                                            : null
                                        }
                                    </>

                                    : null;

                            })
                            : null

                    }

                </tr>
                <tr className="text-center">
                    <th className="fw-normal">Sanctioned</th>
                    <th className="fw-normal" style={{minWidth:100}}>Awarded</th>
                    <th className="fw-normal" style={{minWidth:100}}>Package No</th>
                    <th className="fw-normal" style={{minWidth:100}}>Surveyed</th>
                    {
                        (years && years.length)
                            ? years.map((year: any, i: number) => {
                                return (data.year == year)
                                    ?
                                    <>
                                        <th className="fw-normal" style={{minWidth:95}}>Apr</th>
                                        <th className="fw-normal" style={{minWidth:95}}>May</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Jun</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Jul</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Aug</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Sep</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Oct</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Nov</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Dec</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Jan</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Feb</th>
                                        <th className="fw-normal" style={{minWidth:95}}>Mar</th>
                                    </>
                                    :
                                    null;
                            })
                            : null
                    }
                </tr>
            </thead>
            <tbody className="dynaWidthSMLR">
            {/*<tr className="award-margin-top"><td colSpan={7}></td></tr>*/}
            {
                fields.map((item: any, i: number) => {
                    return (item.delete === false)
                        ? (
                            <>
                                <tr className="align-top">
                                    <td><span className="text-center">{(srNo + i)}</span></td>
                                    {
                                        item.fields.map((field: any, j: number) => {
                                            let extraId = '_' + i + '_' + j;
                                            let style: any = {};
                                            let hidefield = false;
                                            let module = 'award_';
                                            //if ([5, 6, 10, 11, 12].includes(j)) {
                                            if ([5,7,8,9,10,11,12,13].includes(j)) {
                                                module = 'award_';
                                                extraId = '_' + i + '_' + i + '_' + j;
                                                style.display = 'none';
                                                hidefield = true;
                                            }
                                            return (field.type !== 'multijson' && !field.childrens)
                                                ? (
                                                    !hidefield ?
                                                        <td key={`${idRef.current}-${index}-${field.key}`}>
                                                            <FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
                                                        </td>
                                                        :
                                                        <span style={style}>
																<FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
															</span>
                                                ) : null;
                                        })
                                    }

                                    <td colSpan={22} className="multiple-award-items">
                                        <table className="table-responsive RevenueDetailsTable PqTable">
                                            <tbody>
                                            {
                                                item.fields.map((field: any, j: number) => {
                                                    const totalFieldIndex = index + '_' + i + '_' + (item.fields.findIndex((field: any, j: number) => (field.type === 'json' && field.childrens)));
                                                    const totalFields = item.fields.find((field: any, j: number) => (field.type === 'json' && field.childrens));
                                                    return field.type === 'multijson' && (
                                                        <MultiChild field={field} pIndex={i} jIndex={10} index={index + '_' + i + '_' + j} totalFields={{ field: totalFields, index: totalFieldIndex }} />
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                {/* {
							item.fields.map((field: any, j: number) => (
								(field.type === 'json' && field.childrens) && (
									<ChildInput fields={field.childrens} index={index + '_' + i + '_' + j} />
								)
							))
						} */}
                            </>
                        )
                        : null;
                })
            }
            </tbody>
        </>
    );
}
Multijson.propTypes = multijsonPropTypes;

const LossReduction = (props: LossReductionProps) => {
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any[]>([]);
    const [getdistricts, setGetDistricts] = useState<any>(0);
    const [districtsresult, setDistrictsResult] = useState<any>(0);
    const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const syear: any = useRef(null);
    const [year, setCurrentYear] = useState<any>(null);
    const [selected, setSelected] = useState("Section1");
    const formData: any = useRef(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [fieldsvalues, setFieldsValues] = useState<any>(0);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        let currentYearValue = new Date().getFullYear();
        const d = new Date();
        if (d.getMonth() > 2) {
            currentYearValue++;
        }
        setCurrentYear(currentYearValue);
    }, [roleid]);

    const ChangeValueYears = (evt?: any) => {
        setCurrentYear(evt.target.value);
        setIsLoaded(true);
        setSelected("Section2");
    }

    const ChangeValue = (evt?: any) => {
        setGetDistricts(evt.target.value);
        setIsLoaded(true);
    };

    const getDistricts = () => {
        (async () => {
            if (apiurl) {
                await Http.get(apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setDistricts(result.district);
                        setTimeout(() => {
                        }, 2000);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
            }
        })();
    };

    useEffect(() => {
        if (discomid) {
            setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
        }
    }, [apiurl, discomid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        (async () => {
            getDistricts();
        })();
    }, [apiurl]);

    useEffect(() => {
        (async () => {
            setDistrictsDefaultValue(districtsresult);
            setTimeout(() => {
                init();
            }, 500);
        })();
    }, [districtsresult]);

    useEffect(() => {
        (async () => {
            if(year)
            {
                setIsLoading(true);
                await Http.get(props.apiurl + "&district_id=" + getdistricts + "&year=" + year)
                    .then((result) => {
                        setIsLoading(false);
                        setFields(result.fields);
                        setDistrictsResult(result.district_id);
                        setFieldsValues(result.fields[0].value);
                        setTimeout(() => {
                            init();
                            initselect();
                        }, 1000);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error);
                    })
            }
        })();

    }, [props.apiurl + "&district_id=" + getdistricts + "&year=" + year]);

    const saveasdraft = (form_step_no: any) => {
        setIsLoading(true);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', getdistricts);
        myFormData.append('year', year);

        Http.post('/apiv1/save-monitoring-qt-lr', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }


    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', getdistricts);
        myFormData.append('year', year);

        Http.post('/apiv1/save-monitoring-qt-lr', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields ?
                <>
                    <div className='fYearMonth setPosition'>
                        <div className='row justify-content-end align-items-center'>
                            <div className='col-md-auto text-end'>
                                <label>Financial Year</label>
                            </div>
                            <div className='col-md-auto'>
                                <select onChange={ChangeValueYears} name="year" id="year" className="form-select" value={year} >
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                </select>
                            </div>
                            <div className='col-md-4'>
                                {
                                    districts ? <>
                                        <select className='form-select text-start w-100' onChange={ChangeValue} name="district_id" id="district_id">
                                            <option value={0}>Select District</option>
                                            {
                                                districts.map((district: any) => {
                                                    const attr = { value: district.id };
                                                    return <><option {...attr}>{district.district_name}</option></>;
                                                })
                                            }
                                        </select>
                                    </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <form noValidate onSubmit={onSubmit} ref={formData}>
                        <div className="table-responsive RevenueDetailsTable PqTable mt-3">
                            <Block2 field3={fields[0]} field4={fields[1]} year={year} district={getdistricts} />
                        </div>
                        {fieldsvalues != '' ?
                            <>
                            <div className='row mt-3 uploadUcss align-items-center'>
                                <div className='col-md-1'>
                                    <label>Upload</label>
                                </div>
                                <div className='col-md-5'>
                                    <div className="uploadSelect">
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[5].key} field={fields[5]} />
                                    </div>
                                </div>
                                <div className='col-md-1'>
                                    <label>Remarks</label>
                                </div>
                                <div className='col-md-5'>
                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[6].key} field={fields[6]} />
                                </div>
                            </div>
                            </> : null
                        }

                        <div className="formBtnSection justify-content-between is-sticky">
                            {fieldsvalues != '' && roleid == 2 || roleid == 3 || roleid == 4 ?
                                <>
                                    <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)} >
                                        Save as Draft
                                    </Link>
                                    <button className="btn btnSubmit" type="submit">Submit</button>
                                </>
                                : null
                            }
                        </div>
                    </form>
                {isLoading ? <LoadingSpinner /> : null}
                </>
                : null
        );
    }
};

const LossReduction1 = (props: LossReductionProps) => {
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState();
    const [limit, setLimit] = useState<any>(false);
    const [hasPagination, setHasPagination] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [allFields, setAllFields] = useState<any[]>([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any[]>([]);
    const [getdistricts, setGetDistricts] = useState<any>(0);
    const [districtsresult, setDistrictsResult] = useState<any>(0);
    const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const syear: any = useRef(null);
    const [year, setCurrentYear] = useState<any>(null);
    const [selected, setSelected] = useState("Section1");
    const formData: any = useRef(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [fieldsvalues, setFieldsValues] = useState<any>(0);
    const [viewMode, setViewMode] = useState<any>('N');
    const [updatedby, setUpdatedby] = useState<any>('');
    const [updatedat, setUpdatedat] = useState<any>('');

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        let currentYearValue = new Date().getFullYear();
        const d = new Date();
        if (d.getMonth() > 2) {
            currentYearValue++;
        }
        setCurrentYear(currentYearValue);
    }, [roleid]);

    const ChangeValueYears = (evt?: any) => {
        setCurrentYear(evt.target.value);
        setCurrentPage(0);
        setAllFields([]);
        setIsLoaded(true);
        setSelected("Section2");
    };

    const ChangeValue = (evt?: any) => {
        setGetDistricts(evt.target.value);
        setCurrentPage(0);
        setAllFields([]);
        setIsLoaded(true);
    };

    const getDistricts = () => {
        (async () => {
            if (apiurl) {
                await Http.get(apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setDistricts(result.district);
                        setTimeout(() => {
                        }, 2000);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
            }
        })();
    };

    useEffect(() => {
        if (discomid) {
            setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
        }
    }, [apiurl, discomid]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        (async () => {
            getDistricts();
        })();
    }, [apiurl]);

    useEffect(() => {
        (async () => {
            setDistrictsDefaultValue(districtsresult);
            setTimeout(() => {
                init();
            }, 500);
        })();
    }, [districtsresult]);

    useEffect(() => {
        (async () => {
            if(year)
            {
                setHasPagination(false);
                //setCurrentPage(0);
                setTotalPage(0);
                setAllFields([]);
                setIsLoading(true);
                setIsLoaded(false);
                setFields([]);

                let apiUrl = props.apiurl + "&district_id=" + getdistricts + "&year=" + year;
                if (getdistricts === 'itOtScada') {
                    apiUrl = "/apiv1/dprfields/form31MtLrItOt/?form_id=31&roleid=" + roleid + "&discom_id=" + discomid + "&monitoring=1&module=mo_qt_ls_itotscada" + "&district_id=" + getdistricts + "&year=" + year;
                }
                await Http.get(apiUrl)
                    .then((result) => {
                        setIsLoading(false);
                        setIsLoaded(true);
                        //setFields(result.fields);
                        if(result.pagination){
                            setLimit(result.limit);
                            setHasPagination(true);
                            setAllFields(result.fields);
                        } else {
                            setLimit(false);
                            setFields(result.fields);
                        }
                        setDistrictsResult(result.district_id);
                        setViewMode(result.viewMode);
                        setUpdatedby('');
                        setUpdatedat('');
                        if ( result.updated_by )
                            setUpdatedby(result.updated_by);
                        if ( result.updated_at )
                            setUpdatedat(result.updated_at);
                        setTimeout(() => {
                            init();
                            initselect();
                        }, 1000);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setIsLoaded(true);
                        setError(error);
                    })
            }
        })();

    }, [getdistricts, year, refresh]);

    const saveasdraft = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let apiUrl = '/apiv1/save-monitoring-qt-lr-new';
        let districtID = getdistricts;
        if (getdistricts === 'itOtScada') {
            apiUrl = '/apiv1/save-monitoring-qt-lr-itot';
            districtID = '0';
        }
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districtID);
        myFormData.append('year', year);
        Http.post(apiUrl, myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }


    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let apiUrl = '/apiv1/save-monitoring-qt-lr-new';
        let districtID = getdistricts;
        if (getdistricts === 'itOtScada') {
            apiUrl = '/apiv1/save-monitoring-qt-lr-itot';
            districtID = '0';
        }
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', districtID);
        myFormData.append('year', year);

        Http.post(apiUrl, myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }

    useEffect(() => {
        if (hasPagination && allFields.length) {
            setIsLoading(true);
            setFields([]);
            setTimeout(() => {
                setIsLoading(false);
                setTotalPage(allFields[0].childrens.length);
                const reFields = JSON.parse(JSON.stringify(allFields));
                reFields[0].childrens = allFields[0].childrens[currentPage];
                setFields(reFields);
            }, 1500);
        }
    }, [hasPagination, allFields, currentPage]);

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            //fields ?
                <>
                    <div className='fYearMonth setPosition'>
                        <div className='row justify-content-end align-items-center'>
                            <div className='col-md-auto text-end'>
                                <label>Financial Year</label>
                            </div>
                            <div className='col-md-auto'>
                                <select onChange={ChangeValueYears} name="year" id="year" className="form-select" value={year} >
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                </select>
                            </div>
                            <div className='col-md-4'>
                                {
                                    districts ? <>
                                            <select className='form-select text-start w-100' onChange={ChangeValue} name="district_id" id="district_id" value={districtsdefaultvalue}>
                                                <option value={0}>Select District</option>
                                                    {
                                                        districts.map((district: any) => {
                                                            const attr = { value: district.id };
                                                            return <><option {...attr}>{district.district_name}</option></>;
                                                        })
                                                    }
                                                <option disabled style={{fontSize: "0.25em"}}>&nbsp;</option>
                                                <option disabled style={{background: "#c9c9c9",fontSize: "0.1px"}}>&nbsp;</option>
                                                <option disabled style={{fontSize: "0.25em"}}>&nbsp;</option>
                                                <option value={'itOtScada'}>IT/OT Initiatives and SCADA</option>
                                            </select>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        (fields.length && getdistricts != '0') ?
                            <>
                                <form noValidate onSubmit={onSubmit} ref={formData}>
                                    <div className="row justify-content-end mt-2">
                                        {(hasPagination) && (
                                            <>
                                                <div className="col-md-6 text-start mt-3 text-danger">
                                                    Please save current page before proceeding to the next page
                                                </div>
                                                <div className="col-md-6 text-end mb-2">
                                                    <input type="hidden" name="formdata[sl_loss_reduction_district][currentPage]" value={currentPage}/>
                                                    {(currentPage != 0) && (
                                                        <button type="button" onClick={() => setCurrentPage((prev) => prev - 1) } className="btn btn-primary me-2">Previous</button>
                                                    )}
                                                    {
                                                        (() => {
                                                            let arr = [];
                                                            for(let i = 0; i < totalPage; i++){
                                                                arr.push(
                                                                    <button type="button" onClick={() => setCurrentPage(i) } className={`btn btn-secondary me-2`} style={(currentPage == i) ? {"backgroundColor":"#54a800","border":"1px solid #54a800"} : {}}>{i+1}</button>
                                                                );
                                                            }
                                                            return arr;
                                                        })()
                                                    }
                                                    {((totalPage - 1) > currentPage) && (
                                                        <button type="button" onClick={() => setCurrentPage((prev) => prev + 1) } className="btn btn-primary me-2">Next</button>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {/*<div className="table-responsive RevenueDetailsTable PqTable mt-3">
                                        <Block2 field3={fields[0]} field4={fields[1]} year={year} district={getdistricts} />
                                    </div>*/}
                                    <div className="award-table pe-3 position-relative">
                                        <table className="table-responsive RevenueDetailsTable PqTable lrMultipleID monitoringQtLr">
                                            {
                                                fields.map((field: any, index: number) => {
                                                    const totalField = (fields.length && fields[1].childrens) ? fields[1].childrens : null;
                                                    return field.type === 'multijson' && (
                                                        <Multijson field={field} index={index} totalField={totalField} data={{year}} {...{currentPage, limit, hasPagination}} />
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>
                                    { updatedat && updatedby ?
                                    <div className="updated-info">
                                        <p>Last Updated on: <span>{updatedat}</span> by <span>{updatedby}</span></p>
                                    </div>
                                    : null
                                    }
                                    <div className='row mt-3 uploadUcss align-items-center'>
                                        <div className='col-md-1'>
                                            <label>Upload</label>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className="uploadSelect">
                                                <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <label>Remarks</label>
                                        </div>
                                        <div className='col-md-5'>
                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                                        </div>
                                    </div>

                                    <div className="formBtnSection justify-content-between is-sticky">
                                        {viewMode === 'N' && roleid == 2 || roleid == 3 || roleid == 4 ?
                                            <>
                                                <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)} >
                                                    Save as Draft
                                                </Link>
                                                <button className="btn btnSubmit" type="submit">Submit</button>
                                            </>
                                            : null
                                        }
                                    </div>
                                </form>
                            </>
                            : null
                    }
                    {isLoading ? <LoadingSpinner /> : null}
                </>
                //: null
        );
    }
};

const LossReductions = () => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);
    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid]);

    return (
        <>
            {roleid == 0 ? null :
                /*<LossReduction apiurl={"/apiv1/formfields/?form_id=33&roleid=" + roleid + "&discom_id=" + discomid + "&monitoring=1&module=mo_qt_ls"} />*/
                <LossReduction1 apiurl={"/apiv1/dprfields/form33MtLr/?form_id=33&roleid=" + roleid + "&discom_id=" + discomid + "&monitoring=1&module=mo_qt_ls"} />
            }
        </>
    )
}
export default LossReductions;