import React, { useEffect, useState } from "react";
import LossSanctionDiscom from "./SanctionItemsType/LossSanctionDiscom";
import LossSanctionDistrict from "./SanctionItemsType/LossSanctionDistrict";
import { useLayout } from "../../../../../Core/Providers/LayoutProvider";

const ItemsSanctionDetails = ({ refresh, change, act }: any) => {
    const [_refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState("LossDiscom");
    const layout = useLayout();

    useEffect(() => {
        setSelected("LossDiscom");
    }, []);

    useEffect(() => {
        setRefresh(refresh);
    }, [refresh]);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
        layout.set({ sanctioneditemslosstype: e.target.value });
        change(e.target.value); // Called parent component function for set Save as Draft button state
    };

    return (
        <>
            <div className="position-relative">
                <div className="radioYear mb-2 py-xl-2">
                    <div className="year">
                        <div className="switch-field">
                            <input type="radio" value="LossDiscom" id="LossDiscom" checked={selected === "LossDiscom"} onChange={changeHandler} />
                            <label htmlFor="LossDiscom">IT-OT Works</label>
                            <input type="radio" value="LossDistrict" id="LossDistrict" checked={selected === "LossDistrict"} onChange={changeHandler} />
                            <label htmlFor="LossDistrict">District</label>
                        </div>
                    </div>
                </div>
                <div className="table-responsive RevenueDetailsTable PqTable">
                    {
                        selected === "LossDiscom" ? <LossSanctionDiscom act={act} refresh={_refresh} /> : null
                    }
                    {
                        selected === "LossDistrict" ? <LossSanctionDistrict act={act} refresh={_refresh} /> : null
                    }
                </div>
            </div>
        </>
    );
}

export default ItemsSanctionDetails;