import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect, inputEventChecker } from "../../../assets/js/formbuilder";
import { Http } from '../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2, FiEye } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiUpload } from 'react-icons/bi';
import swal from 'sweetalert';

// Images
import FieldComponent from "../../../FieldComponent";
import PropTypes from "prop-types";
import { useStateMounted } from "../../../Core/Hooks";
import { Storage } from "../../../Core/Services/StorageService";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-outer">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}


type SmartMeteringProps = {
    apiurl: string;
};

const Block2 = (props: any) => {

    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);
    const [years, setYears] = useState<number[]>([]);
    const [getcollspan, setCollspan] = useState<any>(0);
    const [months, setMonths] = useState("Apr")
    const [getmonths, setMonthsSmall] = useState("apr")
    const [getpagelink, setPageLink] = useState<any>(null);
    const [district, setDistricts] = useState<any>(null);
    const [getkeyindex, setKeyIndex] = useState<any>(0);


    const yearRange = (start: any, stop: any, step: number) => {
        return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))
    };

    useEffect(() => {
        if (props.field3 != 0 && district != 0) {
            if (props.field3.value != '') {
                setHasBlock2FieldsDeletes(false);
                setBlock2fields([]);
                setNewBlock2fields(props.field3.addnew);
                setTimeout(() => {
                    const fields2 = props.field3.childrens.map((items: any) => {
                        return {
                            fields: items,
                            delete: false
                        }
                    })
                    setBlock2fields(fields2);
                    initselect();
                    multijsoninit();
                }, 500);
            } else {
                setBlock2fields([]);
            }
        } else {
            setBlock2fields([]);
        }
    }, [props.field3]);

    useEffect(() => {
        if (props.year) {
            setYears(props.year);
        }
    }, [props.year]);

    useEffect(() => {
        if (props.month) {
            const monthstr = props.month.charAt(0).toUpperCase() + props.month.slice(1);
            setMonths(monthstr);
            setMonthsSmall(props.month);
        }
    }, [props.month]);

    useEffect(() => {
        if (props.district) {
            setDistricts(props.district);
        }
    }, [props.district]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
            multijsoninit();
        }, 500);
    }

    const location = useLocation();
    let navigate = useNavigate();

    const gottopage = (gottopage: any, id: any) => {
        setPageLink(gottopage);
        setKeyIndex(id);
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { year: years, month: months, district: district, indexid: getkeyindex } });
            }
        })();
    }, [getpagelink]);

    return (
        <>
            <table className="table caption-top newTableUcss">
                <thead className='text-center'>
                    <tr>
                        <th rowSpan={2}>Sl No.</th>
                        <th rowSpan={2}>Major Components</th>
                        <th rowSpan={2}>Item Summary</th>
                        <th colSpan={3} className="border-end noPadding"><span>Quantity (nos.)</span></th>
                        <th colSpan={5} className="border-end noPadding"><span>Monthly Achievement {months}</span></th>
                        <th colSpan={6} className="noPadding"><span>Cumulative Achievement  Up to {months} {years}</span></th>
                    </tr>
                    <tr>
                        <th className="fw-normal">Sanctioned</th>
                        <th className="fw-normal">Awarded</th>
                        <th className="fw-normal">Surveyed</th>
                        <th className="fw-normal">Delivered</th>
                        <th className="fw-normal">Installed</th>
                        <th className="fw-normal">Charged</th>
                        <th className="fw-normal">Communicating <br /> Meters</th>
                        <th className="fw-normal">Prepaid Mode <br /> </th>
                        <th className="fw-normal">Delivered</th>
                        <th className="fw-normal">Installed</th>
                        <th className="fw-normal">Charged</th>
                        <th className="fw-normal">Communicating <br /> Meters</th>
                        <th className="fw-normal">Prepaid Mode <br /> </th>
                        <th className="fw-normal">Details</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (block2Fields.length)
                            ? block2Fields.map((item: any, key: number) => {

                                return (item.delete === false) ? <>
                                    <tr key={key} className="align-top">
                                        <td className="text-center" >{(block2SrNo++) + 1}</td>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    <td key={key + '_' + fkey}>
                                                        <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} />
                                                    </td>
                                                </>;
                                            })
                                        }
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    {field.value == '1' && field.key == 'sl_district_major_component'
                                                        ?
                                                        <td className="text-center">
                                                            <Link className='text-success' to="" onClick={() => gottopage('/sm-consumer-details', key)}><FiEye className="p-0 mb-0 h5" /></Link>
                                                        </td>
                                                        : null
                                                    }
                                                </>;
                                            })
                                        }
                                    </tr>

                                </>
                                    : null;
                            })
                            : null
                    }
                </tbody>
            </table>
        </>
    );
}

// ChildInput
type ChildInputProps = {
    fields: any[];
    index?: string | number,
    showTotal?: any
};

const childInputPropTypes = {
    fields: PropTypes.array.isRequired,
    index: PropTypes.any,
    showTotal: PropTypes.any,
};

function ChildInput({ fields, index, showTotal }: ChildInputProps) {

    const [_fields, _setFields] = useStateMounted<string[]>([]);

    useEffect(() => {
        if (fields.length) {
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                initselect();
                multijsoninit();
            }, 1000);
            _setFields(fields);
        }
    }, [fields]);

    // const extTD = <td colSpan={2}><span className="text-center">Sub Total</span></td>;

    return (
        <>
            <tr aria-hidden={(showTotal) ? "false" : "true"} className="align-top">
                {/* <td><span className="text-center">Sub Total</span></td> */}
                {
                    (_fields.length && index !== 1)
                        ? _fields.map((field: any, i: number) => {
                            return ([
                                'sl_award_items_perunit_total',
                                'sl_award_items_cost_total',
                                'sl_award_items_surveyedcost_total',
                            ].includes(field.key))
                                ? null
                                : <>
                                    <td key={index + '-' + i + '-' + field.key}>
                                        <FieldComponent
                                            options={{ showTitle: false, extraId: '_' + index + '_' + i }}
                                            fieldName={field.key}
                                            field={field} module="award_" />
                                    </td>
                                    {
                                        ([0].includes(i)) && (
                                            <td colSpan={1}>&nbsp;</td>
                                        )
                                    }
                                </>
                        })
                        : null
                }

            </tr>
        </>
    );
}

ChildInput.propTypes = childInputPropTypes;

type MultiChildProps = {
    field: any;
    pIndex: number
    jIndex: number
    index?: string | number,
    totalFields: any
};

const multiChildPropTypes = {
    field: PropTypes.array.isRequired,
    index: PropTypes.any,
    pIndex: PropTypes.number.isRequired,
    totalFields: PropTypes.any.isRequired
};

function MultiChild({ field, index, pIndex, totalFields, jIndex }: MultiChildProps) {

    let srNo = 1;
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
    const [fields, setFields] = useStateMounted<any>([]);
    const [newFields, setNewFields] = useStateMounted<any>();

    useEffect(() => {
        if (field) {
            setHasFieldDelete(false);
            setFields([]);
            setNewFields(field.addnew);
            const fieldsMap = field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setFields(fieldsMap);
        }
    }, [field]);

    useEffect(() => {
        if (fields.length) {
            const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
            setHasFieldDelete(hasDelete);
            srNo = 1;
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                init();
                multijsoninit();
            }, 1000);
        }
    }, [fields]);

    const addNewField = () => {
        setFields((prev: any[]) => {
            return [...prev, { fields: newFields, delete: false }];
        });
        setTimeout(() => {
            init();
            multijsoninit();
        }, 1000);
    }

    const removeField = (index: number) => {
        setFields((prev: any[]) => {
            const newFields = prev.map((item, i) => {
                if (i === index) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });
        setTimeout(() => {
            init();
            multijsoninit();
        }, 1000);
    }
    const extTD = <td colSpan={6} ></td>;

    return (
        <>

            {
                (fields.length > 0) && fields.map((item: any, i: number) => {
                    return (item.delete === false)
                        ? (
                            <>
                                <tr key={`${idRef.current}-${index}`} className="align-top">
                                    {
                                        item.fields.map((field: any, j: number) => {
                                            return ([
                                                'sl_award_rate_per_unit',
                                                'sl_award_service_charge',
                                                'sl_award_om_period',
                                                'sl_award_lumpsum_payment',
                                                'sl_award_items_perunit',
                                                'sl_award_items_cost',
                                                'sl_award_items_surveyedcost',
                                            ].includes(field.key))
                                                ? <span style={{ display: 'none' }} key={`${idRef.current}-${index}-${field.key}`}>
                                                    <FieldComponent
                                                        options={
                                                            { showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
                                                        }
                                                        fieldName={field.key}
                                                        field={field} module="award_" />
                                                </span>
                                                : <td className={'td' + (jIndex + j)} key={`${idRef.current}-${index}-${field.key}`}>
                                                    <FieldComponent
                                                        options={
                                                            { showTitle: false, extraId: '_' + index + '_' + i + '_' + j, parentIndex: [pIndex] }
                                                        }
                                                        fieldName={field.key}
                                                        field={field} module="award_" />
                                                </td>
                                        })
                                    }

                                </tr>
                            </>
                        )
                        : null;
                })
            }

            {
                totalFields && (
                    <ChildInput fields={totalFields.field.childrens} index={totalFields.index} showTotal={hasFieldDelete} />
                )
            }

        </>
    );
}

MultiChild.propTypes = multiChildPropTypes;

// Multijson
type MultijsonProps = {
    field: any;
    index: number,
    totalField: any,
    data: {
        month: any,
        year: any,
        getdistricts: any
    },
    currentPage: any,
    limit: any,
    hasPagination: any
};

const multijsonPropTypes = {
    field: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    totalField: PropTypes.any.isRequired,
    data: PropTypes.any,
    currentPage: PropTypes.any,
    limit: PropTypes.any,
    hasPagination: PropTypes.any
};

function Multijson({ field, index, totalField, data, currentPage, limit, hasPagination }: MultijsonProps) {

    const [srNo, setSrNo] = useStateMounted<number>(1);
    const idRef = useRef(Math.random().toString(36).substring(2, 15));
    const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
    const [fields, setFields] = useStateMounted<any>([]);
    const [newFields, setNewFields] = useStateMounted<any>();
    const [months, setMonths] = useState("Apr");
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getkeyindex, setKeyIndex] = useState<any>(0);
    let navigate = useNavigate();

    useEffect(() => {
        if (field) {
            setHasFieldDelete(false);
            setFields([]);
            setNewFields(field.addnew);
            const fieldsMap = field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setFields(fieldsMap);
        }
    }, [field]);

    useEffect(() => {
        if (fields.length) {
            setSrNo((limit !== false && currentPage > 0) ? (limit * currentPage) + 1 : 1);
            const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
            setHasFieldDelete(hasDelete);
            inputEventChecker.removeAllEvent();
            setTimeout(() => {
                init();
                multijsoninit();
                adjustwidth();
            }, 1000);
        }
    }, [fields]);

    const adjustwidth = () => {
        let awards: any = document.querySelectorAll('.award-td');
        for (let i = 0; i < awards.length; i++) {
            let awardid = awards[i].getAttribute('id');
            if (awardid) {
                let allcss: any = document.querySelectorAll('.' + awardid);
                if (allcss.length) {
                    if (awardid == 'td1') {
                        for (let j = 0; j < allcss.length; j++) {
                            allcss[j].width = awards[i].offsetWidth;
                        }
                    }
                    else
                        awards[i].width = allcss[0].offsetWidth;
                }
            }
            let footid = document.getElementById('tdf' + i);
            if (footid)
                footid.style.width = awards[i].offsetWidth + 'px';
        }
    };

    useEffect(() => {
        if (data.month) {
            const monthstr = data.month.charAt(0).toUpperCase() + data.month.slice(1);
            setMonths(monthstr);
        }
    }, [data.month]);

    const gottopage = (gottopage: any, id: any) => {
        setPageLink(gottopage);
        setKeyIndex(id);
    };

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { year: data.year, month: months, district: data.getdistricts, smCurrentPage: currentPage, indexid: getkeyindex } });
            }
        })();
    }, [getpagelink]);

    return (
        <>
            <thead className="position-sticky top-0 bg-white threWidthSetup">
                <tr className="text-center">
                    <th rowSpan={2}>Sl No.</th>
                    <th rowSpan={2}>Major Components</th>
                    <th rowSpan={2}>Item Summary</th>
                    <th colSpan={4} className="border-end noPadding"><span>Quantity (nos.)</span></th>
                    <th colSpan={5} className="border-end noPadding"><span>Monthly Achievement {months}</span></th>
                    <th colSpan={6} className="noPadding"><span>Cumulative Achievement  Up to {months} {(['Jan', 'Feb', 'Mar'].includes(months)) ? data.year : (data.year - 1)}</span></th>
                </tr>
                <tr className="text-center">
                    <th className="fw-normal">Sanctioned</th>
                    <th className="fw-normal" style={{ minWidth: 95, width: 95, maxWidth: 95 }}>Awarded</th>
                    <th className="fw-normal" style={{ minWidth: "180px", width: 180, maxWidth: "180px" }}>Package No</th>
                    <th className="fw-normal" style={{ minWidth: 95, width: 95, maxWidth: 95 }}>Surveyed</th>
                    <th className="fw-normal" style={{ minWidth: 95, width: 95, maxWidth: 95 }}>Delivered</th>
                    <th className="fw-normal" style={{ minWidth: 95, width: 95, maxWidth: 95 }}>Installed</th>
                    <th className="fw-normal" style={{ minWidth: 95, width: 95, maxWidth: 95 }}>Charged</th>
                    <th className="fw-normal" style={{ minWidth: 130, width: 130, maxWidth: 130 }}>Communicating <br /> Meters</th>
                    <th className="fw-normal" style={{ minWidth: 100 }}>Prepaid Mode <br /> </th>
                    <th className="fw-normal" style={{ minWidth: 100 }}>Delivered</th>
                    <th className="fw-normal" style={{ minWidth: 100 }}>Installed</th>
                    <th className="fw-normal" style={{ minWidth: 100 }}>Charged</th>
                    <th className="fw-normal" style={{ minWidth: 130, width: 130, maxWidth: 130 }}>Communicating <br /> Meters</th>
                    <th className="fw-normal">Prepaid Mode <br /> </th>
                    <th className="fw-normal">Details</th>
                </tr>
            </thead>
            <tbody className="dynaWidthPDSM reWidthSetup">
                {/*<tr className="award-margin-top"><td colSpan={7}></td></tr>*/}
                {
                    fields.map((item: any, i: number) => {
                        return (item.delete === false)
                            ? (
                                <>
                                    <tr className="align-top">
                                        <td><span className="text-center">{(srNo + i)}</span></td>
                                        {
                                            item.fields.map((field: any, j: number) => {
                                                let extraId = '_' + i + '_' + j;
                                                let style: any = {};
                                                let hidefield = false;
                                                let module = 'award_';
                                                //if ([5, 6, 10, 11, 12].includes(j)) {
                                                if ([2, 3, 4, 6, 7, 8, 9, 10, 11].includes(j)) {
                                                    module = 'award_';
                                                    extraId = '_' + i + '_' + i + '_' + j;
                                                    style.display = 'none';
                                                    hidefield = true;
                                                }
                                                return (field.type !== 'multijson' && !field.childrens)
                                                    ? (
                                                        !hidefield ?
                                                            <td key={`${idRef.current}-${index}-${field.key}`}>
                                                                <FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
                                                            </td>
                                                            :
                                                            <span style={style}>
                                                                <FieldComponent options={{ showTitle: false, extraId: extraId }} fieldName={field.key} field={field} module={module} />
                                                            </span>
                                                    ) : null;
                                            })
                                        }

                                        <td colSpan={14} className="multiple-award-items">
                                            <table className="table-responsive RevenueDetailsTable PqTable">
                                                <tbody>
                                                    {
                                                        item.fields.map((field: any, j: number) => {
                                                            const totalFieldIndex = index + '_' + i + '_' + (item.fields.findIndex((field: any, j: number) => (field.type === 'json' && field.childrens)));
                                                            const totalFields = item.fields.find((field: any, j: number) => (field.type === 'json' && field.childrens));
                                                            return field.type === 'multijson' && (
                                                                <MultiChild field={field} pIndex={i} jIndex={10} index={index + '_' + i + '_' + j} totalFields={{ field: totalFields, index: totalFieldIndex }} />
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                        {
                                            item.fields.map((field: any, fkey: number) => {
                                                return <>
                                                    {field.key == 'sl_district_major_component' && field.value == '1'
                                                        ?
                                                        <td className="text-center">
                                                            <Link className='text-success' to="" onClick={() => gottopage('/sm-consumer-details', ((srNo + i) - 1))}><FiEye className="p-0 mb-0 h5" /></Link>
                                                        </td>
                                                        : null
                                                    }
                                                </>;
                                            })
                                        }
                                    </tr>
                                    {/* {
							item.fields.map((field: any, j: number) => (
								(field.type === 'json' && field.childrens) && (
									<ChildInput fields={field.childrens} index={index + '_' + i + '_' + j} />
								)
							))
						} */}
                                </>
                            )
                            : null;
                    })
                }
                {
                    (totalField) && (
                        <tr style={{ display: "none" }}>
                            <td colSpan={8}><span className="text-center">Total</span></td>
                            <td colSpan={1}><FieldComponent options={{ showTitle: false }} fieldName={totalField[0].key} field={totalField[0]} module="award_" /></td>
                            <td colSpan={5}>
                                <table className="table-responsive RevenueDetailsTable PqTable award-footer-table">
                                    <tbody>
                                        <tr>
                                            <td id="tdf10" style={{ minWidth: 98, width: 95 }}></td>
                                            <td id="tdf11" style={{ minWidth: 98, width: 95 }}></td>
                                            <td id="tdf12" style={{ minWidth: 98, width: 98 }}><FieldComponent options={{ showTitle: false }} fieldName={totalField[2].key} field={totalField[2]} module="award_" /></td>
                                            <td id="tdf13"></td>
                                            <td id="tdf14"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </>
    );
}
Multijson.propTypes = multijsonPropTypes;

const SmartMetering = (props?: any) => {
    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState()
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any[]>([]);
    const [getdistricts, setGetDistricts] = useState<any>(0);
    const [districtsresult, setDistrictsResult] = useState<any>(0);
    const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const syear: any = useRef(null);
    const [selected, setSelected] = useState("Section1");
    const formData: any = useRef(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [fieldsvalues, setFieldsValues] = useState<any>(0);
    const [year, setCurrentYear] = useState<any>(null);
    const [month, setCurrentMonth] = useState<any>(null);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        let currentYearValue = new Date().getFullYear();
        const month = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
        const d = new Date();
        if (d.getMonth() > 2) {
            currentYearValue++;
        }
        setCurrentYear(currentYearValue);
        let currentMonthValue = month[d.getMonth()];
        setCurrentMonth(currentMonthValue);
    }, [roleid]);

    const ChangeValueYears = (evt?: any) => {
        setCurrentYear(evt.target.value);
        setIsLoaded(true);
        setSelected("Section1");
    };

    const ChangeValueDistrict = (evt?: any) => {
        setGetDistricts(evt.target.value);
        setIsLoaded(true);
    };

    const ChangeValueMonth = (evt?: any) => {
        // if (!["jan", "feb", "mar"].includes(evt.target.value)) {
        //     setCurrentYear((new Date().getFullYear() + 1));
        // }
        setCurrentMonth(evt.target.value);
        setIsLoaded(true);
    };

    const getDistricts = () => {
        (async () => {
            if (apiurl) {
                await Http.get(apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setDistricts(result.district);
                        setTimeout(() => {
                        }, 2000);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
            }
        })();
    };

    useEffect(() => {
        if (discomid) {
            setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
        }
    }, [apiurl, discomid, year, month]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        (async () => {
            getDistricts();
        })();
    }, [apiurl]);

    useEffect(() => {
        (async () => {
            setDistrictsDefaultValue(districtsresult);
            setTimeout(() => {
                init();
            }, 500);
        })();
    }, [districtsresult]);

    useEffect(() => {
        (async () => {
            if (year && month) {
                setIsLoading(true);
                await Http.get(props.apiurl + "&district_id=" + getdistricts + "&year=" + year + "&month=" + month)
                    .then((result) => {
                        setIsLoading(false);
                        setFields(result.fields);
                        setDistrictsResult(result.district_id);
                        setFieldsValues(result.fields[0].value);
                        setTimeout(() => {
                            init();
                            initselect();
                        }, 1000);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error);
                    })
            }
        })();
    }, [props.apiurl, getdistricts, year, month]);

    const saveasdraft = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', getdistricts);
        myFormData.append('year', year);
        myFormData.append('month', month);

        Http.post('/apiv1/save-monitoring-pd-sm', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', getdistricts);
        myFormData.append('year', year);
        myFormData.append('month', month);

        Http.post('/apiv1/save-monitoring-pd-sm', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }


    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            fields.length ?
                <>
                    <div className='fYearMonth setPosition'>
                        <div className='row justify-content-end align-items-center'>
                            <div className='col-md-auto text-end'>
                                <label>Financial Year</label>
                            </div>
                            <div className='col-md-auto'>
                                <select onChange={ChangeValueYears} name="year" id="year" className="form-select" value={year} >
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                </select>
                            </div>
                            <div className='col-md-2'>
                                <select onChange={ChangeValueMonth} name="month" id="month" className="form-select" defaultValue={month} >
                                    <option value="apr">Apr</option>
                                    <option value="may">May</option>
                                    <option value="jun">Jun</option>
                                    <option value="jul">Jul</option>
                                    <option value="aug">Aug</option>
                                    <option value="sep">Sep</option>
                                    <option value="oct">Oct</option>
                                    <option value="nov">Nov</option>
                                    <option value="dec">Dec</option>
                                    <option value="jan">Jan</option>
                                    <option value="feb">Feb</option>
                                    <option value="mar">Mar</option>
                                </select>
                            </div>
                            <div className='col-md-4'>
                                {
                                    districts ? <>
                                        <select className='form-select text-start w-100' onChange={ChangeValueDistrict} name="district_id" id="district_id">
                                            <option value={0}>Select District</option>
                                            {
                                                districts.map((district: any) => {
                                                    const attr = { value: district.id };
                                                    return <><option {...attr}>{district.district_name}</option></>;
                                                })
                                            }
                                        </select>
                                    </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <form noValidate onSubmit={onSubmit} ref={formData}>
                        <div className="table-responsive RevenueDetailsTable PqTable mt-3">
                            <Block2 field3={fields[0]} field4={fields[1]} year={year} month={month} district={getdistricts} />
                        </div>
                        {fieldsvalues != '' && getdistricts != 0 ?
                            <>
                                <div className='row mt-3 uploadUcss align-items-center'>
                                    <div className='col-md-1'>
                                        <label>Upload</label>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className="uploadSelect">
                                            <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                                        </div>
                                    </div>
                                    <div className='col-md-1 text-end'>
                                        <label>Remarks</label>
                                    </div>
                                    <div className='col-md-5'>
                                        <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                                    </div>
                                </div>
                            </> : null
                        }

                        <div className="formBtnSection justify-content-between is-sticky">
                            {fieldsvalues != '' && roleid == 2 ?
                                <>
                                    <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)} >
                                        Save as Draft
                                    </Link>
                                    <button className="btn btnSubmit" type="submit">Submit</button>
                                </>
                                : null
                            }
                        </div>
                    </form>
                    {isLoading ? <LoadingSpinner /> : null}
                </>
                : null
        );
    }
};

const SmartMetering1 = (props?: any) => {
    const location: any = useLocation();
    let smCurrentPage: number = location?.state?.smCurrentPage ?? 0;
    let smYear: any = location?.state?.year ?? null;
    let smMonth: any = location?.state?.month ?? null;
    let smDistrict: any = location?.state?.district ?? 0;

    const [refresh, setRefresh] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [date, setDate] = useState();
    const [limit, setLimit] = useState<any>(false);
    const [hasPagination, setHasPagination] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(smCurrentPage);
    const [allFields, setAllFields] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [roleid, setRoleid] = useState<any>(0);
    const [apiurl, setApiurl] = useState<any>(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [districts, setDistricts] = useState<any[]>([]);
    const [getdistricts, setGetDistricts] = useState<any>(smDistrict);
    const [districtsresult, setDistrictsResult] = useState<any>(0);
    const [districtsdefaultvalue, setDistrictsDefaultValue] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const syear: any = useRef(null);
    const [selected, setSelected] = useState("Section1");
    const formData: any = useRef(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [fieldsvalues, setFieldsValues] = useState<any>(0);
    const [year, setCurrentYear] = useState<any>(smYear);
    const [month, setCurrentMonth] = useState<any>(smMonth);
    const [viewMode, setViewMode] = useState<any>('N');
    const [updatedby, setUpdatedby] = useState<any>('');
    const [updatedat, setUpdatedat] = useState<any>('');

    useEffect(() => {
        let currentYearValue = new Date().getFullYear();
        const month = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
        const d = new Date();
        if (d.getMonth() > 2) {
            currentYearValue++;
        }
        if(!smYear) setCurrentYear(currentYearValue);
        let currentMonthValue = month[d.getMonth()];
        (smMonth) ? setCurrentMonth(smMonth.toLowerCase()) : setCurrentMonth(currentMonthValue);
    }, [roleid]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
    },[]);

    const ChangeValueYears = (evt?: any) => {
        setCurrentYear(evt.target.value);
        setCurrentPage(0);
        setAllFields([]);
        setIsLoaded(true);
        setSelected("Section1");
    };

    const ChangeValueDistrict = (evt?: any) => {
        setGetDistricts(evt.target.value);
        setCurrentPage(0);
        setAllFields([]);
        setIsLoaded(true);
    };

    const ChangeValueMonth = (evt?: any) => {
        // if (!["jan", "feb", "mar"].includes(evt.target.value)) {
        //     setCurrentYear((new Date().getFullYear() + 1));
        // }
        setCurrentMonth(evt.target.value);
        setCurrentPage(0);
        setAllFields([]);
        setIsLoaded(true);
    };

    const getDistricts = () => {
        (async () => {
            if (apiurl) {
                await Http.get(apiurl)
                    .then((result) => {
                        setIsLoaded(true);
                        setDistricts(result.district);
                        setTimeout(() => {
                        }, 2000);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
            }
        })();
    };

    useEffect(() => {
        if (discomid) {
            setApiurl("/apiv1/getdiscomdistricts/?discom_id=" + discomid);
        }
    }, [apiurl, discomid, year, month]);

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        (async () => {
            getDistricts();
        })();
    }, [apiurl]);

    useEffect(() => {
        (async () => {
            setDistrictsDefaultValue(districtsresult);
            setTimeout(() => {
                init();
            }, 500);
        })();
    }, [districtsresult]);

    const getFields = () => {
        (async () => {
            if (getdistricts && year && month) {
                setHasPagination(false);
                //setCurrentPage(0);
                setTotalPage(0);
                setFields([]);
                setAllFields([]);
                setIsLoading(true);
                setIsLoaded(false);
                await Http.get(props.apiurl + "&district_id=" + getdistricts + "&year=" + year + "&month=" + month)
                    .then((result) => {
                        setIsLoading(false);
                        setIsLoaded(true);
                        if (result.resultflag) {
                            if (result.pagination) {
                                setLimit(result.limit);
                                setHasPagination(true);
                                setAllFields(result.fields);
                            } else {
                                setLimit(false);
                                setFields(result.fields);
                            }
                            setDistrictsResult(result.district_id);
                            //setFieldsValues(result.fields[0].value);
                            setViewMode(result.viewMode);
                            setUpdatedby('');
                            setUpdatedat('');
                            if (result.updated_by)
                                setUpdatedby(result.updated_by);
                            if (result.updated_at)
                                setUpdatedat(result.updated_at);
                            setTimeout(() => {
                                init();
                                initselect();
                            }, 1000);
                        } else {
                            setError('System error occurred!! please try again.');
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setIsLoaded(true);
                        setError(error);
                    })
            }
        })();
    };

    useEffect(() => {
        getFields();
    }, [props.apiurl, getdistricts, year, month]);

    useEffect(() => {
        if (refresh) {
            getFields();
        }
    }, [refresh]);

    const saveasdraft = (form_step_no: any) => {
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', getdistricts);
        myFormData.append('year', year);
        myFormData.append('month', month);

        Http.post('/apiv1/save-monitoring-pd-sm-new', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('district_id', getdistricts);
        myFormData.append('year', year);
        myFormData.append('month', month);

        Http.post('/apiv1/save-monitoring-pd-sm-new', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    setIsLoading(false);
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    setIsLoading(false);
                    setRefresh(true);
                }
            });
    };

    useEffect(() => {
        if (hasPagination && allFields.length) {
            setIsLoading(true);
            setFields([]);
            setTimeout(() => {
                setIsLoading(false);
                setTotalPage(allFields[0].childrens.length);
                const reFields = JSON.parse(JSON.stringify(allFields));
                reFields[0].childrens = allFields[0].childrens[currentPage];
                setFields(reFields);
            }, 1500);
        }
    }, [hasPagination, allFields, currentPage]);


    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            //fields.length ?
            <>
                <div className='fYearMonth setPosition pdYearMonth'>
                    <div className='row justify-content-end align-items-center gx-2'>
                        <div style={{ "paddingRight": "0px" }} className='col-md-auto text-end'>
                            <label>FY</label>
                        </div>
                        <div className='col-md-auto'>
                            <select onChange={ChangeValueYears} name="year" id="year" className="form-select" value={year} >
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select onChange={ChangeValueMonth} name="month" id="month" className="form-select" defaultValue={month} >
                                <option value="apr">Apr</option>
                                <option value="may">May</option>
                                <option value="jun">Jun</option>
                                <option value="jul">Jul</option>
                                <option value="aug">Aug</option>
                                <option value="sep">Sep</option>
                                <option value="oct">Oct</option>
                                <option value="nov">Nov</option>
                                <option value="dec">Dec</option>
                                <option value="jan">Jan</option>
                                <option value="feb">Feb</option>
                                <option value="mar">Mar</option>
                            </select>
                        </div>
                        <div className='col-md-4'>
                            {
                                districts ? <>
                                    <select className='form-select text-start w-100' onChange={ChangeValueDistrict} name="district_id" id="district_id" value={districtsdefaultvalue}>
                                        <option value={0}>Select District</option>
                                        {
                                            districts.map((district: any) => {
                                                const attr = { value: district.id };
                                                return <><option {...attr}>{district.district_name}</option></>;
                                            })
                                        }
                                    </select>
                                </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                {
                    (fields.length && districtsresult != '0') ?
                        <>
                            <form noValidate onSubmit={onSubmit} ref={formData}>
                                <div className="row justify-content-end mt-2">
                                    {(hasPagination) && (
                                        <>
                                            <div className="col-md-6 text-start mt-3 text-danger">
                                                Please save current page before proceeding to the next page
                                            </div>
                                            <div className="col-md-6 text-end mb-2">
                                                <input type="hidden" name="formdata[sl_smart_metering_district][currentPage]" value={currentPage} />
                                                {(currentPage != 0) && (
                                                    <button type="button" onClick={() => setCurrentPage((prev) => prev - 1)} className="btn btn-primary me-2">Previous</button>
                                                )}
                                                {
                                                    (() => {
                                                        let arr = [];
                                                        for (let i = 0; i < totalPage; i++) {
                                                            arr.push(
                                                                <button type="button" onClick={() => setCurrentPage(i)} className={`btn btn-secondary me-2`} style={(currentPage == i) ? { "backgroundColor": "#54a800", "border": "1px solid #54a800" } : {}}>{i + 1}</button>
                                                            );
                                                        }
                                                        return arr;
                                                    })()
                                                }
                                                {((totalPage - 1) > currentPage) && (
                                                    <button type="button" onClick={() => setCurrentPage((prev) => prev + 1)} className="btn btn-primary me-2">Next</button>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                {/*<div className="table-responsive RevenueDetailsTable PqTable mt-3">
                            <Block2 field3={fields[0]} field4={fields[1]} year={year} month={month} district={getdistricts} />
                        </div>*/}
                                <div className="award-table pe-3 position-relative">
                                    <table className="table-responsive RevenueDetailsTable PqTable lrMultipleID monitoringPdSm">
                                        {
                                            fields.map((field: any, index: number) => {
                                                const totalField = (fields.length && fields[1].childrens) ? fields[1].childrens : null;
                                                return field.type === 'multijson' && (
                                                    <Multijson field={field} index={index} totalField={totalField} data={{ month, year, getdistricts }} {...{ currentPage, limit, hasPagination }} />
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                                {updatedat && updatedby ?
                                    <div className="updated-info">
                                        <p>Last Updated on: <span>{updatedat}</span> by <span>{updatedby}</span></p>
                                    </div>
                                    : null
                                }
                                {viewMode === 'N' && getdistricts != 0 ?
                                    <>
                                        <div className='row mt-3 uploadUcss align-items-center'>
                                            <div className='col-md-1'>
                                                <label>Upload</label>
                                            </div>
                                            <div className='col-md-5'>
                                                <div className="uploadSelect">
                                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[2].key} field={fields[2]} />
                                                </div>
                                            </div>
                                            <div className='col-md-1 text-end'>
                                                <label>Remarks</label>
                                            </div>
                                            <div className='col-md-5'>
                                                <FieldComponent options={{ showTitle: false }} fieldName={fields[3].key} field={fields[3]} />
                                            </div>
                                        </div>
                                    </> : null
                                }

                                <div className="formBtnSection justify-content-between is-sticky">
                                    {viewMode === 'N' && roleid == 2 ?
                                        <>
                                            <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)} >
                                                Save as Draft
                                            </Link>
                                            <button className="btn btnSubmit" type="submit">Submit</button>
                                        </>
                                        : null
                                    }
                                </div>
                            </form>
                        </>
                        : null
                }
                {isLoading ? <LoadingSpinner /> : null}
            </>
            //: null
        );
    }
};

const SmartMeterings = (props?: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const [discomid, setDiscomid] = useState<any>(0);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
    }, [roleid]);

    return (
        <>
            {roleid == 0 ? null :
                /*<SmartMetering apiurl={"/apiv1/formfields/?form_id=32&roleid=" + roleid + "&discom_id=" + discomid + "&monitoring=1&module=mo_pd_sm"}  refresh={refresh}/>*/
                <SmartMetering1 apiurl={"/apiv1/dprfields/form32/?form_id=32&roleid=" + roleid + "&discom_id=" + discomid + "&monitoring=1&module=mo_pd_sm"} />
            }
        </>
    )
}
export default SmartMeterings;