import React, { useEffect, useState } from "react"
import DashboardFooter from '../Components/Footer/DashboardFooter'
import DashboardHeader from '../Components/Header/DashboardHeader'
import { BiArrowBack } from 'react-icons/bi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProgressDetailsMainChart from '../Components/DashboardComponents/Chart/ProgressDetailsChart/ProgressDetailsMain'
import { BsBarChart, BsFileEarmarkPdf, BsTable } from "react-icons/bs"
import './dashboard.scss';
import { useLayout } from "../Core/Providers"
import { RiFileExcel2Line } from "react-icons/ri"
import { Http } from "../Core/Services/HttpService"
import jsPDF from "jspdf";
import { utils, writeFile } from "xlsx";
import _ from "lodash"

const SmDistrictwiseData = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState<any>();
    const [tableData, setTableData] = useState<any>([]);
    const [selected, setSelected] = useState("TableSection");
    const [chartLink, setChartLink] = useState<string>('');
    const [headingTitle, setHeadingTitle] = useState<string>('State wise Progress');
    const [noDataMessage, setNoDataMessage] = useState<string>('Loading...');

    const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const currYear = new Date().getFullYear();
    let currMonth: any = new Date().getMonth() + 1;
    currMonth = monthNames[currMonth - 1];
    const layout = useLayout();
    let srNo = 1;
    let reportType = 'Districts';
    let theUrl = '/apiv1/getSummaryReportData';
    const fetchProgressData = async (fParams: any = {}) => {
        setTableData([]);
        setNoDataMessage('Loading...');
        const fnlParams = {
            year : currYear,
            month : currMonth,
            reportType: reportType,
            consolidated: 'D',
            subtotal: 'N',
            page: 0,
            pageSize: 1000,
            module: 'sm',
            monitoringPar: '',
            stateid: params?.stateid,
            districtid: params?.districtid,
            discomid: params?.discomid,
            subcomp: '',
            majorcomponentid: ''
        };
        await Http.get(theUrl, { params: fnlParams })
            .then((res) => {
                if (res._resultflag == "1") {
                    setTableData(res.rows);
                } else {
                    setNoDataMessage('No Data Found!');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found!');
            });
    };
    const exportToPDF = () => {
        let table = document.getElementById("top-panel-data")?.cloneNode(true) as any;
        table = document.createDocumentFragment().appendChild(table);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const frmData = new FormData();
        frmData.append('content', encodeURIComponent(table.outerHTML));
        frmData.append('filename', 'toppaneldata');
        frmData.append('download', 'no');
        Http.post('/apiv1/contentExportPdf', frmData, config)
        .then((res) => {
            if (res._resultflag == "1") {
                window.open(res.file, "_blank");
            } else {
                setNoDataMessage('No Data Found!');
            }
        }).catch((e) => {
            setNoDataMessage('No Data Found!');
        });
    };
    const exportToExcel = () => {
        const table = document.getElementById('top-panel-data');
        const wb = utils.table_to_book(table);
        writeFile(wb, 'smartmeteringdistrict.xlsx');
    };
    useEffect(() => {
        if (params) {
            fetchProgressData(params);
        }
    }, [params]);
    useEffect(() => {
        setParams({
            id: new URLSearchParams(location.search).get('id') || null,
            stateid: new URLSearchParams(location.search).get('stateid') || null,
            discomid: new URLSearchParams(location.search).get('discomid') || null,
            districtid: new URLSearchParams(location.search).get('id') || null
        });
    }, [location]);
    
    return (
        <>
            <div className="mainWrap dashboard">
                <DashboardHeader />
                <div className="container-fluid">
                    <div className="dashboardInner detailedDashboard">
                        <div className="row gx-xxl-5">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="heading mb-2">
                                    <h2 className="mb-0"><Link to={'/dashboard'}><em className="arrow"><BiArrowBack /></em></Link>Dashboard - RDSS</h2>
                                </div>
                                <div className="discomSection">
                                    <div className="discomRight">
                                        <div className="dashChart">
                                            <div className="DiscomSummary">
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <h4 className="mb-2 me-3">
                                                        Smart Metering Report
                                                    </h4>
                                                </div>
                                                <div aria-hidden={selected !== "TableSection" ? true : false}>
                                                    <div className="d-flex justify-content-end mt-2">
                                                        <a title="Export to PDF" onClick={() => exportToPDF()}><div className="pdfBox"><BsFileEarmarkPdf /></div></a>
                                                        <a title="Export to XLS" onClick={() => exportToExcel()}><div className="excelBox bg-success"><RiFileExcel2Line /></div></a>
                                                    </div>
                                                    <div className="table-responsive progressDetailsTable">
                                                        <table className="table-responsive RevenueDetailsTable PqTable mx-auto" id="top-panel-data">
                                                            <thead className="text-center">
                                                                <tr className="d-none show-pdf">
                                                                    <th colSpan={6} className="text-start">
                                                                        <h6 className="mb-2 text-start">
                                                                            Smart Metering Report
                                                                        </h6>
                                                                        <p className="text-end">{new Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'medium', timeZone: 'Asia/Kolkata' }).format(new Date())}</p>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Sr.No.</th>
                                                                    <th className="text-start">State Name</th>
                                                                    <th className="text-center">District / Project Name</th>
                                                                    <th className="text-center">District Name</th>
                                                                    <th className="text-center">Major Components</th>
                                                                    <th className="text-center">Item Summary</th>
                                                                    <th className="text-cener">Sanctioned Items (Nos)</th>
                                                                    <th className="text-center">Sanctioned Cost (INR Lakh)</th>
                                                                    <th className="text-center">Awarded Items (Nos)</th>
                                                                    <th className="text-center">Award Rate (INR/unit)</th>
                                                                    <th className="text-center">Awarded Cost (INR Lakh)</th>
                                                                    <th className="text-center">Surveyed Items (Nos)</th>
                                                                    <th className="text-center">Surveyed Cost (INR Lakh)</th>
                                                                    <th className="text-center">Annual Targets</th>
                                                                    <th className="text-center">Delivered</th>
                                                                    <th className="text-center">Installed</th>
                                                                    <th className="text-center">Charged</th>
                                                                    <th className="text-center">Communicating Meters</th>
                                                                    <th className="text-center">Prepaid Mode</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="text-center">
                                                                {
                                                                    (tableData.length <= 0)
                                                                        ? <tr><td colSpan={12} className="text-center">Loading...</td></tr>
                                                                        : null
                                                                }
                                                                {
                                                                    tableData.map((item: any, index: any) => (
                                                                        <tr key={index}>
                                                                            <td>{item.srNo}</td>
                                                                            <td className="text-start">{item.title1}</td>
                                                                            <td className="text-start">{item.title2}</td>
                                                                            <td className="text-start">{item.title3}</td>
                                                                            <td className="text-start">{item.major_components}</td>
                                                                            <td className="text-start">{item.item_summary}</td>
                                                                            <td>{item.santion_items}</td>
                                                                            <td>{item.santion_cost}</td>
                                                                            <td>{item.award_items}</td>
                                                                            <td>{item.award_rate}</td>
                                                                            <td>{item.award_cost}</td>
                                                                            <td>{item.surveyed_items}</td>
                                                                            <td>{item.surveyed_cost}</td>
                                                                            <td>{item.annual_target}</td>
                                                                            <td>{item.delivered}</td>
                                                                            <td>{item.installed}</td>
                                                                            <td>{item.charged}</td>
                                                                            <td>{item.communicating_meters}</td>
                                                                            <td>{item.prepaid_mode}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DashboardFooter remove='d-none' />
            </div>
        </>
    );
};

export default SmDistrictwiseData;