import React, { useEffect, useState } from 'react'
import LossReduction from './LossReductionForm/LossReduction';
import SmartMetering from './SmartMetering';

const SanctionLetter = () => {

  const [selected, setSelected] = useState("SmartMetering");
  useEffect(() => {
    setSelected("SmartMetering");
  }, []);
  const changeHandler = (e: any) => {
    setSelected(e.target.value);
  };
  const sanctionLetterMainForm = () => {
    document.body.classList.add('test');
  }
  return (
    <>
      <section className="financialForm">
        <div className="financialMainFrom">
          <div className="tab-content border-0 p-0 mt-0">
            <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
              <div className='radioTitleSection'>
                <div className='row mx-0 gx-0 justify-content-between'>
                  <div className='col-lg-12 col-xl-7 col-xxl-6'>
                    <div className="radioYear">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-5 col-md-4 col-xl-4">
                          <div className="form-check">
                            <input type="radio" className="form-check-input" name="SanctionLetter" value="SmartMetering" id="SmartMetering" checked={selected === "SmartMetering"} onChange={changeHandler} />
                            <label className="form-check-label" htmlFor="SmartMetering">
                              Smart Metering
                            </label>
                          </div>
                        </div>
                        <div className="col-5 col-md-4 col-xl-4">
                          <div className="form-check">
                            <input type="radio" className="form-check-input" name="SanctionLetter" value="Loss-Reduction" id="Loss-Reduction" checked={selected === "Loss-Reduction"} onChange={changeHandler} />
                            <label className="form-check-label" htmlFor="Loss-Reduction">Loss Reduction</label>
                          </div>
                        </div>
                        <div className="col-5 col-md-4 col-xl-4">
                          <div className="form-check">
                            <input type="radio" className="form-check-input" name="SanctionLetter" value="Modernization" id="Modernization" checked={selected === "Modernization"} onChange={changeHandler} />
                            <label className="form-check-label" htmlFor="Modernization">Modernization</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div aria-hidden={selected !== "SmartMetering" ? true : false}><SmartMetering /></div>
              <div aria-hidden={selected !== "Loss-Reduction" ? true : false}><LossReduction/></div>
              <div aria-hidden={selected !== "Modernization" ? true : false}>c</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SanctionLetter;