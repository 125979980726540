import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStateMounted } from '../../../../Core/Hooks';
import FieldComponent from '../../../../FieldComponent';
import { Http } from '../../../../Core/Services/HttpService';
import { callFunctions, init, initselect, inputEventChecker, multijsoninit, validatefrm } from '../../../../assets/js/formbuilder';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import swal from 'sweetalert';
import { useAuth } from '../../../../Core/Providers';
import { BiDownload, BiUpload } from 'react-icons/bi';
import { BsFileEarmarkExcel, BsFileEarmarkPdf } from 'react-icons/bs';
import QueryString from 'qs';
import { FiEye, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { Storage } from '../../../../Core/Services/StorageService';

// Multijson
type MultijsonProps = {
  frmId: string;
  field: any;
  index: number;
  view:any;
  currentPage: number;
};

const multijsonPropTypes = {
  frmId: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  view: PropTypes.string.isRequired
};

function Multijson({ frmId, field, index, view, currentPage }: MultijsonProps) {
  let srNo = 1;
  const idRef = useRef(Math.random().toString(36).substring(2, 15));
  const [hasFieldDelete, setHasFieldDelete] = useStateMounted<boolean>(false);
  const [fields, setFields] = useStateMounted<any>([]);
  const [newFields, setNewFields] = useStateMounted<any>();

  useEffect(() => {
    if (field) {
      setHasFieldDelete(false);
      setFields([]);
      setNewFields(field.addnew);
      const fieldsMap = field.childrens.map((items: any) => {
        return {
          fields: items,
          delete: false
        }
      })
      setFields(fieldsMap);
    }
  }, [field]);

  useEffect(() => {
    if (fields.length) {
      const hasDelete = (fields.filter((item: any) => item.delete === false).length > 1);
      setHasFieldDelete(hasDelete);
      srNo = 1;
      setTimeout(() => {
        initselect('#' + frmId + ' .form-control');
        multijsoninit('#' + frmId + ' .form-control');
      }, 500);
    }
  }, [fields]);

  const addNewField = () => {
    setFields((prev: any[]) => {
      return [...prev, { fields: newFields, delete: false }];
    });
  }

  const removeField = (index: number) => {
    setFields((prev: any[]) => {
      const newFields = prev.map((item, i) => {
        if (i === index) {
          item.delete = true;
        }
        return item;
      })
      return newFields;
    });
  }

  return (
    <table className="table caption-top newTableUcss">
      {
        <thead className='text-center'>
          <tr key={idRef.current}>
            {/* <th style={{ minWidth: "50px" }} className="text-center">Sr. No.</th> */}
            {
              (newFields)
                ? newFields.map((field: any, index: number) => {
                  return (field.type === 'hidden') ?
                    <th key={`${idRef.current}-${index}`} aria-hidden="true"></th>
                    : <th key={`${idRef.current}-${index}`} className="text-center" style={{ minWidth: '150px' }}>{field.caption}</th>;
                })
                : null
            }
            <th>HH Data</th>
            {
              (newFields && view != 'Y') ?
                <th rowSpan={2} className='text-end pe-1' style={{ width: "50px" }}>
                  <Link to="#" className='p-0 text-success' onClick={() => addNewField()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                </th>
                : null
            }
          </tr>
        </thead>
      }
      <tbody>
        {
          fields.map((item: any, i: number) => {
            return (item.delete === false)
              ? (
                <tr key={`${idRef.current}-${i}`}>
                  {
                    item.fields.map((field: any, fkey: number) => {
                      return <>
                        <td key={i + '_' + fkey} aria-hidden={(field.type === 'hidden') ? "true" : "false"}>
                          <FieldComponent options={{ showTitle: false, extraId: '_' + i + '_' + fkey }} fieldName={field.key} field={field} />
                        </td>
                      </>;
                    })
                  }
                  <td className='text-center'>
                    {
                      (item.fields[16].value) ?
                          <Link
                              to={`/vvp-hh-data?id=` + item.fields[16].value}
                              title='HH Data'
                              state={{'vvpCurrentPage' : currentPage}}
                          ><FiEye className="text-success" /></Link>
                        /*<Link to={`/vvp-hh-data?id=` + item.fields[16].value} title='HH Data'><FiEye className="text-success" /></Link>*/
                        : null
                    }
                  </td>
                  {view != 'Y' ?
                    <td className="text-end">{(hasFieldDelete) ? <Link className='text-danger' to="#" onClick={() => removeField(i)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                  :null
                  }
                </tr>
              )
              : null;
          })
        }
      </tbody>
    </table>
  );
}

Multijson.propTypes = multijsonPropTypes;

// ChildInput
type ChildInputProps = {
  field: any;
  index?: number,
  view:any;
};

const childInputPropTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number,
  view: PropTypes.string.isRequired
};

function ChildInput({ field, index, view }: ChildInputProps) {

  const [fields, setFields] = useStateMounted<string[]>([]);

  useEffect(() => {
    if (field.childrens.length) {
      setFields(field.childrens);
    }
  }, [field]);

  return (
    <>
      {
        (fields.length)
          ? fields.map((field: any, i: number) => (
            <td key={index + '-' + i + '-' + field.key} aria-hidden={(field.type === 'hidden') ? "true" : "false"}>
              <FieldComponent
                options={{ showTitle: false }}
                fieldName={field.key}
                field={field} />
            </td>))
          : null
      }
    </>
  );
}

ChildInput.propTypes = childInputPropTypes;

export type VvpProps = {
  tab: string;
  form_id?: number;
  goTo?: (tab: string) => void;
};

const propTypes = {
  tab: PropTypes.string.isRequired,
  form_id: PropTypes.number,
  goTo: PropTypes.func
};

function Vvp({ tab, form_id }: VvpProps) {

  const user: any = useAuth().user();
  const location: any = useLocation();
  let vvpCurrentPage: number = location?.state?.vvpCurrentPage ?? 0;
  const idRef = useRef(Math.random().toString(36).substring(2, 15));
  const [loading, setLoading] = useStateMounted<boolean>(true);
  const [fields, setFields] = useStateMounted<any[]>([]);
  const [error, setError] = useStateMounted<string>();
  const [frmLoading, setFrmLoading] = useStateMounted<boolean>(false);
  const frmRef = useRef<any>();
  const [prevfields, setPrevFields] = useStateMounted<any[]>([]);
  const [pdfLink, setPdfLink] = useStateMounted('#');
  const [excelLink, setExcelLink] = useStateMounted('#');
  const [importFile, setImportFile] = useStateMounted<any>(null);
  const [sampleFileLink, setSampleFileLink] = useStateMounted('#');
  const [discomId, setDiscomId] = useStateMounted<any>(0);
  const [roleId, setRoleId] = useStateMounted<any>(0);
  const [view, setView] = useStateMounted<any>('N');
  const [limit, setLimit] = useState<any>(false);
  const [hasPagination, setHasPagination] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(vvpCurrentPage);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const frmBuilderFunc = callFunctions;
  let getLgdcOfVillageFire: any = {};

  const getFrm245LgdcOfVillage = function () {
    let selectId = arguments[2].trim();
    if (getLgdcOfVillageFire[selectId]) return;
    getLgdcOfVillageFire[selectId] = true;
    let optionIdsplit = selectId.split('_').reverse();
    let lgdElm: any = document.getElementById('vvp_details_lgd_code_' + optionIdsplit[1] + '_4');
    let talukaElm: any = document.getElementById('vvp_details_block_' + optionIdsplit[1] + '_2');
    let elm: any = document.getElementById(selectId);
    if (lgdElm) lgdElm.value = '';
    if (talukaElm) talukaElm.value = '';
    if (!elm) {
      getLgdcOfVillageFire[selectId] = false;
      return;
    }
    if (!elm.value) {
      getLgdcOfVillageFire[selectId] = false;
      return;
    }
    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-lgd-code-of-village?village_id=' + elm.value)
        .then((result) => {
          lgdElm.value = result.lgd_code;
          talukaElm.value = result.block_taluka;
        })
        .catch((error) => {
        })
        .finally(() => {
          getLgdcOfVillageFire[selectId] = false;
        });
    })();
  };

  useEffect(() => {
    if (roleId == "5" || roleId == "6") {
      setDiscomId(Storage.get('discom_id'));
      setRoleId(Storage.get('roleid'));
    } else {
      setDiscomId(user?.discom_id);
      setRoleId(user?.role_id);
    }

    frmBuilderFunc.addMethod('getFrm245LgdcOfVillage', getFrm245LgdcOfVillage);
  }, [tab, discomId, roleId]);


  const getFields = () => {
    (async () => {
      setHasPagination(false);
      setTotalPage(0);
      setTotalRecords(0);
      setFields([]);
      setLoading(true);
      setError('');
      if (discomId) {
        const params = {
          form_id: form_id,
          discom_id: discomId,
          role_id: roleId,
          user_id: user?.discom_user_id,
          current_page: currentPage
        };
        await Http.get(`/apiv1/vvp/form/${form_id}`, { params })
          .then((res) => {
            if (res.resultflag && res.fields) {
              if (res.fields && Array.isArray(res.fields)) {
                if (!res.fields.length) setError("No data found.");
                if(res.pagination){
                  setLimit(res.limit);
                  setHasPagination(true);
                  setFields(res.fields);
                  setView(res.view);
                  setTotalRecords(res.totalRecords);
                  setTotalPage(Math.ceil(res.totalRecords / res.limit));
                } else {
                  setLimit(false);
                  setFields(res.fields);
                  setView(res.view);
                }
              } else {
                setError("Invalid data type.");
              }
            } else if (res.resultflag !== undefined && !res.resultflag) {
              setError(res.message);
            } else {
              setError('No data found.');
            }
          })
          .catch((error) => {
            setError('System error occurred!! please try again.');
          }).finally(() => {
            setLoading(false);
          });
      }

    })();
  };

  useEffect(() => {
    if (form_id && discomId != null) {
      getFields();
    }
  }, [form_id, discomId, currentPage]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    const isValid = validatefrm(`#frm-${form_id}_${idRef.current} .form-control`);
    if (!isValid) return false;
    setFrmLoading(true);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const formData = new FormData(frmRef?.current);
    const params: any = {
      form_id: form_id,
      discom_id: discomId,
      role_id: roleId,
      user_id: user?.discom_user_id,
    };
    for (let key in params) {
      formData.append(key, params[key]);
    }
    Http.post(`/apiv1/vvp/saveForm/${form_id}`, formData, config)
      .then((res) => {
        if (res.resultflag) getFields();
        swal(res.message, "", (res.resultflag ? "success" : "error"));
      })
      .catch((err) => {
        swal("System error occurred!! please try again.", "", "error");
      })
      .finally(() => {
        setFrmLoading(false);
      });
  };

  useEffect(() => {
    if (form_id && discomId != null) {
      getLgdcOfVillageFire = {};
      getFields();
      let samplelink = process.env.REACT_APP_API_ENDPOINT + `/uploads/vvp-pvtg-data-sample/vvpdata.xlsx`;
      setSampleFileLink(samplelink);
    }
  }, [form_id, discomId]);

  useEffect(() => {
    if (form_id && discomId != null) {
      const params = {
        form_id: form_id,
        discom_id: discomId,
        role_id: roleId,
        user_id: user?.discom_user_id,
      };
      let link = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/vvp/frmPdf', form_id].join('/');
      link += '?' + QueryString.stringify(params);
      setPdfLink(link);
      let elink = [process.env.REACT_APP_API_ENDPOINT, 'apiv1/vvp/frmExcel', form_id].join('/');
      elink += '?' + QueryString.stringify(params);
      setExcelLink(elink);
    }
  }, [form_id, discomId]);

  useEffect(() => {
    if (fields.length) {
      setPrevFields(fields);
      setTimeout(() => {
        const selector = `#frm-${form_id}_${idRef.current} .form-control`;
        inputEventChecker.removeGroupEvent(selector);
        init(selector);
        multijsoninit(selector);
      }, 2000);
    }
  }, [fields]);

  useEffect(() => {
    if (fields.length && importFile != null) {
      setTimeout(() => {
        const importFileEle: any = document.getElementById(`importDataFile_${idRef.current}`);
        if (importFileEle !== null) {
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(importFile);
          importFileEle.files = dataTransfer.files;
          // Safari out
          if (importFileEle.webkitEntries.length) {
            importFileEle.dataset.file = `${dataTransfer.files[0].name}`;
          }
        }
        setImportFile(null);
      }, 100);
    }
  }, [importFile, fields]);

  const importData = (e: any) => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('import-file', e.target.files[0]);
    const params: any = {
      form_id: form_id,
      discom_id: discomId,
      role_id: roleId,
      user_id: user?.discom_user_id,
    };
    for (let key in params) {
      formData.append(key, params[key]);
    }

    let msg = '(1) Overwrite action will replace the existing data and action cannot be undone. (2) Add action will add data into existing data.';
    swal({
      title: "Would you like to overwrite or add to current data?",
      text: msg,
      icon: "warning",
      buttons: {
        add: {
          visible: true,
          text: 'Add',
          value: 'add',
          closeModal: true
        },
        overwrite: {
          visible: true,
          text: 'Overwrite',
          value: 'overwrite',
          closeModal: true
        },
        cancel: {
          visible: true,
          text: 'Cancel',
          value: 'cancel',
          closeModal: true
        }
      },
      dangerMode: true,
    }).then((isConfirm) => {
      if(isConfirm === 'cancel') return false;
      setCurrentPage(0);
      setLoading(true);
      if(isConfirm === 'add'){
        formData.append('add', '1');
        Http.post(`/apiv1/vvp/frmImportData/${form_id}`, formData, config)
            .then((res) => {
              const _msg = (res.message) ? res.message : "System error occurred!! please try again.";
              swal(_msg, "", (res.resultflag ? "success" : "error"));
              setImportFile(formData.get('import-file'));
              /*if (res.resultflag && res.fields) {
                if (res.fields && Array.isArray(res.fields)) {
                  setFields(res.fields);
                  setImportFile(formData.get('import-file'));
                } else {
                  setFields(prevfields);
                }
              } else {
                setFields(prevfields);
              }*/
            })
            .catch((err) => {
              swal("System error occurred!! please try again.", "", "error");
              //setFields(prevfields);
            })
            .finally(() => {
              setLoading(false);
              if(currentPage === 0) getFields();
            });
      } else if (isConfirm === 'overwrite') {
        Http.post(`/apiv1/vvp/frmImportData/${form_id}`, formData, config)
            .then((res) => {
              const _msg = (res.message) ? res.message : "System error occurred!! please try again.";
              swal(_msg, "", (res.resultflag ? "success" : "error"));
              setImportFile(formData.get('import-file'));
              /*if (res.resultflag && res.fields) {
                if (res.fields && Array.isArray(res.fields)) {
                  setFields(res.fields);
                  setImportFile(formData.get('import-file'));
                } else {
                  setFields(prevfields);
                }
              } else {
                setFields(prevfields);
              }*/
            })
            .catch((err) => {
              swal("System error occurred!! please try again.", "", "error");
              //setFields(prevfields);
            })
            .finally(() => {
              setLoading(false);
              if(currentPage === 0) getFields();
            });
      }
    });
    e.target.value = null;
  };

  return (
    <div className="container">
      <div className="financialMainFrom inputFromNew">
        <div className='tab-content border-0'>
          <form id={'frm-' + form_id + '_' + idRef.current} ref={frmRef} onSubmit={onSubmit}>
            {frmLoading ? <div className="spinner-container">
              <div className="spinner-outer">
                <div className="loading-spinner"></div>
              </div>
            </div> : null}
            {loading && (
              <>
                <div className="content-spinner spinner-container h-50vh">
                  <div className="spinner-outer">
                    <div className="loading-spinner"></div>
                  </div>
                </div>
              </>
            )}
            {error && (
              <>
                <Alert className='mt-3 mb-3' variant="danger">{error}</Alert>
              </>
            )}
            {((!loading && !error) && fields?.length > 0) && (
              <>
                <div className='row justify-content-end'>
                  {(roleId == "2" || roleId == "3" || roleId == "4") ?
                    <>
                      <div className="mb-3 form-field w-3">
                        <div className="d-none">
                          <input type="file" name="import_data_file" id={'importDataFile_' + idRef.current} className="form-control" />
                        </div>
                        <div className="fileUpload position-relative text-center">
                          <div className="downEqule">
                            <input title="Upload Data in Excel" type="file" onChange={importData} className="form-control" />
                            <div className="customUpload justify-content-center flex-wrap">
                              <span><BiUpload /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-2">
                        <Link title="Download Sample Format" to={sampleFileLink} target="_blank"><BiDownload className="text-success h3 mt-2" /></Link>
                      </div>
                    </>
                    : null
                  }
                  <div className="w-2">
                    <Link title="Export in Excel" to={excelLink} target="_blank"><BsFileEarmarkExcel className="text-success h3 mt-2" /></Link>
                  </div>
                  <div className="w-2">
                    <Link title="Export in PDF" to={pdfLink} target="_blank"><BsFileEarmarkPdf className="text-danger h3 mt-2" /></Link>
                  </div>
                </div>
                <div className="row justify-content-end">
                  {(hasPagination) && (
                      <>
                        <div className="col-md-6 text-start mt-3 text-danger">
                          Please save current page before proceeding to the next page
                        </div>
                        <div className="col-md-6 text-end mb-2">
                          <input type="hidden" name="formdata[vvp_details][currentPage]" value={currentPage}/>
                          {(currentPage != 0) && (
                              <button type="button" onClick={() => setCurrentPage((prev) => prev - 1) } className="btn btn-primary me-2">Previous</button>
                          )}
                          {
                            (currentPage > 3 && totalPage > 10) ?
                                <>
                                  <button type="button" onClick={() => setCurrentPage(0)} className={`btn btn-secondary me-2`} style={(currentPage == 0) ? {"backgroundColor": "#54a800", "border": "1px solid #54a800"} : {}}>{1}</button>
                                  <button type="button" className={`btn btn-secondary me-2`}>...</button>
                                </>
                                : null
                          }
                          {
                            (() => {
                              let arr = [];
                              for(let i = 0; i < totalPage; i++){
                                if(totalPage > 10){
                                  if ( i >= (currentPage - 3) && i <= (currentPage + 3) ){
                                    arr.push(
                                        <button type="button" onClick={() => setCurrentPage(i) } className={`btn btn-secondary me-2`} style={(currentPage == i) ? {"backgroundColor":"#54a800","border":"1px solid #54a800"} : {}}>{i+1}</button>
                                    );
                                  }
                                } else {
                                  arr.push(
                                      <button type="button" onClick={() => setCurrentPage(i) } className={`btn btn-secondary me-2`} style={(currentPage == i) ? {"backgroundColor":"#54a800","border":"1px solid #54a800"} : {}}>{i+1}</button>
                                  );
                                }
                              }
                              return arr;
                            })()
                          }
                          {
                            (totalPage > 10 && currentPage < (totalPage - 4)) ?
                                <>
                                  <button type="button" className={`btn btn-secondary me-2`}>...</button>
                                  <button type="button" onClick={() => setCurrentPage(totalPage - 1)} className={`btn btn-secondary me-2`} style={(currentPage == (totalPage - 1)) ? {"backgroundColor": "#54a800", "border": "1px solid #54a800"} : {}}>{totalPage}</button>
                                </>
                                : null
                          }
                          {((totalPage - 1) > currentPage) && (
                              <button type="button" onClick={() => setCurrentPage((prev) => prev + 1) } className="btn btn-primary me-2">Next</button>
                          )}
                        </div>
                      </>
                  )}
                </div>
                <div className="table-responsive RevenueDetailsTable">
                  <table className="table">
                    <tbody>
                      {
                        fields.map((field: any, index: number) => (
                          field.type === 'multijson' ? (
                            <Multijson key={`${index}-${field.key}`} frmId={'frm-' + form_id + '_' + idRef.current} field={field} index={index} view={view} currentPage={currentPage}/>
                          ) : field.childrens ? (
                            <tr key={`${index}-${field.key}`}>
                              <ChildInput field={field} index={index} view={view} />
                            </tr>
                          ) : null
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {(roleId == "2" || roleId == "3" || roleId == "4") ?
              <div className="formBtnSection is-sticky justify-content-end">
                <button className="bttn btnDraft" type="submit">Submit</button>
              </div>
              : null
            }
          </form>
        </div>
      </div>
    </div>
  );
}

Vvp.defaultProps = {
  form_id: 245
};

Vvp.propTypes = propTypes;

export default Vvp;
