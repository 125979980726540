import { Stepper, Step } from "react-form-stepper";
import React, { useEffect, useRef, useState } from "react";
import "../../../../RdssForms/rdssforms.scss";
import swal from 'sweetalert';
import { $, init, validatefrm } from "../../../../assets/js/formbuilder";
import { Link } from "react-router-dom";

// Formsls";
import ApprovedCost from "./ApprovedCost";
import { Http } from "../../../../Core/Services/HttpService";
import SanctionItems from "./SanctionItems";
import { useLayout } from "../../../../Core/Providers/LayoutProvider";
import { Storage } from "../../../../Core/Services/StorageService";

export default function SmartMetering() {
  return (
    <div className="App">
      <PlaceOrder />
    </div>
  );
}

export const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner-outer">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}

export const PlaceOrder = () => {

  const layout = useLayout();
  const formData: any = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [roleid, setRoleid] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const [discomuserid, setDiscomUserid] = useState<any>(0);
  const [discomid, setDiscomid] = useState<any>(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [steps, setSteps] = useState(['active', '', '', '']);
  const [submittedStatus, setSubmittedStatus] = useState<any>(0);
  const [error, setError] = useState(null);
  const [act, setAct] = useState<any>(0);
  const [draftButtonSwitch, setDraftButtonSwitch] = useState<any>('DISCOM');
  const [smDistrictAchiveView, setSmDistrictAchiveView] = useState<boolean>(false);

  let layoutDataSubscribe: any;
  const [selected, setSanctionedItemsType] = useState("DISCOM");

  useEffect(() => {
    setDiscomUserid(Storage.get('discom_user_id'));
  }, [discomuserid]);

  useEffect(() => {
    setDiscomid(Storage.get('discom_id'));
  }, [discomid]);

  useEffect(() => {
    setRoleid(Storage.get('roleid'));
  }, [roleid]);

  useEffect(() => {
    (async () => {
      layoutDataSubscribe = await layout.data.subscribe((res: any) => {
        if (res.sanctioneditemstype) {
          setSanctionedItemsType(res.sanctioneditemstype);
        } else {
          setSanctionedItemsType("DISCOM");
        }
        if (res.smDistrictAchiveView) {
          setSmDistrictAchiveView(res.smDistrictAchiveView);
        } else {
          setSmDistrictAchiveView(false);
        }
      });
    })();
    return () => {
      if (layoutDataSubscribe !== undefined) {
        layoutDataSubscribe.unsubscribe();
      }
    }
  }, []);


  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('sanctioned_items_type', selected);

    Http.post('/apiv1/save-sanction-data', myFormData, config)
      .then((res) => {
        if (res._resultflag == 0) {
          setIsLoading(false);
          swal("Warning", res.message, "info");
        } else {
          const importFileEle: any = document.getElementById(`import_data_file`);
          if (importFileEle !== null) {
            const dataTransfer = new DataTransfer();
            importFileEle.files = dataTransfer.files;
          }
          swal("Input Form", "Data have been saved", "success");
          setIsLoading(false);
          setRefresh(true);
          setGoSteps(1);
        }
      });
  }

  const navtab = (opt: number) => {

    if (opt == 0)
      setSteps(['active', '', '', '']);
    if (opt == 1)
      setSteps(['completed', 'active', '', '']);
    if (opt == 2)
      setSteps(['completed', 'completed', 'active', '']);
    if (opt == 3)
      setSteps(['completed', 'completed', 'completed', 'active']);

    if (act != 'view') {
      setRefresh(true);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let myFormData = new FormData(formData.current);
      myFormData.append('discom_id', discomid);
      myFormData.append('discom_user_id', discomuserid);
      myFormData.append('sanctioned_items_type', selected);
      Http.post('/apiv1/save-sanction-data', myFormData, config)
        .then((res) => {
          setRefresh(false);
          console.log("Data have been saved");
        });
    }

    setGoSteps(opt);
  }

  const next = (opt: number) => {
    if (opt == 0)
      setSteps(['active', '', '', '']);
    if (opt == 1)
      setSteps(['completed', 'active', '', '']);
    if (opt == 2)
      setSteps(['completed', 'completed', 'active', '']);
    if (opt == 3)
      setSteps(['completed', 'completed', 'completed', 'active']);

    setIsLoading(true);
    setRefresh(false);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('sanctioned_items_type', selected);
    Http.post('/apiv1/save-sanction-data', myFormData, config)
      .then((res) => {
        if (res._resultflag == 0) {
          swal("Warning", res.message, "info");
        } else {
          swal("Input Form", "Data have been saved", "success");
          const importFileEle: any = document.getElementById(`import_data_file`);
          if (importFileEle !== null) {
            const dataTransfer = new DataTransfer();
            importFileEle.files = dataTransfer.files;
          }
          setRefresh(true);
          setGoSteps(opt);
        }
      })
      .catch((error) => {
        swal("Warning", 'System error, please try again later.', "info");
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const nextAfterSubmitted = (opt: number) => {
    if (opt == 0)
      setSteps(['active', '', '', '']);
    if (opt == 1)
      setSteps(['completed', 'active', '', '']);
    if (opt == 2)
      setSteps(['completed', 'completed', 'active', '']);
    if (opt == 3)
      setSteps(['completed', 'completed', 'completed', 'active']);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    setGoSteps(opt);
  }

  const saveasdraft = (form_step_no: any) => {
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('sanctioned_items_type', selected);

    Http.post('/apiv1/save-sanction-data', myFormData, config)
      .then((res) => {
        if (res._resultflag == 0) {
          setIsLoading(false);
          swal("Warning", res.message, "info");
        } else {
          const importFileEle: any = document.getElementById(`import_data_file`);
          if (importFileEle !== null) {
            const dataTransfer = new DataTransfer();
            importFileEle.files = dataTransfer.files;
          }
          swal("Input Form", "Data have been saved", "success");
        }
        setIsLoading(false);
        setRefresh(true);
      });
  }

  const saveasdraftDistrict = (form_step_no: any) => {
    setIsLoading(true);
    setRefresh(false);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let myFormData = new FormData(formData.current);
    myFormData.append('discom_id', discomid);
    myFormData.append('discom_user_id', discomuserid);
    myFormData.append('sanctioned_items_type', selected);
    Http.post('/apiv1/save-sanction-data-district', myFormData, config)
      .then((res) => {
        if (res._resultflag == 0) {
          setIsLoading(false);
          swal("Warning", res.message, "info");
        } else {
          const importFileEle: any = document.getElementById(`import_data_file`);
          if (importFileEle !== null) {
            const dataTransfer = new DataTransfer();
            importFileEle.files = dataTransfer.files;
          }
          swal("Input Form", "Data have been saved", "success");
        }
        setRefresh(true);
        setIsLoading(false);
      });
  }

  const submitted = (form_step_no: any) => {
    swal({
      title: "Warning",
      text: "Upon submission, the forms will be uneditable. Are you sure you would like to submit?",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        setIsLoading(true);
        setRefresh(false);
        // Sava data first
        let config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append('sanctioned_items_type', selected);
        Http.post('/apiv1/save-sanction-data-district', myFormData, config)
          .then((res) => {
            if (res._resultflag == 0) {
              setIsLoading(false);
              swal("Warning", res.message, "info");
            } else {
              const importFileEle: any = document.getElementById(`import_data_file`);
              if (importFileEle !== null) {
                const dataTransfer = new DataTransfer();
                importFileEle.files = dataTransfer.files;
              }
              // Submit data block
              let config = { headers: { 'Content-Type': 'multipart/form-data' } };
              let myFormData = new FormData();
              myFormData.append('discom_id', discomid);
              myFormData.append('discom_user_id', discomuserid);
              myFormData.append('form_id', '28,29,32');
              Http.post('/apiv1/save-sanction-data-block', myFormData, config)
                .then((blockRes) => {
                  if (blockRes._resultflag == 0) {
                    swal("Warning", res.message, "info");
                  } else {
                    setSubmittedStatus(blockRes.submitted_status);
                    if (blockRes.submitted_status == 1) {
                      setAct('view');
                    }
                    swal({
                      title: 'Input Form!',
                      text: 'Your data has been submitted.',
                      icon: 'success'
                    }).then(() => {
                      setRefresh(true);
                    });
                  }
                }).catch((error) => {
                  swal("Warning", 'System error, please try again later.', "info");
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          }).catch((error) => {
            setIsLoading(false);
            swal("Warning", 'System error, please try again later.', "info");
          });
      }
    });

  }

  const getSubmittedStatus = () => {
    (async () => {
      if (discomid) {
        setIsLoading(true);
        await Http.get("/apiv1/sanction-block-status?form_id=28,29,32&discom_id=" + discomid)
          .then((res) => {
            setIsLoaded(true);
            setIsLoading(false);
            setSubmittedStatus(res.submitted_status);
            if (res.submitted_status == 1) {
              setAct('view');
            }
          })
          .catch((error) => {
            setIsLoaded(true);
            setError(error);
          });
      }
    })();
  };

  const handleDraftButtonState = (tabName: any) => {
    setDraftButtonSwitch(tabName);
  };

  useEffect(() => {
    (async () => {
      getSubmittedStatus();
    })();
  }, [discomid]);

  useEffect(() => {
    if (submittedStatus == 1) {
      setAct('view');
    }
  }, [discomid, submittedStatus]);

  return (
    <>
      <div>
        <div className='container'>
          <div className='customStepper'>
            <div className="StepperContainer-0-2-1">
              <div className="StepContainer-0-2-2">
                <div className="StepMain-0-2-3">
                  <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 " + (steps[0] ? steps[0] : '')}>
                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 " + (steps[0] ? steps[0] : '')}>1</span>
                  </button>
                  <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">Approved Cost</span></div>
                </div>
              </div>
              <div className="StepContainer-0-2-2">
                <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                <div className="StepMain-0-2-3">
                  <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 " + (steps[1] ? steps[1] : '')}>
                    <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 " + (steps[1] ? steps[1] : '')}>2</span>
                  </button>
                  <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Sanctioned Items</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form noValidate onSubmit={onSubmit} ref={formData}>
          {goSteps === 0 && (
            <div>
              <div>
                <ApprovedCost refresh={refresh} act={act} />
              </div>
              <div className="formBtnSection justify-content-between is-sticky">
                <div></div>
                {
                  (roleid == 2 || roleid == 3 || roleid == 4) && submittedStatus != 1 ?
                    <>
                      <Link to="#" className="btnDraft" onClick={() => saveasdraft(0)} >
                        Save as Draft
                      </Link>
                      <button className="btn btnSubmit" type="submit" onClick={() => next(1)}>Next</button>
                    </>
                    : null
                }

                {submittedStatus == 1 ?
                  <button className="btn btnSubmit" type="submit" onClick={() => nextAfterSubmitted(1)}>Next</button>
                  : null
                }
              </div>
            </div>
          )}
          {goSteps === 1 && (
            <div>
              <div>
                <SanctionItems refresh={refresh} act={act} change={handleDraftButtonState} />
              </div>
              <div aria-hidden={
                (selected === 'District') ? ((smDistrictAchiveView) ? "true" : "false")
                  : "false"
              } className="formBtnSection justify-content-between is-sticky">
                <Link to="#" className="btn btnSubmit me-4" onClick={() => setGoSteps(0)}>Previous</Link>
                {
                  (roleid == 2 || roleid == 3 || roleid == 4) && submittedStatus != 1 ?
                    <>
                      {
                        /*
                        = JUST FOR FUTURE REFERENCE =
                        (draftButtonSwitch.toLowerCase() === 'district') ?
                            <Link to="#" className="btnDraft district" onClick={() => saveasdraftDistrict(1)} >
                                Save as Draft
                            </Link> :
                            <Link to="#" className="btnDraft" onClick={() => saveasdraft(1)} >
                                Save as Draft
                            </Link>*/
                      }
                      <Link to="#" className="btnDraft district" onClick={() => saveasdraftDistrict(1)} >
                        Save as Draft
                      </Link>
                      <Link to="#" className="btnCancle" onClick={() => submitted(1)} >
                        Submit
                      </Link>
                      <button className="btn btnSubmit" type="submit">Update</button>
                    </>
                    : null
                }
              </div>
            </div>
          )}
        </form>
      </div>
      {isLoading ? <LoadingSpinner /> : null}
    </>
  );
};
