import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, validatefrm, multijsoninit, initselect, inputEventChecker } from "../../../../../../assets/js/formbuilder";
import { Http } from '../../../../../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { GrAddCircle, GrTrash } from "react-icons/gr";
import { BiUpload, BiDownload } from 'react-icons/bi';
import { OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { useStateMounted } from "../../../../../../Core/Hooks";

// Images
import FieldComponent from "../../../../../../FieldComponent";
import { FiUploadCloud } from "react-icons/fi";
import { useLayout } from "../../../../../../Core/Providers";
import { Storage } from "../../../../../../Core/Services/StorageService";

type LossReductionProps = {
	apiurl: string;
	act: any;
	refresh: any;
};

const Block1 = (props: any) => {

	let block1SrNo = 0;
	const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
	const [block1Fields, setBlock1fields] = useState<any>([]);
	const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);
	const [blockKey, setBlockKey] = useState<string>('');
	const [_remarkFields, setRemarkFields] = useState<any>({});

	useEffect(() => {
		if (props.field5) {
			setBlockKey(props.field5.key);
			setHasBlock1FieldsDeletes(false);
			setBlock1fields([]);
			setNewBlock1fields(props.field5.addnew);
			setTimeout(() => {
				const fields1 = props.field5.childrens.map((items: any) => {
					return {
						fields: items,
						delete: false
					}
				})
				setBlock1fields(fields1);
			}, 500);
		}
	}, [props.field5]);

	useEffect(() => {
		if (props.remarkFields) {
			setRemarkFields([]);
			setRemarkFields(props.remarkFields);
		}
	}, [props.remarkFields]);

	useEffect(() => {
		if (block1Fields.length) {
			const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
			setHasBlock1FieldsDeletes(hasDelete);
			block1SrNo = 0;
			inputEventChecker.removeAllEvent();
			setTimeout(() => {
				initselect();
				multijsoninit();
			}, 1000);
		}
	}, [block1Fields]);

	const addItem = (block?: any) => {
		setBlock1fields((prev: any[]) => {
			return [...prev, { fields: newBlock1Fields, delete: false }];
		});

		setTimeout(() => {
			initselect();
			multijsoninit();
		}, 1000);
	}

	const removeItem = (rindex: number, block?: any) => {
		setBlock1fields((prev: any[]) => {
			const newFields = prev.map((item, index) => {
				if (index === rindex) {
					item.delete = true;
				}
				return item;
			})
			return newFields;
		});

		setTimeout(() => {
			initselect();
			multijsoninit();
		}, 500);
	}

	return (
		<>
			<table className="table caption-top">
				<thead className='text-center'>
					<tr>
						<th style={{ minWidth: "50px" }}>S. No.</th>
						<th>Major Components</th>
						<th>Sub Component</th>
						<th>Item Specification</th>
						<th>Remarks</th>
						<th>Unit</th>
						<th>Sanctioned Rate (INR/unit)</th>
						<th>Quantity</th>
						<th>Cost (INR Lakh)</th>
						{props.act != 'view' ?
							<th rowSpan={2} className='text-end pe-1' style={{ width: "30px" }}>
								<Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
							</th>
							: null
						}

					</tr>

				</thead>
				<tbody>
					{
						block1Fields.map((item: any, key: number) => {
							return (item.delete === false) ?
								<tr key={key}>
									<td className="text-center" >{(block1SrNo++) + 1}</td>
									{
										item.fields.map((field: any, fkey: number) => {
											return <>
												<td key={key + '_' + fkey}>
													<FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
											</>;
										})
									}
									{props.act != 'view' && props.excelSwitch ?
										<td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
										: null
									}
								</tr>
								: <tr key={key} className="d-none">
									<td className="text-center">
										<input type="hidden" name={'deleteIndex[' + blockKey + '][]'} value={key} />
									</td>
								</tr>;
						})
					}
					<>
						{
							(block1Fields.length && props.field2)
								? <>
									<tr>
										<td colSpan={8} className="text-center"><h5>Total</h5></td>
										{/* <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[0].key} field={props.field2.childrens[0]} /></td>
                                     <td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[1].key} field={props.field2.childrens[1]} /></td>
                                     */}
										<td><FieldComponent options={{ showTitle: false }} fieldName={props.field2.childrens[2].key} field={props.field2.childrens[2]} /></td>
									</tr>
								</>
								: null
						}
					</>
					{
						(_remarkFields && _remarkFields.childrens && _remarkFields.childrens.length > 0)
							? <tr>
								{_remarkFields.childrens.map((field: any, key: number) => {
									return <td key={key} colSpan={(key == 0) ? 7 : 2}>
										<label className="form-label">{field.caption}</label>
										<FieldComponent options={{ showTitle: false }} fieldName={field.key} field={field} />
									</td>;
								})}
							</tr>
							: null
					}
				</tbody>
			</table>
		</>
	);
}

const ArchiveView = ({ discom, setArchiveData, archiveRevisionId }: any) => {

	const [loading, setLoading] = useStateMounted<boolean>(true);
	const [fields, setFields] = useStateMounted<any[]>([]);
	const [error, setError] = useStateMounted<string>();

	const getFields = (id?: any) => {
		(async () => {
			setArchiveData({});
			setLoading(true);
			setError('');
			setFields([]);
			const config = { headers: { 'Content-Type': 'multipart/form-data' } };
			const formData = new FormData();
			const params: any = {
				form_id: 31,
				discom: discom,
			};
			for (let key in params) {
				if (params[key] instanceof Object) {
					Object.keys(params[key]).forEach((k: any) => {
						formData.append(key + '[' + k + ']', params[key][k]);
					});
				} else {
					formData.append(key, params[key]);
				}
			}
			if (id) {
				formData.append('revisionId', id);
			}
			await Http.post(`/apiv1/archive-view/form/31`, formData, config)
				.then((res) => {
					if (res.resultflag && res.fields) {
						if (res.fields && Array.isArray(res.fields)) {
							if (!res.fields.length) setError("No data found.");
							setFields(res.fields);
						} else {
							setError("Invalid data type.");
						}
						setArchiveData({
							userName: res.userName,
							createdDate: res.createdDate,
							nextId: res.nextId,
							previousId: res.previousId,
						});
					} else if (res.resultflag !== undefined && !res.resultflag) {
						setError(res.message);
					} else {
						setError('No data found.');
					}
				})
				.catch((error) => {
					setError('System error occurred!! please try again.');
				}).finally(() => {
					setLoading(false);
				});
		})();
	};

	useEffect(() => {
		if (discom) {
			getFields(archiveRevisionId);
		}
	}, [discom, archiveRevisionId]);

	return (
		<div>
			{loading && (
				<div className="content-spinner spinner-container h-50vh">
					<div className="spinner-outer">
						<div className="loading-spinner"></div>
					</div>
				</div>
			)}
			{error && (
				<Alert className='mt-3 mb-3' variant="danger">
					{error}
				</Alert>
			)}
			{((!loading && !error) && fields?.length > 0) && (
				<Block1 field5={fields[0]} field2={fields[1]} remarkFields={fields[2]} act={'view'} />
			)}
		</div>
	);
}

const SanctionDiscomForm = (props: LossReductionProps) => {

	const layout = useLayout();
	const [isLoaded, setIsLoaded] = useState(false);
	const [fields, setFields] = useState<any[]>([]);
	const [roleid, setRoleid] = useState<any>(0);
	const [discomid, setDiscomid] = useState<any>(0);
	const [isLoading, setIsLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [sampleFileLink, setSampleFileLink] = useStateMounted('#');
	const [loading, setLoading] = useStateMounted<boolean>(true);
	const [error, setError] = useStateMounted<string>();
	const [excelSwitch, setexcelSwitch] = useState<boolean>(true);
	const [archiveView, setArchiveView] = useState<boolean>(false);
	const [archiveData, setArchiveData] = useState<any>({});
	const [archiveRevisionId, setArchiveRevisionId] = useState<any>(0);

	const handleFileInput = (e: any) => {
		setIsLoading(true);
		setRefresh(false);
		const config = { headers: { 'Content-Type': 'multipart/form-data' } };
		let formData = new FormData();
		formData.append("file_to_upload", e.target.files[0]);
		formData.append("role_id", roleid);
		formData.append("discom_id", discomid);
		formData.append("form_id", "31");
		setFields([]);
		Http.post('/apiv1/lossreductionuploaddata', formData, config)
			.then((result) => {
				setFields(result.fields);
				setIsLoading(false);
				setRefresh(true);
				setFields(result.fields);
				// console.log(result.fields);
				setTimeout(() => {
					initselect();
					multijsoninit();
				}, 2000);
				console.log("File uploaded successfully");
			}).catch((error) => {
				setIsLoaded(true);
				setError(error);
			}).finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		setDiscomid(Storage.get('discom_id'));
	}, [discomid]);

	useEffect(() => {
		setRoleid(Storage.get('roleid'));
	}, [roleid]);


	// useEffect(() => {
	//     setFields([]);
	//     (async () => {
	//         let footerspinner:any = document.getElementById('footer-spinner');
	//         if ( footerspinner )
	//             footerspinner.classList.remove('d-none');
	//         await Http.get(props.apiurl)
	//             .then((result) => {
	//                 setIsLoaded(true);
	//                 setFields(result.fields);
	//                 setTimeout(() => {
	//                     init();
	//                     initselect();
	//                 }, 500);
	//             })
	//             .catch((error) => {
	//                 setIsLoaded(true);
	//                 setError(error);
	//             }).finally(() => {
	//                 footerspinner.classList.add('d-none');
	//             });
	//     })();
	// }, [props.apiurl , props.refresh]);

	const getFields = () => {
		(async () => {
			setIsLoaded(false);
			let footerspinner: any = document.getElementById('footer-spinner');
			if (footerspinner)
				footerspinner.classList.remove('d-none');
			setFields([]);
			await Http.get(props.apiurl)
				.then((res) => {
					if (res.resultflag && res.fields) {
						if (res.fields && Array.isArray(res.fields)) {
							if (!res.fields.length) setError("No data found.");
							setIsLoaded(true);
							setFields(res.fields);
							if (typeof res.excelSwitch !== "undefined") setexcelSwitch(res.excelSwitch);
							setTimeout(() => {
								init();
							}, 500);

						} else {
							setError('System error occurred!! please try again.');
							setIsLoaded(true);
						}

					} else if (!res.resultflag) {
						setError(res.message);
						footerspinner.classList.remove('d-none');
						setIsLoaded(true);
					} else {
						setError('No data found.');
						footerspinner.classList.remove('d-none');
						setIsLoaded(true);
					}

				}).catch((error) => {
					setError('System error occurred!! please try again.');
					footerspinner.classList.remove('d-none');
					setIsLoaded(true);
				})
				.finally(() => {
					footerspinner.classList.add('d-none');
					setIsLoaded(true);
				});
			setIsLoaded(true);
		})();
	};

	useEffect(() => {
		(async () => {
			getFields();
		})();
	}, [props.apiurl, props.refresh]);


	useEffect(() => {
		if (discomid) {
			let samplelink = process.env.REACT_APP_API_ENDPOINT + '/uploads/sanctionletter-excel-sample/Loss_Reduction_Discom_and_District.xlsx';
			setSampleFileLink(samplelink);
		}
	}, [discomid]);

	useEffect(() => {
		setArchiveData({});
		setArchiveRevisionId(0);
		layout.set({ lsDiscomAchiveView: archiveView });
	}, [layout, archiveView]);

	if (error) {
		return <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>;
	} else if (!isLoaded) {
		return <div>Loading...</div>;
	} else {

		return (
			fields ?
				<>
					<div className='DistrictSelect'>
						<div className='row'>
							<div className="col-md-2">
								<button onClick={() => setArchiveView((prev) => {
									// Toggle the archive view
									return !prev;
								})} type="button" className={(archiveView) ? 'btn btn-secondary' : 'btn btn-primary'}>
									{archiveView ? "Back" : "Archive"}
								</button>
							</div>
						</div>
					</div>
					{/* <div className='DistrictSelect'>
                        {props.act != 'view' ?
                            <>
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div className="form-field">
                                            <div className="fileUpload position-relative text-center">
                                                <div className="downEqule">
                                                    <input type="file" onChange={handleFileInput} data-prefix="fpl_expenditure_details" className="form-control" />
                                                    <div className="customUpload justify-content-center flex-wrap">
                                                        <span><BiUpload /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className="w-2">
                                            <Link title="Download Sample Format" to={sampleFileLink} target="_blank"><BiDownload className="text-success h3 mt-2" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null
                        }
                    </div> */}
					<div aria-hidden={archiveView ? "true" : "false"}>
						<div className="pageMiddleTitle">
							<div className="row justify-content-between align-items-center mx-0 gx-0">
								<h5>DISCOM must only enter IT/OT & SCADA Initiatives related items</h5>
							</div>
						</div>
						{error && (<Alert className='mt-3 mb-3' variant="danger">{error}</Alert>)}
						{
						(!error) && (fields?.length > 0) ?
							<Block1 field5={fields[0]} field2={fields[1]} remarkFields={fields[4]} act={props.act} excelSwitch={excelSwitch} />
							: null
						}
					</div>
					{(archiveView) && <div>
						<div className="pageMiddleTitle">
							<div className="row justify-content-between align-items-center mx-0 gx-0">
								<h5 className="col-md-10">
									DISCOM must only enter IT/OT & SCADA Initiatives related items (Archived{(archiveData.createdDate) ? ' - ' + archiveData.createdDate : ''})
									{archiveData.userName && <span className="text-muted"> by {archiveData.userName}</span>}
								</h5>
								<div className="col-md-2 d-flex justify-content-end">
									{archiveData.previousId && <button onClick={() => setArchiveRevisionId(archiveData.previousId)} type="button" className="btn btn-secondary me-2">Previous</button>}
									{archiveData.nextId && <button onClick={() => setArchiveRevisionId(archiveData.nextId)} type="button" className="btn btn-primary me-2">Next</button>}
								</div>
							</div>
						</div>
						<ArchiveView {...{ discom: discomid, archiveRevisionId, setArchiveData }} />
					</div>}
				</>
				: null
		);
	}
};

const SanctionDiscomForms = (props: any) => {
	const [roleid, setRoleid] = useState<any>(0);
	const [discomid, setDiscomid] = useState<any>(0);
	useEffect(() => {
		setRoleid(Storage.get('roleid'));
		setDiscomid(Storage.get('discom_id'));
	}, [roleid]);

	return (
		<>
			<div className="mainWrap">
				{roleid == 0 ? null :
					<SanctionDiscomForm apiurl={"/apiv1/formfields/?form_id=31&roleid=" + roleid + "&discom_id=" + discomid + "&act=" + props.act} act={props.act} refresh={props.refresh} />
				}
			</div>
		</>
	)
}
export default SanctionDiscomForms;