import React, { useState, useEffect, useRef } from "react";
import 'react-nice-dates/build/style.css'
import { init, initselect, multijsoninit } from "../../assets/js/formbuilder";
import { Http } from '../../Core/Services/HttpService';
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import swal from 'sweetalert';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useLayout } from "../../Core/Providers/LayoutProvider";
import { useStateMounted } from '../../Core/Hooks';
import { Alert } from 'react-bootstrap';
import FieldComponent from "../../FieldComponent";
import { Storage } from "../../Core/Services/StorageService";

const Block1 = (props: any) => {
    let block1SrNo = 0;
    const [hasBlock1FieldsDelete, setHasBlock1FieldsDeletes] = useState<boolean>(false);
    const [block1Fields, setBlock1fields] = useState<any>([]);
    const [newBlock1Fields, setNewBlock1fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock1fields(props.field.addnew);
            const fields1 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock1fields(fields1);
        }
    }, [props.field]);

    useEffect(() => {
        if (block1Fields.length) {
            const hasDelete = (block1Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock1FieldsDeletes(hasDelete);
            block1SrNo = 0;
        }
    }, [block1Fields]);

    const addItem = (block?: any) => {
        setBlock1fields((prev: any[]) => {
            return [...prev, { fields: newBlock1Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock1fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
        }, 500);
    }

    return (
        <>
            <tr className='caption text-center'>
                <td colSpan={4}>Smart Metering</td>
                {props.view != 'Y' ?
                    <th className='text-end pe-1' style={{ width: "30px" }}>
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    </th>
                    : null
                }
            </tr>
            <>
                {
                    block1Fields.map((item: any, key: number) => {
                        return (item.delete === false) ? <>
                            <tr key={key}>
                                <td className="text-center">{(block1SrNo++) + 1}</td>
                                {
                                    item.fields.map((field: any, fkey: number) => {
                                        return <>
                                            <td key={key + '_' + fkey}>
                                                <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                        </>;
                                    })
                                }
                                {props.view != 'Y' ?
                                    <td className="text-end">{(hasBlock1FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                    : null
                                }
                            </tr>
                        </>
                            : null;
                    })
                }
            </>
        </>
    );
}

const Block2 = (props: any) => {
    let block2SrNo = 0;
    const [hasBlock2FieldsDelete, setHasBlock2FieldsDeletes] = useState<boolean>(false);
    const [block2Fields, setBlock2fields] = useState<any>([]);
    const [newBlock2Fields, setNewBlock2fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock2fields(props.field.addnew);
            const fields2 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock2fields(fields2);
        }
    }, [props.field]);

    useEffect(() => {
        if (block2Fields.length) {
            const hasDelete = (block2Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock2FieldsDeletes(hasDelete);
            block2SrNo = 0;
        }
    }, [block2Fields]);

    const addItem = (block?: any) => {
        setBlock2fields((prev: any[]) => {
            return [...prev, { fields: newBlock2Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock2fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
        }, 500);
    }

    return (
        <>
            <tr className='caption text-center'>
                <td colSpan={4}>Loss Reduction</td>
                {props.view != 'Y' ?
                    <th className='text-end pe-1' style={{ width: "30px" }}>
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    </th>
                    : null
                }
            </tr>
            <>
                {
                    block2Fields.map((item: any, key: number) => {
                        return (item.delete === false) ? <>
                            <tr key={key}>
                                <td className="text-center">{(block2SrNo++) + 1}</td>
                                {
                                    item.fields.map((field: any, fkey: number) => {
                                        return <>
                                            <td key={key + '_' + fkey}>
                                                <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                        </>;
                                    })
                                }
                                {props.view != 'Y' ?
                                    <td className="text-end">{(hasBlock2FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                    : null
                                }
                            </tr>
                        </>
                            : null;
                    })
                }
            </>
        </>
    );
}

const Block3 = (props: any) => {
    let block3SrNo = 0;
    const [hasBlock3FieldsDelete, setHasBlock3FieldsDeletes] = useState<boolean>(false);
    const [block3Fields, setBlock3fields] = useState<any>([]);
    const [newBlock3Fields, setNewBlock3fields] = useState<any>([]);

    useEffect(() => {
        if (props.field) {
            setNewBlock3fields(props.field.addnew);
            const fields3 = props.field.childrens.map((items: any) => {
                return {
                    fields: items,
                    delete: false
                }
            })
            setBlock3fields(fields3);
        }
    }, [props.field]);

    useEffect(() => {
        if (block3Fields.length) {
            const hasDelete = (block3Fields.filter((item: any) => item.delete === false).length > 1);
            setHasBlock3FieldsDeletes(hasDelete);
            block3SrNo = 0;
        }
    }, [block3Fields]);

    const addItem = (block?: any) => {
        setBlock3fields((prev: any[]) => {
            return [...prev, { fields: newBlock3Fields, delete: false }];
        });

        setTimeout(() => {
            initselect();
        }, 500);
    }

    const removeItem = (rindex: number, block?: any) => {
        setBlock3fields((prev: any[]) => {
            const newFields = prev.map((item, index) => {
                if (index === rindex) {
                    item.delete = true;
                }
                return item;
            })
            return newFields;
        });

        setTimeout(() => {
            initselect();
        }, 500);
    }

    return (
        <>
            <tr className='caption text-center'>
                <td colSpan={4}>MODERNISATION</td>
                {props.view != 'Y' ?
                    <th className='text-end pe-1' style={{ width: "30px" }}>
                        <Link to="#" className='p-0 text-success' onClick={() => addItem()}><FiPlusCircle className="h5 p-0 mb-0" /></Link>
                    </th>
                    : null
                }
            </tr>
            <>
                {
                    block3Fields.map((item: any, key: number) => {
                        return (item.delete === false) ? <>
                            <tr key={key}>
                                <td className="text-center">{(block3SrNo++) + 1}</td>
                                {
                                    item.fields.map((field: any, fkey: number) => {
                                        return <>
                                            <td key={key + '_' + fkey}>
                                                <FieldComponent options={{ showTitle: false, extraId: '_' + key + '_' + fkey }} fieldName={field.key} field={field} /></td>
                                        </>;
                                    })
                                }
                                {props.view != 'Y' ?
                                    <td className="text-end">{(hasBlock3FieldsDelete) ? <Link className='text-danger' to="#" onClick={() => removeItem(key, props.block)}><FiTrash2 className="p-0 mb-0 h5" /></Link> : null}</td>
                                    : null
                                }
                            </tr>
                        </>
                            : null;
                    })
                }
            </>
        </>
    );
}

const PhysicalProgressFormYearReport = (props?: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const formData: any = useRef(null);
    const [discomid, setDiscomid] = useState<any>(0);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getdocumentation, getDocumentation] = useState<any[]>([]);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [error, setError] = useStateMounted<string>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [fields, setFields] = useState<any[]>([]);
    const [year, setYear] = useState<any>(0);
    const [releasefundid, setReleaseFundId] = useState<any>(0);
    const [isSubmittedStatus, setSubmittedStatus] = useState<any>(0);
    const [view, setView] = useState<any[]>([]);

    const layout = useLayout();
    let layoutDataSubscribe: any;

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        (async () => {
            if (location.state) {
                getDocumentation(location.state.data);
                setYear(location.state.year);
                setReleaseFundId(location.state.release_fund_id);
            }
            else {
                navigate('/fund-disbursal');
            }
        })();
    }, [releasefundid, year]);

    const navmaintab = () => {
        setPageLink('/fund-disbursal');
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { release_fund_id: releasefundid, data: getdocumentation, year: year, is_yearly_back: '1' } });
            }
        })();
    }, [getpagelink]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, []);

    const getFields = () => {
        (async () => {
            setIsLoaded(true);
            setError('');
            setFields([]);

            if (roleid && discomid && year) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 21,
                    discom_user_id: discomuserid,
                    year: year,
                    module: 'fd_yearly'
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                await Http.get(`/apiv1/fdyearfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoaded(true);
                                setFields(res.fields);
                                setSubmittedStatus(res.submittedStatus);
                                setView(res.view);
                                layout.set({ fieldsresult: res });
                                setTimeout(() => {
                                    init();
                                    multijsoninit();
                                }, 1000);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoaded(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [year]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // setIsLoading(true);
        // setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        if (myFormData.get('formdata[fd_physical_progress][fd_date]') === "") {
            swal("Warning", "Please select Date", "info");
        } else {
            
            myFormData.append('discom_id', discomid);
            myFormData.append('discom_user_id', discomuserid);
            myFormData.append("release_fund_id", releasefundid);
            myFormData.append("year", year);
            myFormData.append("roleid", roleid);

            Http.post('/apiv1/save-fund-disbursal-data-yearly', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        swal("Warning", res.message, "info");
                    } else {
                        swal("Input Form", "Data have been saved", "success");
                        navigate('/fund-disbursal', { state: { release_fund_id: releasefundid, data: getdocumentation, year: year, is_yearly_back: '1' } });
                    }
                });
        }
    }

    const getReport = (e: any) => {
        e.preventDefault();
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
        let myFormData = new FormData(formData.current);

        if (myFormData.get('formdata[fd_physical_progress][fd_date]') === "") {
            swal("Warning", "Please select Date", "info");
        } else {
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner)
                footerspinner.classList.remove('d-none');
            setIsLoaded(true);
            myFormData.append('discom_id', discomid);
            myFormData.append('discom_user_id', discomuserid);
            myFormData.append("release_fund_id", releasefundid);
            myFormData.append('tranche_id', gettrancheid);
            myFormData.append("year", year);
            myFormData.append('component_id', getcomponentid);
            myFormData.append('task', 'pdf');
            myFormData.append("roleid", roleid);

            Http.post('/apiv1/save-fund-disbursal-data-yearly', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        swal("Warning", res.message, "info");
                    } else {
                        setIsLoaded(false);
                        footerspinner.classList.add('d-none');
                        var filename = "Physical_Progress_Report_Year.pdf";
                        var blob = new Blob([res], { type: 'application/pdf' });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                    }
                });
        }
    }

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh, discomid]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                :
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            }
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Fund Disbursal</Link></li>
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Yearly Report Submission</Link></li>
                            <li>Physical Progress</li>
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <form noValidate onSubmit={onSubmit} ref={formData}>
                                        <>
                                            {error && (
                                                <>
                                                    <Alert className='mt-3 mb-3' variant="info">{error}</Alert>
                                                </>
                                            )}

                                            {((!error) && fields?.length > 0) && (
                                                <>
                                                    <div className="table-responsive RevenueDetailsTable">
                                                        <div className='caption overflow-hidden'>
                                                            <div className='row'>
                                                                <div className="col-sm-1">
                                                                    <label>Date</label>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <FieldComponent options={{ showTitle: false }} fieldName={fields[0].key} field={fields[0]} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive RevenueDetailsTable mt-4">
                                                        <table className="table caption-top">
                                                            <thead className='text-center'>
                                                                <tr>
                                                                    <th style={{ width: "50px" }}>S.No</th>
                                                                    <th>District/Project</th>
                                                                    <th>Grant No./Scheme Code</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <Block1 field={fields[2]} view={view} />
                                                                <Block2 field={fields[3]} view={view} />
                                                                <Block3 field={fields[4]} view={view} />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )
                                            }
                                        </>
                                        <div className="formBtnSection justify-content-between">
                                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navmaintab()}>Back</Link>
                                            {((isSubmittedStatus == 1) && (roleid == 2 || roleid == 3 || roleid == 4)) ?
                                                <>
                                                    <Link to="#" className="btnDraft" onClick={getReport}>Get Report</Link>
                                                    <button className="btnDraft" type="submit">Save & Back</button>
                                                </>
                                                : null
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    )
}

export default PhysicalProgressFormYearReport;
