import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../RdssForms/rdssforms.scss";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Http } from '../../Core/Services/HttpService';
import swal from 'sweetalert';
import { useStateMounted } from '../../Core/Hooks';
import GoIGrantDisbursementForm from './FinancialProgressForms/GoIGrantDisbursementForm';
import CounterpartFundingForm from './FinancialProgressForms/CounterpartFundingForm';
import FinancialProgressPreviewForm from './FinancialProgressForms/FinancialProgressPreviewForm';
import { useLayout } from "../../Core/Providers/LayoutProvider";
import { Storage } from "../../Core/Services/StorageService";

export const FinancialProgressForm = (props: any) => {
    const [roleid, setRoleid] = useState<any>(0);
    const [username, setUsername] = useState<any>(0);
    const formData: any = useRef(null);
    const [discomuserid, setDiscomUserid] = useState<any>(0);
    const [getdocumentation, getDocumentation] = useState<any[]>([]);
    const [getcomponentname, setComponentname] = useState<any>(null);
    const [gettranchename, setTranchename] = useState<any>(null);
    const [getpagelink, setPageLink] = useState<any>(null);
    const [getcomponentid, setComponentId] = useState<any>(0);
    const [gettrancheid, setTrancheId] = useState<any>(0);
    const [getreleasefundid, setReleaseFundId] = useState<any>(0);
    const [goSteps, setGoSteps] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [getaplicationtrackerid, setAplicationTrackerId] = useState<any>(0);
    const [gettrackerid, setTrackerId] = useState<any>(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSubmittedStatus, setSubmittedStatus] = useState<any>(0);
    const [isDgSignatureAccepted, setIsDgSignatureAccepted] = useState<any>(0);
    const [view, setView] = useState<any[]>([]);
    const [error, setError] = useStateMounted<string>();
    const layout = useLayout();
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        (async () => {
            if (location.state) {
                getDocumentation(location.state.data);
                setComponentname(location.state.component_name);
                setTranchename(location.state.tranche_name);
                setComponentId(location.state.component_id);
                setTrancheId(location.state.tranche_id);
                setReleaseFundId(location.state.id);
                setAplicationTrackerId(location.state.aplication_tracker_id);
                setTrackerId(location.state.ref_id);
            }
            else {
                navigate('/fund-disbursal');
            }
        })();
    }, [getcomponentid, gettrancheid, getaplicationtrackerid]);

    const navmaintab = () => {
        setPageLink('/fund-disbursal-link');
    }

    useEffect(() => {
        (async () => {
            if (getpagelink) {
                navigate(getpagelink, { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
            }
        })();
    }, [getpagelink]);

    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
        setDiscomid(Storage.get('discom_id'));
        setDiscomUserid(Storage.get('discom_user_id'));
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // setIsLoading(true);
        // setRefresh(false);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData(formData.current);
        myFormData.append('discom_id', discomid);
        myFormData.append('discom_user_id', discomuserid);
        myFormData.append("release_fund_id", getreleasefundid);
        myFormData.append('component_id', getcomponentid);
        myFormData.append("tranche_id", gettrancheid);
        myFormData.append("aplication_tracker_id", getaplicationtrackerid);
        myFormData.append("roleid", roleid);

        Http.post('/apiv1/save-fund-disbursal-data', myFormData, config)
            .then((res) => {
                if (res._resultflag == 0) {
                    swal("Warning", res.message, "info");
                } else {
                    swal("Input Form", "Data have been saved", "success");
                    navigate('/fund-disbursal-link', { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
                }
            });
    }

    const saveAsDraft = () => {

        if (roleid == 2 || roleid == 3 || roleid == 4) {
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let myFormData = new FormData(formData.current);
            myFormData.append('discom_id', discomid);
            myFormData.append('discom_user_id', discomuserid);
            myFormData.append("release_fund_id", getreleasefundid);
            myFormData.append('component_id', getcomponentid);
            myFormData.append("tranche_id", gettrancheid);
            myFormData.append("aplication_tracker_id", getaplicationtrackerid);
            myFormData.append("roleid", roleid);
            Http.post('/apiv1/save-fund-disbursal-data', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        swal("Warning", res.message, "info");
                    } else {
                        swal("Input Form", "Data have been saved", "success");
                    }
                });
        }
    }

    const [steps, setSteps] = useState(['active', '', '', '', '', '']);
    // Nav Steps
    const next = (opt: number) => {
    
        if ((isSubmittedStatus == 1) && (roleid == 2 || roleid == 3 || roleid == 4) && isDgSignatureAccepted != 1) {
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let myFormData = new FormData(formData.current);
            myFormData.append('discom_id', discomid);
            myFormData.append('discom_user_id', discomuserid);
            myFormData.append("release_fund_id", getreleasefundid);
            myFormData.append('component_id', getcomponentid);
            myFormData.append("tranche_id", gettrancheid);
            myFormData.append("aplication_tracker_id", getaplicationtrackerid);
            myFormData.append("roleid", roleid);

            Http.post('/apiv1/save-fund-disbursal-data', myFormData, config)
                .then((res) => {
                    if (res._resultflag == 0) {
                        swal("Warning", res.message, "info");
                    } else {
                        swal("Input Form", "Data have been saved", "success");
                        if (opt == 0)
                            setSteps(['active', '', '', '']);
                        if (opt == 1)
                            setSteps(['completed', 'active', '', '']);
                        if (opt == 2)
                            setSteps(['completed', 'completed', 'active', '']);

                        setGoSteps(opt);
                        
                        if (opt == 3) {
                            navigate('/fund-disbursal-link', { state: { ref_id: gettrackerid, id: gettrancheid.tranche_id, data: getdocumentation, component_name: getcomponentname, tranche_name: gettranchename, component_id: getcomponentid, tranche_id: gettrancheid, aplication_tracker_id: getaplicationtrackerid } });
                        }
                    }
                });
        }else{
            if (opt == 0)
                setSteps(['active', '', '', '']);
            if (opt == 1)
                setSteps(['completed', 'active', '', '']);
            if (opt == 2)
                setSteps(['completed', 'completed', 'active', '']);

            setGoSteps(opt);
        }
    }

    const navtab = (opt: number) => {
        if (opt == 0)
            setSteps(['active', '', '', '']);
        if (opt == 1)
            setSteps(['completed', 'active', '', '']);
        if (opt == 2)
            setSteps(['completed', 'completed', 'active', '']);
        if (opt == 3)
            setSteps(['completed', 'completed', 'completed', 'active']);
        setGoSteps(opt);
    }

    useEffect(() => {
        if (props.opt) navtab(parseInt(props.opt));
    }, [props]);

    useEffect(() => {
        setRefresh(false);
        if (props.refresh !== undefined && props.refresh === true) {
            setRefresh(true);
        }
    }, [props.refresh, discomid]);

    const getFields = () => {
        (async () => {
            setIsLoaded(true);
            setError('');
            if (roleid && discomid && getaplicationtrackerid) {
                const params = {
                    roleid: roleid,
                    discom_id: discomid,
                    form_id: 39,
                    discom_user_id: discomuserid,
                    application_id: getaplicationtrackerid,
                };
                let footerspinner: any = document.getElementById('footer-spinner');
                if (footerspinner)
                    footerspinner.classList.remove('d-none');
                await Http.get(`/apiv1/fdfields`, { params })
                    .then((res) => {
                        if (res.fields) {
                            if (res.fields && Array.isArray(res.fields)) {
                                if (!res.fields.length) setError("No data found.");
                                setIsLoaded(true);
                                setSubmittedStatus(res.submittedStatus);
                                setIsDgSignatureAccepted(res.isDgSignatureAccepted);
                            } else {
                                setError("Invalid data type.");
                            }
                        } else if (!res.resultflag) {
                            setError(res.message);
                        } else {
                            setError('No data found.');
                        }
                    })
                    .catch((error) => {
                        setError('System error occurred!! please try again.');
                    }).finally(() => {
                        setIsLoaded(false);
                        footerspinner.classList.add('d-none');
                    });
            }
        })();
    };

    useEffect(() => {
        (async () => {
            getFields();
        })();
    }, [getaplicationtrackerid]);

    return (
        <>
            <div className="mainWrap">
                <Header />
                <section className="InnerBanner">
                    <div className="container">
                        <ul className="BreadCumb list-unstyled mb-0">
                            <li><Link to="/" className="text-decoration-none">Home</Link></li>
                            {(roleid == 2 || roleid == 3 || roleid == 4) ?
                                <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                                :
                                <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li>
                            }
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Fund Disbursal</Link></li>
                            <li><Link to="/fund-disbursal" className="text-decoration-none">Request for Fund Release</Link></li>
                            <li>Financial Progress Form</li>
                        </ul>
                    </div>
                </section>
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div>
                                        <div className='container'>
                                            <div className='customStepper'>
                                                <div className="StepperContainer-0-2-1">
                                                    <div className="StepContainer-0-2-2">
                                                        <div className="StepMain-0-2-3">
                                                            <button onClick={() => navtab(0)} className={"StepButton-0-2-4 StepButton-d0-0-2-6 " + (steps[0] ? steps[0] : '')}>
                                                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d1-0-2-7 " + (steps[0] ? steps[0] : '')}>1</span>
                                                            </button>
                                                            <div className="LabelContainer-0-2-8 LabelContainer-d0-0-2-10"><span className="Label-0-2-9 Label-d1-0-2-11">GoI Grant Disbursement</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="StepContainer-0-2-2">
                                                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d0-0-2-16"><span className="Connector-0-2-15 Connector-d1-0-2-17"></span></div>
                                                        <div className="StepMain-0-2-3">
                                                            <button onClick={() => navtab(1)} className={"StepButton-0-2-4 StepButton-d2-0-2-12 " + (steps[1] ? steps[1] : '')}>
                                                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d3-0-2-13 " + (steps[1] ? steps[1] : '')}>2</span>
                                                            </button>
                                                            <div className="LabelContainer-0-2-8 LabelContainer-d2-0-2-18"><span className="Label-0-2-9 Label-d3-0-2-19">Counterpart Funding</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="StepContainer-0-2-2">
                                                        <div className="ConnectorContainer-0-2-14 ConnectorContainer-d2-0-2-22"><span className="Connector-0-2-15 Connector-d3-0-2-23"></span></div>
                                                        <div className="StepMain-0-2-3">
                                                            <button onClick={() => navtab(2)} className={"StepButton-0-2-4 StepButton-d4-0-2-20 " + (steps[2] ? steps[2] : '')}>
                                                                <span className={"StepButtonContent-0-2-5 StepButtonContent-d5-0-2-21 " + (steps[2] ? steps[2] : '')}>3</span>
                                                            </button>
                                                            <div className="LabelContainer-0-2-8 LabelContainer-d4-0-2-24"><span className="Label-0-2-9 Label-d5-0-2-25">Financial Progress Preview</span></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <form noValidate onSubmit={onSubmit} ref={formData} >
                                            {goSteps === 0 && (
                                                <div>
                                                    <div>
                                                        <GoIGrantDisbursementForm getaplicationtrackerid={getaplicationtrackerid} />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between">
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navmaintab()}>Back</Link>
                                                        </div>
                                                        {((isSubmittedStatus == 1) && (roleid == 2 || roleid == 3 || roleid == 4) && isDgSignatureAccepted != 1) ?
                                                            <Link to="#" className="btnDraft" onClick={() => saveAsDraft()}>Save as Draft</Link>
                                                            : null
                                                        }
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => next(1)}>Next</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 1 && (
                                                <div>
                                                    <div>
                                                        <CounterpartFundingForm getaplicationtrackerid={getaplicationtrackerid} />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between">
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit me-4" onClick={() => navtab(0)}>Previous</Link>
                                                        </div>
                                                        {((isSubmittedStatus == 1) && (roleid == 2 || roleid == 3 || roleid == 4) && isDgSignatureAccepted != 1) ?
                                                            <Link to="#" className="btnDraft" onClick={() => saveAsDraft()}>Save as Draft</Link>
                                                            : null
                                                        }
                                                        <div>
                                                            <Link to="#" className="btn btnSubmit" onClick={() => next(2)}>Next</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {goSteps === 2 && (
                                                <div>
                                                    <div>
                                                        <FinancialProgressPreviewForm refresh={refresh} getaplicationtrackerid={getaplicationtrackerid} />
                                                    </div>
                                                    <div className="formBtnSection justify-content-between mt-4">
                                                        <Link to="#" className="btn btnSubmit me-4" onClick={() => navmaintab()}>Back</Link>
                                                        {((isSubmittedStatus == 1) && (roleid == 2 || roleid == 3 || roleid == 4) && isDgSignatureAccepted != 1) ?
                                                            <button className="btnDraft" type="submit">Save & Back</button>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div >
        </>
    );
};
export default FinancialProgressForm;
