import React, { useEffect, useState } from 'react'
import LossReduction from './LossReduction';
import SmartMetering from './SmartMetering';
import InnerTemplate from "../../../Components/InnerTemplate/InnerTemplate";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import { Link, useLocation } from 'react-router-dom'
import VvpPvtg from '../../../Module/VvpPvtg/Pages/Main';
import { useStateMounted } from '../../../Core/Hooks';
import { useAuth } from '../../../Core/Providers';
import { Storage } from '../../../Core/Services/StorageService';

const ProgressDetails = () => {

  const location = useLocation();
  const activeTab = (location.state as { tab: any })?.tab || "SmartMetering";
  const user: any = useAuth().user();
  const [selected, setSelected] = useState(activeTab);
  const [roleId, setRoleId] = useStateMounted<any>(0);

  const changeHandler = (e: any) => {
    setSelected(e.target.value);
  };

  useEffect(() => {
    if (roleId == "5" || roleId == "6") {
      setRoleId(Storage.get('roleid'));
    } else {
      setRoleId(user?.role_id);
    }
  }, [roleId]);


  return (
    <>
      <Header />
      <div className="mainWrap">
        <section className="InnerBanner">
          <div className="container">
            <ul className="BreadCumb list-unstyled mb-0">
              <li><Link to="/" className="text-decoration-none">Home</Link></li>
              {
                (roleId == "5" || roleId == "6") ? <li><Link to="/nodal-view" className="text-decoration-none">RDSS</Link></li> : <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
              }
              <li><Link to="/monitoring" className="text-decoration-none">Monitoring</Link></li>
              <li><Link to="/all-progress-details" className='text-decoration-none'>AllProgressDetails</Link></li>
              <li>District-wise Progress Details</li>
            </ul>
          </div>
        </section>
        <section className="financialForm">
          <div className="container">
            <div className="financialMainFrom inputFromNew">
              <div className="tab-content border-0 p-0">
                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                  <div className='radioTitleSection'>
                    <div className='row mx-0 gx-0 justify-content-between'>
                      <div className='col-lg-12 col-xl-7 col-xxl-6'>
                        <div className="radioYear progressive-details">
                          <div className="row justify-content-center align-items-center gx-0 mx-0">
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" name="SanctionLetter" value="SmartMetering" id="SmartMetering" checked={selected === "SmartMetering"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="SmartMetering">
                                  Smart Metering
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" name="SanctionLetter" value="Loss-Reduction" id="Loss-Reduction" checked={selected === "Loss-Reduction"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="Loss-Reduction">Loss Reduction</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" name="SanctionLetter" value="Modernization" id="Modernization" checked={selected === "Modernization"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="Modernization">Modernization</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-check">
                                <input type="radio" className="form-check-input" name="vvppvtg" value="VVPPVTG" id="VVPPVTG" checked={selected === "VVPPVTG"} onChange={changeHandler} />
                                <label className="form-check-label" htmlFor="VVPPVTG">VVP/PVTG</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    (selected == "SmartMetering") ? <div aria-hidden={selected !== "SmartMetering" ? true : false}><SmartMetering /></div> : null
                  }
                  {
                    (selected == "Loss-Reduction") ? <div aria-hidden={selected !== "Loss-Reduction" ? true : false}><LossReduction /></div> : null
                  }
                  {
                    (selected == "Modernization") ? <div aria-hidden={selected !== "Modernization" ? true : false}>c</div> : null
                  }
                  {
                    (selected == "VVPPVTG") ? <div aria-hidden={selected !== "VVPPVTG" ? true : false}><VvpPvtg /></div> : null
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div >
      <Footer />
    </>
  );
}

export default ProgressDetails;