import React, { useEffect } from "react";
import "./Footer.scss";
import { Link, useLocation } from "react-router-dom";
import { TiSocialFacebook, TiSocialTwitter, TiSocialLinkedin } from "react-icons/ti";
import { IoLogoInstagram } from "react-icons/io";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../../node_modules/swiper/swiper.scss";
import { Navigation } from "swiper";

// Imges
import IndiaGov from '../../../../images/india-gov.png';
import MyGov from '../../../../images/mygov.png';
import AKAM from '../../../../images/akam.png';
import G20 from '../../../../images/g20.png';
import PMIndia from '../../../../images/pmindia-logo.png';
import MinisryPower from '../../../../images/ministry-fo-pwer.png';
import MakeInIndia from '../../../../images/makeinindia.png';

function Footer(props: any) {

    const location = useLocation();

    const fixedFooter = () => {
        const body = document.body;
        const html = document.documentElement;
        const bodyheight = Math.max(body.getBoundingClientRect().height, html.getBoundingClientRect().height);
        const height = window.innerHeight;
        const footer = document.getElementById('footer-content');
        if (footer && height > bodyheight) {
            let top = height - bodyheight;
            footer.setAttribute('style', 'top: ' + top + 'px; position: relative;');
        }
    };

    useEffect(() => {
        window.addEventListener('resize', fixedFooter);
        window.addEventListener('scroll', fixedFooter);
        return () => {
            window.removeEventListener('resize', fixedFooter);
            window.removeEventListener('scroll', fixedFooter);
        }
    }, []);

    useEffect(() => {
        fixedFooter();
    }, [location]);

    const Fade = require('react-reveal/Fade');
    return (
        <>
            <div id="footer-content">
                <section className={'logoSection ' + props.remove}>
                    <div className="container">
                        <Fade bottom cascade>
                            <Swiper
                                spaceBetween={0}
                                modules={[Navigation]}
                                navigation={{
                                    prevEl: '.press-prev',
                                    nextEl: '.press-next',
                                }}
                                breakpoints={{
                                    1200: {
                                        slidesPerView: 7,
                                    },
                                    991: {
                                        slidesPerView: 6,
                                    },
                                    768: {
                                        slidesPerView: 5,
                                    },
                                    575: {
                                        slidesPerView: 4,
                                    },
                                    360: {
                                        slidesPerView: 1,
                                    }

                                }}
                                className="logoSwiper"
                            >
                                <SwiperSlide><div className="logoImage"><a target="_blank" href="https://www.india.gov.in/" rel="noreferrer"><img alt="India Gov" title="India Gov" className="img-fluid" src={IndiaGov} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" href="https://www.mygov.in/" rel="noreferrer"><img alt="My Gov" title="My Gov" className="img-fluid" src={MyGov} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" href="https://amritmahotsav.nic.in/" rel="noreferrer"><img alt="Amrit Mahotsav" title="Amrit Mahotsav" className="img-fluid" src={AKAM} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" href="https://g20.mygov.in/" rel="noreferrer"><img alt="G20" title="G20" className="img-fluid" src={G20} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" href="https://www.pmindia.gov.in/en/" rel="noreferrer"><img alt="PM India" title="PM India" className="img-fluid" src={PMIndia} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" href="https://powermin.gov.in/" rel="noreferrer"><img alt="Power Ministry" title="Power Ministry" className="img-fluid" src={MinisryPower} /></a></div></SwiperSlide>
                                <SwiperSlide><div className="logoImage"><a target="_blank" href="https://www.makeinindia.com/" rel="noreferrer"><img alt="Make in India" title="Make In India" className="img-fluid" src={MakeInIndia} /></a></div></SwiperSlide>
                            </Swiper>
                        </Fade>
                    </div>
                </section>
                <footer>
                    <div className="container">
                        <div className="footerInner d-flex align-items-center justify-content-between">
                            <div className="copyRight">
                                <ul>
                                    <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                                    <li><Link to="/website-policy">Website Policy</Link></li>
                                    <li><Link to="/copyright-policy">Copyright Policy</Link></li>
                                    <li><Link to="/disclaimer">Disclaimer</Link></li>
                                    {/* <li><Link to="/">Accessibility Statement</Link></li> */}
                                    <li><Link to="/help">Help</Link></li>
                                    <li><Link to="/">Important Link</Link></li>
                                </ul>
                                <p className="mb-0">Copyright © 2023 REC Ltd., PFC Ltd., Ministry of Power, Government of India. All rights reserved</p>
                            </div>
                            <div className="socialSection">
                                <ul className="social">
                                    <li><Link to="https://www.facebook.com/RECLIndia"><TiSocialFacebook /></Link></li>
                                    <li><Link to="https://twitter.com/reclindia?lang=en"><TiSocialTwitter /></Link></li>
                                    <li><Link to="https://www.linkedin.com/company/reclindia/"><TiSocialLinkedin /></Link></li>
                                    <li><Link to="https://www.instagram.com/reclindia/?hl=en"><IoLogoInstagram /></Link></li>
                                </ul>
                            </div>
                            <div className="visitor text-end">
                                <p className="mb-0">Last Updated: {process.env.REACT_APP_UPDATED_DATE}</p>
                                <p className="mb-0">Version: {process.env.REACT_APP_VERSION}</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Footer;