import React, { useEffect, useState } from "react"
import DashboardFooter from '../Components/Footer/DashboardFooter'
import DashboardHeader from '../Components/Header/DashboardHeader'
import { BiArrowBack } from 'react-icons/bi'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ProgressDetailsMainChart from '../Components/DashboardComponents/Chart/ProgressDetailsChart/ProgressDetailsMain'
import { BsBarChart, BsFileEarmarkPdf, BsTable } from "react-icons/bs"
import './dashboard.scss';
import { useLayout } from "../Core/Providers"
import { RiFileExcel2Line } from "react-icons/ri"
import { Http } from "../Core/Services/HttpService"
import { utils, writeFile } from "xlsx";
import { Modal } from "react-bootstrap"
import Select from 'react-select';

const AllStateProgressViewLoss = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const locationModal = location.state && location.state.modal;

    const [params, setParams] = useState<any>();
    const [progressData, setProgressData] = useState<any>([]);
    const [reportData, setReportData] = useState<any>([]);
    const [selected, setSelected] = useState("ChartSection");
    const [chartLink, setChartLink] = useState<string>('');
    const [headingTitle, setHeadingTitle] = useState<string>('State wise Progress');
    const [noDataMessage, setNoDataMessage] = useState<string>('Loading...');
    const [radioOptions, setRadioOptions] = useState<any>([]);
    const [defaultVal, setDefaultVal] = useState("");
    const [radioval, setRadioVal] = useState("");
    const [options, setOptions] = useState<any>([]);
    const [filterParams, setFilterParams] = useState<any>({
        sortBy: 'alphabetically',
        query: [],
    });

    const layout = useLayout();
    let srNo = 1;
    let reportType = 'DisStates';

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
        layout.set({ awarditemstype: e.target.value });
    };

    const fetchProgressData = async (fParams: any = {}) => {
        setProgressData([]);
        setNoDataMessage('Loading...');
        if (fParams.chartfor == 'state') {
            fParams.stateid = fParams.id;
            reportType = 'Discom';
        }
        if (fParams.chartfor == 'discom') {
            fParams.stateid = fParams.state;
            fParams.discomid = fParams.id;
            reportType = 'Districts';
        }

        const fnlParams = {
            ...fParams,
            charttype: fParams.type,
            reportType: reportType,
            module: 'ls'
        };
        await Http.get('/apiv1/d-board/progressData', { params: fnlParams })
            .then((res) => {
                if (res._resultflag == "1") {
                    setProgressData(res.stateProgressData);
                } else {
                    setNoDataMessage('No Data Found!');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found!');
            });
    };

    const exportToPDF = () => {
        let table = document.getElementById("progress-data-loss")?.cloneNode(true) as any;
        table = document.createDocumentFragment().appendChild(table);
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const frmData = new FormData();
        frmData.append('content', encodeURIComponent(table.outerHTML));
        frmData.append('filename', 'progressdata');
        frmData.append('download', 'no');
        Http.post('/apiv1/contentExportPdf', frmData, config)
            .then((res) => {
                if (res._resultflag == "1") {
                    window.open(res.file, "_blank");
                } else {
                    setNoDataMessage('No Data Found!');
                }
            }).catch((e) => {
                setNoDataMessage('No Data Found!');
            });
    };

    const exportToExcel = () => {
        const table = document.getElementById('progress-data-loss');
        const wb = utils.table_to_book(table);
        writeFile(wb, 'progressdata.xlsx');
    };

    const sortBy = (e: any) => {
        setFilterParams((prev: any) => { return { ...prev, sortBy: e.target.value } });
    };

    const filterQuery = (item: any) => {
        setFilterParams((prev: any) => { return { ...prev, query: item } });
    };

    useEffect(() => {
        if (params?.type == 'LT') {
            setRadioOptions([
                // { label: 'LT Line', value: 'LT' },
                { label: 'LT Line - Total', value: 'tot_LT' },
                { label: 'New LT line', value: 'new_LT' },
                { label: 'Reconductoring/ Augmentation of LT Line', value: 'recnd_LT' },
            ]);
        }
        if (params?.type == 'HT') {
            setRadioOptions([
                // { label: 'HT Line', value: 'HT' },
                { label: 'HT Line - Total', value: 'tot_HT' },
                { label: 'New HT line', value: 'new_HT' },
                { label: 'Reconductoring/ Augmentation of HT Line', value: 'recnd_HT' },
            ]);
        }
        if (params?.type == 'DTR') {
            setRadioOptions([
                // { label: 'DTR', value: 'DTR' },
                { label: 'DTR - Total', value: 'tot_DTR' },
                { label: 'New DTR', value: 'new_DTR' },
                { label: 'Replacement/ Augmentation of DTR', value: 'recnd_DTR' },
            ]);
        }
        if (params?.type == 'SUB') {
            setRadioOptions([
                // { label: 'Substation', value: 'SUB' },
                { label: 'Substation - Total', value: 'tot_SUB' },
                { label: 'New Substation', value: 'new_SUB' },
                { label: 'Augmentation of Substation', value: 'recnd_SUB' },
            ]);
        }
    }, [params?.type]);

    const handleOptionChange = async (event: any) => {
        const value = event.target.value;
        setDefaultVal(value);
        setRadioVal(event.target.labels[0].innerText);
        setParams((prev: any) => ({ ...prev, type: value }));
    };

    useEffect(() => {
        setReportData(progressData);
    }, [progressData]);

    useEffect(() => {
        if (params) {
            setChartLink('');
            //setSelected("ChartSection");
            fetchProgressData(params);
            switch (params.chartfor) {
                case 'discom':
                    setHeadingTitle('District wise Progress');
                    setChartLink(`/ls-districtwise-data?stateid=${params.state}&discomid=${params.id}&reportType=Districts&module=ls`);
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
                case 'state':
                    setChartLink(`/all-state-progress-loss?chartfor=discom&type=${params.type}&state=${params.id}`);
                    setHeadingTitle('Discom wise Progress');
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
                case 'all':
                    setChartLink(`/all-state-progress-loss?chartfor=state&type=${params.type}`);
                    setHeadingTitle('State wise Progress');
                    setFilterParams((prev: any) => { return { ...prev, query: [] } });
                    break;
            }
        }
    }, [params]);

    useEffect(() => {
        const sectionfor = new URLSearchParams(location.search).get('sectionfor');
        if (sectionfor == 'TableSection') { setSelected("TableSection"); }
        const type = new URLSearchParams(location.search).get('type');
        if (type == 'LT') {
            setDefaultVal('tot_LT');
            setRadioVal('LT Line - Total');
        }
        if (type == 'HT') {
            setDefaultVal('tot_HT');
            setRadioVal('HT Line - Total');
        }
        if (type == 'DTR') {
            setDefaultVal('tot_DTR');
            setRadioVal('DTR - Total');
        }
        if (type == 'SUB') {
            setDefaultVal('tot_SUB');
            setRadioVal('Substation - Total');
        }
        setParams({
            id: new URLSearchParams(location.search).get('id') || null,
            state: new URLSearchParams(location.search).get('state') || null,
            discom: new URLSearchParams(location.search).get('discom') || null,
            chartfor: new URLSearchParams(location.search).get('chartfor') || 'all',
            type: type
        });
    }, [location]);

    useEffect(() => {
        const opt = progressData.map((item: any) => {
            return { label: item.title, value: item.title };
        });
        opt.pop();
        setOptions(opt);
    }, [progressData]);

    useEffect(() => {
        (async () => {
            if (progressData.length > 0) {
                let filterData = JSON.parse(JSON.stringify(progressData));
                let lastrec = filterData.pop();
                if (filterParams.query.length > 0) {
                    filterData = await filterData.filter((element: any) => filterParams.query.some((item: any) => item.value === element.title));
                }
                filterData.sort((a: any, b: any) => (a.title > b.title) ? 1 : -1);
                if(filterParams.sortBy == 'alphabetically') {
                    filterData.sort((a: any, b: any) => (a.title > b.title) ? 1 : -1);
                }
                else if (filterParams.sortBy == 'asc') {
                    filterData.sort((a: any, b: any) => (a.santion_items > b.santion_items) ? 1 : -1);
                } else {
                    filterData.sort((a: any, b: any) => (a.santion_items < b.santion_items) ? 1 : -1);
                }
                if ( filterParams.query.length == 0 )
                    filterData.push(lastrec);
                setReportData(filterData);
            }
        })();
    }, [progressData, filterParams]);

    const Content = () => {
        const linkState = (locationModal && (params && params.chartfor !== 'discom'))
            ? { state: { modal: locationModal } }
            : {};

        return <div className="container-fluid">
            <div className="dashboardInner detailedDashboard">
                <div className="row gx-xxl-5">
                    <div className="col-xxl-12 col-xl-12">
                        <div className="discomSection">
                            <div className="discomRight">
                                <div className="dashChart">
                                    <div className="DiscomSummary">
                                        <div className="row align-items-center">
                                            <div className="col-md-2">
                                                <select className="form-select" onChange={sortBy}>
                                                    <option value={"alphabetically"} selected={filterParams.sortBy === "alphabetically"}>A-Z</option>
                                                    <option value={"asc"} selected={filterParams.sortBy === "asc"}>Ascending</option>
                                                    <option value={"desc"} selected={filterParams.sortBy === "desc"}>Descending</option>
                                                </select>
                                            </div>
                                            <div className="col-md-7">
                                                <Select
                                                    isMulti={true}
                                                    options={options}
                                                    onChange={filterQuery}
                                                    value={filterParams.query}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <ul className="list-unstyled d-flex switch-field justify-content-end">
                                                    <li className="position-relative">
                                                        <input type="radio" value="ChartSection" id="ChartSection" checked={selected === "ChartSection"} onChange={changeHandler} />
                                                        <label htmlFor="ChartSection"><BsBarChart /></label>
                                                    </li>
                                                    <li className="position-relative">
                                                        <input type="radio" value="TableSection" id="TableSection" checked={selected === "TableSection"} onChange={changeHandler} />
                                                        <label htmlFor="TableSection"><BsTable /></label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center radioSelect">
                                            {radioOptions.map((option: any) => (
                                                <div className='form-check me-3'>
                                                    <input
                                                        id={option.value}
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={option.value}
                                                        checked={defaultVal === option.value}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <label htmlFor={option.value} className="form-check-label" key={option.value}>
                                                        {option.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>

                                        <div aria-hidden={selected !== "ChartSection" ? true : false}>
                                            {(selected == "ChartSection") && <ProgressDetailsMainChart progressData={reportData} noDataMessage={noDataMessage} chartLink={chartLink} ptype={params?.type} headingTitle={headingTitle} />}
                                        </div>
                                        <div aria-hidden={selected !== "TableSection" ? true : false}>
                                            <div className="d-flex justify-content-end mt-2">
                                                <div className="pdfBox" onClick={() => exportToPDF()}><BsFileEarmarkPdf /></div>
                                                <div className="excelBox bg-success" onClick={() => exportToExcel()}><RiFileExcel2Line /></div>
                                            </div>
                                            <div className="table-responsive progressDetailsTable">
                                                <table className="table-responsive RevenueDetailsTable PqTable mx-auto" id="progress-data-loss">
                                                    <thead className="text-center">
                                                        <tr className="d-none show-pdf">
                                                            <th colSpan={6} className="text-start">
                                                                <h6 className="mb-2 text-start">
                                                                    {(params?.type.match(/_LT$/) || params?.type == 'LT') ? headingTitle + ' - LT lines' : ''}
                                                                    {(params?.type.match(/_HT$/) || params?.type == 'HT') ? headingTitle + ' - HT lines(%) (Installed/ Sanctioned)' : ''}
                                                                    {(params?.type.match(/_DTR$/) || params?.type == 'DTR') ? headingTitle + ' - DTRs' : ''}
                                                                    {(params?.type.match(/_SUB$/) || params?.type == 'SUB') ? headingTitle + ' - Substations' : ''}
                                                                </h6>
                                                                <span>{radioval}</span>
                                                                <p className="text-end"> {new Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'medium', timeZone: 'Asia/Kolkata' }).format(new Date())}</p>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th className="text-start">
                                                                {params?.chartfor == 'all' ? 'State' : ''}
                                                                {params?.chartfor == 'state' ? 'Discom' : ''}
                                                                {params?.chartfor == 'discom' ? 'District' : ''}
                                                            </th>
                                                            {(params?.type.match(/_LT$/) || params?.type == 'LT' || (params?.type.match(/_HT$/) || params?.type == 'HT')) ? <th>Sanctioned (Ckms.)</th> : <th>Sanctioned (Nos.)</th>}
                                                            {(params?.type.match(/_LT$/) || params?.type == 'LT' || (params?.type.match(/_HT$/) || params?.type == 'HT')) ? <th>Awarded (Ckms.)</th> : <th>Awarded (Nos.)</th>}
                                                            {(params?.type.match(/_LT$/) || params?.type == 'LT' || (params?.type.match(/_HT$/) || params?.type == 'HT')) ? <th>Installed (Ckms.)</th> : <th>Installed (Nos.)</th>}
                                                            <th>Physical Progress % <br /> (Installed/Sanctioned)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-center">
                                                        {
                                                            (reportData.length <= 0)
                                                                ? <tr><td colSpan={6} className="text-center">Loading...</td></tr>
                                                                : null
                                                        }
                                                        {
                                                            reportData.map((item: any, index: any) => (
                                                                <tr key={index}>
                                                                    <td>{item.title == 'Total..' ? '' : srNo++}</td>
                                                                    { item.title == 'Total..' ? <td className="text-start">{item.title}</td> : <td className="text-start"><Link to={chartLink + '&id=' + item.id + '&sectionfor=TableSection'} {...linkState}>{item.title}</Link></td>}
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.santion_items)}</td>
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.award_items)}</td>
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.installed_items)}</td>
                                                                    <td className="text-center">{new Intl.NumberFormat('en-IN', { currency: 'INR', maximumFractionDigits: 2 }).format(item.progress)}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <h4 className="mb-0 text-end stickyBack"><a onClick={() => navigate(-1)} className="text-decoration-none text-dark"><em className="arrow"><BiArrowBack onClick={() => navigate(-1)} /></em> Go Back</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    };
    return (
        <>
            {
                (locationModal)
                    ? (
                        <Modal show={true} onHide={() => navigate(-1)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {(params?.type.match(/_LT$/) || params?.type == 'LT') ? headingTitle + ' - LT lines' : ''}
                                    {(params?.type.match(/_HT$/) || params?.type == 'HT') ? headingTitle + ' - HT lines(%) (Installed/ Sanctioned)' : ''}
                                    {(params?.type.match(/_DTR$/) || params?.type == 'DTR') ? headingTitle + ' - DTRs' : ''}
                                    {(params?.type.match(/_SUB$/) || params?.type == 'SUB') ? headingTitle + ' - Substations' : ''}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Content />
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                    )
                    : (
                        <div className="mainWrap dashboard">
                            <DashboardHeader />
                            <Content />
                            <DashboardFooter remove='d-none' />
                        </div>
                    )
            }
        </>
    );
};

export default AllStateProgressViewLoss;