import React, { useEffect, useRef, useState } from "react";
import { Http } from '../../../../../Core/Services/HttpService';
import Select from "react-select";
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineSearch } from "react-icons/ai";
import { fyByStartEnd, yearByStartEnd } from '../../../../../Core/Utility/Datetime';
import { useStateMounted } from '../../../../../Core/Hooks';

const LrCumulativeGrantDistrictReports = () => {

    let currentYearValue = new Date().getFullYear();
    const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const d = new Date();
    if (d.getMonth() > 2) {
        currentYearValue++;
    }
    let currentMonthValue = months[d.getMonth()];

    const [fundReleasedData, setFundReleasedData] = useState<any>();
    const [selectedDiscom, setSelectedDiscom] = useState<any>(0);
    const [reportType, setReportType] = useState<any>('Discom');
    const [exportData, setexportData] = useState<any>('');
    const [misReportDataCount, setMisReportDataCount] = useState<any>(0);
    const [searchText, setSearchText] = useState('');
    const [_discomid, setDiscomid] = useState<any>(0);
    const [backlink, setBackLink] = useState<any>(null);
    const [nodalAgency, SetNodalAgency] = useState<any>(null);
    const [discomList, setDiscomList] = useState([{ 'id': 0, 'discom_name': 'Select' }]);
    const [districtList, setDistrictList] = useState([{ 'id': 0, 'district_name': 'All' }]);
    const [year, setYear] = useState<any>(2021);
    const [years, setYears] = useStateMounted<[{ label: string, value: string }] | []>([]);

    const location = useLocation();
    let navigate = useNavigate();

    // For pagination
    const [currentPage, setCurrentPage] = useState<any>(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(pageSize);
    const [pageInput, setPageInput] = useState(1);
    const [currentFYGgRelMopTotal, setCurrentFYGgRelMopTotal] = useState<any>(0);
    const [currentFYGoiGrantLapsedTotal, setCurrentFYGoiGrantLapsedTotal] = useState<any>(0);
    const [currentFYNetGoiDisbursementTotal, setCurrentFYNetGoiDisbursementTotal] = useState<any>(0);
    const [cumulativeFYGgRelMopTotal, setCumulativeFYGgRelMopTotal] = useState<any>(0);
    const [cumulativeFYGoiGrantLapsedTotal, setCumulativeFYGoiGrantLapsedTotal] = useState<any>(0);
    const [cumulativeFYNetGoiDisbursementTotal, setCumulativeFYNetGoiDisbursementTotal] = useState<any>(0);
    const [selectedDistrict, setSelectedDistrict] = useState<any>(0);
    const [selectedDiscomValue, setSelectedDiscomValue] = useState<any>(0);

    useEffect(() => {
        (async () => {
            const fyearList: any = fyByStartEnd("2021", new Date().getFullYear().toString());
            await fyearList.map((item: any) => {
                item.label = `${item.label} - ${(parseInt(item.label) + 1)}`;
                return item;
            });
            setYears(fyearList);
        })();
    }, [year, nodalAgency]);

    const handleYearSelect = (evt?: any) => {
        setCurrentPage(0);
        setYear(evt.target.value);
    };

    const handleDiscomSelect = (selectedDiscom: any) => {
        setSelectedDistrict(0);
        setCurrentPage(0);
        setSelectedDiscom(selectedDiscom);
        setSelectedDiscomValue(selectedDiscom.value);
    }

    const handleDistrictSelect = (selectedDistrict: any) => {
        setCurrentPage(0);
        setSelectedDistrict(selectedDistrict);
    };

    const handleFirstClick = () => {
        setCurrentPage(1);
        setFirst(1);
        setLast(pageSize);
    };

    const handleLastClick = () => {
        setCurrentPage(totalPages);
        setFirst((totalPages - 1) * pageSize + 1);
        setLast(totalPages * pageSize);
    };

    const handlePageChange = (event: any) => {
        if (isNaN(event.target.value)) {
            setPageInput(1);
            setCurrentPage(1);
            setFirst(1);
            setLast(pageSize);
        } else {
            if (event.target.value > totalPages) {
                setPageInput(totalPages);
                setCurrentPage(totalPages);
            } else if (event.target.value < 1) {
                setPageInput(1);
                setCurrentPage(1);
            } else {
                setPageInput(event.target.value);
                setCurrentPage(event.target.value);
            }
        }
    };

    const handleGoClick = (e: any) => {
        if (e.keyCode === 13) {
            const pageNumber = pageInput;
            if (pageNumber >= 1 && pageNumber <= totalPages) {
                setCurrentPage(pageNumber);
                setFirst((pageNumber - 1) * pageSize + 1);
                setLast(Math.min(totalItems, pageNumber * pageSize));
            }
        }
    };

    const fetchDiscomData = async () => {
        if (year && nodalAgency) {
            await Http.get("/apiv1/report-lr-nodel-discom-list/?nodal_agency=" + nodalAgency)
                .then((res) => {
                    setDiscomList(res.discomList);
                }).catch((e) => {
                    console.log(e);
                });
        }
    }

    useEffect(() => {
        fetchDiscomData();
    }, [year, nodalAgency])

    const handleSearchTextChange = (event: any) => {
        setSearchText(event.target.value);
    }

    useEffect(() => {
        (async () => {
            if (location.state) {
                console.log(location.state);
                setDiscomid(location.state.discomid);
                SetNodalAgency(location.state.nodal_agency);
            } else {
                navigate('/nodal-view');
            }
        })();
    }, []);

    const fetchFundReleasedData = async () => {
        const curPage = (currentPage != 0) ? currentPage - 1 : 0;
        await Http.get('/apiv1/report-lr-district-cum-grant-data?discomid=' + selectedDiscomValue + '&distid=' + selectedDistrict.value + '&year=' + year + '&searchtext=' + searchText + '&page=' + curPage + '&pageSize=' + pageSize)
            .then((res) => {
                setMisReportDataCount(res.fundReleasedData.length);
                setFundReleasedData(res.fundReleasedData);
                setTotalItems(res.totalRecord);
                setPageCount(Math.ceil(res.totalRecord / pageSize));
                setTotalPages(Math.ceil(res.totalRecord / pageSize));
                setCurrentFYGgRelMopTotal(res.current_fY_gg_rel_mop);
                setCurrentFYGoiGrantLapsedTotal(res.current_fY_goi_grant_lapsed);
                setCurrentFYNetGoiDisbursementTotal(res.current_fY_net_goi_disbursement);
                setCumulativeFYGgRelMopTotal(res.cumulative_fY_gg_rel_mop);
                setCumulativeFYGoiGrantLapsedTotal(res.cumulative_fY_goi_grant_lapsed);
                setCumulativeFYNetGoiDisbursementTotal(res.cumulative_fY_net_goi_disbursement)
                let cPage = (!currentPage) ? 1 : currentPage;
                setFirst((cPage - 1) * pageSize + 1);
                setLast(Math.min(res.totalRecord, cPage * pageSize));

            }).catch((e) => {
                console.log(e);
            });
    }

    useEffect(() => {
        if (currentPage != 0) {
            fetchFundReleasedData();
        }
    }, [currentPage, searchText]);

    useEffect(() => {
        if (year && nodalAgency) {
            fetchFundReleasedData();
        }
    }, [year, nodalAgency])


    const fetchReportDataDistrict = async () => {
        if (reportType == 'Districts') {
            await Http.get('/apiv1/misdistrict')
                .then((res) => {
                    setSelectedDistrict(0);
                    setDistrictList(res.districtsList);
                }).catch((e) => {
                    console.log(e);
                });
        } else {
            setDistrictList([]);
            setSelectedDistrict(0);
        }
    }

    useEffect(() => {
        fetchReportDataDistrict();
    }, [reportType])

    const fetchDistrict = async () => {
        if (selectedDiscomValue > 0) {
            await Http.get('/apiv1/report-lr-cum-discom-district?discomid=' + selectedDiscomValue)
                .then((res) => {
                    setDistrictList(res.districtsList);
                }).catch((e) => {
                    console.log(e);
                });
        } else {
            setDistrictList([]);
            setSelectedDistrict(0);
        }
    }

    useEffect(() => {
        fetchDistrict();
    }, [selectedDiscom]);

    function toFixed(amt: any, decimal: number = 0) {
        amt = `${amt}`;
        let hasCondition = amt.includes("mark");
        if (hasCondition) {
            amt = amt.replace(/<\/?[^>]+(>|$)/g, "");
        }
        amt = (amt + "").replace(/[^\-.0-9]+/g, '');
        if (amt == '') amt = decimal == 0 ? 0 : "0.00";

        amt = new Intl.NumberFormat("en-IN", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        }).format(amt);
        if (hasCondition) {
            amt = "<mark class='d-inline-block'>" + amt + "</mark>";
        }
        return amt;
    }

    const back = () => {
        setBackLink('/nodal-view#FundReleased');
    }

    useEffect(() => {
        (async () => {
            if (backlink) {
                navigate(backlink, { state: { discomid: _discomid, module_type: 'LossReduction', module_sub_type: 'LrGrantReleased' } });
            }
        })();
    }, [backlink]);

    return (
        <>
            <div className="container mb-5 mt-3">
                <div className="dashboardInner detailedDashboard">
                    <div className="row gx-xxl-5">
                        <div className="col-xxl-12 col-xl-12">
                            <div className="heading mb-4">
                                <div className="row align-items-center">
                                    <div className="col-md-12 d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0">Fund Released Reports - Loss Reduction - Cumulative Grant - District</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-end">
                    <div className='col-md-3'>
                        <label htmlFor="discom" className="form-label">Select Year (FY)</label>
                        <select
                                onChange={handleYearSelect}
                                value={year}
                                className="form-select misReportSelect" id="year" name="year" aria-label="Select Year (FY)">
                                {
                                    years.map((year, index) => {
                                        return (
                                            <option key={index} value={year.value}>{year.label}</option>
                                        )
                                    })
                                }
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="discom" className="form-label">Select Discom</label>
                        <Select id="discom" className="misReportSelect" aria-label="Discom" onChange={handleDiscomSelect} isSearchable={true} options={discomList} value={selectedDiscom} />
                    </div>
                    <div className="col-md-3">
                        <div className="">
                            <label htmlFor="districts" className="form-label">Select Districts</label>
                            <Select id="districts" className="misReportSelect" aria-label="Districts" onChange={handleDistrictSelect} isSearchable={true} options={districtList} value={selectedDistrict} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="d-flex justify-content-between align-items-center misBottomBtn search-hover">
                            <button className="bsSearchBtn mt-0" onClick={fetchFundReleasedData}><AiOutlineSearch className="pl-3 mb-0 h5 text-light" /></button>
                            <div className="dIcnBtn">
                                <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/report-lr-district-cum-grant-data?discomid=' + selectedDiscomValue + '&distid=' + selectedDistrict.value + '&year=' + year + '&reportType=' + reportType + '&searchtext=' + searchText + '&task=excel'}><AiOutlineFileExcel className="pl-3 mb-0 h5 text-info" /></a>
                                <a target="_blank" href={process.env.REACT_APP_API_ENDPOINT + '/apiv1/report-lr-district-cum-grant-data?discomid=' + selectedDiscomValue + '&distid=' + selectedDistrict.value + '&year=' + year + '&reportType=' + reportType + '&searchtext=' + searchText + '&task=pdf'}><AiOutlineFilePdf className="pl-3 mb-0 h5 text-warning" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="financialForm misNew">
                    <div className="mt-4">
                        <div>Displaying {first} to {last} of {totalItems}</div>
                        <div className="mt-2 financialMainFrom misReportTable">
                            <div className="tab-content p-0 border-0">
                                <div className="table-responsive RevenueDetailsTable misreportTable">
                                    <table className="table">
                                        <thead className='text-center'>
                                            <tr>
                                                <th rowSpan={2}>S. No.</th>
                                                <th rowSpan={2}>State</th>
                                                <th rowSpan={2}>Utility</th>
                                                <th rowSpan={2}>District/Project</th>
                                                <th rowSpan={2}>Scheme</th>
                                                <th colSpan={3}><span>Current FY</span></th>
                                                <th colSpan={3}><span>Cumulative till Date</span></th>
                                            </tr>
                                            <tr>
                                                <th className="fw-normal">GoI Grant released by MoP</th>
                                                <th className="fw-normal">GoI Grant Lapsed (INR)</th>
                                                <th className="fw-normal">Net GoI Grant Disbursement</th>
                                                <th className="fw-normal">GoI Grant released by MoP (INR)</th>
                                                <th className="fw-normal">GoI Grant Lapsed</th>
                                                <th className="fw-normal">Net GoI Grant Disbursement</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {misReportDataCount > 0 && fundReleasedData.length > 0 ? fundReleasedData.map((releasedData: any, index: any) => {
                                                return <tr>
                                                    <td>{index + 1}</td>
                                                    <td dangerouslySetInnerHTML={{ __html: releasedData.state }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: releasedData.utility }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: releasedData.district_project }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: releasedData.scheme }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: toFixed(releasedData.current_fY_gg_rel_mop,2) }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: toFixed(releasedData.current_fY_goi_grant_lapsed,2) }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: toFixed(releasedData.current_fY_net_goi_disbursement,2) }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: toFixed(releasedData.cumulative_fY_gg_rel_mop,2) }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: toFixed(releasedData.cumulative_fY_goi_grant_lapsed,2) }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: toFixed(releasedData.cumulative_fY_net_goi_disbursement,2) }}></td>
                                                </tr>
                                            }) : <tr><td colSpan={10} className="text-center my-5"><h5>No Record Found</h5></td></tr>}

                                            {misReportDataCount > 0 && fundReleasedData.length > 0 ?
                                                <tr>
                                                    <td colSpan={5} className="text-center"><strong>Grand Total</strong></td>
                                                    <td className="text-center"><strong>{toFixed(currentFYGgRelMopTotal,2)}</strong></td>
                                                    <td className="text-center"><strong>{toFixed(currentFYGoiGrantLapsedTotal,2)}</strong></td>
                                                    <td className="text-center"><strong>{toFixed(currentFYNetGoiDisbursementTotal,2)}</strong></td>
                                                    <td className="text-center"><strong>{toFixed(cumulativeFYGgRelMopTotal,2)}</strong></td>
                                                    <td className="text-center"><strong>{toFixed(cumulativeFYGoiGrantLapsedTotal,2)}</strong></td>
                                                    <td className="text-center"><strong>{toFixed(cumulativeFYNetGoiDisbursementTotal,2)}</strong></td>
                                                </tr>
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {totalItems != 0 ?
                                    <div className="mt-3">
                                        <nav aria-label="Page navigation g-3 d-flex justify-content-center">
                                            <ul className="pagination justify-content-center">
                                                <li className="page-item">
                                                    <button className="page-link" onClick={handleFirstClick} disabled={(currentPage === 1 || currentPage === 0)}>
                                                        <BsChevronDoubleLeft />
                                                    </button>
                                                </li>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => setCurrentPage(parseInt(currentPage) - 1)} disabled={(currentPage === 1 || currentPage === 0)}>
                                                        <BsChevronCompactLeft />
                                                    </button>
                                                </li>
                                                <li className="page-item col-1">
                                                    <input type="input" className="form-control" value={(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} onChange={handlePageChange} onKeyDown={handleGoClick}></input>
                                                </li>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={() => setCurrentPage((currentPage === 0 ? parseInt(currentPage) + 1 : parseInt(currentPage)) + 1)} disabled={currentPage === totalPages}>
                                                        <BsChevronCompactRight />
                                                    </button>
                                                </li>
                                                <li className="page-item">
                                                    <button className="page-link" onClick={handleLastClick} disabled={currentPage === totalPages}>
                                                        <BsChevronDoubleRight />
                                                    </button>
                                                </li>
                                                {/* <li className="page-item">
                                                <span>
                                                    Page {currentPage} of {totalPages}. Showing {first} to {last} of {totalItems} entries.
                                                </span>
                                                <span>
                                                    Go to page: <input className="form-control" type="text" value={pageInput} onChange={handlePageChange} />
                                                    <button className="page-link" onClick={handleGoClick}>Go</button>
                                                </span>
                                            </li> */}
                                            </ul>
                                        </nav>
                                        <div className="page-item text-center">
                                            <span>
                                                Page {(currentPage === 0) ? parseInt(currentPage) + 1 : currentPage} of {totalPages}
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <div className="formBtnSection justify-content-end mt-4">
                    <Link to="#" className="btn btnSubmit me-4" onClick={() => back()}>Back</Link>
                </div>
            </div>
        </>
    );
}

export default LrCumulativeGrantDistrictReports;